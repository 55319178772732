import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as AntMessage } from 'antd';
import * as newsService from 'app/services/news';
import * as newsTypes from 'app/types/news';

export const fetchNews = createAsyncThunk('news/fetchNews', async () => {
  const { data, success, message } = await newsService.getNews();
  if (success) {
    return data;
  } else {
    throw new Error(message);
  }
});
export const getTypesNews = createAsyncThunk('news/getTypesNews', async () => {
  const { data, success, message } = await newsService.getTypesNews();
  if (success) {
    return data;
  } else {
    throw new Error(message);
  }
});
export const fetchNewsItem = createAsyncThunk(
  'news/fetchNewsItem',
  async (id: string) => {
    const { data, success, message } = await newsService.getNewsItem(id);
    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  },
);

export const updateNews = createAsyncThunk(
  'news/updateNews',
  async (updateData: newsTypes.UpdateNewsData) => {
    const { data, success, message } = await newsService.sendUpdateNews(
      updateData,
    );
    if (success) {
      AntMessage.success(message);
      return data;
    } else {
      throw new Error(message);
    }
  },
);
export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (news: newsTypes.News) => {
    const { success, message } = await newsService.sendDeleteNews(news.slug);
    if (success) {
      AntMessage.success('Новость успешно удалена!');
      return news;
    } else {
      throw new Error(message);
    }
  },
);

export const addNews = createAsyncThunk(
  'news/addNews',
  async (news: newsTypes.NewsForm) => {
    const { data, success, message } = await newsService.sendAddNews(news);
    if (success) {
      AntMessage.success(message);
      return data;
    } else {
      throw new Error(message);
    }
  },
);
