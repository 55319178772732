import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { CoilResiduesService } from 'app/services/admin/coilResidues';
import * as types from '../../types/coilResidues';

export const fetchCoilResidues = createAsyncThunk(
  'coilResidues/fetchCoilResidues',
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await CoilResiduesService.getMany(
      search,
    );
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchResiduesTypes = createAsyncThunk(
  'typesOfCoilResidues/fetchResiduesTypes',
  async (_, { rejectWithValue }) => {
    const { data, success, message } = await CoilResiduesService.getTypes();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const createOrUpdateCoilResidue = createAsyncThunk(
  'coilResidues/createOrUpdateCoilResidue',
  async (payload: types.CreateOrUpdateResidueDto, { rejectWithValue }) => {
    const { data, success, message } = await CoilResiduesService.createOrUpdate(
      payload,
    );
    if (success && data) {
      //TODO: Исправить, когда бэк будет присылать сообщение об успешном добавлении
      antdMessage.success('Остатки упешно обновлены');
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteCoilResidue = createAsyncThunk(
  'coilResidues/deleteCoilResidue',
  async (
    { data: residue, residueTypeIndex }: types.DeleteResidueDto,
    { rejectWithValue },
  ) => {
    if (residue != null) {
      const residueType = residue.types[residueTypeIndex];
      const { success, message } = await CoilResiduesService.delete(
        residue.id,
        {
          productType: residueType.productType?.code ?? null,
          withCoating: residueType.withCoating,
        },
      );
      if (success) {
        antdMessage.success(message);
        return { data: residue, residueTypeIndex };
      } else {
        antdMessage.error(message);
        return rejectWithValue({ data: residue, residueTypeIndex });
      }
    }
    return null;
  },
);
