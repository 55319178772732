import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { setMetals as $setMetals, clearVisibleLastOrders as $clearVisibleLastOrders } from './slice';
import * as types from 'app/types/cart';
import { useSyncTabs } from 'hooks/useSyncTabs';
import { useIsRole } from 'hooks/useIsRole';
import { useMemo } from 'react';

export const useCart = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.cart);
  const metalsHandbook = useSelector((state: RootState) => state.auth.metals);
  const { syncAnyTabs } = useSyncTabs();

  const { hasParentUser, actionsWithRemainderInCart } = useSelector((state: RootState) => state.auth);
  const isManager = useIsRole('manager');
  const isAdmin = useIsRole('admin');
  const isAdminOrManager = useMemo(() => isAdmin || isManager || hasParentUser, [isAdmin, isManager, hasParentUser]);

  const checkCart = (options: types.CheckCartData) => {
    dispatch(actions.checkCart(options));
  };

  const fetchCart = () => {
    dispatch(actions.fetchCart());
  };

  const addToCart = async (product: types.AddingProduct) => {
    const result = await dispatch(actions.addToCart(product));
    syncAnyTabs('cart');

    return result;
  };

  const removeToCart = async (action: types.UpdateCartPayload) => {
    const result = await dispatch(actions.removeToCart({ action, isAdminOrManager }));
    syncAnyTabs('cart');

    return result;
  };

  const updateToCart = async (action: types.UpdateCartPayload) => {
    const result = await dispatch(actions.updateToCart({ action, isAdminOrManager }));
    syncAnyTabs('cart');

    return result;
  };

  const snapshotCart = async () => {
    const result = await dispatch(actions.snapshotCart());
    syncAnyTabs('cart');

    return result;
  };

  const getDifferenceCartOrders = async (payload: types.CartDifferenceOptions) => {
    const result = await dispatch(actions.getDifferenceCartOrders(payload));
    syncAnyTabs('cart');

    return result;
  };

  const transferTo = async (payload: types.TransferPosition) => {
    const result = await dispatch(actions.transferTo(payload));
    syncAnyTabs('cart');

    return result;
  };

  const fetchCartExcel = (orderType: types.OrderType) => {
    dispatch(actions.fetchCartExcel(orderType));
  };

  const fetchCartImportExcel = (payload: { orderType: types.OrderType; metals?: number[] }) => {
    dispatch(actions.fetchCartImportExcel(payload));
  };

  const unionCoilAndFinished = async (isUnion: boolean) => {
    const result = await dispatch(actions.unionCoilAndFinished(isUnion));
    syncAnyTabs('cart');

    return result;
  };

  const checkoutOrder = async (options: types.OrderOptions) => {
    const resultAction = await dispatch(actions.checkoutOrder(options));
    return actions.checkoutOrder.fulfilled.match(resultAction);
  };

  const setMetals = (payload: number[]) => {
    dispatch($setMetals(payload));
  };

  const clearVisibleLastOrders = () => {
    dispatch($clearVisibleLastOrders());
  };

  return {
    state,
    metalsHandbook,
    actionsWithRemainderInCart,
    checkCart,
    fetchCart,
    addToCart,
    removeToCart,
    updateToCart,
    transferTo,
    fetchCartExcel,
    fetchCartImportExcel,
    unionCoilAndFinished,
    checkoutOrder,
    setMetals,
    clearVisibleLastOrders,
    snapshotCart,
    getDifferenceCartOrders,
  };
};
