import React, { useEffect } from 'react';
import { Title } from '../../Title';
import { Button } from '../../KrasUIKit';
import { MODAL_NAME } from '../../Modals/ActRequest';
import { useModal } from 'app/ducks/modals';
import { Acts as ActsInterface } from 'app/types/documents';
import { date } from 'helpers/format';
import { File } from './File';
import { useTranslation } from 'react-i18next';
import { actionTypes, sendEvent } from 'logger';

export interface Props {
  getActs: () => void;
  acts: ActsInterface;
}

export const Acts: React.FC<Props> = ({ acts, getActs }) => {
  const { t } = useTranslation(['personal']);
  useEffect(() => {
    getActs();
  }, []);
  const { showModal } = useModal(MODAL_NAME);
  return (
    <>
      <Title upper level={3}>
        {t('actsReconciliationSettlements')}
      </Title>
      <Button
        type="primary"
        onClick={() => {
          showModal();
          sendEvent(actionTypes.DOCUMENTS_CLICK_ACT);
        }}
      >
        {t('requestReconciliationStatement')}
      </Button>
      {acts.map(({ contractId, requests }) => (
        <div key={contractId}>
          <Title level={4} style={{ marginTop: 30 }}>
            {t('layout:contract')} {contractId}
          </Title>
          {requests.map(({ dateFrom, dateTo, tessaDocumentId }, index) => {
            return (
              <File
                name={t('actReconciliation')}
                uuid={tessaDocumentId}
                description={`${date(dateFrom)} - ${date(dateTo)}`}
                key={index}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};
