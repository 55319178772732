import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert as KrasAlert } from 'components/KrasUIKit/components/Alert';
import { warning } from 'components/KrasUIKit/icons';
import { ValidationListItem } from './ValidationListItem';
import { Rule } from 'hooks/useValidation';

export type Props = {
  rules: Rule<string>[];
  validCodes: string[];
};

export const AlertPasswordRequirements: React.FC<Props> = ({
  rules,
  validCodes,
}) => {
  const { t } = useTranslation('reset');

  return (
    <KrasAlert
      style={{ width: '100%', marginBottom: 20 }}
      icon={warning}
      title={`${t('passwordRequirements')}:`}
      description={
        <ul style={{ padding: '0 1.5em' }}>
          {rules.map(({ code }) => {
            return (
              <ValidationListItem
                key={code}
                text={t(`reset:validation:${code}`)}
                isValid={validCodes.includes(code)}
              />
            );
          })}
        </ul>
      }
      alertType="warning"
    />
  );
};
