import styled from 'styled-components';

export const DocumentTitle = styled.span``;

export const DocumentLink = styled.a`
  display: inline-block;
  padding: 6px 15px;
  color: #4f4f4f;
  border: 1px solid #4f4f4f;
  border-radius: 50px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 14px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
`;
