import React, { Fragment } from 'react';
import * as S from './styled';
import { cart, waiting } from '../../icons';
import { IconButton } from '.././IconButton';
import { Tag } from '../Tag';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'features/AdditionalProducts';

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: string;
  subTitle?: JSX.Element | string | null;
  description?: JSX.Element | string | null;
  tags?: JSX.Element[];
  imageTags?: React.ReactNode[];
  countInBasket?: number;
  countInWaitingList?: number;
  disabled?: boolean;
  preview?: string;
  horizontal?: boolean;
  viaClaim?: boolean;
}

export const Card: React.SFC<Props> = ({
  title,
  subTitle,
  description,
  tags,
  imageTags,
  disabled,
  countInBasket = 0,
  countInWaitingList = 0,
  preview,
  horizontal,
  viaClaim,
}) => {
  const { t, i18n } = useTranslation('tags');
  const { language } = i18n;
  const isRu = language === 'ru';
  return (
    <S.Card horizontal={horizontal} disabled={disabled}>
      <S.Image horizontal={horizontal}>
        {tags && !horizontal && imageTags && (
          <S.Tags horizontal={horizontal}>
            {imageTags.map((imageTag, index) => (
              <Tag key={index} type="primary" size="medium" color="#fff" fillColor="#d77721">
                {imageTag}
              </Tag>
            ))}
          </S.Tags>
        )}
        <img src={preview} alt={title} />
      </S.Image>
      <S.Horizontal horizontal={horizontal}>
        {tags && horizontal && (
          <S.Tags horizontal={horizontal}>
            {imageTags &&
              imageTags.map((imageTag, index) => (
                <Tag key={index} type="primary" size="medium" color="#fff" fillColor="#d77721">
                  {imageTag}
                </Tag>
              ))}
            {tags.map((tag, index) => (
              <Fragment key={index}>{tag}</Fragment>
            ))}
          </S.Tags>
        )}
        <S.Description horizontal={horizontal} disabled={disabled}>
          {description}
        </S.Description>
        {isRu && subTitle && <S.SubTitle disabled={disabled}>{subTitle}&nbsp;/ </S.SubTitle>}

        <S.Title disabled={disabled}>{title}</S.Title>
        <S.Container horizontal={horizontal}>
          {tags && !horizontal && (
            <S.Tags>
              {tags.map((tag, index) => (
                <Fragment key={index}>{tag}</Fragment>
              ))}
            </S.Tags>
          )}
          {countInBasket > 0 && (
            <S.Label>
              <S.Icon>
                <IconButton disabled={disabled}>{cart}</IconButton>
              </S.Icon>
              <S.Count disabled={disabled}>{countInBasket} шт</S.Count>
            </S.Label>
          )}
          {countInWaitingList > 0 && (
            <S.Label>
              <S.Icon>
                <IconButton disabled={disabled}>{waiting}</IconButton>
              </S.Icon>
              <S.Count disabled={disabled}>{countInWaitingList} шт</S.Count>
            </S.Label>
          )}
          {viaClaim && (
            <Tag type="primary" size="medium" color="#4f4f4f" fillColor="#f4f4f4" disabled={disabled}>
              По заявке
              <Tooltip noMarginRight>Заказ будет оформлен после согласования с менеджером</Tooltip>
            </Tag>
          )}
        </S.Container>
      </S.Horizontal>
    </S.Card>
  );
};
