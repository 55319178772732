/* eslint-disable prettier/prettier */
import { Offers, Offer } from '../../app/types/products';
import { DetailOrderType } from '.';
import { OrderTypeCart } from 'containers/ProductDetailModalContainer';
import { WITHOUT_COVERING_ID } from './Content';
import round from 'lodash.round';

interface TotalOrder {
  count: number;
  weight: number;
}

export const getOfferById = (id: number, offers: Offers) => {
  return offers.find(({ id: offerId }) => offerId === id);
};

export const getOffersByFilters = (offers: Offers, filters: [keyof Offer, number | null][]) => {
  if (!filters.length) return offers;
  return offers.filter(offer => {
    const getOfferValueByKey = (key: keyof Offer) => offer[key];

    return filters.every(([key, value]) => {
      // return offer[key] && offer[key] === value;
      return getOfferValueByKey(key) === value;
    });
  });
};

export const getOrderCountByCovering = (id: number, order: OrderTypeCart | null, offers: Offers): number => {
  if (!order) return 0;

  const { coil = 0, custom = [], ...ids } = order;
  const count = Object.entries(ids).reduce<number>((final, item) => {
    const [offerId, count] = item;
    const offer = getOfferById(parseInt(offerId), offers);

    if (!offer) return final;

    return offer.coveringId === id ? final + count : final;
  }, 0);

  const coilCount = WITHOUT_COVERING_ID === id && coil > 0 ? 1 : 0;
  return (
    count +
    coilCount +
    custom.filter(({ coating_id: coatingId }) => coatingId === id).reduce((sum, { count }) => sum + count, 0)
  );
};

export type CustomOffer = {
  length: number;
  weight: number;
  count: number;
  coatingId: number;
  id: number;
};

export type GetCustomWeightArgs = {
  offers: CustomOffer[];
  chainMinLength?: number;
  activeCovering: number | null;
};

export const getCustomWeight = ({ offers, chainMinLength = 0, activeCovering }: GetCustomWeightArgs) => {
  const result = {
    total: 0,
    weightsByType: {
      chain: 0,
      band: 0,
    },
  };

  if (!offers.length || !activeCovering) return result;

  const filteredOffers = offers.filter(offer =>
    activeCovering === WITHOUT_COVERING_ID
      ? offer.coatingId === WITHOUT_COVERING_ID
      : offer.coatingId !== WITHOUT_COVERING_ID,
  );

  const customTotalWeight = filteredOffers.reduce((sum, offer): number => {
    const { weight, count } = offer;
    return sum + round(weight, 2) * count;
  }, 0);

  const customWeightsMap = filteredOffers.reduce(
    (sum, offer) => {
      const { length } = offer;
      return {
        chain: length >= chainMinLength ? sum.chain : 0,
        band: length < chainMinLength ? sum.band : 0,
      };
    },
    {
      chain: customTotalWeight,
      band: customTotalWeight,
    },
  );

  result.total = customTotalWeight;
  result.weightsByType = customWeightsMap;

  return result;
};

export type OrderTotal = {
  order: OrderTypeCart | null;
  offers: Offers;
  chainMinLength: number;
  type?: 'chain' | 'band';
  orderType: DetailOrderType;
  activeCovering: number | null;
};

export const getOrderTotal = ({ order, offers, chainMinLength, type, orderType, activeCovering }: OrderTotal) => {
  const defaultOrderTotal = {
    count: 0,
    weight: 0,
  };
  if (!order) return defaultOrderTotal;

  const { coil = 0, custom = [], ...ids } = order;

  const finishedTotal = Object.entries(ids).reduce<TotalOrder>((sum, [id, offerCount]: [string, number]) => {
    const offer = getOfferById(parseInt(id), offers);
    return {
      count: sum.count + offerCount,
      weight: sum.weight + offerCount * (offer?.weight ?? 0),
    };
  }, defaultOrderTotal);

  const { total: customTotalWeight, weightsByType: customWeightsMap } = getCustomWeight({
    offers: custom.map(offer => ({
      id: offer.offer_id,
      count: offer.count,
      length: offer.length,
      weight: offer.weight,
      coatingId: offer.coating_id,
    })),
    chainMinLength,
    activeCovering,
  });

  const customWeight = orderType === 'products_coil' && type ? customWeightsMap[type] : customTotalWeight;

  const totalWeight = finishedTotal.weight + customWeight + coil;
  const coilCount = coil > 0 ? 1 : 0;
  const customCount = custom.reduce((sum, offer) => {
    const { count } = offer;

    return sum + count;
  }, 0);

  return {
    count: finishedTotal.count + customCount + coilCount,
    weight: totalWeight,
  };
};
