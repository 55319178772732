import { useRef } from 'react';
/**
 * Хук возвращает функцию отложенного вызова с возможностью прерывания на основе состояния из вне.
 * Параметры функции debounceByOpen:
 * @param action функция
 * @param open состояние. true - запускает таймер, false - отменяет вызов
 * @param delay задержка. По умолчанию = 300ms
 */
export const useDebounceByOpen = () => {
  const timerRef = useRef<number | null>(null);

  const debounceByOpen = (action: () => void, open: boolean, delay: number | undefined = 300) => {
    if (open) {
      timerRef.current = setTimeout(() => {
        action();
      }, delay);
    }

    if (!open && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  return {
    debounceByOpen,
  };
};
