import React from 'react';
import * as S from './Aside.styled';
import { Button } from 'components/KrasUIKit';
import { Popconfirm, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { date, number } from 'helpers/format';
import { AutoOrderDetailEntity } from 'app/types/autoOrders';
import { actionTypes, sendEvent } from 'logger';

const { Text } = Typography;

type AsideProps = {
  onCancelOrder: () => void;
} & Pick<
  AutoOrderDetailEntity,
  'createdAt' | 'user' | 'productType' | 'knfmpNumber' | 'countAll' | 'weightAll' | 'status' | 'comment' | 'metalName'
>;

export const Aside = ({
  createdAt,
  user,
  productType,
  knfmpNumber,
  countAll,
  weightAll,
  status,
  comment,
  metalName,
  onCancelOrder,
}: AsideProps) => {
  const cancelHandler = () => {
    onCancelOrder();
    sendEvent(actionTypes.O2C_ORDER_DETAIL_CANCEL);
  };

  return (
    <div>
      <S.Wrapper>
        <Text>
          <CalendarOutlined /> {date(createdAt)}
        </Text>
        <div>
          <S.TextWrapper>
            <S.SecondaryText>Имя пользователя</S.SecondaryText>
            <S.Text>{user}</S.Text>
          </S.TextWrapper>
          <S.TextWrapper>
            <S.SecondaryText>Тип готовой продукции</S.SecondaryText>
            <S.Text>{productType}</S.Text>
          </S.TextWrapper>
          <S.TextWrapper>
            <S.SecondaryText>Металл</S.SecondaryText>
            <S.Text>{metalName}</S.Text>
          </S.TextWrapper>
          <S.TextWrapper>
            <S.SecondaryText>Договор</S.SecondaryText>
            <S.Text>{knfmpNumber}</S.Text>
          </S.TextWrapper>
        </div>
        {comment && (
          <S.TextWrapper>
            <S.SecondaryText>Комментарий к заказу</S.SecondaryText>
            <S.Text>{comment}</S.Text>
          </S.TextWrapper>
        )}
        <S.TextWrapper>
          <S.SecondaryText>Общее кол-во / вес</S.SecondaryText>
          <S.Text>
            {countAll} шт / {number(weightAll)} г
          </S.Text>
        </S.TextWrapper>
        <S.TextWrapper>
          <S.SecondaryText>Статус</S.SecondaryText>
          <S.Text>{status.title}</S.Text>
        </S.TextWrapper>
      </S.Wrapper>
      {status.code === 'processing' && (
        <Popconfirm title="Подтвердить действие?" onConfirm={cancelHandler}>
          <Button style={{ marginTop: 16, width: '100%' }}>Остановить автозаказ</Button>
        </Popconfirm>
      )}
    </div>
  );
};
