import React from 'react';
import { fileOutlined } from '../../icons';
import * as S from './styled';
import { Button } from '../Button';

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  name: string;
  description?: string;
  size?: string;
  disabled?: boolean;
  onDownload?: () => void;
  href?: string;
  buttonText?: string;
}

export const File: React.FC<Props> = ({
  name,
  description,
  size,
  disabled,
  onDownload,
  href,
  buttonText = 'Скачать',
  ...props
}) => {
  return (
    <S.File {...props} disabled={!disabled ? true : false}>
      <S.Field>
        <S.Icon>{fileOutlined}</S.Icon>
        <S.Content disabled={!disabled ? true : false}>
          <S.Name>{name}</S.Name>
          <S.Description disabled={!disabled ? true : false}>{description}</S.Description>
        </S.Content>
      </S.Field>
      <S.Size disabled={!disabled ? true : false}>{size}</S.Size>
      <Button
        href={href}
        disabled={disabled}
        onClick={onDownload}
        rel={href ? 'noopener noreferrer' : undefined}
        target={href ? '_blank' : undefined}
      >
        {buttonText}
      </Button>
    </S.File>
  );
};
