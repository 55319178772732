import {
  createSlice,
  PayloadAction,
  AnyAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { RoleEntity, Permission } from 'app/types/roles';
import * as actions from './actions';

export const rolesAdapter = createEntityAdapter<
  RoleEntity & { loading?: boolean; removing?: boolean }
>();

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('roles/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('roles/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('roles/') && action.type.endsWith('/fulfilled');

const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesAdapter.getInitialState<{
    loading?: boolean;
    permission: Permission[];
    lastUpdatedRole?: number | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({
    loading: false,
    permission: [],
    lastUpdatedRole: null,
  }),
  reducers: {
    editStart(state, { payload }: PayloadAction<number>) {
      rolesAdapter.updateOne(state, {
        id: payload,
        changes: { loading: true },
      });
      state.lastUpdatedRole = payload;
    },
    editEnd(state) {
      state.lastUpdatedRole = null;
    },
    removeStart(state, { payload }: PayloadAction<number>) {
      rolesAdapter.updateOne(state, {
        id: payload,
        changes: { removing: true },
      });
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.getRoles.pending, state => {
      state.loading = true;
    });

    addCase(actions.getRoles.fulfilled, (state, action) => {
      const { payload } = action;
      rolesAdapter.setAll(state, payload);
      state.loading = false;
    });

    addCase(actions.getRoles.rejected, state => {
      state.loading = false;
    });

    addCase(actions.getPermissions.fulfilled, (state, action) => {
      const { payload } = action;
      state.permission = payload;
    });

    addCase(actions.getRoleDetail.fulfilled, (state, { payload }) => {
      rolesAdapter.upsertOne(state, { ...payload, loading: false });
      state.lastUpdatedRole = payload.id;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return (
          isPendingAction(action) ||
          isRejectedAction(action) ||
          isFulFilledAction(action)
        );
      },
      (state, action) => {
        const isLoading = action.type.endsWith('/pending');
        const isRejected = action.type.endsWith('/rejected');
        const requestName = action.type.split('/')[0];

        state[requestName] = isLoading;
        if (isRejected) antdMessage.error(action.error.message);
      },
    );
  },
});

export const { editStart, editEnd, removeStart } = rolesSlice.actions;
export default rolesSlice.reducer;
