import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { date } from 'helpers/format';
import * as S from '../styled/Table.styled';

type TrackingProps = {
  dateRelease: string;
  dateCreate: string;
  showReleaseDate: boolean | '' | null;
};

export const Tracking = ({ dateRelease, dateCreate, showReleaseDate }: TrackingProps) => {
  const { t } = useTranslation(['personal']);
  return (
    <Fragment>
      {dateCreate ? (
        <Fragment>
          <S.SecondaryText>{t('orderDate')}</S.SecondaryText>
          <S.MainText>{date(dateCreate)}</S.MainText>
        </Fragment>
      ) : (
        '-'
      )}
      {showReleaseDate && (
        <S.MainText>
          {t('orderCompletionDate')}{' '}
          <Tooltip title={t('personal:orderDateTooltip')}>
            <QuestionCircleOutlined />
          </Tooltip>{' '}
          : {date(dateRelease)}
        </S.MainText>
      )}
    </Fragment>
  );
};
