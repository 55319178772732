/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';

const isArray = function (a: any): boolean {
  return Array.isArray(a);
};

const isObject = function (o: any): boolean {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const keysToCamel = function (o: any): any {
  if (isObject(o)) {
    const n: Record<string, object[]> = {};

    Object.keys(o).forEach((k: string) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getBasicHeader = (): AxiosRequestConfig => ({
  headers: {
    Authorization:
      'Bearer ' +
      (sessionStorage.getItem('jwtAccess') ||
        localStorage.getItem('jwtAccess')),
  },
});
