import { WeightInCoilInCart } from './../../../../app/types/products';
import { THookProps } from '../types';
import { CoilProductionPower, CoilProductionPowerMode, CoilProductionPowerValue } from 'app/types/products';
import { assertNever } from 'helpers/typeChecks';
import { useTranslation } from 'react-i18next';
import { DetailOrderType } from 'components/ProductDetail';
import { useMemo } from 'react';

export type EmptyResidues = {
  balance: number;
  balanceTitle: string;
  balanceUnit: string;
};

export type Overall = {
  overall: number;
};

export type TypeAndCoatingDividedResidues = {
  withCoating: {
    chain: number;
    band: number;
  };
  withoutCoating: {
    chain: number;
    band: number;
  };
};

export type CoatingDividedResidues = {
  withCoating: number;
  withoutCoating: number;
};

export type TypeDevidedResidues = {
  chain: number;
  band: number;
};

export type Residues =
  | { mode: CoilProductionPowerMode.EMPTY; residues: EmptyResidues }
  | { mode: CoilProductionPowerMode.OVERALL; residues: Overall }
  | {
      mode: CoilProductionPowerMode.TYPE_AND_COATING;
      residues: TypeAndCoatingDividedResidues;
    }
  | {
      mode: CoilProductionPowerMode.ONLY_COATING;
      residues: CoatingDividedResidues;
    }
  | { mode: CoilProductionPowerMode.ONLY_TYPE; residues: TypeDevidedResidues };

export const useResidues = (product: THookProps, orderType: DetailOrderType) => {
  const { t } = useTranslation();

  const getEmpty = () => ({
    mode: 0,
    residues: {
      balance: 0,
      balanceTitle: t('availableInCoils'),
      balanceUnit: t('units:g'),
    },
  });

  const getValueFromFiltered = (arr: CoilProductionPowerValue[]) => {
    const [{ value }] = arr;
    return value;
  };

  type GetOverallProps = {
    productionPower: number;
    weightInCoilInCart: WeightInCoilInCart;
  };

  const getOverall = ({ productionPower, weightInCoilInCart }: GetOverallProps) => {
    return {
      mode: 1,
      residues: {
        overall: productionPower - weightInCoilInCart.total,
      },
    };
  };

  type GetByTypeAndCoatingProps = {
    coilProductionPower: CoilProductionPower;
    weightInCoilInCart: WeightInCoilInCart;
  };

  const getByTypeAndCoating = ({ coilProductionPower, weightInCoilInCart }: GetByTypeAndCoatingProps) => {
    const { values } = coilProductionPower;
    const withCoating = values.filter(item => item.withCoating);
    const withoutCoating = values.filter(item => !item.withCoating);

    return {
      mode: 2,
      residues: {
        withCoating: {
          band: getValueFromFiltered(withCoating.filter(item => !item.isChain)) - weightInCoilInCart.band.withCoating,
          chain: getValueFromFiltered(withCoating.filter(item => item.isChain)) - weightInCoilInCart.chain.withCoating,
        },
        withoutCoating: {
          band:
            getValueFromFiltered(withoutCoating.filter(item => !item.isChain)) - weightInCoilInCart.band.withoutCoating,
          chain:
            getValueFromFiltered(withoutCoating.filter(item => item.isChain)) - weightInCoilInCart.chain.withoutCoating,
        },
      },
    };
  };

  type GetByCoatingProps = {
    coilProductionPower: CoilProductionPower;
    weightInCoilInCart: WeightInCoilInCart;
  };

  const getByCoating = ({ coilProductionPower, weightInCoilInCart }: GetByCoatingProps) => {
    const { values } = coilProductionPower;
    return {
      mode: 3,
      residues: {
        withCoating: getValueFromFiltered(values.filter(item => item.withCoating)) - weightInCoilInCart.withCoating,
        withoutCoating:
          getValueFromFiltered(values.filter(item => !item.withCoating)) - weightInCoilInCart.withoutCoating,
      },
    };
  };

  type GetByTypeProps = {
    coilProductionPower: CoilProductionPower;
    weightInCoilInCart: WeightInCoilInCart;
  };

  const getByType = ({ coilProductionPower, weightInCoilInCart }: GetByTypeProps) => {
    const { values } = coilProductionPower;
    return {
      mode: 4,
      residues: {
        chain: getValueFromFiltered(values.filter(item => item.isChain)) - weightInCoilInCart.chain.total,
        band: getValueFromFiltered(values.filter(item => !item.isChain)) - weightInCoilInCart.band.total,
      },
    };
  };

  const residues = useMemo((): Residues => {
    const {
      coilProductionPower: { mode },
      coilProductionPower,
      weightInCoilInCart,
    } = product;
    const productionPower = product.productionPower === null ? Infinity : product.productionPower;

    if (orderType === 'products_production') {
      return getOverall({ productionPower, weightInCoilInCart });
    }
    if (orderType === 'products_coil') {
      switch (mode) {
        case CoilProductionPowerMode.EMPTY:
          return getEmpty();
        case CoilProductionPowerMode.OVERALL:
          return getOverall({
            productionPower: coilProductionPower.values[0].value,
            weightInCoilInCart,
          });
        case CoilProductionPowerMode.TYPE_AND_COATING:
          return getByTypeAndCoating({
            coilProductionPower,
            weightInCoilInCart,
          });
        case CoilProductionPowerMode.ONLY_COATING:
          return getByCoating({ coilProductionPower, weightInCoilInCart });
        case CoilProductionPowerMode.ONLY_TYPE:
          return getByType({
            coilProductionPower,
            weightInCoilInCart,
          });
        default:
          return assertNever(mode);
      }
    }
    return getEmpty();
  }, [product, orderType]);

  return {
    residues,
  };
};
