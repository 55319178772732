import { useState } from 'react';

export const useButtonExpand = <T>(list: Array<T>, defaultCount = 3) => {
  const [visibleCount, setVisibleCount] = useState<number>(defaultCount);
  const isDefaultCount = visibleCount === defaultCount;
  const isButtonVisible = list.length > defaultCount;
  const filteredList = list.slice(0, visibleCount);

  const clickHandler = () => {
    setVisibleCount(isDefaultCount ? list.length : defaultCount);
  };

  return {
    isExpanded: !isDefaultCount,
    filteredList,
    isButtonVisible,
    toggleVisible: clickHandler,
  };
};
