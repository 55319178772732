import React, { useState, useEffect, useCallback } from 'react';
import { useAdditionalProducts } from 'app/ducks/addititionalProducts';
import { RootState } from 'app/store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Description } from '../Description';
import { Breadcrumb, Col, Row, Tabs } from 'antd';
import { ProductSlider } from 'components/ProductDetail/ProductSlider';
import { Title } from 'components/Title';
import { Tabs as UiKitTabs } from 'components/KrasUIKit';
import { Paragraph, Title as DescriptionTitle } from '../../styled/Common.styled';
import { FurnitureTabs } from './FurnitureTabs/FurnitureTabs';
import { setFittings } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { Footer } from './Footer';
import { CartContent } from './Сart/CartContent';
import { useForm } from 'features/AdditionalProducts/hooks/useForm';
import { AdditionalRequest, FormValues, MODAL_NAME } from 'features/AdditionalProducts/components/Rhodium/Modal';
import { useModal } from 'app/ducks/modals';
import { message as antdMessage } from 'antd';
import { Link } from 'react-router-dom';
import { Cart } from '../Cart';
import emptyPreview from 'images/png/tile-empty.png';
import { Image } from 'app/types/products';
import { useTranslation } from 'react-i18next';
import { sendReachGoal } from 'common/ymetrica/utils';

const YMETRICA_TARGET_NAME = 'otpravka_zayavka_furnitura';

interface ImagesStruct {
  [key: string]: {
    [key: string]: Image[];
  };
}

export const Furniture = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['additional']);
  const {
    state: {
      products,
      metadata: { fittings },
      loading,
      submitting,
    },
    requestAll,
    postClaim,
  } = useAdditionalProducts();
  const { showModal, visible } = useModal(MODAL_NAME);
  const { fitting } = useSelector((state: RootState) => state.additionalProductsOrder);
  const dispatch = useDispatch();
  const [showCart, setShowCart] = useState(false);
  const [productImages, setProductImages] = useState<Image[]>([]);

  const handleSubmit = async (values: FormValues) => {
    const additionalProducts: { id: number; alloyId: number; count: number }[] = [];
    const fittingGroups = Object.values(fitting);

    fittingGroups.forEach(fittingGroup => {
      const alloyGroups = Object.values(fittingGroup);
      alloyGroups.forEach(({ products }) => {
        products.forEach(item => {
          if (item.count > 0) {
            const { id, alloyId, count } = item;
            additionalProducts.push({ id, alloyId, count });
          }
        });
      });
    });

    const newValues: FormValues = {
      ...values,
      additionalProducts: JSON.stringify(additionalProducts),
    };

    const data = await postClaim(newValues);

    if (data?.success) {
      showModal();
    } else {
      antdMessage.error(data?.message ?? 'Произошла ошибка при отправке формы');
    }
  };

  const form = useForm({ onSubmit: handleSubmit });

  const submitForm = useCallback(() => {
    form.formik.submitForm();
    sendReachGoal({
      targetName: YMETRICA_TARGET_NAME,
    });
  }, [form]);

  useEffect(() => {
    requestAll();
  }, []);

  useEffect(() => {
    if (!products) return;
    products
      .filter(product => fittings.includes(product.type) && product.active)
      .forEach(product => {
        product.alloys.forEach(alloy => {
          dispatch(
            setFittings({
              alloy,
              fittingType: product.type,
              item: {
                ...product,
                alloyId: alloy.id,
                count: 0,
                weight: 0,
              },
            }),
          );
        });
      });
  }, [products]);

  const availableProductTypes = Object.keys(fitting);

  const images: ImagesStruct = {};

  availableProductTypes.forEach(key => {
    images[key] = {};
    Object.values(fitting[key]).forEach(({ alloy, products }) => {
      if (!images[key][alloy.id]) {
        images[key][alloy.id] = [];
      }
      products.forEach(product => {
        const { imagePath } = product.alloys.filter(productAlloy => productAlloy.id === alloy.id)[0];
        images[key][alloy.id].push({ preview: imagePath || emptyPreview, detail: imagePath || '' });
      });
    });
  });

  const handleChangeTab = (productType: string, alloyId: string | number) => {
    setProductImages(images[productType][alloyId] ?? []);
  };

  const primaryTabs = [
    {
      id: 1,
      title: t('orderTab'),
      content: <FurnitureTabs onTabChange={handleChangeTab} />,
    },
    {
      id: 2,
      title: t('descriptionTab'),
      content: (() => {
        const barProducts = products.filter(
          product => fittings.includes(product.type) && product.ruDescription !== null && product.active,
        );
        return barProducts.length > 0 ? (
          <Description>
            {barProducts.map(product => (
              <>
                <DescriptionTitle>
                  {t('descriptionFor')} {language === 'ru' ? product.ruTitle : product.enTitle}
                </DescriptionTitle>
                <Paragraph>{language === 'ru' ? product.ruDescription : product.enDescription}</Paragraph>
              </>
            ))}
          </Description>
        ) : (
          <DescriptionTitle style={{ textAlign: 'center' }}>{t('noDescription')}</DescriptionTitle>
        );
      })(),
    },
  ];

  const breadcrumbs = () => (
    <Breadcrumb separator="/">
      <Breadcrumb.Item>
        <Link to="/catalog">{t('products')}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{t('semifinished')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('furniture')}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <div>
      <Row gutter={50}>
        {!showCart ? (
          <>
            <Col md={8} style={{ minHeight: 'calc(100vh - 150px)' }}>
              <ProductSlider
                list={productImages.length > 0 ? productImages.slice(1) : []}
                main={productImages.length > 0 ? productImages[0] : { preview: emptyPreview, detail: '' }}
              />
            </Col>
            <Col md={16}>
              {breadcrumbs()}
              <Title upper level={4}>
                {t('furniture')}
              </Title>
              <Tabs>
                {primaryTabs.map(tab => (
                  <Tabs.TabPane tab={tab.title} key={tab.id}>
                    {tab.content}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </>
        ) : (
          <Cart title="Ювелирная фурнитура" breadcrumbs={breadcrumbs()} goBack={() => setShowCart(false)}>
            <CartContent formRowProps={form.formRowProps} />
          </Cart>
        )}
      </Row>
      {products.length && (
        <Footer
          cartVisible={showCart}
          onСlick={() => {
            !showCart ? setShowCart(true) : submitForm();
          }}
          type="fittings"
        />
      )}
      <AdditionalRequest loading={false} showSuccess={true} visible={visible} ymTargetName={YMETRICA_TARGET_NAME} />
    </div>
  );
};
