import { createAsyncThunk } from '@reduxjs/toolkit';
import download from 'downloadjs';
import * as OrdersApi from 'app/services/orders';

export const fetchOrders = createAsyncThunk('personalOrders/fetchOrders', async (search: string) => {
  const { data, message, success } = await OrdersApi.getOrders(search);
  if (success) return data;
  else throw new Error(message);
});

export const fetchOrder = createAsyncThunk('personalOrders/fetchOrder', async (id: number) => {
  const { data, message, success } = await OrdersApi.getOrder(id);
  if (success) return data;
  else throw new Error(message);
});

export const fetchOrderExcel = createAsyncThunk('personalOrders/fetchOrderExcel', async (id: string) => {
  const { data, message, success } = await OrdersApi.getOrderExcel(id);
  if (success) download(data.data, data.name, data.mimeType);
  else throw new Error(message);
});

export const fetchOrderImportExcel = createAsyncThunk('personalOrders/fetchOrderImportExcel', async (id: string) => {
  const { data, message, success } = await OrdersApi.getOrderImportExcel(id);
  if (success) download(data.data, data.name, data.mimeType);
  else throw new Error(message);
});

export const fetchForecastExcel = createAsyncThunk('personalOrders/fetchForecastExcel', async () => {
  const { data, message, success } = await OrdersApi.getForecastExcel();
  if (success) download(data.data, data.name);
  else throw new Error(message);
});
