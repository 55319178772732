import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import * as types from 'app/types/managers';
import { Pagination } from 'app/types/pagination';
import { message as antMessage } from 'antd';
import * as actions from './actions';

export interface ManagersSliceProps {
  loading: boolean;
  managers: types.Manager[];
  pagination?: Pagination;
}

export const companyInitialState: ManagersSliceProps = {
  loading: true,
  managers: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('managers/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('managers/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('managers/') && action.type.endsWith('/fulfilled');

const ManagersSlice = createSlice({
  name: 'managers',
  initialState: companyInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchManagers.fulfilled,
      (state, { payload }: PayloadAction<types.ManagersResponse>) => {
        state.managers = payload.items;
        state.pagination = payload.pagination;
      },
    );
    addCase(
      actions.removeCompanyRelation.fulfilled,
      (state, { payload }: PayloadAction<types.Manager>) => {
        const index = state.managers.findIndex(item => item.id === payload.id);
        state.managers[index] = payload;
      },
    );
    addCase(
      actions.addCompanyRelation.fulfilled,
      (state, { payload }: PayloadAction<types.Manager>) => {
        const index = state.managers.findIndex(item => item.id === payload.id);
        state.managers[index] = payload;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) antMessage.error(action.error.message);
        state.loading = false;
      },
    );
  },
});
export default ManagersSlice.reducer;
