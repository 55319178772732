import React from 'react';
import * as S from './styled';
import { AdditionalProductParam } from '../../app/types/products';

export type Props = AdditionalProductParam;

export const Property: React.FC<Props> = ({ value, name }) => {
  return (
    <S.Property>
      <S.PropertyValue>{name}</S.PropertyValue>
      <S.PropertyDivider />
      <S.PropertyValue>{value}</S.PropertyValue>
    </S.Property>
  );
};
