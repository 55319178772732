import React from 'react';
import { Feedback, MODAL_NAME } from '../components/Modals/Feedback';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { Feedback as FeedbackType } from '../app/types/feedback';
import { useModal } from 'app/ducks/modals';
import { useFeedback } from 'app/ducks/feedback';

export const FeedbackFormContainer: React.FC<{}> = () => {
  const authStore = useSelector((state: RootState) => state.auth);
  const { state: feedbackStore, saveFeedback } = useFeedback();

  const { visible, hideModal } = useModal(MODAL_NAME);
  const submitHandler = async (values: FeedbackType) => {
    const resultSubmitHandler = await saveFeedback(values);
    if (resultSubmitHandler) hideModal();
  };

  return (
    <Feedback
      onSubmit={submitHandler}
      loading={feedbackStore.loading}
      visible={visible}
      hideModal={hideModal}
      user={authStore.user}
      company={authStore.company}
    />
  );
};
