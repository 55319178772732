import styled from 'styled-components';

export const Slider = styled.div`
  position: relative;
  padding: 2px 0;
  width: 100%;
  height: 28px;
  border-radius: 40px;
  overflow: hidden;
`;

export const Rail = styled.div`
  position: absolute;
  width: 100%;
  height: 24px;
  background: #f4d4bf;
  border-radius: 50px;
`;

export const Track = styled.div<{ width: number }>`
  position: absolute;
  width: ${({ width }) => width}%;
  height: 24px;
  background: #d67f3c;
  border-radius: 40px;
`;

export const Point = styled.div<{ translateX: number }>`
  position: absolute;
  top: -2px;
  right: 0;
  padding: 6px 8px;
  width: fit-content;
  min-width: 44px;
  height: 28px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background: #d67f3c;
  box-shadow: 1px 0px 1px rgba(184, 108, 49, 0.25), -1px 0px 1px #b86c31;
  border-radius: 40px;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
  z-index: 2;
`;

export const ScaleMark = styled.div<{ left: number; mark?: boolean }>`
  position: absolute;
  display: inline-block;
  left: ${({ left }) => left}%;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  text-align: end;
  transform: ${({ left }) => (left > 0 ? 'translateX(-100%)' : 'translateX(0)')};
  white-space: nowrap;

  &::after {
    display: ${({ mark = true }) => (mark ? 'block' : 'none')};
    position: absolute;
    content: '';
    bottom: 100%;
    left: 100%;
    width: 4px;
    height: 40px;
    background-color: #fff;
  }
  b {
    display: block;
    color: #212529;
  }
  span {
    color: #4f4f4f;
  }
`;

export const MarksContainer = styled.div`
  position: relative;
  margin-top: 4px;

  ${ScaleMark}:first-child {
    position: relative;
  }
`;
