import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ImportsApi from 'app/services/imports';
import { fetchCart } from 'app/ducks/cart';
import { ImportForm } from 'app/types/imports';

export const fetchContinueImportFile = createAsyncThunk(
  'imports/fetchContinueImportFile',
  async (values: ImportForm, { dispatch }) => {
    const { success } = await ImportsApi.executeImportFile(values);
    await dispatch(fetchCart());
    if (success) return;
    else throw new Error('Ошибка сервера!');
  },
);
