import styled from 'styled-components';
import { Button } from 'antd';
import { Button as ButtonUiKit } from 'components/KrasUIKit/components/Button';

export const Header = styled.header`
  min-width: 1000px;
`;

export const MainNavContainer = styled.div<{ auth: boolean }>`
  font-size: 1rem;
  background-color: #4f4f4f;
  padding: 0 1em 0 2.5em;
  display: flex;
  display: grid;
  grid-template-columns: ${({ auth }) => (auth ? 'auto 1fr auto auto auto' : 'auto 1fr auto auto')};
  align-items: center;
  grid-column-gap: 0.5em;
  min-height: 4em;
`;

export const TopNavContainer = styled.div<{ auth: boolean; isRu: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  padding: ${({ auth }) => (auth ? '12px 40px' : '2.14em 2.857em 0.85em 2.857em')};
  align-items: ${({ auth }) => (auth ? 'end' : 'center')};

  @media screen and (max-width: 1050px) {
    padding: 12px 10px;
  }
`;

export const TopGroup = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 16px;

  &:first-child {
    margin-right: 1em;
  }

  @media screen and (max-width: 1250px) {
    grid-column-gap: 8px;
  }
`;

export const CompanyInformation = styled.div`
  &&& {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* @media (max-width: 992px) {
      display: none;
    } */
  }
`;

export const MenuItemIcon = styled.span`
  font-size: 22px;
  margin-right: 4px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg path {
    transition: fill 0.5s ease;
  }
`;

export const MenuItemSuffix = styled.div`
  margin-left: 8px;
  color: #4f4f4f;
  background-color: #fff;
  padding: 2px 6px;
  border-radius: 50px;
  font-family: --var(--ff);
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  min-width: 22px;
  text-align: center;
  transition: all 0.2s linear;
`;

export const NavigationWrap = styled.div`
  display: flex;
  align-items: end;
`;

export const LangChanger = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1100px) {
    font-size: 0.875em;
  }
`;

export const LangButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  padding: 8px 12px;
  width: 2.9em;
  color: #4f4f4f;
  line-height: 16px;
  background: ${({ active }) => (active ? '#F4F4F4' : 'transparent')};
  border: 0;
  border-radius: 3px;
  transition: 0.25s ease;
  cursor: pointer;

  &:focus-visible,
  &:active {
    color: ${({ active }) => (active ? '#212529' : '#4F4F4F')} !important;
  }
  &:hover {
    color: ${({ active }) => (active ? '#4F4F4F' : '#767676')};
  }
  &:hover,
  &:focus-visible,
  &:active {
    background: ${({ active }) => (active ? '#e0e0e0' : 'transparent')};
  }
`;

export const TopNavButton = styled(Button)`
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    border-right: 1px solid #59585b;
  }
`;

export const IconLetter = styled.span<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? size : '1em')};
  height: ${({ size }) => (size ? size : '1em')};
  svg {
    width: 100%;
    height: 100%;
  }
`;

//TODO fix any, ошибка появилась после обновления @types/react@17.0.0
//Ошибка фиксится обновлением styled-components >=5v
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconLetterRifht = styled(IconLetter as any)`
  margin-left: 0.3em;
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconLetterLeft = styled(IconLetter as any)`
  margin-right: 0.3em;
`;

export const LogoWrap = styled.div`
  width: 8.1em;
  @media (max-width: 1217px) {
    width: 1.5em;
  }
`;

export const CartWrap = styled.div`
  color: #fff;
  border-left: 1px solid #e0e0e0;
  padding: 1em 0 1em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3em;
`;

export const CartCount = styled.span`
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const CartLabel = styled.div`
  font-size: 0.875rem;
  line-height: 18px;
`;

export const SearchDesctop = styled.div`
  width: 12.37em;
  @media (min-width: 1920px) {
    width: 17.2em;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

export const SearchMobile = styled.span`
  display: none;
  /* @media (max-width: 1199.98px) {
    display: inline-block;
  } */
`;

export const CartInfoWrap = styled.div`
  color: white;
  min-width: 15.1em;
  margin: 0.4em 0.5em;
`;

export const MetalOrdering = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 4fr auto 5fr;
  grid-column-gap: 0.57em;
  text-align: end;
`;

export const NameMetal = styled.span`
  text-align: left;
`;

export const ButtonInverseOutline = styled(ButtonUiKit)`
  &&& {
    color: white;
    margin-right: 0.57em;
    :hover,
    :focus,
    :active {
      color: var(--primary);
    }
  }
`;
