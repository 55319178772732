import React from 'react';
import { useTessaStorage } from 'hooks/useTessaStorage';
import { File as FileBlock } from '../../KrasUIKit';
import { useFilePathStorage } from 'hooks/useFilePathStorage';

export type Props = {
  name: string;
  description: string;
  uuid?: string;
  filePath?: string;
  buttonText?: string;
  disabled?: boolean;
};

export const File: React.FC<Props> = ({ name, uuid, description, buttonText, disabled }) => {
  const trigger = !!uuid ? useTessaStorage(uuid) : undefined;

  return (
    <FileBlock
      name={name}
      onDownload={trigger}
      style={{ marginBottom: 15 }}
      description={description}
      buttonText={buttonText}
      disabled={disabled}
    />
  );
};
