import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { actionTypes, sendEvent } from 'logger';
import * as S from './styled';

export interface CompaniesMenuProps {
  menu: React.ReactNode;
  companySearch: string;
  setCompanySearch: (company: string) => void;
  showNotFound?: boolean;
}

export const CompanyMenu = ({ menu, companySearch, showNotFound, setCompanySearch }: CompaniesMenuProps) => {
  const { t } = useTranslation(['layout']);

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCompanySearch(value);
  };

  return (
    <S.MenuWrap>
      <S.SearchCompanyInput
        placeholder={t('searchCompanyLabel')}
        suffix={<SearchOutlined />}
        value={companySearch}
        onChange={inputHandler}
        onBlur={() =>
          sendEvent({
            ActionType: actionTypes.HEADER_CHANGE_COMPANY_LOST_FOCUS,
            Query: companySearch,
          })
        }
      />
      {React.cloneElement(menu as React.ReactElement)}
      {showNotFound && <S.SerchNotFound>{t('main:searchNotFoundTitle')}</S.SerchNotFound>}
    </S.MenuWrap>
  );
};
