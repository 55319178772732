import React, { useState, useEffect } from 'react';
import { Breadcrumb, Col, Row, Spin, Tabs } from 'antd';
import { ProductSlider } from 'components/ProductDetail/ProductSlider';
import { Title } from 'components/Title';
import { Paragraph, Title as DescriptionTitle } from '../../styled/Common.styled';
import { useAdditionalProducts } from 'app/ducks/addititionalProducts';
import { RhodiumChars } from '../Characteristics';
import { Footer } from './Footer';
import { RhodiumOrder } from './RhodiumOrder';
import { Description } from '../Description';
import { AdditionalRequest, FormValues, MODAL_NAME } from './Modal';
import { useModal } from 'app/ducks/modals';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { Link } from 'react-router-dom';
import { Document } from '../Document';
import emptyPreview from 'images/png/tile-empty.png';
import { useForm } from 'features/AdditionalProducts/hooks/useForm';
import { useTranslation } from 'react-i18next';

const YMETRICA_TARGET_NAME = 'otpravka_zayavka_rodium';

type RhoduimTab = {
  id: number;
  content: JSX.Element;
  count?: number;
  title: string;
  image: string;
  productId: number;
};

export const Rhodium = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['additional']);
  const {
    state: { products, submitting },
    requestAll,
    postClaim,
  } = useAdditionalProducts();
  const authStore = useSelector((state: RootState) => state.auth);

  const { showModal, hideModal, visible } = useModal(MODAL_NAME);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [productTab, setProductTab] = useState<number>(1);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [tabs, setTabs] = useState<RhoduimTab[]>([]);

  useEffect(() => {
    requestAll();
  }, []);

  useEffect(() => {
    if (!products) return;

    const rhodiumProducts = products.filter(product => product.type === 'rhodium' && product.active);

    setTabs(
      rhodiumProducts.map((product, index) => ({
        id: index + 1,
        productId: product.id,
        title: language === 'ru' ? product.ruTitle : product.enTitle,
        count: 0,
        image: product.imagePath,
        content: <RhodiumChars list={product.features} />,
      })),
    );
  }, [products]);

  const handleSubmit = async (values: FormValues) => {
    const products = tabs
      .filter(tab => tab.count && tab.count > 0)
      .map(tab => ({ id: tab.productId, count: tab.count }));
    values.additionalProducts = JSON.stringify(products);
    const isSuccess: unknown = await postClaim(values);
    if (isSuccess) {
      setShowSuccess(true);
    } else {
      setShowSuccess(false);
    }
  };

  const form = useForm({ onSubmit: handleSubmit });

  const tabClickHandler = (id: number | string) => {
    setActiveTab(Number(id));
  };

  if (!products?.length) {
    return (
      <Spin
        spinning={true}
        style={{
          minHeight: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  const handleCountChange = (value: number): void => {
    setTabs(
      tabs.map(tab => {
        if (tab.id === productTab) {
          tab.count = value;
        }
        return tab;
      }),
    );
  };

  const primaryTabs = [
    {
      id: 1,
      title: t('orderTab'),
      content: <RhodiumOrder tabs={tabs} activeTab={productTab} onTabChange={setProductTab} />,
    },
    {
      id: 2,
      title: t('descriptionTab'),
      content: (() => {
        const rhodiumProducts = products.filter(
          product => product.type === 'rhodium' && product.ruDescription !== null && product.active,
        );
        return rhodiumProducts.length > 0 ? (
          <Description>
            {rhodiumProducts.map(product => (
              <>
                <DescriptionTitle>
                  {t('descriptionFor')} {language === 'ru' ? product.ruTitle : product.enTitle}
                </DescriptionTitle>
                <Paragraph>{language === 'ru' ? product.ruDescription : product.enDescription}</Paragraph>
              </>
            ))}
            <Document text="Техническая документация" link="/rhodium_tech_spec.pdf" />
          </Description>
        ) : (
          <DescriptionTitle style={{ textAlign: 'center' }}>{t('noDescription')}</DescriptionTitle>
        );
      })(),
    },
  ];

  const isFooterDisabled = (): boolean => {
    let disabled = true;
    tabs.forEach(tab => {
      if (tab.count && tab.count > 0) {
        disabled = false;
      }
    });
    return disabled;
  };

  const breadcrumbs = () => (
    <Breadcrumb separator="/">
      <Breadcrumb.Item>
        <Link to="/catalog">{t('products')}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{t('solute')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('rhodium')}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <div>
      <Row gutter={50}>
        <Col md={8} style={{ minHeight: 'calc(100vh - 150px)' }}>
          <ProductSlider
            list={[]}
            main={{
              preview: tabs[productTab - 1]?.image || emptyPreview,
              detail: tabs[productTab - 1]?.image,
            }}
            lazyLoad="ondemand"
          />
        </Col>
        <Col md={16}>
          {breadcrumbs()}
          <Title upper level={4}>
            {t('rhodium')}
          </Title>
          <Tabs onChange={tabClickHandler}>
            {primaryTabs.map(tab => (
              <Tabs.TabPane tab={tab.title} key={tab.id}>
                {tab.content}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>

        {tabs.length && (
          <Footer
            value={tabs[Number(productTab) - 1].count}
            onChange={handleCountChange}
            onSubmit={showModal}
            disabled={isFooterDisabled()}
          />
        )}
      </Row>

      <AdditionalRequest
        visible={visible}
        onSubmit={handleSubmit}
        hideModal={hideModal}
        form={form}
        company={authStore.company}
        loading={submitting}
        showSuccess={showSuccess}
        ymTargetName={YMETRICA_TARGET_NAME}
      />
    </div>
  );
};
