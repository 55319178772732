import React, { useState, useEffect, useCallback } from 'react';
import { useAdditionalProducts } from 'app/ducks/addititionalProducts';
import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';
import { Description } from '../Description';
import { Breadcrumb, Col, Row, Tabs } from 'antd';
import { ProductSlider } from 'components/ProductDetail/ProductSlider';
import { Title } from 'components/Title';
import { Paragraph, Title as DescriptionTitle } from '../../styled/Common.styled';
import { AdditionalProductAlloy, AdditionalProductItem } from 'app/types/adminAdditional';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { AlloysTabs } from './AlloysTabs';
import { Footer } from './Footer';
import { CartContent } from './Сart/CartContent';
import { useForm } from 'features/AdditionalProducts/hooks/useForm';
import { StripesItem } from 'features/AdditionalProducts/types/OrderItem';
import { AdditionalRequest, FormValues, MODAL_NAME } from 'features/AdditionalProducts/components/Rhodium/Modal';
import { useModal } from 'app/ducks/modals';
import { message as antdMessage } from 'antd';
import { Link } from 'react-router-dom';
import { Cart } from '../Cart';
import emptyPreview from 'images/png/tile-empty.png';
import { useTranslation } from 'react-i18next';
import { sendReachGoal } from 'common/ymetrica/utils';

const YMETRICA_TARGET_NAME = 'otpravka_zayavka_lenta';

export const Ribbons = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['additional']);
  const {
    state: { products, loading, submitting },
    requestAll,
    postClaim,
  } = useAdditionalProducts();
  const { showModal, visible } = useModal(MODAL_NAME);
  const { ribbon } = useSelector((state: RootState) => state.additionalProductsOrder);

  const [showCart, setShowCart] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [sortedByAlloy, setSortedByAlloy] = useState<ProductTab[]>([]);

  const handleSubmit = async (values: FormValues) => {
    const additionalProducts: StripesItem[] = [];

    const alloyGroups = Object.values(ribbon);
    alloyGroups.forEach(thickness => {
      const thicknessGroup = Object.values(thickness);
      thicknessGroup.forEach(thickness => {
        Object.values(thickness)
          .flat()
          .forEach(item => {
            if (item.weight > 0) {
              additionalProducts.push(item);
            }
          });
      });
    });
    const newValues: FormValues = {
      ...values,
      additionalProducts: JSON.stringify(additionalProducts),
    };

    const data = await postClaim(newValues);

    if (data?.success) {
      showModal();
    } else {
      antdMessage.error(data?.message ?? 'Произошла ошибка при отправке формы');
    }
  };

  const form = useForm({ onSubmit: handleSubmit });

  const submitForm = useCallback(() => {
    form.formik.submitForm();
    sendReachGoal({
      targetName: YMETRICA_TARGET_NAME,
    });
  }, [form]);

  useEffect(() => {
    requestAll();
  }, []);

  useEffect(() => {
    if (!products) return;
    const tabsStruct: { [key: number]: ProductTab } = {};

    products
      .filter(product => product.type === 'ribbon' && product.active)
      .forEach(product => {
        product.alloys.forEach(alloy => {
          if (!tabsStruct[alloy.id]) {
            tabsStruct[alloy.id] = {
              alloy,
              image: '',
              products: [],
            };
          }
          if (tabsStruct[alloy.id].image === '' && alloy.imagePath) {
            tabsStruct[alloy.id].image = alloy.imagePath;
          }
          tabsStruct[alloy.id].products.push(product);
        });
      });

    setSortedByAlloy(Object.values(tabsStruct));
  }, [products]);

  const primaryTabs = [
    {
      id: 1,
      title: t('orderTab'),
      content: <AlloysTabs sortedByAlloy={sortedByAlloy} onTabChange={setActiveTab} />,
    },
    {
      id: 2,
      title: t('descriptionTab'),
      content: (() => {
        const ribbonProducts = products.filter(
          product => product.type === 'ribbon' && product.ruDescription !== null && product.active,
        );
        return ribbonProducts.length > 0 ? (
          <Description>
            {ribbonProducts.map(product => (
              <>
                <DescriptionTitle>
                  {t('descriptionFor')} {language === 'ru' ? product.ruTitle : product.enTitle}
                </DescriptionTitle>
                <Paragraph>{language === 'ru' ? product.ruDescription : product.enDescription}</Paragraph>
              </>
            ))}
          </Description>
        ) : (
          <DescriptionTitle style={{ textAlign: 'center' }}>{t('noDescription')}</DescriptionTitle>
        );
      })(),
    },
  ];

  const breadcrumbs = () => (
    <Breadcrumb separator="/">
      <Breadcrumb.Item>
        <Link to="/catalog">{t('products')}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{t('semifinished')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('ribbons')}</Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <div>
      <Row gutter={50}>
        {!showCart ? (
          <>
            <Col md={8} style={{ minHeight: 'calc(100vh - 150px)' }}>
              <ProductSlider
                list={[]}
                main={{
                  preview: sortedByAlloy[activeTab - 1]?.image || emptyPreview,
                  detail: sortedByAlloy[activeTab - 1]?.image || '',
                }}
                lazyLoad="ondemand"
              />
            </Col>
            <Col md={16}>
              {breadcrumbs()}
              <Title upper level={4}>
                {t('ribbons')}
              </Title>
              <Tabs>
                {primaryTabs.map(tab => (
                  <Tabs.TabPane tab={tab.title} key={tab.id}>
                    {tab.content}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </>
        ) : (
          <Cart title={t('ribbons')} breadcrumbs={breadcrumbs()} goBack={() => setShowCart(false)}>
            <CartContent formRowProps={form.formRowProps} sortedByAlloy={sortedByAlloy} />
          </Cart>
        )}
      </Row>
      {products.length && (
        <Footer
          cartVisible={showCart}
          onСlick={() => {
            !showCart ? setShowCart(true) : submitForm();
          }}
          type="ribbon"
        />
      )}
      <AdditionalRequest loading={false} showSuccess={true} visible={visible} ymTargetName={YMETRICA_TARGET_NAME} />
    </div>
  );
};
