import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Balance, SortedBalance } from 'app/types/contracts';
import { date } from 'helpers/format';
import { MetalsBlock } from '../MetalsBlock/MetalsBlock';
import { refresh } from 'components/KrasUIKit/icons';
import { DropdownDate } from '../Dropdown/Dropdown';
import * as S from './styled';
import { Button } from 'components/KrasUIKit';
import { actionTypes, sendEvent } from 'logger';
import { useHistory } from 'react-router-dom';

export interface BalanceInfoProps {
  balance: Balance;
}

export const BalanceInfo: React.FC<BalanceInfoProps> = ({ balance }) => {
  const { t } = useTranslation(['layout']);
  const history = useHistory();

  const sortedBalance = balance.reduce<SortedBalance>(
    (sorted, { metal, metalFree, assaysFree, metalBids, assaysBids, updatedAt }) => {
      return {
        availableMetals: [...sorted.availableMetals, { metal, mass: +metalFree, assays: assaysFree }],
        productionMetals: [...sorted.productionMetals, { metal, mass: +metalBids, assays: assaysBids }],
        lastDate: new Date(updatedAt),
      };
    },
    {
      availableMetals: [],
      productionMetals: [],
      lastDate: null,
    },
  );

  return (
    <S.BalanceWrap>
      <MetalsBlock title={t('availableBalance')} metals={sortedBalance.availableMetals} />
      <MetalsBlock title={t('metalInWork')} metals={sortedBalance.productionMetals} />
      {sortedBalance.lastDate && (
        <DropdownDate>
          <S.RefreshIcon>{refresh}</S.RefreshIcon>
          <Trans
            i18nKey="balanceTitle"
            t={t}
            values={{
              date: date(sortedBalance.lastDate.toISOString()),
            }}
            components={[date]}
          />
        </DropdownDate>
      )}
      <Button
        type="primary"
        onClick={() => {
          sendEvent(actionTypes.METAL_REPORT_HEADER_CLICK);
          history.push('/personal/documents/metal-report');
        }}
        style={{ marginTop: 16 }}
      >
        Запросить отчет об израсходовании металлов
      </Button>
    </S.BalanceWrap>
  );
};
