import React from 'react';
import * as S from './SuccessRequest.styled';
import { successLarge } from 'components/KrasUIKit/icons/primary';

type SuccessRequestProps = {
  visible: boolean;
  onCancel: () => void;
};

export const SuccessRequest = ({ visible, onCancel }: SuccessRequestProps) => {
  return (
    <S.Modal open={visible} onCancel={onCancel} footer={null}>
      <S.SuccessIcon>{successLarge}</S.SuccessIcon>
      <S.TitleWrapper>
        <S.Title>Запрос на формирование отчёта отправлен</S.Title>
        <S.Description>
          Ваш запрос обрабатывается, через некоторое время он станет доступен на данной странице
        </S.Description>
      </S.TitleWrapper>
    </S.Modal>
  );
};
