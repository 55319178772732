import * as actions from './actions';
import { setProductDetail as $setProductDetail, saveOffers as $saveOffers } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as types from 'app/types/products';

export const useProducts = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.productsAdmin);
  const productBadges = useSelector((state: RootState) => state.auth.productBadges);

  const fetchProducts = (search: string) => {
    dispatch(actions.fetchProducts(search));
  };

  const updateOffer = async (offer: types.AdminOffer) => {
    const resultActionUpdateOffer = await dispatch(actions.updateOffer(offer));
    return actions.updateOffer.fulfilled.match(resultActionUpdateOffer);
  };
  const addOffer = async (offer: types.AdminOffer) => {
    const resultActionAddOffer = await dispatch(actions.addOffer(offer));
    return actions.addOffer.fulfilled.match(resultActionAddOffer);
  };

  const deleteOffer = (id: number) => {
    dispatch(actions.deleteOffer(id));
  };
  const getCoatings = () => {
    dispatch(actions.getCoatings());
  };
  const addProduct = (product: types.AdminProductsUpload) => {
    dispatch(actions.addProduct(product));
  };

  const updateProduct = (product: types.AdminProductsUpload) => {
    dispatch(actions.updateProduct(product));
  };

  const deleteProduct = (id: number) => {
    dispatch(actions.deleteProduct(id));
  };
  const getMetals = () => {
    dispatch(actions.getMetals());
  };
  const getFeatures = () => {
    dispatch(actions.getFeatures());
  };
  const getAlloys = () => {
    dispatch(actions.getAlloys());
  };
  const getOfferTypes = () => {
    dispatch(actions.getOfferTypes());
  };
  const getDetails = () => {
    dispatch(actions.getDetails());
  };
  const getAdditionalLists = () => {
    getCoatings();
    getMetals();
    getFeatures();
    getAlloys();
    getOfferTypes();
    getDetails();
  };
  const setProductDetail = (product: types.Detail) => {
    dispatch($setProductDetail(product));
  };
  const saveOffers = (offers: types.AdminOffer[]) => {
    dispatch($saveOffers(offers));
  };
  const setProductImage = async (params: types.ImageUpdate) => {
    const resultAction = await dispatch(actions.setProductImage(params));
    return actions.setProductImage.fulfilled.match(resultAction);
  };
  const setCoatingImage = async (params: types.ImageCoatingUpdate) => {
    const resultAction = await dispatch(actions.setCoatingImage(params));
    return actions.setCoatingImage.fulfilled.match(resultAction);
  };
  const setAdditionalImage = async (params: types.ImageAdditionalUpdate) => {
    const resultAction = await dispatch(actions.setAdditionalImage(params));
    return actions.setAdditionalImage.fulfilled.match(resultAction);
  };
  const deleteAdditionalImage = (params: types.ImageAdditionalDelete) => {
    dispatch(actions.deleteAdditionalImage(params));
  };

  return {
    state,
    productBadges,
    fetchProducts,
    updateOffer,
    deleteOffer,
    addProduct,
    updateProduct,
    getCoatings,
    deleteProduct,
    getMetals,
    getFeatures,
    getAlloys,
    setProductDetail,
    getOfferTypes,
    saveOffers,
    addOffer,
    setProductImage,
    setCoatingImage,
    getAdditionalLists,
    setAdditionalImage,
    deleteAdditionalImage,
    getDetails,
  };
};
