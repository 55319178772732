import axios from 'axios';
import { LOGGER_URL, PROD_HOSTNAME } from './constants';
import { getLoggerBasePayload } from './extractors';
import { LoggerPayload } from './types';

const allowLogger = () => {
  /* 
      Проверка прод версии на основе хардкода хостнейма плохое решение, 
      лучше перенести все это на бек и завязать за env
    */

  return (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname === PROD_HOSTNAME
  );
};

export const sendEvent = async (
  payload:
    | Omit<
        LoggerPayload,
        | 'SaltHash'
        | 'Connection'
        | 'Resolution'
        | 'isAdmin'
        | 'isManager'
        | 'BitrixId'
        | 'isMajor'
        | 'Channel'
      >
    | string,
) => {
  const basePayload = await getLoggerBasePayload();

  const data = {
    ...basePayload,
    ...(typeof payload === 'string' ? { ActionType: payload } : payload),
  };

  if (allowLogger()) {
    axios.post(LOGGER_URL, data);
  } else {
    console.table({ [data?.ActionType]: data });
  }
};
