import { Col, Row, Spin } from 'antd';
import { useAdditionalProducts } from 'app/ducks/addititionalProducts';
import { AdditionalProductFeature } from 'app/types/adminAdditional';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CharsItem, CharsName, CharsValue, CharsWrapper } from '../styled/Characteristics.styled';
import { Title } from '../styled/Common.styled';

const chars = [
  { name: 'Напряжение', value: '2-3 В' },
  { name: 'Содержание металла', value: '0,2% Rh' },
  { name: 'Толщина слоя', value: '0,1-0,3 мкм' },
  { name: 'Цвет', value: 'Блестящий белый' },
  { name: 'Рабочая температура', value: '40-45 °С\n(предпочтительнее 40 °С)' },
];

type RhodiumCharsProps = {
  list: AdditionalProductFeature[];
};

export const RhodiumChars = ({ list }: RhodiumCharsProps) => {
  const { t } = useTranslation(['additional']);
  const {
    state: { metadata },
  } = useAdditionalProducts();

  if (!metadata) return <Spin />;

  const { productsMeta } = metadata;

  const [{ features }] = productsMeta.filter(item => item.code === 'rhodium');

  return (
    <CharsWrapper>
      <Title>{t('characteristics')}</Title>
      <Row>
        {features.map((item, index) => (
          <Col key={item.code} md={(index + 1) % 5 === 0 ? 24 : 6}>
            <CharsItem elIndex={index % 5}>
              <CharsValue>{list[index].value}</CharsValue>
              <CharsName>{item.title}</CharsName>
            </CharsItem>
          </Col>
        ))}
      </Row>
    </CharsWrapper>
  );
};
