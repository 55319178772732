import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { useLocation, useParams } from 'react-router-dom';
import { getSearchWithCompanyId } from 'features/AutoOrders/utils';
import { useCart } from '../cart';

export const useAutoOrders = () => {
  const { id: paramsId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { company, contracts, metals } = useSelector((state: RootState) => ({
    contracts: state.contracts.contracts,
    company: state.auth.company,
    metals: state.auth.metals,
  }));
  const state = useSelector((state: RootState) => state.autoOrders);
  const { list, pagination, statuses, detail, loading } = state;
  const { fetchCart } = useCart();

  const getAutoOrders = (search = '') => {
    dispatch(actions.getAutoOrders(search));
  };

  const getAutoOrder = (id: number) => {
    dispatch(actions.getAutoOrder(id));
  };

  const cancelAutoOrder = (id: number) => {
    dispatch(actions.cancelAutoOrder(id))
      .unwrap()
      .then(() => {
        if (!!paramsId) {
          getAutoOrder(id);
          return;
        }
        if (!!company) {
          const searchWithCompanyId = getSearchWithCompanyId(company.id, search);
          getAutoOrders(searchWithCompanyId);
        }
      })
      .then(() => fetchCart());
  };

  return {
    list,
    pagination,
    statuses,
    detail,
    company,
    contracts,
    metals,
    isLoading: loading,
    getAutoOrders,
    getAutoOrder,
    cancelAutoOrder,
  };
};
