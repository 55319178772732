import styled from 'styled-components';
import { Modal as AntdModal, Button as AntButton } from 'antd';
import { Valid } from '../../Fields/Valid';
import { Title as StyledTitle } from '../../Title';
import CheckIcon from '../../../images/png/check.png';

export const Modal = styled(AntdModal)`
  width: 504px !important;

  .ant-modal-close {
    top: 6px;
    right: 16px;
  }

  .ant-modal-content {
    border-top: 5px solid #cd743d;
    padding: 59px 55px 65px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export const Field = styled(Valid)`
  font-family: Roboto !important;

  .ant-col-24.ant-form-item-label {
    padding-bottom: 4px;
    line-height: 1;

    > label {
      height: 18px;
      line-height: 1;
    }
  }

  .ant-input {
    font-size: 16px;
  }
`;

export const Title = styled(StyledTitle)`
  font-family: Roboto !important;
`;

export const SubTitle = styled(StyledTitle)`
  margin-bottom: 1.55em !important;
  margin-top: 0.4em !important;
  font-family: Roboto !important;
  color: #6c7175 !important;
  font-size: var(--s4) !important;
  line-height: var(--s6) !important;
`;

export const Text = styled.p`
  margin-top: 8px;
  text-align: left;
  font-size: 0.75em;
  color: #767676;
`;

export const Button = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 11px 15px;
  font-size: 17px;
  line-height: 1;
  text-align: center;
  background: #d67f3c;
  border-radius: 3px;
`;

export const SuccessModal = styled(Modal)`
  width: 458px !important;

  .ant-modal-content {
    padding: 59px 45px 65px;
  }
`;

export const SuccessIcon = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 45px;
  border-radius: 50%;
  background-color: #41AF29;
  background-image: url(${CheckIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 35px;

  &::before {
    position: absolute;
    left: -10px;
    top: -10px;
    width: 100px;
    height: 100px;
    border: 20px solid #41AF2950
    border-radius: 40px;
    border-radius: 50%;
    content: "";
  }

  &::after {
    position: absolute;
    left: -20px;
    top: -20px;
    width: 120px;
    height: 120px;
    border: 20px solid #41AF2919
    border-radius: 40px;
    border-radius: 50%;
    content: "";
  }
`;

export const SuccessTitle = styled(StyledTitle)`
  margin-bottom: 0 !important;
  font-family: Roboto !important;
`;
