import { combineReducers } from 'redux';
import contracts from 'features/slices/contracts';

// moved to ducks
import feedback from 'app/ducks/feedback';
import companies from 'app/ducks/companies';
import users from 'app/ducks/users';
import adminRenderRequests from 'app/ducks/admin/renderRequests';
import waitingLists from 'app/ducks/waitingLists';
import techOrders from 'app/ducks/techOrders';
import managerUsers from 'app/ducks/managerUsers';
import managers from 'app/ducks/managers';
import news from 'app/ducks/news';
import productsAdmin from 'app/ducks/productsAdmin';
import adminContracts from 'app/ducks/adminContracts';
import documents from 'app/ducks/documents';
import modals from 'app/ducks/modals';
import imports from 'app/ducks/imports';
import personalOrders from 'app/ducks/personalOrders';
import renderRequest from 'app/ducks/renderRequest';
import productDetail from 'app/ducks/productDetail';
import roles from 'app/ducks/roles';
import searchProducts from 'app/ducks/searchProducts';
import adminOrders from 'app/ducks/admin/orders';
import powers from 'app/ducks/powers';
import categories from 'app/ducks/categories';
import closedPositions from 'app/ducks/closedPositions';
import coilResidues from 'app/ducks/coilResidues';
import ResiduesFinished from 'app/ducks/residuesFinished';
import cart from 'app/ducks/cart';
import documentsSection from 'app/ducks/documentsSection';
import productCategories from 'app/ducks/admin/productCategories';
import demoUsers from 'app/ducks/admin/demoUsers';
import publicBanners from 'app/ducks/publicBanners';
import banners from 'app/ducks/admin/banners';
import auth from 'app/ducks/auth';
import actionLogs from 'app/ducks/admin/actionLogs';
import { consultation } from 'app/ducks/consultation';
import { blanks } from 'app/ducks/blanks';
import { additionalProducts } from 'app/ducks/addititionalProducts';
import { additionalProductsOrder } from 'features/AdditionalProducts';
import adminMetalCost from 'app/ducks/admin/metalCost';
import metalCost from 'app/ducks/metalCost';
import metalReport from 'app/ducks/metalReport';
import autoOrders from 'app/ducks/autoOrders';

const rootReducer = combineReducers({
  auth,
  managers,
  news,
  managerUsers,
  techOrders,
  categories,
  contracts,
  imports,
  closedPositions,
  feedback,
  productDetail,
  productsAdmin,
  searchProducts,
  cart,
  documents,
  personalOrders,
  modals,
  companies,
  users,
  renderRequest,
  adminOrders,
  adminContracts,
  adminRenderRequests,
  roles,
  waitingLists,
  powers,
  coilResidues,
  ResiduesFinished,
  documentsSection,
  productCategories,
  demoUsers,
  publicBanners,
  banners,
  actionLogs,
  consultation,
  blanks,
  additionalProducts,
  additionalProductsOrder,
  adminMetalCost,
  metalCost,
  metalReport,
  autoOrders,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
