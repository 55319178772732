import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { AddMetalCostShema } from 'app/types/adminMetalCost';
import { useMetalCost as useMetalCostClient } from 'app/ducks/metalCost';

export const useMetalCostAdmin = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.adminMetalCost);

  const { getActiveMetalCost } = useMetalCostClient();

  const getMetalCosts = (params: string) => {
    return dispatch(actions.getMetalCosts(params));
  };

  const addMetalCost = async (payload: AddMetalCostShema, params: string) => {
    return dispatch(actions.addMetalCost(payload))
      .unwrap()
      .then(() => {
        getActiveMetalCost();
        getMetalCosts(params);
      });
  };

  const stopMetalCost = async (params: string) => {
    return dispatch(actions.stopMetalCost())
      .unwrap()
      .then(() => {
        getActiveMetalCost();
        getMetalCosts(params);
      });
  };

  const uploadTemplate = async (payload: FormData, params: string) => {
    return dispatch(actions.uploadTemplate(payload))
      .unwrap()
      .then(res => {
        getActiveMetalCost();
        getMetalCosts(params);

        return res.item;
      });
  };

  const deleteTemplate = async (params: string) => {
    return dispatch(actions.deleteTemplate())
      .unwrap()
      .then(() => {
        getActiveMetalCost();
        getMetalCosts(params);
      });
  };

  const checkFileLink = async (payload: { filepath: string }) => {
    return dispatch(actions.checkFileLink(payload))
      .unwrap()
      .then(res => res.item);
  };

  return {
    state,
    getMetalCosts,
    addMetalCost,
    stopMetalCost,
    uploadTemplate,
    deleteTemplate,
    checkFileLink,
  };
};
