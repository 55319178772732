/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import { Row, Alert, Col } from 'antd';
import { useAuth } from 'app/ducks/auth';
import { OrderTerm } from './OrderTerm';
import { Coverings } from './Coverings';
import { Offers } from './Offers';
import {
  Offers as OffersType,
  ProductTypes,
  ProductType,
  Covering as CoveringType,
  StockType,
  MassData as MassDataType,
} from '../../app/types/products';
import * as S from './styled';
import { number } from '../../helpers/format';
import { CustomLength } from './CustomOffers/CustomLength';
import { Coil } from './CustomOffers/Coil';
import { Footer } from './Footer';
import { getOffersByFilters, getOrderCountByCovering, getOfferById } from './helpers';
import { AddingProduct, AddingOffer } from '../../app/types/cart';
import { OrderType as OrderStateType } from 'containers/ProductDetailModalContainer';
import { DetailOrderType } from '.';
import { useTranslation, Trans } from 'react-i18next';
import { actionTypes, sendEvent } from 'logger';
import { cart, IconLetter } from '../KrasUIKit/icons';
import { Tooltip } from 'components/Tooltip';
import round from 'lodash.round';
import { Residues } from './OrderTypeTabs/orderTypes/useResidues';
import { CoilProductionPowerMode } from 'app/types/products';
import { ResiduesBalance } from './ResiduesBalance';
import { useResiduesBalance } from './hooks/useResiduesBalance';
import { LimitAlerts } from './LimitAlerts';

export const WITHOUT_COVERING_ID = 1;

export interface Props {
  offers: OffersType;
  productionBalance?: number;
  inCoilsBalance?: Residues;
  requiredSetCovering?: boolean;
  weightInBasket?: number;
  balanceUnit?: string;
  orderType: DetailOrderType;
  types: ProductTypes;
  article: string;
  diameter: number;
  massData: MassDataType;
  productionFactor: number;
  isReady?: boolean;
  minOrderWeight?: number;
  minOrderCount?: number;
  setParentCounter: React.Dispatch<React.SetStateAction<number>>;
  updateActiveCovering: (coveringId: number) => void;
  onAddToCart: (product: Omit<AddingProduct, 'product_id'>) => void;
  addingToCart?: boolean;
  availableAddToCart?: boolean;
  order: OrderStateType | null;
  setOrder: React.Dispatch<React.SetStateAction<OrderStateType | null>>;
  wasAdded: null | 'waiting' | 'cart';
  setWasAdded: React.Dispatch<React.SetStateAction<'waiting' | 'cart' | null>>;
  leftoversAvailable?: boolean;
  login: boolean;
  stockType?: StockType;
  disabledPlatings?: number[];
  coilProductionAvailable: boolean;
  customLengthAvailable: boolean;
  isDemo: boolean;
  productionPowerMode?: CoilProductionPowerMode;
  isDimensioned: boolean;
}

export const Content = ({
  offers,
  weightInBasket,
  productionBalance,
  inCoilsBalance,
  requiredSetCovering,
  types,
  article,
  diameter,
  massData,
  balanceUnit,
  isReady,
  minOrderWeight,
  minOrderCount,
  setParentCounter,
  updateActiveCovering,
  onAddToCart,
  addingToCart,
  availableAddToCart,
  order,
  setOrder,
  orderType,
  wasAdded,
  setWasAdded,
  leftoversAvailable,
  login,
  stockType,
  disabledPlatings = [],
  coilProductionAvailable,
  customLengthAvailable,
  isDemo,
  productionPowerMode = 0,
  isDimensioned,
}: Props) => {
  const coilWeight = order?.[orderType]?.coil || 0;
  const custom = order?.[orderType]?.custom || [];
  const { t } = useTranslation('productDetail');
  const [activeCovering, setActiveCovering] = useState<number | null>(null);
  const slope = massData.slope || 1;
  const intercept = massData.intercept || 0;
  const {
    state: { variables },
  } = useAuth();

  const setCoilWeight = (weight: number) => {
    setWasAdded(null);
    const current = order?.[orderType];
    setOrder({ ...order, [orderType]: { ...current, coil: weight } });
  };

  const allowFakeWithout = !disabledPlatings.includes(WITHOUT_COVERING_ID);

  const getCoverings = (offers: OffersType) => {
    const coverings = offers.reduce<Omit<CoveringType, 'images'>[]>(
      (coverings, { coveringId, covering: coveringName }) => {
        const covering = coverings.find(({ id }) => id === coveringId);
        const currentOrder = order?.[orderType] || null;
        const orderCount = getOrderCountByCovering(coveringId, currentOrder, offers);

        if (!covering) {
          return [
            ...coverings,
            {
              id: coveringId,
              name: coveringName,
              count: orderCount,
            },
          ];
        }
        return coverings;
      },
      [],
    );

    const hasWithout = coverings.find(({ id }) => id === WITHOUT_COVERING_ID) || !coilProductionAvailable;

    return hasWithout || !allowFakeWithout
      ? coverings
      : [
          ...coverings,
          {
            id: WITHOUT_COVERING_ID,
            count: getOrderCountByCovering(WITHOUT_COVERING_ID, order?.[orderType] || null, offers),
            name: t('productParameters:withoutCover'),
          },
        ];
  };

  const hasFakeWithout = !offers.find(({ coveringId }) => WITHOUT_COVERING_ID === coveringId);

  const getDefaultCovering = (required: boolean | undefined, offers: OffersType) => {
    const coverings = getCoverings(offers);
    const FIRST_COVERING_INDEX = 0;

    if (coverings.length === 1) return coverings[FIRST_COVERING_INDEX].id;

    return required ? null : getCoverings(offers)[FIRST_COVERING_INDEX].id;
  };

  const changeCovering = (id: number) => {
    setWasAdded(null);
    setActiveCovering(id);
    updateActiveCovering(id);

    sendEvent({
      ActionType: actionTypes.DETAIL_CHANGE_COVER,
      Query: id.toString(),
      ContextKey: article,
    });
  };

  const coveringSetted = !requiredSetCovering || activeCovering !== null;

  const { total, getLosePower, getBalanceValue } = useResiduesBalance({
    order,
    orderType,
    offers,
    inCoilsBalance,
    productionBalance,
    activeCovering,
    chainMinLength: variables?.chainMinLength ?? 0,
    productionPowerMode,
  });

  useEffect(() => {
    setActiveCovering(getDefaultCovering(requiredSetCovering, offers));
  }, [article, diameter]);

  useEffect(() => {
    setParentCounter(total.count);
  }, [order, coilWeight]);

  const changeOfferHandler = (order: OrderStateType | null) => {
    setWasAdded(null);
    setOrder(order);
  };

  const renderOfferCol = (item: ProductType) => {
    const coveringId = activeCovering || getCoverings(offers)[0]?.id;

    const list = getOffersByFilters(offers, [
      ['coveringId', coveringId],
      ['typeId', item.id],
    ]);

    return list.length > 0 ? (
      <Col key={item.code} sm={12} xs={24}>
        <Offers
          article={article}
          isReady={isReady}
          title={`${item.title} ${article}`}
          list={list}
          order={order}
          setOrder={changeOfferHandler}
          orderType={orderType}
          stockType={stockType}
          typeCode={item.code}
          isDimensioned={isDimensioned}
        />
      </Col>
    ) : null;
  };

  const changeCustomLengthHandler = (value: [number, number]) => {
    setWasAdded(null);
    const { custom = [], ...current } = order?.[orderType] || {};
    const [length, count] = value;
    const coating_id = activeCovering || getCoverings(offers)[0].id;
    const index = custom.findIndex(({ coating_id: covering }) => covering === coating_id);

    let customs: AddingOffer[];

    const offer = {
      length,
      count,
      coating_id,
      weight: length * slope + intercept,
      offer_id: 0,
    };

    if (~index) {
      customs = [...custom.slice(0, index), offer, ...custom.slice(index + 1, custom.length)];
    } else {
      customs = [...custom, offer];
    }

    setOrder({ ...order, [orderType]: { ...current, custom: customs } });
  };

  const minWeight = minOrderWeight && minOrderWeight !== 0 ? minOrderWeight : total.weight;
  const minCount = minOrderCount && minOrderCount !== 0 ? minOrderCount : total.count;
  const isWishlist = (minWeight > total.weight || minCount > total.count) && orderType === 'products_production';

  const addToCartHandler = () => {
    const currentOrder = order?.[orderType];
    const { coil: orderTypeCoil = 0, custom, ...finished } = currentOrder || {};

    const ids = finished ? Object.entries(finished) : [];
    const coil =
      orderTypeCoil > 0
        ? {
            offer_id: 0,
            length: round((orderTypeCoil - intercept) / slope),
            weight: orderTypeCoil,
            coating_id: 1,
            count: 1,
          }
        : null;

    onAddToCart({
      order_type: isWishlist ? 'waiting_list' : orderType,
      name: '1',
      offers: {
        coil,
        custom: custom || [],
        finished: ids
          .filter(([id, count]) => id !== 'coil' && id !== 'custom' && count > 0)
          .map(([id, count]) => {
            const offer_id = Number(id);
            const offer = getOfferById(offer_id, offers);
            return {
              offer_id,
              count,
              length: offer?.length || 0,
              weight: offer?.weight || 0,
              coating_id: offer?.coveringId || 0,
            };
          }),
      },
    });
  };

  const losePowerWithNoType = getLosePower();
  const hasProductionBalance = Boolean(productionBalance);
  const hasInCoilsBalance = Boolean(inCoilsBalance);
  const hasCovering = orderType === 'products_coil' ? Boolean(activeCovering) : true;
  const showBalance =
    !isDemo && hasCovering && (hasProductionBalance || hasInCoilsBalance) && losePowerWithNoType !== Infinity;

  return (
    <>
      <Row style={{ marginBottom: '0.5em' }} justify="space-between">
        <Col span={12}>
          <OrderTerm />
        </Col>
        {showBalance && (
          <ResiduesBalance
            orderType={orderType}
            getBalanceValue={getBalanceValue}
            productionPowerMode={productionPowerMode}
            balanceUnit={balanceUnit}
          />
        )}
      </Row>
      <Row justify="end">
        <Tooltip title={t('inBasketTolltipText')} simple>
          <S.InBasket>
            <IconLetter size="1.28em">{cart}</IconLetter>
            {weightInBasket ? number(weightInBasket) : 0} {balanceUnit}
          </S.InBasket>
        </Tooltip>
      </Row>
      <Row>
        <Coverings list={getCoverings(offers)} onChange={changeCovering} active={activeCovering} />
      </Row>
      {login && minOrderWeight !== undefined && minOrderWeight > 0 && (
        <Alert
          type="info"
          showIcon
          style={{ width: '100%', marginBottom: 15 }}
          message={
            <Trans
              t={t}
              i18nKey="warningMinWeight"
              values={{
                count: number(minOrderWeight),
                leftoversAvailable: leftoversAvailable ? t('leftoversAvailable') : '',
              }}
            />
          }
        />
      )}
      {login && minOrderCount !== undefined && minOrderCount > 0 && (
        <Alert
          type="info"
          showIcon
          style={{ width: '100%', marginBottom: 15 }}
          message={
            <Trans
              t={t}
              i18nKey="warningMinOrderCount"
              values={{
                count: minOrderCount,
              }}
            />
          }
        />
      )}
      {!coveringSetted && (
        <Row>
          <Alert style={{ width: '100%', marginBottom: 10 }} showIcon type="warning" message={t('needChooseCoating')} />
        </Row>
      )}
      {!isDemo && (
        <LimitAlerts getLosePower={getLosePower} orderType={orderType} productionPowerMode={productionPowerMode} />
      )}
      <S.BlockedContainer disabled={!coveringSetted}>
        <Row gutter={10} style={{ padding: '16px 0' }}>
          {types.map(renderOfferCol)}
        </Row>

        <Row gutter={10}>
          {customLengthAvailable && !(hasFakeWithout && activeCovering === WITHOUT_COVERING_ID) && (
            <Col md={12} sm={24}>
              <CustomLength
                article={article}
                current={custom.find(({ coating_id }) => coating_id === activeCovering)}
                massData={massData}
                onChange={changeCustomLengthHandler}
                balance={losePowerWithNoType}
                orderType={orderType}
                isDimensioned={isDimensioned}
              />
            </Col>
          )}

          {coilProductionAvailable && (
            <Col md={12} sm={24}>
              <Coil
                current={coilWeight}
                massData={massData}
                onChange={setCoilWeight}
                activeCovering={activeCovering}
                balance={losePowerWithNoType}
                orderType={orderType}
                isDimensioned={isDimensioned}
              />
            </Col>
          )}
        </Row>
        {/* ? Подумать над возможностью просто блокировать кнопки*/}
        {availableAddToCart && (
          <S.ContentFooter>
            <Footer
              activeCovering={activeCovering}
              article={article}
              wasAdded={wasAdded}
              addingToCart={addingToCart}
              mass={total.weight}
              count={total.count}
              addToCart={addToCartHandler}
              isWishlist={isWishlist}
            />
          </S.ContentFooter>
        )}
      </S.BlockedContainer>
    </>
  );
};
