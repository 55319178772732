import * as actions from './actions';
import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { PaginationNew } from 'app/types/pagination';
import { UserEntity } from 'app/types/userAdmin';
import { RoleEntity } from 'app/types/roles';

interface InitialState {
  loading: boolean;
  users: UserEntity[];
  pagination?: PaginationNew;
  assignableRoles: RoleEntity[];
}

export const initialState: InitialState = {
  loading: false,
  users: [],
  assignableRoles: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('users/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('users/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('users/') && action.type.endsWith('/fulfilled');

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchUsers.fulfilled, (state, { payload }) => {
      state.users = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.updateUser.fulfilled, (state, { payload }) => {
      const index = state.users.findIndex(item => item.id === payload.id);
      if (index > -1) state.users[index] = payload;
    });

    addCase(actions.deleteUser.fulfilled, (state, { payload }) => {
      const index = state.users.findIndex(item => item.id === payload);
      if (index > -1) state.users.splice(index, 1);
    });

    addCase(actions.getAssignableRoles.fulfilled, (state, { payload }) => {
      state.assignableRoles = payload;
    });

    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default usersSlice.reducer;
