/* 
  Правила именования: ${ SECTION } _${ EVENT } _${ NAME }
  где section это раздел
  EVENT тип интерфейсного события события
  NAME название события
*/

/* Common */
export const ENTERPOINT = 'ENTERPOINT';
export const PAGE_CHANGED = 'PAGE_CHANGED';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR';
export const INPUT_SEARCH_LOOP_CLICK = 'INPUT_SEARCH_LOOP_CLICK';
export const PAGINATION = 'PAGINATION';
export const INPUT_SEARCH_STOPED_TYPING = 'INPUT_SEARCH_STOPED_TYPING';
export const INPUT_SEARCH_PRESS_ENTER = 'INPUT_SEARCH_PRESS_ENTER';
export const INPUT_SEARCH_SHOW_HINT = 'INPUT_SEARCH_SHOW_HINT';
export const INPUT_SEARCH_HINT_CLICK_MORE = 'INPUT_SEARCH_HINT_CLICK_MORE';
export const INPUT_SEARCH_CLICK_HINT_OPTION = 'INPUT_SEARCH_CLICK_HINT_OPTION';

/* Главная страница */
export const MAIN_CLICK_TO_SHOP = 'MAIN_CLICK_TO_SHOP';
export const WELCOME_BONUS_CLICK_SPECIAL_PAGE = 'WELCOME_BONUS_CLICK_SPECIAL_PAGE';
export const AG_BONUS_CLICK_RULES = 'AG_BONUS_CLICK_RULES';

/* Card */
export const CARD_CLICK_CATEGORY = 'CARD_CLICK_CATEGORY';
export const CARD_CLICK_PRODUCT = 'CARD_CLICK_PRODUCT';

/* Cart */
export const CART_MINUS_OFFER = 'CART_MINUS_OFFER';
export const CART_PLUS_OFFER = 'CART_PLUS_OFFER';
export const CART_CLEAR_TAB = 'CART_CLEAR_TAB';
export const CART_CLEAR_ALL = 'CART_CLEAR_ALL';
export const CART_TRANSFER_PRODUCT = 'CART_TRANSFER_PRODUCT';
export const CART_TRANSFER_OFFER = 'CART_TRANSFER_OFFER';
export const CART_DOWNLOAD_IMPORT = 'CART_DOWNLOAD_IMPORT';
export const CART_DOWNLOAD_EXCEL = 'CART_DOWNLOAD_EXCEL';
export const CART_CHANGE_CONTRACT = 'CART_CHANGE_CONTRACT';
export const CART_CHANGE_METALS = 'CART_CHANGE_METALS';
export const CART_CHECK_SUCCESS = 'CART_CHECK_SUCCESS';
export const CART_CHECK_ERROR = 'CART_CHECK_ERROR';
export const CART_CLICK_TAB = 'CART_CLICK_TAB';
export const CART_CHANGE_COVER = 'CART_CHANGE_COVER';
export const CART_CLICK_CARD_IMAGE = 'CART_CLICK_CARD_IMAGE';
export const CART_CLICK_CARD_TITLE = 'CART_CLICK_CARD_TITLE';
export const CART_EMPTY_GO_TO_PRODUCTS = 'CART_EMPTY_GO_TO_PRODUCTS';
export const CART_EMPTY_GO_TO_IMPORT = 'CART_EMPTY_GO_TO_IMPORT';

export const CART_FETCH_DATA = 'CART_FETCH_DATA';
export const CART_ADD_PRODUCT = 'CARD_ADD_PRODUCT';

/* Checkout */
export const CHECKOUT_BACK_TO_CART = 'CHECKOUT_BACK_TO_CART';
export const CHECKOUT_CREATE_ORDER = 'CHECKOUT_CREATE_ORDER';
export const CHECKOUT_OPEN_FINAL_MODAL = 'CHECKOUT_OPEN_FINAL_MODAL';
export const CHECKOUT_CHANGE_COVER = 'CHECKOUT_CHANGE_COVER';
export const CHECKOUT_PLUS_OFFER = 'CHECKOUT_PLUS_OFFER';
export const CHECKOUT_MINUS_OFFER = 'CHECKOUT_MINUS_OFFER';
export const CHECKOUT_DELETE_OFFER = 'CHECKOUT_DELETE_OFFER';
export const CHECKOUT_CHOOSE_PRODUCT_FINISHED_TYPE = 'CHECKOUT_CHOOSE_PRODUCT_FINISHED_TYPE';
export const CHECKOUT_SHOW_MORE_PRODUCTS = 'CHECKOUT_SHOW_MORE_PRODUCTS';
export const CHECKOUT_LOST_FOCUS_COMMENT = 'CHECKOUT_LOST_FOCUS_COMMENT';
export const CHECKOUT_MODAL_SELECT_TYPE = 'CHECKOUT_MODAL_SELECT_TYPE';

/* Import */
export const IMPORT_TAB_CLICK = 'IMPORT_TAB_CLICK';
export const IMPORT_CLICK_DOWNLOAD_BLANKS = 'IMPORT_CLICK_DOWNLOAD_BLANKS';
export const IMPORT_CLICK_LOAD_BLANK = 'IMPORT_CLICK_LOAD_BLANK';
export const IMPORT_CLICK_LOAD_BLANK_SUCCESS = 'IMPORT_CLICK_LOAD_BLANK_SUCCESS';
export const IMPORT_CLICK_LOAD_BLANK_FAILED = 'IMPORT_CLICK_LOAD_BLANK_FAILED';
export const IMPORT_CLICK_LOAD_BLANK_CANCEL = 'IMPORT_CLICK_LOAD_BLANK_CANCEL';
export const IMPORT_CLICK_LOAD_BLANK_CONTINUE = 'IMPORT_CLICK_LOAD_BLANK_CONTINUE';
export const IMPORT_CLICK_LOAD_BLANK_QUESTION = 'IMPORT_CLICK_LOAD_BLANK_QUESTION';

/* Detail */
export const DETAIL_CHANGE_COVER = 'DETAIL_CHANGE_COVER';
export const DETAIL_CLICK_PLUS = 'DETAIL_CLICK_PLUS';
export const DETAIL_CLICK_PLUS10 = 'DETAIL_CLICK_PLUS10';
export const DETAIL_CLICK_MINUS = 'DETAIL_CLICK_MINUS';
export const DETAIL_ADD_TO_CART = 'DETAIL_ADD_TO_CART';
export const DETAIL_ADD_TO_WAITING_LIST = 'DETAIL_ADD_TO_WAITING_LIST';
export const DETAIL_GO_TO_CART = 'DETAIL_GO_TO_CART';
export const DETAIL_GO_TO_WAITING_LIST = 'DETAIL_GO_TO_WAITING_LIST';
export const DETAIL_CLICK_TAB = 'DETAIL_CLICK_TAB';
export const DETAIL_CLICK_ADDITIONAL_IMAGE = 'DETAIL_CLICK_ADDITIONAL_IMAGE';

/* Categories */

/* Header */
export const HEADER_CLICK_PARTNERSHIP = 'HEADER_CLICK_PARTNERSHIP';
export const HEADER_CLICK_SUPPORT = 'HEADER_CLICK_SUPPORT';
export const HEADER_MENU_CLICK_NAV = 'HEADER_MENU_CLICK_NAV';
export const HEADER_PERSONAL_CLICK_NAV = 'HEADER_PERSONAL_CLICK_NAV';
export const HEADER_CHANGE_LANGUAGE = 'HEADER_CHANGE_LANGUAGE';
export const HEADER_CLICK_LOGO = 'HEADER_CLICK_LOGO';
export const HEADER_CLICK_CHANGE_COMPANY = 'HEADER_CLICK_CHANGE_COMPANY';
export const HEADER_HOVER_COMPANY = 'HEADER_HOVER_COMPANY';
export const HEADER_CHANGE_COMPANY_LOST_FOCUS = 'HEADER_CHANGE_COMPANY_LOST_FOCUS';
export const HEADER_HOVER_CONTRACT = 'HEADER_HOVER_CONTRACT';
export const HEADER_CLICK_CHANGE_CONTRACT = 'HEADER_CLICK_CHANGE_CONTRACT';
export const HEADER_HOVER_PERSONAL = 'HEADER_HOVER_PERSONAL';
export const HEADER_CLICK_LOGOUT = 'HEADER_CLICK_LOGOUT';
export const HEADER_HOVER_CART = 'HEADER_HOVER_CART';
export const HEADER_CLICK_CART = 'HEADER_CLICK_CART';
export const HEADER_CLICK_CLIENTS = 'HEADER_CLICK_CLIENTS';

/* Footer */
export const FOOTER_CLICK_LOGO = 'FOOTER_CLICK_LOGO';
export const FOOTER_CLICK_LINK = 'FOOTER_CLICK_LINK';
export const FOOTER_CLICK_SUPPORT = 'FOOTER_CLICK_SUPPORT';
export const FOOTER_CLICK_PHONE = 'FOOTER_CLICK_PHONE';

/* Обращение ("Напишите нам") */
export const MODAL_SUPPORT_CLOSE = 'MODAL_SUPPORT_CLOSE';
export const MODAL_SUPPORT_CANCEL = 'MODAL_SUPPORT_CANCEL';
export const MODAL_SUPPORT_SEND_SUCCESS = 'MODAL_SUPPORT_SEND_SUCCESS';
export const MODAL_SUPPORT_SEND_FAILED = 'MODAL_SUPPORT_SEND_FAILED';

/* Бланки */
export const BLANKS_DOWNLOAD = 'BLANKS_DOWNLOAD';
export const BLANKS_GO_TO_IMPORT = 'BLANKS_GO_TO_IMPORT';
export const BLANKS_GO_TO_MANUAL = 'BLANKS_GO_TO_MANUAL';

/* Инструкция */
export const MANUAL_GO_TO_IMPORT = 'MANUAL_GO_TO_IMPORT';
export const MANUAL_GO_TO_LOAD_PAGE = 'MANUAL_GO_TO_LOAD_PAGE';

/* Personal */
export const PERSONAL_CLICK_TAB = 'PERSONAL_CLICK_TAB';

/* Мои заказы */
export const ORDERS_DOWNLOAD_IMPORT = 'ORDERS_DOWNLOAD_IMPORT';
export const ORDERS_DOWNLOAD_EXCEL = 'ORDERS_DOWNLOAD_EXCEL';
export const ORDERS_DOWNLOAD_RENDERS = 'ORDERS_DOWNLOAD_RENDERS';
export const ORDERS_CLICK_ORDER = 'ORDERS_CLICK_ORDER';

/* Автозаказы */
export const O2C_ORDER_LIST_CANCEL = 'O2C_ORDER_LIST_CANCEL';
export const O2C_ORDER_DETAIL_CANCEL = 'O2C_ORDER_DETAIL_CANCEL';
export const O2C_MANAGER_LIST_FILTER = 'O2C_MANAGER_LIST_FILTER';

/* Документы */
export const DOCUMENTS_CLICK_ACT = 'DOCUMENTS_CLICK_ACT';
export const DOCUMENTS_DOWNLOAD = 'DOCUMENTS_DOWNLOAD';

/* Акт сверки */
export const MODAL_ACT_SEND_SUCCESS = 'MODAL_ACT_SEND_SUCCESS';
export const MODAL_ACT_SEND_FAILD = 'MODAL_ACT_SEND_FAILD';
export const MODAL_ACT_CANCEL = 'MODAL_ACT_CANCEL';
export const MODAL_ACT_CLOSE = 'MODAL_ACT_CLOSE';

/* Отчет об израсходовании драг.металлов */
export const METAL_REPORT_HEADER_CLICK = 'METAL_REPORT_HEADER_CLICK';
export const METAL_REPORT_PERSONAL_CLICK = 'METAL_REPORT_PERSONAL_CLICK';
export const METAL_REPORT_MODAL_CLICK = 'METAL_REPORT_MODAL_CLICK';
export const METAL_REPORT_PERSONAL_DOWNLOAD = 'METAL_REPORT_PERSONAL_DOWNLOAD';

/* Баннер Welcome бонусы */
export const WELCOME_BONUS_CLICK_BANNER_NEW = 'WELCOME_BONUS_CLICK_BANNER_NEW';
export const WELCOME_BONUS_CLICK_BANNER_OLD = 'WELCOME_BONUS_CLICK_BANNER_OLD';

export const BANNER_ANKLET_CATALOG = 'BANNER_ANKLET_CATALOG';
export const BANNER_ANKLET_TO_GOLD = 'BANNER_ANKLET_TO_GOLD';
export const BANNER_ANKLET_TO_SILVER = 'BANNER_ANKLET_TO_SILVER';

/* Баннер Консультация */
export const CONSULTING_ORDER = 'CONSULTING_ORDER';
export const CONSULTING_DETAILS = 'CONSULTING_DETAILS';
export const CONSULTING_REQUEST = 'CONSULTING_REQUEST';

/* Баннера общее */
export const BANNER_AG_TRIPLE_BENEFIT = 'BANNER_AG_TRIPLE_BENEFIT';
export const BANNER_GOLD_COMBO = 'BANNER_GOLD_COMBO';
export const BANNER_DECOR_TIME = 'BANNER_DECOR_TIME';
export const BANNER_PT_DEGUSTATION = 'BANNER_PT_DEGUSTATION';

/* Баннер Серебро */
export const AG_BONUS_CLICK_BANNER = 'AG_BONUS_CLICK_BANNER';

/* Заказы */
export const STATUS_ORDER_FORECAST = 'STATUS_ORDER_FORECAST';
export const STATUS_ORDER_MODAL = 'STATUS_ORDER_MODAL';
export const STATUS_ORDER_SHIPMENT = 'STATUS_ORDER_SHIPMENT';

/* MetalCost Цены */
export const METAL_COST_HEADER_EXCHANGE = 'METAL_COST_HEADER_EXCHANGE';
export const METAL_COST_HEADER_PREPAYMENT = 'METAL_COST_HEADER_PREPAYMENT';
export const METAL_COST_HEADER_EXCHANGE_TERMS = 'METAL_COST_HEADER_EXCHANGE_TERMS';
export const METAL_COST_HEADER_PREPAYMENT_25_TERMS = 'METAL_COST_HEADER_PREPAYMENT_25_TERMS';
export const METAL_COST_HEADER_PREPAYMENT_50_TERMS = 'METAL_COST_HEADER_PREPAYMENT_50_TERMS';
export const METAL_COST_HEADER_PREPAYMENT_100_TERMS = 'METAL_COST_HEADER_PREPAYMENT_100_TERMS';
