import { ItemResult, ItemsResult, Result } from 'app/types/api';
import {
  CreateDocumentsSectionDto,
  DocumentSectionTypeEntity,
  DocumentSectionEntity,
  UpdateDocumentsSectionDto,
  UpdateDocumentDto,
  CreateDocumentDto,
  DocumentEntity,
} from 'app/types/documentsSection';
import { apiDelete, apiGet, apiPost } from './connect';

class DocumentsSection {
  async getTypesMany() {
    try {
      const { data } = await apiGet<ItemsResult<DocumentSectionTypeEntity>>('/documents/sections/types');

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async getMany() {
    try {
      const { data } = await apiGet<ItemsResult<DocumentSectionEntity>>('/documents/sections');

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async getManyByCode(code: string) {
    try {
      const { data } = await apiGet<ItemsResult<DocumentSectionEntity>>(`/documents/sections/${code}`);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async create(payload: CreateDocumentsSectionDto) {
    try {
      const { data } = await apiPost<ItemResult<DocumentSectionEntity>>('/documents/sections', payload);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async update(sectionId: number, payload: UpdateDocumentsSectionDto) {
    try {
      const { data } = await apiPost<ItemResult<DocumentSectionEntity>>(`/documents/sections/${sectionId}`, payload);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async delete(sectionId: number) {
    try {
      const { data } = await apiDelete<Result>(`/documents/sections/${sectionId}`);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
  async createDocument(payload: CreateDocumentDto) {
    try {
      const { data } = await apiPost<ItemResult<DocumentEntity>>('/documents', payload);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async updateDocument(payload: UpdateDocumentDto) {
    try {
      const { data } = await apiPost<ItemResult<DocumentEntity>>(`/documents/${payload.id}`, payload);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  async deleteDocument(documentId: number) {
    try {
      const { data } = await apiDelete<Result>(`/documents/${documentId}`);

      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const DocumentsSectionService = new DocumentsSection();
