/* eslint-disable prettier/prettier */
import {
  createSlice,
  PayloadAction,
  AnyAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import * as actions from './actions';
import { Pagination } from 'app/types/pagination';
import {
  ResidueEntity,
  ResidueType,
  EditableResidue,
} from 'app/types/coilResidues';

interface CoilResiduesInitialState {
  loading: boolean;
  types: ResidueType[];
  editableResidue: EditableResidue | null;
  pagination?: Pagination;
}

export const coilResiduesAdapter = createEntityAdapter<ResidueEntity>();

const initialState: CoilResiduesInitialState = {
  loading: true,
  types: [],
  editableResidue: null,
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('coilResidues/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('coilResidues/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('coilResidues/') && action.type.endsWith('/fulfilled');

const coilResiduesSlice = createSlice({
  name: 'coilResidues',
  initialState: coilResiduesAdapter.getInitialState(initialState),
  reducers: {
    setEditable: (
      state,
      { payload }: PayloadAction<EditableResidue | null>,
    ) => {
      state.editableResidue = payload;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchCoilResidues.fulfilled, (state, { payload }) => {
      coilResiduesAdapter.setAll(state, payload.items);
      state.pagination = payload.pagination;
    });
    addCase(actions.fetchResiduesTypes.fulfilled, (state, { payload }) => {
      state.types = payload;
    });
    addCase(
      actions.createOrUpdateCoilResidue.fulfilled,
      (state, { payload }) => {
        coilResiduesAdapter.upsertOne(state, payload)
      },
    );
    addCase(actions.deleteCoilResidue.fulfilled, (state, { payload }) => {
      if(payload?.data.types.length === 1){
        coilResiduesAdapter.removeOne(state, payload.data.id);
      } else if(payload) {
        const newPayload: ResidueEntity = {
          ...payload.data,
          types: payload.data.types.filter((_, index) => index != payload.residueTypeIndex),
        }
        coilResiduesAdapter.setOne(state, newPayload)
      }
      
    });
    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export const { setEditable } = coilResiduesSlice.actions;

export default coilResiduesSlice.reducer;
