import { useDefaultOrderTypeProps } from './orderTypes/useDefaultOrderType';
import { useInCoils } from './orderTypes/useInCoils';
import { useInFinished } from './orderTypes/useInFinished';
import { useInProduction } from './orderTypes/useInProduction';
import { useInSelfMark } from './orderTypes/useInSelfMark';
import { OrderTypeHook, THookProps } from './types';

const createOrderTypes = (product: THookProps, hooks: OrderTypeHook[]) => {
  const defaultProps = useDefaultOrderTypeProps(product);

  return hooks.map(hook => {
    const props = hook(product);
    return {
      ...props,
      content: {
        ...defaultProps,
        ...props.content,
      },
    };
  });
};

export const initOrderTypes = (product: THookProps) =>
  createOrderTypes(product, [useInProduction, useInFinished, useInSelfMark, useInCoils]);
