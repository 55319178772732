import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentsSectionService } from 'app/services/documentsSection';
import { message as antdMessage } from 'antd';
import {
  CreateDocumentsSectionDto,
  UpdateDocumentsSectionDto,
  UpdateDocumentDto,
  CreateDocumentDto,
  DeleteDocumentDto,
} from 'app/types/documentsSection';

export const getDocumentSections = createAsyncThunk(
  'documentsSection/getDocumentsSection',
  async (_, { rejectWithValue }) => {
    const { data, success, message } = await DocumentsSectionService.getMany();

    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
export const getDocumentsSectionsTypes = createAsyncThunk(
  'DocumentsSection/getDocumentsSectionsTypes',
  async (_, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await DocumentsSectionService.getTypesMany();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
export const getDocumentSectionsByCode = createAsyncThunk(
  'documentsSection/getDocumentSectionsByCode',
  async (code: string, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await DocumentsSectionService.getManyByCode(code);

    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const createDocumentsSection = createAsyncThunk(
  'documentsSection/createDocumentsSection',
  async (payload: CreateDocumentsSectionDto, { rejectWithValue }) => {
    const { data, success, message } = await DocumentsSectionService.create(
      payload,
    );

    if (success && data) {
      // TODO: нет нормального сообщения
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateDocumentsSection = createAsyncThunk(
  'documentsSection/updateDocumentsSection',
  async (
    { id, payload }: { id: number; payload: UpdateDocumentsSectionDto },
    { rejectWithValue, dispatch },
  ) => {
    const { data, success, message } = await DocumentsSectionService.update(
      id,
      payload,
    );

    if (success && data) {
      antdMessage.success(message);
      await dispatch(getDocumentSections());
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteDocumentsSection = createAsyncThunk(
  'documentsSection/deleteDocumentsSection',
  async (id: number, { rejectWithValue }) => {
    const { data, success, message } = await DocumentsSectionService.delete(id);

    if (success && data) {
      antdMessage.success(message);
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const createDocument = createAsyncThunk(
  'documentsSection/createDocument',
  async (payload: CreateDocumentDto, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await DocumentsSectionService.createDocument(payload);

    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateDocument = createAsyncThunk(
  'documentsSection/updateDocument',
  async (payload: UpdateDocumentDto, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await DocumentsSectionService.updateDocument(payload);

    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'documentsSection/deleteDocument',
  async (payload: DeleteDocumentDto, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await DocumentsSectionService.deleteDocument(payload.documentId);

    if (success && data) {
      antdMessage.success(message);
      updateDocument;
      return payload;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
