import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import * as ProductApi from 'app/services/admin/products';
import * as types from 'app/types/products';

export const fetchProducts = createAsyncThunk('productsAdmin/fetchProducts', async (search: string) => {
  const { data, success, message } = await ProductApi.getProducts(search);
  if (success) return data;
  else throw new Error(message);
});

export const updateOffer = createAsyncThunk('productsAdmin/updateOffer', async (offer: types.AdminOffer) => {
  const { data, success, message } = await ProductApi.updateOffer(offer);
  if (success) {
    antdMessage.success('Торговое предложение успешно обновлено');
    return data;
  } else throw new Error(message);
});
export const addOffer = createAsyncThunk('productsAdmin/addOffer', async (offer: types.AdminOffer) => {
  const { data, success, message } = await ProductApi.addOffer(offer);
  if (success) {
    antdMessage.success('Торговое предложение успешно добавлено');
    return data;
  } else throw new Error(message);
});

export const deleteOffer = createAsyncThunk('productsAdmin/deleteOffer', async (id: number) => {
  const { success, message } = await ProductApi.deleteOffer(id);
  if (success) {
    antdMessage.success('Торговое предложение успешно удалено');
    return id;
  } else throw new Error(message);
});

export const updateProduct = createAsyncThunk(
  'productsAdmin/updateProduct',
  async (product: types.AdminProductsUpload) => {
    const { success, message, data } = await ProductApi.updateProduct(product);
    if (success) {
      antdMessage.success(message || 'Товар успешно сохранен');
      return data;
    } else throw new Error(message);
  },
);

export const addProduct = createAsyncThunk('productsAdmin/addProduct', async (product: types.AdminProductsUpload) => {
  const { success, message, data } = await ProductApi.addProduct(product);
  if (success) {
    antdMessage.success(message || 'Товар успешно добавлен');
    return data;
  } else throw new Error(message);
});

export const deleteProduct = createAsyncThunk('productsAdmin/deleteProduct', async (id: number) => {
  const { success, message } = await ProductApi.deleteProduct(id);
  if (success) {
    antdMessage.success('Товар успешно удален');
    return id;
  } else throw new Error(message);
});

export const getCoatings = createAsyncThunk('additionalInformation/getCoatings', async () => {
  const { message, success, data } = await ProductApi.getCoatings();
  if (success) {
    return data;
  } else throw new Error(message);
});

export const getMetals = createAsyncThunk('additionalInformation/getMetalls', async () => {
  const { message, success, data } = await ProductApi.getMetals();
  if (success) return data;
  else throw new Error(message);
});

export const getFeatures = createAsyncThunk('additionalInformation/getFeatures', async () => {
  const { message, success, data } = await ProductApi.getFeatures();
  if (success) return data;
  else throw new Error(message);
});
export const getAlloys = createAsyncThunk('additionalInformation/getAlloys', async () => {
  const { message, success, data } = await ProductApi.getAlloys();
  if (success) {
    return data;
  } else throw new Error(message);
});
export const getOfferTypes = createAsyncThunk('additionalInformation/getOfferTypes', async () => {
  const { message, success, data } = await ProductApi.getOfferTypes();
  if (success) {
    return data;
  } else throw new Error(message);
});

export const setProductImage = createAsyncThunk('productsAdmin/setProductImage', async (params: types.ImageUpdate) => {
  const { success, message, data } = await ProductApi.setProductImage(params);
  if (success) {
    antdMessage.success('Изображение успешно установлено');
    return data;
  } else throw new Error(message);
});

export const setCoatingImage = createAsyncThunk(
  'productsAdmin/setCoatingImage',
  async (params: types.ImageCoatingUpdate) => {
    const { success, message, data } = await ProductApi.setCoatingImage(params);
    if (success) {
      antdMessage.success('Изображение успешно установлено');
      return data;
    } else throw new Error(message);
  },
);

export const setAdditionalImage = createAsyncThunk(
  'productsAdmin/setAdditionalImage',
  async (params: types.ImageAdditionalUpdate) => {
    const { success, message, data } = await ProductApi.setAdditionalImage(params);
    if (success && data) {
      antdMessage.success('Изображение успешно установлено');
      return data;
    } else throw new Error(message);
  },
);

export const deleteAdditionalImage = createAsyncThunk(
  'productsAdmin/deleteAdditionalImage',
  async (params: types.ImageAdditionalDelete) => {
    const { success, message } = await ProductApi.deleteAdditionalImage(params);
    if (success) {
      antdMessage.success('Изображение успешно удалено');
      return params.imageId;
    } else {
      throw new Error(message);
    }
  },
);

export const getDetails = createAsyncThunk('productsAdmin/getDetails', async () => {
  const { data, success, message } = await ProductApi.getDetails();
  if (success) {
    return data;
  } else {
    throw new Error(message);
  }
});
