import { apiGet, apiPost, apiDelete } from './connect';
import { Result } from 'app/types/api';
import { TechOrders, TechOrder, TechOrderForm } from 'app/types/techOrders';

export const getTechOrders = async () => {
  const { data: response } = await apiGet<Result<TechOrders>>('/techOrders');

  return response;
};

export const addTechOrder = async (order: Partial<TechOrder>) => {
  const { data: response } = await apiPost<Result<TechOrder>>(
    '/techOrders/add',
    order,
  );

  return response;
};

export const deleteTechOrder = async (id: number) => {
  const { data: response } = await apiPost<Result<TechOrders>>(
    `/techOrders/${id}/delete`,
  );

  return response;
};

export const updateTechOrder = async (id: number, order: TechOrderForm) => {
  const { data: response } = await apiPost<Result<TechOrder>>(
    `/techOrders/${id}/update`,
    order,
  );

  return response;
};

export const forceOrderSend = async () => {
  const { data } = await apiGet<Result<{}>>('/techOrders/send');

  return data;
};
