import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { Header } from 'components/Layout/Header';
import { sapNumber } from 'app/types/contracts';
import { CompanyResponse } from 'app/types/company';
import { changeContract } from 'features/slices/contracts';

import { positionsByMetalSelector, waitingListTotalSelector } from 'app/ducks/cart';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Affix, Button, Col, Row } from 'antd';
import { UserOutlined, ToolOutlined } from '@ant-design/icons';
import { actionTypes, sendEvent } from 'logger';
import { useIsRole } from 'hooks/useIsRole';
import { usePermission } from 'hooks/usePermission';
import { useSyncTabs } from 'hooks/useSyncTabs';
import { useAuth } from 'app/ducks/auth';
import { adminPermissions } from 'constants/index';
import { useMetalCost } from 'app/ducks/metalCost';

export const HeaderContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { syncAnyTabs } = useSyncTabs();
  const history = useHistory();
  const { logOut, setUserCompany } = useAuth();
  const { state: metalCostState, getActiveMetalCost, checkFileLink: checkMetalCostFile } = useMetalCost();

  const logoutHandler = async () => {
    await logOut();
    syncAnyTabs('logout');
  };

  const { login: isLogin, user, contracts, companies, company, balance, contractsLoading, metalsLoading } = useSelector(
    (state: RootState) => ({
      ...state.auth,
      ...state.contracts,
    }),
  );

  const clearQueryParams = () => {
    history.push({ search: '' });
  };

  const changeContractHandler = (id: sapNumber) => {
    if (company) {
      dispatch(changeContract(id, company.id));
      syncAnyTabs('contract');
      sendEvent({
        ActionType: actionTypes.HEADER_CLICK_CHANGE_CONTRACT,
        ContextKey: id,
      });
      clearQueryParams();
    }
  };

  const changeCompanyHandler = async (company: CompanyResponse) => {
    if (user) {
      await setUserCompany(company.id);
      syncAnyTabs('company');
      sendEvent({
        ActionType: actionTypes.HEADER_CLICK_CHANGE_COMPANY,
        ContextKey: company.fullName,
      });
      clearQueryParams();
    }
  };

  const total = useSelector(positionsByMetalSelector);
  const waitingListTotal = useSelector(waitingListTotalSelector);

  const { i18n } = useTranslation();
  const changeLocaleHandler = (lng: string) => {
    i18n.changeLanguage(lng);
    sendEvent({
      ActionType: actionTypes.HEADER_CHANGE_LANGUAGE,
      ContextKey: lng,
    });
  };
  const { menu } = useSelector((state: RootState) => ({
    ...state.auth,
    ...state.categories,
  }));

  const isManager = useIsRole('manager');
  const isAdmin = useIsRole('admin');
  const hasAccessToAdminPanel = usePermission(Object.values(adminPermissions), 'some');

  useEffect(() => {
    if (isLogin && contracts.some(({ active }) => !!active)) {
      getActiveMetalCost();
    }
  }, [isLogin, contracts]);

  return (
    <>
      <Header
        onChangeLocale={changeLocaleHandler}
        isLogin={isLogin}
        onLogout={logoutHandler}
        user={user}
        contractsLoading={contractsLoading}
        metalsLoading={metalsLoading}
        contracts={contracts}
        onChangeContract={changeContractHandler}
        balance={balance}
        total={total}
        waitingListTotal={waitingListTotal}
        headerPages={menu?.header || []}
        topPages={menu?.top || []}
        company={company}
        companies={companies}
        changeCompanyHandler={changeCompanyHandler}
        costMetal={metalCostState.costMetal}
        isLoadingMetalCost={metalCostState.loading}
        isLoadingMetalCostFile={metalCostState.loadingFile}
        onCheckMetalCostFile={checkMetalCostFile}
      />
      {/* Скорей всего уберем */}
      {(isManager || isAdmin || hasAccessToAdminPanel) && (
        <Row justify="end" style={{ paddingTop: '1px' }}>
          <Affix offsetTop={10}>
            <Col>
              {(isAdmin || hasAccessToAdminPanel) && (
                <Link to="/admin">
                  <Button icon={<ToolOutlined />} type="primary" style={{ borderRight: '1px solid #e0e0e0' }}>
                    Админка
                  </Button>
                </Link>
              )}
              {isManager && (
                <Link to="/users">
                  <Button
                    icon={<UserOutlined />}
                    type="primary"
                    onClick={() => sendEvent(actionTypes.HEADER_CLICK_CLIENTS)}
                  >
                    Мои клиенты
                  </Button>
                </Link>
              )}
            </Col>
          </Affix>
        </Row>
      )}
    </>
  );
};
