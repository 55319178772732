import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import reducer, { RootState } from './reducer';
import rootEpic from './epic';

import { createEpicMiddleware } from 'redux-observable';

const epicMiddleware = createEpicMiddleware<Action, Action, RootState, void>();

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware),
});

epicMiddleware.run(rootEpic);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducer', () => store.replaceReducer(reducer));
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<Promise<void>, RootState, null, Action<string>>;

export default store;
