import { apiPost } from './connect';
import { Result } from 'app/types/api';
import { OrderRenders } from 'app/types/renders';

export const getOrderRenders = async (dataRender: {
  id: number;
  comment: OrderRenders;
}) => {
  const { data } = await apiPost<Result<OrderRenders>>(
    `/order/${dataRender.id}/renders`,
    { comments: dataRender.comment },
  );
  return data;
};
