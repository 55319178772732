import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { RoleEntity } from 'app/types/roles';
import * as RoleApi from 'app/services/admin/roles';

export const getRoles = createAsyncThunk('roles/getRoles', async () => {
  const { data } = await RoleApi.getRoles();
  return data;
});

export const getPermissions = createAsyncThunk(
  'roles/getPermissions',
  async () => {
    const { data } = await RoleApi.getPermissions();
    return data;
  },
);

export const getRoleDetail = createAsyncThunk<
  RoleEntity,
  number,
  { state: RootState }
>('roles/getRoleDetail', async (id: number) => {
  const { data, success, message } = await RoleApi.getRoleById(id);
  if (success) {
    return data;
  } else throw new Error(message);
});

export const addRole = createAsyncThunk(
  'roles/addRole',
  async (newRole: RoleApi.ChangeableRoleFields, { dispatch }) => {
    const { data, success, message } = await RoleApi.addRole(newRole);
    if (success) {
      await dispatch(getRoles());
      return data;
    } else throw new Error(message);
  },
);

export const removeRole = createAsyncThunk(
  'roles/removeRole',
  async (id: number, { dispatch }) => {
    const { data } = await RoleApi.removeRole(id);
    await dispatch(getRoles());

    return data;
  },
);

export const updateRole = createAsyncThunk(
  'roles/updateRole',
  async (
    {
      id,
      newRole,
    }: {
      id: number;
      newRole: RoleApi.ChangeableRoleFields;
    },
    { dispatch },
  ) => {
    const { data, success, message } = await RoleApi.updateRole(id, newRole);
    if (success) {
      await dispatch(getRoles());
      return data;
    } else throw new Error(message);
  },
);
