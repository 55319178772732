import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';

export const useIsAutoOrderVisible = () => {
  const { company, hasParentUser } = useSelector((state: RootState) => state.auth);

  //JBP-315 hasParentUser флаг временный, только для менеджеров
  const isAutoOrderVisible = company?.settings['auto_order_is_available'] && hasParentUser;

  return isAutoOrderVisible;
};
