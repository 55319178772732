import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { OrdersService } from 'app/services/admin/orders';
import { UsersService } from 'app/services/admin/users';
import { CompaniesService } from 'app/services/admin/companies';
import { UpdateOrderDto } from 'app/types/adminOrders';
import { SLICE_NAME } from './constants';

export const fetchOrders = createAsyncThunk(
  `${SLICE_NAME}/fetchOrders`,
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await OrdersService.getMany(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateOrder = createAsyncThunk(
  `${SLICE_NAME}/updateOrder`,
  async (payload: UpdateOrderDto, { rejectWithValue }) => {
    const { data, success, message } = await OrdersService.update(payload);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchUserCompanies = createAsyncThunk(
  'forAdminOrders/fetchUserCompanies',
  async (payload: number, { rejectWithValue }) => {
    const { data, success, message } = await UsersService.getUserCompanies(payload);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchCompanyContracts = createAsyncThunk(
  'forAdminOrders/fetchCompanyContracts',
  async (payload: number, { rejectWithValue }) => {
    const { data, success, message } = await CompaniesService.getCompanyContracts(payload);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchProductTypes = createAsyncThunk(
  'forAdminOrders/fetchProductTypes',
  async (_: void, { rejectWithValue }) => {
    const { data, success, message } = await OrdersService.getProductTypes();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
