import { WelcomeBonus, SilverBonus } from 'models/api/promotions';

export type Bonus = WelcomeBonus | SilverBonus;

export const isWelcomeBonus = (bonus: Bonus): bonus is WelcomeBonus => {
  return (bonus as WelcomeBonus).newCompany !== undefined;
};

export const isSilverBonus = (bonus: Bonus): bonus is SilverBonus => {
  return (bonus as SilverBonus).holding !== undefined;
};
