import styled from 'styled-components';

export const SecondaryText = styled.span`
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;

export const MainText = styled.span`
  display: block;
  white-space: nowrap;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
`;

export const OrderNumberWrap = styled.div`
  display: grid;
  grid-row-gap: 0.35em;
`;

export const OrderPercentageWrap = styled.div<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#000' : '#d77721')};
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;

export const OrderProgress = styled.div<{ percent: number | string }>`
  position: relative;
  background-color: #f3f3f3;
  color: #fff;
  height: 26px;
  line-height: 26px;

  &::before {
    content: "${({ percent }) => `${percent}%`}";
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percent }) => `${percent}%`};
    min-width: 55px;
    padding-right: 15px;
    text-align: ${({ percent }) => `${percent == 100 ? 'center' : 'right'}`};
    background-color: #d77721;
    white-space:nowrap;
  }
`;

export const BlockTitle = styled.p`
  margin: 0 0 8px;
  color: #545353;
  font-size: 14px;
  font-family: 'Roboto';
`;

export const BlockValue = styled.p`
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-family: 'Roboto';
`;

export const BlockDescription = styled.p`
  margin: 8px 0 0;
  color: #545353;
  font-size: 12px;
  font-family: 'Roboto';
`;
