import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { TechOrder, TechOrderForm } from 'app/types/techOrders';
import * as TechOrdersApi from 'app/services/techOrders';

export const techOrdersAdapter = createEntityAdapter<TechOrder>({
  sortComparer: (a, b) =>
    new Date(a.createAt).getTime() - new Date(b.createAt).getTime(),
});

export const deleteTechOrder = createAsyncThunk(
  'techOrders/deleteTechOrder',
  async (id: number) => {
    const { message, success } = await TechOrdersApi.deleteTechOrder(id);
    if (success) {
      antdMessage.success(message);
      return id;
    } else {
      throw new Error(message);
    }
  },
);

export const updateTechOrder = createAsyncThunk(
  'techOrders/udpateTechOrder',
  async (parameters: { id: number; order: TechOrderForm }) => {
    const { message, success } = await TechOrdersApi.updateTechOrder(
      parameters.id,
      parameters.order,
    );
    if (success) antdMessage.success(message);
    else {
      throw new Error(message);
    }
  },
);

export const fetchTechOrders = createAsyncThunk(
  'techOrders/fetchTechOrder',
  async () => {
    const { message, success, data } = await TechOrdersApi.getTechOrders();
    if (success) return data;
    else throw new Error(message);
  },
);

export const addTechOrder = createAsyncThunk(
  'techOrders/addTechOrder',
  async (order: Partial<TechOrder>) => {
    const { message, success } = await TechOrdersApi.addTechOrder(order);
    if (success) {
      antdMessage.success(message);
    } else {
      throw new Error(message);
    }
  },
);

export const forceOrderSend = createAsyncThunk(
  'techOrders/forceOrderSend',
  async () => {
    const { message, success } = await TechOrdersApi.forceOrderSend();
    if (success) {
      antdMessage.success(message);
    } else {
      throw new Error(message);
    }
  },
);

const slice = createSlice({
  name: 'techOrders',
  initialState: techOrdersAdapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTechOrders.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchTechOrders.fulfilled,
      (state, { payload }: PayloadAction<TechOrder[]>) => {
        techOrdersAdapter.setAll(state, payload);
        state.loading = false;
      },
    );
    builder.addCase(fetchTechOrders.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
    builder.addCase(addTechOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(addTechOrder.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(addTechOrder.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
    builder.addCase(deleteTechOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      deleteTechOrder.fulfilled,
      (state, { payload: id }: PayloadAction<number>) => {
        state.loading = false;
        techOrdersAdapter.removeOne(state, id);
      },
    );
    builder.addCase(deleteTechOrder.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
    builder.addCase(updateTechOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateTechOrder.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(updateTechOrder.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });

    /* forceOrderSend */
    builder.addCase(forceOrderSend.pending, state => {
      state.loading = true;
    });
    builder.addCase(forceOrderSend.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(forceOrderSend.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});

const { reducer } = slice;

export default reducer;
