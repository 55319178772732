import { ThemeConfig } from 'antd';

export const THEME_CONFIG: ThemeConfig = {
  components: {
    Alert: {
      colorInfoBg: '#f5f5f5',
      colorInfoBorder: '#f5f5f5',
      colorSuccessBg: '#f6ffed',
    },
    Menu: {
      darkItemBg: '#ffffff',
      darkItemColor: '#212529',
      darkItemSelectedBg: '#E0E0E0',
      darkItemSelectedColor: '#212529',
      darkItemHoverBg: '#E0E0E0',
    },
    Tag: {
      colorSuccessBg: '#f6ffed',
    },
  },
  token: {
    colorPrimary: '#d77721',
    colorLink: '#d77721',
    colorSuccess: '#209e31',
    colorWarning: '#f0af30',
    colorInfo: '#d77721',
    borderRadius: 2,
    fontSize: 14,
    lineHeight: 1.5715,
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
};
