import React from 'react';
import * as S from './styled';

export type UserMenuProps = {
  menu: React.ReactNode;
};

export const UserMenu: React.FC<UserMenuProps> = ({ menu }) => {
  return <S.Menu size="1rem">{React.cloneElement(menu as React.ReactElement)}</S.Menu>;
};
