import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersService } from 'app/services/admin/users';
import { message as antdMessage } from 'antd';
import { UpdateUserDto, CreateUserDto } from 'app/types/userAdmin';
import { ChangePasswordPayload } from 'app/types/login';
import { LoginService } from 'app/services/login';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await UsersService.getMany(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload: UpdateUserDto, { rejectWithValue }) => {
    const { data, success, message } = await UsersService.update(payload);
    if (success && data) {
      //TODO: Исправить, когда бэк будет присылать сообщение об успешном обновлении
      antdMessage.success('Успешное обновление пользователя');
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const getAssignableRoles = createAsyncThunk(
  'roles/getAssignable',
  async (_: undefined, { rejectWithValue }) => {
    const { success, data, message } = await UsersService.getAssignableRoles();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: number, { rejectWithValue }) => {
    const { success, message } = await UsersService.delete(id);
    if (success) {
      //TODO: Исправить, когда бэк будет присылать сообщение об успешном удалении
      antdMessage.success('Пользователь успешно удалён');
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(id);
    }
  },
);
export const addUser = createAsyncThunk(
  'users/addUser',
  async (payload: CreateUserDto, { rejectWithValue }) => {
    const { success, message, data } = await UsersService.create(payload);
    if (success) {
      //TODO: Исправить, когда бэк будет присылать сообщение об успешном добавлении
      antdMessage.success('Пользователь успешно добавлен');
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const requestPasswordRecovery = createAsyncThunk(
  'users/requestPasswordRecovery',
  async (email: string, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
      code,
    } = await LoginService.requestPasswordRecovery(email);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      if (code === 404) {
        return rejectWithValue(data);
      } else {
        antdMessage.error(message);
      }
    }
  },
);
export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (payload: ChangePasswordPayload, { rejectWithValue }) => {
    const { data, success, message } = await LoginService.changePassword(
      payload,
    );
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
