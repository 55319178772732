/* eslint-disable camelcase */
import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChangePasswordForm } from './ChangePasswordForm';
import { useUser } from 'app/ducks/users';
import { ChangePasswordPayload } from 'app/types/login';
import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';

export interface Props {
  visible: boolean;
  hideModal: () => void;
  forcedPasswordСhange?: boolean;
}

export const ChangePasswordModal: React.FC<Props> = ({ visible, hideModal, forcedPasswordСhange = false }) => {
  const { t } = useTranslation('reset');
  const { changePassword, state: stateUser } = useUser();
  const { auth } = useSelector((state: RootState) => state);
  const { user } = auth;

  const handleChangePassword = async (payload: ChangePasswordPayload) => {
    const result = await changePassword(payload);
    if (result) {
      hideModal();
    }
  };

  return (
    <Modal
      confirmLoading={stateUser.loading}
      onCancel={forcedPasswordСhange ? undefined : hideModal}
      closable={forcedPasswordСhange ? false : true}
      open={visible}
      title={t(forcedPasswordСhange ? 'forcedСhangeModalTitle' : 'changeModalTitle')}
      footer={null}
      centered={true}
      destroyOnClose
      style={{ minWidth: '40em' }}
    >
      <ChangePasswordForm onChange={handleChangePassword} login={user?.login || null} />
    </Modal>
  );
};
