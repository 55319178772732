import React from 'react';
import { angleBottom } from 'components/KrasUIKit/icons';
import { Overlay } from './Overlay';
import * as S from './styled/Bonus.styled';

type BonusProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  children: JSX.Element | null;
  title: string;
};

export const Bonus = ({ open, setOpen, icon, children, title }: BonusProps) => {
  const handleOpenChange = (newFlag: boolean) => setOpen(newFlag);
  const handleClose = () => setOpen(false);

  if (children) {
    return (
      <S.Dropdown
        overlay={
          <Overlay title={title} onClose={handleClose}>
            {children}
          </Overlay>
        }
        placement="bottom"
        trigger={['click']}
        open={open}
        onOpenChange={handleOpenChange}
        overlayStyle={{ width: 'min-content' }}
        getPopupContainer={() => document.querySelector('header') as HTMLElement}
        arrow
      >
        <S.Button>
          <S.Icon src={icon} />
          <span>Бонусы</span>
          <S.AngleBottomIcon>{angleBottom}</S.AngleBottomIcon>
        </S.Button>
      </S.Dropdown>
    );
  }
  return null;
};
