import React from 'react';
export {
  angleBottom,
  angleLeft,
  angleRight,
  angleTop,
  production,
  picture,
  close,
  plus,
  notAllowed,
  trash,
  loading,
  notification,
  zoom,
  minus,
  question,
  search,
  fileOutlined,
  user,
  exit,
  cartOutlined,
  copyOutlined,
  finishedProduction,
  coil,
  waiting,
  download,
  nextRight,
  bubble,
  document,
  company,
  metall,
  phone as phoneOutlined,
  arrowBottom,
  cart as newCartOutlined,
  searchRight,
  next,
  clock,
  infoOutlined,
  refresh,
  email,
  arrowReturn,
} from './outlined';

export const IconLetter: React.FC<{
  size?: string | number;
  style?: React.CSSProperties;
}> = ({ children, size = '1em', style = {} }) => (
  <span
    style={{
      width: size,
      height: size,
      display: 'inline-block',
      lineHeight: '100%',
      verticalAlign: 'middle',
      ...style,
    }}
  >
    {children}
  </span>
);
export {
  cart,
  file,
  info,
  warning,
  copy,
  error,
  success,
  truck,
  palm,
  box,
  catalog,
  document as documentPrimary,
  phone,
  arrowTopFilled,
  arrowBottomFilled,
  number25,
  number50,
  number100,
} from './primary';
