import React, { useState } from 'react';
import { Valid, ValidProps } from 'components/Fields/Valid';
import { AutocompleteCompany } from 'app/features/Admin/AutocompleteForms/CompaniesDaData';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, Input, Row, Switch } from 'antd';
import { Text } from 'components/Modals/styled';

export interface Props {
  style?: { [key: string]: string };
  formRowProps: Omit<ValidProps, 'component' | 'name'>;
  showFooter?: boolean;
}

export const OrderForm = ({ style, formRowProps, showFooter = true }: Props) => {
  const { t } = useTranslation(['layout', 'feedback', 'company', 'form', 'modal', 'additional']);
  const [manualInput, setManualInput] = useState<boolean>(false);
  const { form } = formRowProps;

  return (
    <div style={style}>
      <Valid
        name="company"
        label={t('feedback:company')}
        placeholder={`Укажите название компании ${manualInput && 'вручную'}`}
        component={manualInput ? Input : AutocompleteCompany}
        style={{
          marginBottom: '11px',
        }}
        {...formRowProps}
      />
      <Row gutter={[20, 20]} style={{ marginBottom: '11px', marginLeft: 0 }}>
        <Switch
          defaultChecked={manualInput}
          onChange={checked => setManualInput(checked)}
          style={{ marginRight: '8px' }}
        />
        <span>{t('additional:noCompany')}</span>
      </Row>
      <Valid
        name="username"
        label={t('feedback:fullName')}
        placeholder="Как к вам обращаться?"
        component={Input}
        onChange={value => form.setFieldValue('phone', value.replace(/([a-zA-Zа-яА-Я])/g, ''))}
        {...formRowProps}
      />
      <Valid name="email" label="E-mail" placeholder="example@mail.ru" component={Input} {...formRowProps} />
      <Valid name="phone" label={t('feedback:phone')} placeholder="89123458912" component={Input} {...formRowProps} />
      {showFooter && (
        <>
          <Divider type="horizontal" />
          <Text>
            <Trans
              t={t}
              ns="additional"
              i18nKey="policy"
              components={[
                <a
                  key={1}
                  href="https://www.krastsvetmet.ru/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </Text>
        </>
      )}
    </div>
  );
};
