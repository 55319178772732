import { createSlice, Action } from '@reduxjs/toolkit';
import { PaginationNew } from 'app/types/pagination';
import * as actions from './actions';
import { AutoOrderDetailEntity, AutoOrderEntity, StatusEntity } from 'app/types/autoOrders';
import { SLICE_NAME } from './consts';

export interface Props {
  loading: boolean;
  list: AutoOrderEntity[];
  pagination?: PaginationNew;
  statuses: StatusEntity[];
  detail: AutoOrderDetailEntity | null;
}

const initialState: Props = {
  loading: false,
  list: [],
  statuses: [],
  detail: null,
};

const isPendingAction = (action: Action): action is Action =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/pending');

const isRejectedAction = (action: Action): action is Action =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/rejected');

const isFulFilledAction = (action: Action): action is Action =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/fulfilled');

const autoOrdersSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.getAutoOrders.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
      state.statuses = payload.meta.data?.statuses || [];
    });
    addCase(actions.getAutoOrder.fulfilled, (state, { payload }) => {
      state.detail = payload.item;
    });
    addMatcher(
      (action): action is Action => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is Action => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default autoOrdersSlice.reducer;
