import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { DataForDeleteOffer, DataForGetOfferList } from 'app/types/waitingLists';

export const useWaitingLists = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.waitingLists);

  const deleteOffer = ({ cartPositionId, productId }: DataForDeleteOffer) => {
    dispatch(actions.deleteOffer({ cartPositionId, productId }));
  };

  const fetchOffersList = (filters: DataForGetOfferList) => {
    dispatch(actions.fetchOffersList(filters));
  };

  const fetchWaitingLists = (search: string) => {
    dispatch(actions.fetchWaitingLists(search));
  };

  const fetchWaitingListsExcel = (search: string) => {
    dispatch(actions.fetchWaitingListsExcel(search));
  };

  return {
    state,
    deleteOffer,
    fetchOffersList,
    fetchWaitingLists,
    fetchWaitingListsExcel,
  };
};
