/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as types from 'app/types/cart';
import * as actions from './actions';

export interface CartState {
  offers: types.AddedOffers;
  total: types.Total | null;
  loading?: boolean;
  loadingCheckout?: boolean;
  loadingDifference?: boolean;
  updating?: boolean;
  loadingExel?: boolean;
  loadingImportExel?: boolean;
  positions: types.CartPosition[];
  chekedPositions: types.CheckedOrderType[];
  pickedMetals?: number[];
  lastOrders: types.Order[];
  lastAutoOrders: types.Order[];
  visibleLastOrders?: boolean;
  isVisibleLastAutoOrders?: boolean;
  differenceCartOrders?: types.CartDifferenceOrder[];
}

export const cartInitialState: CartState = {
  offers: [],
  total: null,
  positions: [],
  chekedPositions: [],
  lastOrders: [],
  lastAutoOrders: [],
};

const modifyCheckedPositionsByUpdate = (
  state: CartState,
  arg: types.UpdateCartPayload,
  payload: types.CartOfferUpdateEntity,
  isDelete?: boolean,
) => {
  state.chekedPositions = state.chekedPositions.map(position => {
    if (position.orderType !== arg.orderType) {
      return position;
    }

    const errors = Object.entries<types.ChekedErrorBody[]>(position.errors).map(([code, body]) => {
      return {
        code,
        body: body.map(checkedErrorBody => {
          return {
            ...checkedErrorBody,
            items: checkedErrorBody.items.map(item => {
              const modifiedOffers = isDelete
                ? item.offers.filter(offer => offer.id !== arg.cartOfferId)
                : item.offers.map(offer => {
                    if (offer.id !== payload.id) {
                      return offer;
                    }
                    return { ...offer, ...payload };
                  });
              return {
                ...item,
                offers: modifiedOffers,
              };
            }),
          };
        }),
      };
    });

    const modifiedErrors = errors.reduce(
      (acc, current) => ({
        ...acc,
        [current.code]: current.body,
      }),
      {} as {
        [key in types.ChekedError]: types.ChekedErrorBody[];
      },
    );

    return { ...position, errors: modifiedErrors };
  });
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: cartInitialState,
  reducers: {
    getCartSuccess: (state, { payload }: PayloadAction<types.CartPosition[]>) => {
      const metals = payload.map(({ metal }) => metal?.id || 0);
      const uniqMetals = new Set(metals);

      state.pickedMetals = state.pickedMetals ? state.pickedMetals : Array.from(uniqMetals);
      state.positions = payload;
      state.loading = false;
    },
    getCartEnd: state => {
      state.loading = false;
    },
    setMetals: (state, { payload }: PayloadAction<number[]>) => {
      state.pickedMetals = payload;
    },
    clearVisibleLastOrders: state => {
      state.visibleLastOrders = false;
      state.isVisibleLastAutoOrders = false;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(actions.removeToCart.pending, (state, action) => {
      const arg = action.meta.arg.action;
      state.updating = true;

      if (arg.action === 'offer') {
        const productIndex = state.positions.findIndex(({ productId, orderType }) => {
          return arg.positionId === productId && arg.orderType === orderType;
        });

        const offerIndex = state.positions[productIndex]?.offers.findIndex(({ offerId, typeOffer }) => {
          return arg.offerId === offerId && arg.typeOffer === typeOffer;
        });

        if (state.positions[productIndex].offers.length === 1) {
          state.positions.splice(productIndex, 1);
        } else {
          state.positions[productIndex].offers.splice(offerIndex, 1);
        }
      }

      if (arg.action === 'tab') {
        state.positions = state.positions.filter(({ orderType }) => orderType !== arg.orderType);
      }

      if (arg.action === 'products') {
        state.positions = state.positions.filter(({ orderType }) => orderType === 'waiting_list');
      }
    });

    addCase(actions.removeToCart.rejected, state => {
      state.updating = false;
    });

    addCase(actions.removeToCart.fulfilled, (state, action) => {
      state.updating = false;
      const arg = action.meta.arg.action;
      const payload = action.payload;

      action.meta.arg.isAdminOrManager && modifyCheckedPositionsByUpdate(state, arg, payload, true);
    });

    addCase(actions.addToCart.pending, state => {
      state.loading = true;
    });

    addCase(actions.addToCart.fulfilled, state => {
      state.loading = false;
    });

    addCase(actions.addToCart.rejected, state => {
      state.loading = false;
    });

    addCase(actions.updateToCart.pending, (state, action) => {
      state.updating = true;
      const arg = action.meta.arg.action;

      const productIndex = state.positions.findIndex(({ productId, orderType }) => {
        return arg.positionId === productId && arg.orderType === orderType;
      });

      const offerIndex = state.positions[productIndex]?.offers.findIndex(({ offerId, typeOffer, id }) => {
        return arg.offerId === offerId && arg.typeOffer === typeOffer && id === arg.cartOfferId;
      });

      if (arg.action === 'coating') {
        const coating = state.positions[productIndex].offers[offerIndex]?.coatingOfferList?.find(
          ({ id }) => id === arg.newOfferId,
        );

        if (coating) {
          state.positions[productIndex].offers[offerIndex].productResidues = Boolean(coating.productResidues);
          state.positions[productIndex].offers[offerIndex].max = coating.productResidues;
        }

        state.positions[productIndex].offers[offerIndex].offerId = arg.newOfferId || 0;
      }

      if (arg.action === 'length') {
        state.positions[productIndex].offers[offerIndex].length = arg.length || 0;
      }

      if (arg.action === 'weight') {
        state.positions[productIndex].offers[offerIndex].weight = arg.weight || 0;
      }

      if (arg.action === 'count') {
        state.positions[productIndex].offers[offerIndex].count = arg.count || 0;
      }
    });

    addCase(actions.updateToCart.rejected, state => {
      state.updating = false;
    });

    addCase(actions.updateToCart.fulfilled, (state, action) => {
      state.updating = false;
      const arg = action.meta.arg.action;
      const payload = action.payload;

      const productIndex = state.positions.findIndex(({ productId, orderType }) => {
        return arg.positionId === productId && arg.orderType === orderType;
      });

      const offerIndex = state.positions[productIndex]?.offers.findIndex(({ offerId, typeOffer, id }) => {
        return arg.offerId === offerId && arg.typeOffer === typeOffer && id === arg.cartOfferId;
      });

      const product = state.positions[productIndex].offers[offerIndex];

      state.positions[productIndex].offers[offerIndex] = {
        ...product,
        ...payload,
      };

      action.meta.arg.isAdminOrManager && modifyCheckedPositionsByUpdate(state, arg, payload);
    });

    addCase(actions.fetchCart.fulfilled, (state, { payload }) => {
      const metals = payload.data.positions.map(({ metal }) => metal?.id || 0);
      const uniqMetals = new Set(metals);
      state.pickedMetals = state.pickedMetals ? state.pickedMetals : Array.from(uniqMetals);
      state.positions = payload.data.positions;

      state.loading = false;
    });

    addCase(actions.fetchCart.pending, state => {
      // state.loading = true;
    });

    addCase(actions.fetchCart.rejected, state => {
      state.loading = false;
    });

    addCase(actions.fetchCartExcel.pending, state => {
      state.loadingExel = true;
    });

    addCase(actions.fetchCartExcel.fulfilled, state => {
      state.loadingExel = false;
    });

    addCase(actions.fetchCartExcel.rejected, state => {
      state.loadingExel = false;
    });

    addCase(actions.fetchCartImportExcel.pending, state => {
      state.loadingImportExel = true;
    });

    addCase(actions.fetchCartImportExcel.fulfilled, state => {
      state.loadingImportExel = false;
    });

    addCase(actions.fetchCartImportExcel.rejected, state => {
      state.loadingImportExel = false;
    });

    addCase(actions.checkCart.pending, state => {
      state.loading = true;
    });

    addCase(actions.checkCart.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chekedPositions = payload.data;
      state.pickedMetals = payload.metals.map(id => Number(id));
    });

    addCase(actions.checkCart.rejected, state => {
      state.loading = false;
    });

    addCase(actions.checkoutOrder.fulfilled, (state, { payload }) => {
      state.loadingCheckout = false;
      state.lastOrders = payload.data.createdOrders;
      state.lastAutoOrders = payload.data.createdAutoOrders;
      state.visibleLastOrders = payload.success;
      state.isVisibleLastAutoOrders = payload.success && payload.data.createdAutoOrders.length > 0;
      state.chekedPositions = [];
    });

    addCase(actions.checkoutOrder.rejected, state => {
      state.loadingCheckout = false;
      state.visibleLastOrders = false;
      state.isVisibleLastAutoOrders = false;
    });

    addCase(actions.checkoutOrder.pending, state => {
      state.loadingCheckout = true;
      state.visibleLastOrders = false;
      state.isVisibleLastAutoOrders = false;
    });

    addCase(actions.transferTo.pending, state => {
      state.loading = true;
    });

    addCase(actions.transferTo.fulfilled, state => {
      state.loading = false;
    });

    addCase(actions.transferTo.rejected, state => {
      state.loading = false;
    });

    addCase(actions.unionCoilAndFinished.pending, state => {
      state.loading = true;
    });

    addCase(actions.unionCoilAndFinished.fulfilled, state => {
      state.loading = false;
    });

    addCase(actions.unionCoilAndFinished.rejected, state => {
      state.loading = false;
    });

    addCase(actions.getDifferenceCartOrders.pending, state => {
      state.loadingDifference = true;
    });

    addCase(actions.getDifferenceCartOrders.fulfilled, (state, { payload }) => {
      state.loadingDifference = false;
      state.differenceCartOrders = payload;
    });

    addCase(actions.getDifferenceCartOrders.rejected, state => {
      state.loadingDifference = false;
    });
  },
});

export const { getCartSuccess, setMetals, clearVisibleLastOrders } = cartSlice.actions;

export default cartSlice.reducer;
