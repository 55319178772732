import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import { from, of, EMPTY } from 'rxjs';
import { message as AntMessage } from 'antd';
import { AppEpic } from '../epic';
import { fetchCart } from './actions/cart';
import { getCartSuccess } from 'app/ducks/cart';
import { getCart } from 'app/services/cart';

//TODO: fix ts error
//@ts-ignore
export const fetchCartEpic: AppEpic = action$ => {
  return action$.pipe(
    ofType(fetchCart().type),
    switchMap(() =>
      from(getCart()).pipe(
        switchMap(response => {
          const { success, data, message } = response;
          if (success) {
            return of(getCartSuccess(data.positions));
          }
          AntMessage.info(message);
          return EMPTY;
        }),
        catchError(() => AntMessage.info('Ошибка сети при инициализации корзины.')),
      ),
    ),
  );
};
