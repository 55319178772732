import React from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import * as S from './styled';
import { Button } from '../KrasUIKit';
import { BannerEntity } from 'app/types/banners';

export const BannersCarousel: React.FC<{
  list: BannerEntity[];
}> = ({ list }) => {
  return (
    <S.BannersCarousel autoplay={true} autoplaySpeed={3000} effect="fade">
      {list.map(({ title, id, image, description, link, linkTitle }) => (
        <Row key={id}>
          <Row justify="center">
            <S.ImgCarousel src={image.url || ''} alt="" />
          </Row>
          <Row justify="center">
            <Link to={link || ''}>
              <S.H4>{title}</S.H4>
            </Link>
          </Row>
          <Row justify="center">
            <S.StyledText type="secondary">{description}</S.StyledText>
          </Row>
          <Row justify="center">
            <Link to={link || ''}>
              <Button type="primary">{linkTitle}</Button>
            </Link>
          </Row>
        </Row>
      ))}
    </S.BannersCarousel>
  );
};
