import React, { useState, useEffect } from 'react';
import { Modal, Select, Input, Button, Row, Form, Col, Checkbox, InputNumber, Switch, Upload } from 'antd';
import { AdditionalProductType, MetadataFeaturesItem } from 'app/types/adminAdditional';
import { ValidProps } from 'components/Fields/Valid';
import { FieldArray } from 'formik';
import { MinusCircleOutlined } from '@ant-design/icons';
import { TagsAndAutocomplete } from 'components/TagsAndAutocomplete';
import { CategoriesAutocomplete } from 'app/features/Admin/AutocompleteForms/Categories';
import { Option } from 'components/Autocomplete';
import { useProducts } from 'app/ducks/productsAdmin';
import { AdditionalProductService } from 'app/services/admin/additionalProducts';
import { useAdditionalProducts } from 'app/ducks/addititionalProducts/hook';
import { message as antdMessage } from 'antd';

const { TextArea } = Input;

interface AdditionalProductFormProps {
  hideModal?: () => void;
}
interface ValuesType {
  type: string;
  ruTitle: string;
  enTitle: string;
  ruDescription: string;
  enDescription: string;
  categoryId: unknown[];
  alloyIds: number[];
  alloyWeights?: number[];
  alloyImages?: string[];
  active: boolean;
  image: string;
  additional: { [key: string]: any };
}

const defaultValues: ValuesType = {
  type: '',
  ruTitle: '',
  enTitle: '',
  ruDescription: '',
  enDescription: '',
  categoryId: [],
  alloyIds: [],
  alloyWeights: [],
  alloyImages: [],
  active: false,
  image: '',
  additional: {},
};

const formRowProps: Omit<ValidProps, 'form' | 'component' | 'name'> = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'right',
};

const rulesRequired = [{ required: true, message: 'Обязательное поле' }];

const generateForm = (metadata: MetadataFeaturesItem[]) => {
  const fields = metadata.map(item => {
    return {
      label: item.title,
      name: item.code,
      type: item.type === 'numeric' ? 'number' : 'text',
      Component: !item.select ? (item.type === 'numeric' ? InputNumber : Input) : FieldArray,
      validation: 'required',
      defaultValue: item.select ? [] : null,
      select: item.select !== null,
    };
  });

  const generated = fields.map(field => {
    return field.select ? (
      <Form.List
        name={field.name}
        rules={[
          {
            validator: async (_, values) => {
              if (!values || values.length < 1) {
                return Promise.reject(new Error('Добавьте хотя бы одно значение'));
              }
            },
          },
        ]}
      >
        {(itemFields, { add, remove }, { errors }) => (
          <>
            {itemFields.map((itemField, index) => {
              return (
                <Form.Item
                  {...(index === 0 ? formRowProps : { labelCol: { span: 0 }, wrapperCol: { span: 16, offset: 8 } })}
                  label={index === 0 ? field.label : ''}
                  key={itemField.key}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item rules={rulesRequired} {...itemField}>
                    <Input style={{ width: 'calc(100% - 30px)', marginRight: '15px' }} />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-20px)' }}
                    onClick={() => remove(itemField.name)}
                  />
                </Form.Item>
              );
            })}
            <Form.ErrorList errors={errors} />
            <Form.Item label={field.label} {...formRowProps}>
              <Button
                onClick={() => {
                  add();
                }}
              >
                Добавить
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    ) : (
      <Form.Item key={field.name} name={field.name} label={field.label} {...formRowProps} rules={rulesRequired}>
        <Input />
      </Form.Item>
    );
  });

  const values: { [key: string]: any } = {};

  fields.forEach(field => {
    values[field.name] = field.defaultValue;
  });

  return { fields: generated, values };
};

export const AdditionalProductForm = ({ hideModal }: AdditionalProductFormProps) => {
  const { getAdditionalLists, state } = useProducts();
  const { alloys } = state;
  const [meta, setMeta] = useState<MetadataFeaturesItem[]>([]);

  const [formProduct] = Form.useForm();

  useEffect(() => {
    getAdditionalLists();
  }, []);

  const selectCategoryHandler = (name: string, option: Option[]) => {
    formProduct.setFieldsValue({ [name]: option });
  };
  const { fields, values } = generateForm(meta);
  const initialValues = { ...defaultValues, ...values };
  const {
    state: { loading, metadata },
    requestAll,
  } = useAdditionalProducts();

  const handleSubmit = () => {
    formProduct.validateFields().then(async values => {
      const formdata = new FormData();
      const { alloyImages, alloyWeights, image } = values;
      if (image.length) {
        formdata.append('image', image[0]);
      }
      alloyImages.forEach((image: FileList, index: number) => {
        if (image) {
          formdata.append(`alloyImages[${index}]`, image[0]);
        }
      });
      alloyWeights.forEach((weight: string, index: number) => {
        if (weight) {
          formdata.append(`alloyWeights[${index}]`, weight);
        }
      });
      formdata.set('active', String(Number(values.active)));
      formdata.set('categoryId', values.categoryId[0].value);

      delete values.alloyWeights;
      delete values.alloyImages;
      delete values.categoryId;
      delete values.active;
      delete values.image;

      const keys = Object.keys(values);
      keys.forEach(key => {
        if (Array.isArray(values[key])) {
          values[key].forEach((value: any) => {
            formdata.append(`${key}[]`, value);
          });
        } else formdata.append(key, values[key]);
      });

      const { success, message } = await AdditionalProductService.createProduct(formdata);
      if (success) {
        requestAll();
        hideModal && hideModal();
        formProduct.resetFields();
        antdMessage.info(message);
      } else antdMessage.error(message);
    });
  };

  const handleCancel = () => {
    hideModal && hideModal();
    formProduct.resetFields();
  };

  const productTypesOptions = metadata
    ? metadata.productsMeta.map(({ code, title }) => ({ value: code, label: title }))
    : [];

  const handleProductTypeChange = (value: AdditionalProductType) => {
    const [{ features }] = metadata ? metadata?.productsMeta.filter(item => item.code === value) : [{ features: [] }];
    setMeta(features);
  };

  return (
    <>
      <Form form={formProduct} initialValues={initialValues} encType="multipart/form-data">
        <Form.Item label="Тип товара" name="type" {...formRowProps} rules={rulesRequired}>
          <Select options={productTypesOptions} onChange={handleProductTypeChange} />
        </Form.Item>
        <Form.Item label="Название (RU)" name="ruTitle" {...formRowProps} rules={rulesRequired}>
          <Input />
        </Form.Item>
        <Form.Item label="Название (ENG)" name="enTitle" {...formRowProps} rules={rulesRequired}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание (RU)" name="ruDescription" {...formRowProps}>
          <TextArea />
        </Form.Item>
        <Form.Item label="Описание (ENG)" name="enDescription" {...formRowProps}>
          <TextArea />
        </Form.Item>
        <Form.Item label="Изображение товара" name="image" valuePropName="files" {...formRowProps}>
          <input type="file" />
        </Form.Item>
        <Form.Item name="categoryId" label="Категория товаров" {...formRowProps} rules={rulesRequired}>
          <TagsAndAutocomplete
            component={CategoriesAutocomplete}
            onSetTags={selectCategoryHandler}
            dropdownMatchSelectWidth={false}
            singleTag
          />
        </Form.Item>
        <Form.Item name="active" label="Активный" valuePropName="checked" {...formRowProps}>
          <Switch checkedChildren="1" unCheckedChildren="0" defaultChecked={true} />
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
          {metadata.withAlloys.includes(formProduct.getFieldValue('type')) && (
            <Form.Item label="Сплав" name="alloyIds" {...formRowProps} rules={rulesRequired}>
              <Select
                placeholder="Сплав не выбран"
                mode="multiple"
                onChange={(value: number[]) => {
                  const alloyImages: string[] = [];
                  const alloyWeights: number[] = [];
                  const images = formProduct.getFieldValue('alloyImages');
                  const weights = formProduct.getFieldValue('alloyWeights');
                  value.forEach(id => {
                    alloyImages[id] = images[id];
                    alloyWeights[id] = weights[id];
                  });
                  formProduct.setFieldsValue({ alloyImages, alloyWeights });
                }}
              >
                {alloys.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.List name="alloyImages">
          {fields => (
            <>
              {fields.map(field => {
                if (field) {
                  const [targetAlloy] = alloys.filter(alloy => alloy.id === field.name);
                  return (
                    <Form.Item
                      label={`Изображение для ${targetAlloy && targetAlloy.name}`}
                      {...field}
                      key={field.key}
                      style={{ whiteSpace: 'pre' }}
                      valuePropName="files"
                    >
                      <input type="file" />
                    </Form.Item>
                  );
                }
              })}
            </>
          )}
        </Form.List>
        <Form.List name="alloyWeights">
          {fields => (
            <>
              {fields.map(field => {
                if (field) {
                  const [targetAlloy] = alloys.filter(alloy => alloy.id === field.name);
                  return (
                    <Form.Item
                      label={`Вес за единицу (${targetAlloy.name})`}
                      {...field}
                      key={field.key}
                      rules={[
                        {
                          required: metadata?.fittings.includes(formProduct.getFieldValue('type')),
                          message: 'Обязательное поле',
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  );
                }
              })}
            </>
          )}
        </Form.List>
        {fields}
        <Row gutter={[20, 20]} justify="center">
          <Col>
            <Button htmlType="button" disabled={loading} onClick={handleCancel}>
              Отмена
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="button" disabled={loading} onClick={handleSubmit}>
              Создать
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
