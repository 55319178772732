import styled from 'styled-components';

export const CheckboxContainer = styled.label<{
  disabled?: boolean;
}>`
  display: inline-flex;
  vertical-align: middle;
  ${props => (props.disabled ? 'color: #bbbbbb; cursor: not-allowed;' : 'color: #4f4f4f;')}
`;

export const Content = styled.span`
  margin-left: 0.5em;
  font-size: 16px;
  line-height: 1.188em;
`;

export const Icon = styled.svg`
  display: none;
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
//@ts-expect-error fixed in styled components v5 и выше
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{
  checked?: boolean;
}>`
  display: inline-block;
  flex: none;
  align-self: baseline;
  margin-top: 1px;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'salmon' : 'transparent')};
  border: 1px solid #767676;
  border-radius: 2px;
  transition: all 150ms;

  ${HiddenCheckbox}:hover + & {
    border: 1px solid #212529;
  }

  ${HiddenCheckbox}:focus + & {
    border: 1px solid #212529;
    box-shadow: 0px 0px 4px rgba(118, 118, 118, 0.5);
  }

  ${HiddenCheckbox}:disabled + & {
    border: 1px solid #bbbbbb;
    color: #bbbbbb;
  }

  ${HiddenCheckbox}:checked + & {
    border: 1px solid #d77721;
    background-color: #d77721;
    ${Icon} {
      display: block;
    }
  }

  ${HiddenCheckbox}:checked:disabled + & {
    cursor: not-allowed;
    border: 1px solid #bbbbbb;
    background-color: #bbbbbb;
  }
`;

// export const Checkbox = styled.label`
//   position: relative;
//   font-size: 16px;
//   padding-left: 1.5em;
//   display: inline-block;
//   line-height: 1.188em;
//   cursor: pointer;
//   :after,
//   :before {
//     content: '';
//     width: 1em;
//     height: 1em;
//     position: absolute;
//     display: block;
//     top: 0.125em;
//     left: 0;
//     border-radius: 0.125em;
//   }

//   :before {
//     border: 1px solid #767676;
//   }
// `;

// export const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
//   border: 0;
//   clip: rect(0 0 0 0);
//   clippath: inset(50%);
//   height: 1px;
//   margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   position: absolute;
//   white-space: nowrap;
//   width: 1px;
// `;

// export const Label = styled.span`
//   line-height: 1.188em;
// `;
