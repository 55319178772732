/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { number, declFromNum } from 'helpers/format';
import { sendEvent, actionTypes } from 'logger';
import { getTotal } from 'app/ducks/cart';
import Logo from 'components/Logo';
import { ProductSearchAutocomplete } from 'components/ProductSearchAutocomplete';
import { PageNav } from './components/PageNav/PageNav';
import { UserNav } from './components/UserNav/UserNav';
import { TotalByMetall, Total } from 'app/types/cart';
import { ParentMenuItem } from 'app/types/layout';
import { User } from 'app/types/user';
import * as S from './styled';
import { arrowBottom, newCartOutlined, next, clock, catalog } from 'components/KrasUIKit/icons';
import { Button } from 'components/KrasUIKit/components/Button';
import { useDebounceByOpen } from 'hooks/useDebounceByOpen';

export interface Props {
  pages: ParentMenuItem[];
  user: User | null;
  isLogin: boolean;
  total: TotalByMetall[];
  waitingListTotal: Total;
  onLogout: () => void;
  isRu: boolean;
}

export const MainNav: React.FC<Props> = ({ pages, total, waitingListTotal, user, isLogin, onLogout, isRu }) => {
  const { t } = useTranslation('main');
  const [isHoverCart, setIsHoverCart] = useState<boolean>(false);
  const { debounceByOpen } = useDebounceByOpen();

  const { count, weight, positions } = total.reduce<{
    weight: number;
    count: number;
    positions: number;
  }>(
    (sum, item) => ({
      count: sum.count + item.count,
      weight: sum.weight + item.total,
      positions: sum.positions + getTotal(item.positions).positions,
    }),
    { count: 0, weight: 0, positions: 0 },
  );

  const CartInfo: React.FC<{ total: TotalByMetall[] }> = ({ total }) => {
    return (
      <S.CartInfoWrap>
        <b>{t('layout:metalsInBasket')}</b>
        <S.MetalOrdering>
          {total.map(({ count, total, metal, metalId }) => (
            <React.Fragment key={metalId}>
              <S.NameMetal>{metal}</S.NameMetal>
              <span>
                <b>{number(count, 0)}</b> {t('units:pcs')}
              </span>
              <span>
                <b>{number(total)}</b> {t('units:g')}
              </span>
            </React.Fragment>
          ))}
        </S.MetalOrdering>
      </S.CartInfoWrap>
    );
  };

  /* Only first level customize */
  const customMenuItems: Record<string, { suffix?: React.ReactNode; icon?: JSX.Element }> = {
    '/catalog': {
      icon: catalog,
    },
    '/cart/waiting_list': {
      suffix:
        waitingListTotal.positions > 0 ? (
          <S.MenuItemSuffix>{number(waitingListTotal.positions, 0)}</S.MenuItemSuffix>
        ) : (
          undefined
        ),
      icon: clock,
    },
    '/import': {
      suffix: <S.IconLetterRifht size="0.75em"> {arrowBottom}</S.IconLetterRifht>,
    },
  };

  useEffect(() => {
    debounceByOpen(() => sendEvent(actionTypes.HEADER_HOVER_CART), isHoverCart, 100);
  }, [isHoverCart]);

  return (
    <S.MainNavContainer auth={Boolean(isLogin && user)}>
      <S.LogoWrap>
        <Link to="/" onClick={() => sendEvent(actionTypes.HEADER_CLICK_LOGO)}>
          <Logo type="inverse" />
        </Link>
      </S.LogoWrap>
      <PageNav
        items={pages.map(page => {
          if (customMenuItems[page.to]) {
            return { ...page, ...customMenuItems[page.to] };
          }
          return page;
        })}
        inverse={true}
      />
      {isLogin && user && (
        <>
          <UserNav onLogout={onLogout} />
          <S.SearchDesctop>
            <ProductSearchAutocomplete placeholder={t('main:search')} compact />
          </S.SearchDesctop>
          {positions && count && weight ? (
            <Tooltip
              title={<CartInfo total={total} />}
              placement="bottomRight"
              color="#4f4f4f"
              overlayStyle={{ maxWidth: '100%' }}
            >
              <Link
                to="/cart/products_production"
                onMouseEnter={() => setIsHoverCart(true)}
                onMouseLeave={() => setIsHoverCart(false)}
                onClick={() => sendEvent(actionTypes.HEADER_CLICK_CART)}
              >
                <S.CartWrap>
                  <S.IconLetterLeft size="1.75em">{newCartOutlined}</S.IconLetterLeft>
                  <S.CartLabel>
                    <Trans
                      t={t}
                      values={{
                        positions: number(positions, 0),
                        word: isRu
                          ? declFromNum(positions, ['позиция', 'позиции', 'позиций'])
                          : declFromNum(positions, ['position', 'positions', 'positions']),
                      }}
                      i18nKey="layout:counts"
                    />
                    <S.CartCount>
                      / {number(count, 0)} {t('units:pcs')}
                    </S.CartCount>
                    <br />
                    <b>{`${number(weight)} ${t('units:g')}`}</b>
                  </S.CartLabel>
                </S.CartWrap>
              </Link>
            </Tooltip>
          ) : (
            <Link to="/cart">
              <S.CartWrap>
                <S.IconLetter size="1.75em">{newCartOutlined}</S.IconLetter>
                <S.CartLabel>{t('layout:emptyCart')}</S.CartLabel>
              </S.CartWrap>
            </Link>
          )}
        </>
      )}
      {(!isLogin || !user) && (
        <>
          {isRu && (
            <a href="http://jewelry.krastsvetmet.ru/start/#rec348597829">
              <S.ButtonInverseOutline type="outline" onClick={() => sendEvent(actionTypes.HEADER_CLICK_PARTNERSHIP)}>
                {t('layout:startCooperation')}
              </S.ButtonInverseOutline>
            </a>
          )}
          <Link to="/login">
            <Button type="primary" icon={next} iconDirection="right">
              {t('layout:login')}
            </Button>
          </Link>
        </>
      )}
    </S.MainNavContainer>
  );
};

export default MainNav;
