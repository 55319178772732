import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { production, IconLetter } from 'components/KrasUIKit/icons';
import { date } from 'helpers/format';
import { OrderType } from 'app/types/cart';
import { Specification } from 'app/types/orders';
import { getOrderTypeTitles } from 'features/Orders/utils/getOrderTypeTitles';
import { OrderDownloadLinks } from 'common/orders/components/OrderDownloadLinks/OrderDownloadLinks';
import { useDownloadFiles } from 'common/orders/hooks/useDownloadFiles';
import * as S from './styled/Aside.styled';

const { Text, Paragraph } = Typography;

type AsideProps = {
  orderId: number;
  comment: string | null;
  dateCreate: string;
  dateEnd: string | null;
  dateRelease: string;
  showReleaseDate: boolean | '' | null;
  orderType: OrderType;
  productType: string;
  userName: string;
  specifications: Specification[];
};

export const Aside = ({
  orderId,
  comment,
  dateCreate,
  dateEnd,
  dateRelease,
  showReleaseDate,
  orderType,
  productType,
  userName,
  specifications,
}: AsideProps) => {
  const { onDownloadExcel, onDownloadImport, onDownloadSpecification, onRenderRequest } = useDownloadFiles(orderId);
  const { t } = useTranslation(['orderTypes']);
  const orderTypeTitles = getOrderTypeTitles(t);

  return (
    <S.Aside>
      <Row>
        <Col span={12}>
          <Text>
            <CalendarOutlined /> {date(dateCreate)}
          </Text>
        </Col>
        {dateEnd && (
          <Col span={24}>
            <S.SecondaryText>
              {t('orderParameters:estimatedReadinessDate')}
              {date(dateEnd)}
            </S.SecondaryText>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Paragraph style={{ fontSize: 18 }}>
            <IconLetter>{production}</IconLetter>

            <span
              style={{
                height: 22,
                verticalAlign: 'middle',
                paddingLeft: '0.1em',
              }}
            >
              {orderTypeTitles[orderType]}
            </span>
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paragraph style={{ fontSize: 18 }}>
            <S.SecondaryText>{t('orderParameters:userName')}</S.SecondaryText>
            {userName}
            <S.SecondaryText>{t('checkout:finishedproducttype')}</S.SecondaryText>
            {productType}
          </Paragraph>
        </Col>
      </Row>
      {comment && (
        <Row>
          <Paragraph style={{ fontSize: 18 }}>
            <Col>
              <S.SecondaryText>{t('orderParameters:commentOrder')}</S.SecondaryText>
              {comment}
            </Col>
          </Paragraph>
        </Row>
      )}
      {showReleaseDate && (
        <Row style={{ marginTop: 20 }}>
          <Paragraph style={{ fontSize: 18 }}>
            <S.SecondaryText>
              {t('personal:orderCompletionDate')}{' '}
              <Tooltip title={t('personal:orderDateTooltip')}>
                <QuestionCircleOutlined />
              </Tooltip>{' '}
              : {date(dateRelease)}
            </S.SecondaryText>
          </Paragraph>
        </Row>
      )}
      <Row style={{ marginTop: 20 }}>
        <Col>
          <OrderDownloadLinks
            specifications={specifications}
            onDownloadSpecification={onDownloadSpecification}
            onDownloadImport={onDownloadImport}
            onDownloadExcel={onDownloadExcel}
            onRenderRequest={onRenderRequest}
          />
          {/* <ImportLinks
            onRenderRequest={onRenderRequest}
            onDownloadImport={onDownloadImport}
            onDownloadExcel={onDownloadExcel}
          /> */}
        </Col>
      </Row>
      {/* <Row style={{ marginTop: 20 }}>
        <Col>
          <Specifications list={specifications} onDownload={onDownloadSpecificationById} />
        </Col>
      </Row> */}
    </S.Aside>
  );
};
