import { apiPost, apiGet, apiDelete } from './connect';
import { ManagersResponse, Manager, CompanyControl } from 'app/types/managers';
import { Result } from 'app/types/api';

export type ManagersResponseResult = Result<ManagersResponse>;

export const getAllManagers = async (page = '1', search: string) => {
  const { data } = await apiGet<ManagersResponseResult>('/managers', {
    page,
    filterBy: 'manager,company,client',
    filterValue: search,
  });
  return data;
};

export type AddAndDeleteCompanyRelation = Result<Manager>;

export const removeCompanyRelation = async (company: CompanyControl) => {
  const { data } = await apiDelete<AddAndDeleteCompanyRelation>(
    '/managers/bind',
    {
      managerId: company.managerId,
      companies: [company.companyId],
    },
  );
  return data;
};

export const addCompanyRelation = async (company: CompanyControl) => {
  const { data } = await apiPost<AddAndDeleteCompanyRelation>(
    '/managers/bind',
    {
      managerId: company.managerId,
      companies: [company.companyId],
    },
  );
  return data;
};
