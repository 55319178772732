import { apiPost, apiGet, apiDelete } from './connect';
import {
  News,
  NewsList,
  NewsType,
  NewsForm,
  UpdateNewsData,
} from 'app/types/news';
import { Result } from 'app/types/api';

export type NewsResult = Result<NewsList>;

export type NewsItemResult = Result<News | null>;

export const getNews = async () => {
  const { data } = await apiGet<NewsResult>('/news');
  return data;
};

export const getNewsItem = async (id: string) => {
  const { data } = await apiGet<NewsItemResult>(`/news/${id}`);
  return data;
};

export type NewsResponse = Result<News>;

export const sendUpdateNews = async ({ slug, news }: UpdateNewsData) => {
  const { data } = await apiPost<NewsResponse>(`/news/${slug}`, news);
  return data;
};

export type DeleteNewsResponse = Result<[]>;

export const sendDeleteNews = async (id: string) => {
  const { data } = await apiDelete<DeleteNewsResponse>(`/news/${id}`);
  return data;
};

export const sendAddNews = async (news: NewsForm) => {
  const { data } = await apiPost<NewsResponse>('/news/', news);
  return data;
};

export const getTypesNews = async () => {
  const { data } = await apiGet<Result<NewsType[]>>('/news/types');
  return data;
};
