import { DeleteOutlined } from '@ant-design/icons';
import { OrderRow, OrderCell } from 'features/AdditionalProducts/styled/Cart.styled';
import { TableImg } from 'features/AdditionalProducts/styled/Common.styled';
import { FittingItem } from 'features/AdditionalProducts/types/OrderItem';
import emptyPreview from 'images/png/tile-empty.png';
import React from 'react';
import { Count } from '../../Count';

interface CartItemRowProps {
  item: FittingItem;
  onChange: (value: number) => void;
  onDelete?: () => void;
}

export const CartItemRow = ({ item, onChange, onDelete }: CartItemRowProps) => {
  const [itemAlloy] = item.alloys.filter(alloy => alloy.id === item.alloyId);
  return (
    <OrderRow>
      <OrderCell style={{ width: '15%' }}>
        <TableImg src={itemAlloy.imagePath ?? emptyPreview} />
      </OrderCell>

      <OrderCell style={{ width: '25%' }}>{item.ruTitle}</OrderCell>

      <OrderCell style={{ width: '10%' }}>{itemAlloy.massPerUnit}</OrderCell>

      <OrderCell style={{ width: '20%' }}>
        <Count count={item.count} onChange={onChange} article="" hideTenIncr={true} />
      </OrderCell>

      <OrderCell style={{ width: '30%' }}>
        <span style={{ marginLeft: '35px' }} onClick={() => onDelete && onDelete()}>
          <DeleteOutlined />
        </span>
      </OrderCell>
    </OrderRow>
  );
};
