import { apiPost, apiGet, apiDelete } from '../connect';
import { Result, ItemsResultWithMeta } from 'app/types/api';
import { UserEntity, CreateUserDto, UpdateUserDto, UserCompanyEntity } from 'app/types/userAdmin';
import { RoleEntity } from 'app/types/roles';

class UsersServiceFactory {
  public async getMany(search = '') {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<UserEntity>>(`/admin/users${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async create(payload: CreateUserDto) {
    try {
      const { data } = await apiPost<Result<UserEntity>>('/users', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async update({ id, ...payload }: UpdateUserDto) {
    try {
      const { data } = await apiPost<Result<UserEntity>>(`/users/${id}`, payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async delete(id: number) {
    try {
      const { data } = await apiDelete<Result>(`/user/${id}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getUserCompanies(id: number) {
    try {
      const { data } = await apiGet<Result<UserCompanyEntity[]>>(`/user/${id}/companies`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getAssignableRoles() {
    try {
      const { data } = await apiGet<Result<RoleEntity[]>>('/users/assignable/roles');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const UsersService = new UsersServiceFactory();
