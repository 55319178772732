import React from 'react';
import { Modal, Spin } from 'antd';
import { Title } from '../Title';
import { ProductDetail } from '../ProductDetail';
import { Product } from '../../app/types/products';
import { AddingProduct } from '../../app/types/cart';
import { Contract } from 'app/types/contracts';
import { OrderType } from 'containers/ProductDetailModalContainer';

export interface Props {
  product: Product | null;
  visible?: boolean;
  onCancel: () => void;
  onAddToCart: (product: Omit<AddingProduct, 'product_id'>) => void;
  loading?: boolean;
  login: boolean;
  addingToCart?: boolean;
  order: OrderType | null;
  setOrder: React.Dispatch<React.SetStateAction<OrderType | null>>;
  activeContract?: Contract;
  wasAdded: null | 'waiting' | 'cart';
  setWasAdded: React.Dispatch<React.SetStateAction<'waiting' | 'cart' | null>>;
  isOneContract: boolean;
}

export const ProductModal: React.FC<Props> = ({
  product,
  visible,
  onCancel,
  loading,
  login,
  onAddToCart,
  addingToCart,
  order,
  setOrder,
  activeContract,
  wasAdded,
  setWasAdded,
  isOneContract,
}) => {
  return (
    <Modal
      title={
        <Title upper level={4} style={{ marginBottom: 0 }}>
          {product?.weaving} {product?.title}
        </Title>
      }
      open={visible}
      onCancel={onCancel}
      footer={null}
      width="95%"
      style={{
        minHeight: '100vh',
      }}
    >
      {loading && (
        <Spin
          spinning={true}
          style={{
            minHeight: '70vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}

      {product && !loading && (
        <ProductDetail
          {...product}
          onAddToCart={onAddToCart}
          login={login}
          addingToCart={addingToCart}
          order={order}
          setOrder={setOrder}
          activeContract={activeContract}
          wasAdded={wasAdded}
          setWasAdded={setWasAdded}
          isOneContract={isOneContract}
        />
      )}
    </Modal>
  );
};
