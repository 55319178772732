import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ru from './ru.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      ru,
    },
    fallbackLng: 'ru',
    lng: location.hostname.split('.').pop() === 'com' ? 'en' : 'ru',
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
