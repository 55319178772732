import React, { Fragment } from 'react';
import { ConsultationForm, MODAL_NAME } from '../components/Modals/ConsultationForm/ConsultationForm';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { useModal } from 'app/ducks/modals';
import { ConsultationFormData } from 'app/types/consultation';
import { useConsultation } from 'app/ducks/consultation';

import {
  ConsultationSuccess,
  MODAL_NAME as SUCCESS_MODAL_NAME,
} from 'components/Modals/ConsultationForm/ConsultationRequestSuccess';

export const ConsultationFormContainer: React.FC = () => {
  const { visible, hideModal } = useModal(MODAL_NAME);
  const { visible: successVisible, showModal: showSuccessModal, hideModal: hideSuccessModal } = useModal(
    SUCCESS_MODAL_NAME,
  );
  const { state, requestConsultation } = useConsultation();
  const { auth: authStore } = useSelector((state: RootState) => state);

  const submitHandler = async (values: ConsultationFormData) => {
    const resultSubmitHandler = await requestConsultation({ ...values, type: 'consulting' });
    if (resultSubmitHandler) {
      hideModal();
      showSuccessModal();
    }
  };

  return (
    <Fragment>
      <ConsultationForm
        onSubmit={submitHandler}
        loading={state.loading}
        visible={visible}
        hideModal={hideModal}
        user={authStore.user}
        company={authStore.company}
      />
      <ConsultationSuccess visible={successVisible} hideModal={hideSuccessModal} />
    </Fragment>
  );
};
