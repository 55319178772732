import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { CreateProductCategoryDto, UpdateProductCategoryDto } from 'app/types/productCategories';
import * as actions from './actions';

export const useProductCategories = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.productCategories);
  const categoryBadges = useSelector((state: RootState) => state.auth.categoryBadges);

  const getProductCategories = () => {
    return dispatch(actions.getProductCategories());
  };

  const updateProductCategory = async (id: number, payload: UpdateProductCategoryDto) => {
    return dispatch(actions.updateProductCategory({ id, payload }));
  };

  const createProductCategory = async (payload: CreateProductCategoryDto) => {
    const resultAction = await dispatch(actions.createProductCategory(payload));
    return actions.createProductCategory.fulfilled.match(resultAction);
  };

  const deleteProductCategory = async (id: number) => {
    return dispatch(actions.deleteProductCategory(id));
  };

  return {
    state,
    categoryBadges,
    createProductCategory,
    updateProductCategory,
    getProductCategories,
    deleteProductCategory,
  };
};
