import React from 'react';
import { Checkbox, Col, Form, Row, Select as SelectAntd } from 'antd';
import { CompanyResponse } from 'app/types/company';
import { Contracts } from 'app/types/contracts';
import { Filters as QueryFilters } from 'components/Filters';
import { Input, Select } from 'components/Filters/Elements';
import { Metal } from 'app/types/orders';
import { useQuery } from 'hooks/useQuery';
import { StatusEntity } from 'app/types/autoOrders';

const { Option } = SelectAntd;

type FiltersProps = {
  company: CompanyResponse | null;
  contracts: Contracts;
  metals: Metal[];
  statuses: StatusEntity[];
};

export const Filters = ({ contracts, company, metals, statuses }: FiltersProps) => {
  const query = useQuery();
  const checkedMetals = query.get('metals')?.split(',');
  const COL_STYLE: React.CSSProperties = { flex: 'auto', maxWidth: '20%' };

  return (
    <QueryFilters layout="vertical" wrapperCol={{ offset: 0, span: 8 }}>
      <Row gutter={20}>
        <Col style={COL_STYLE}>
          <Input
            name="autoOrderId"
            label="Поиск"
            placeholder="Введите номер заказа"
            formItemProps={{
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
            }}
          />
        </Col>
        <Col style={COL_STYLE}>
          <Select
            name="contractNumber"
            label="Договор"
            placeholder="Не выбрано"
            formItemProps={{
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
            }}
          >
            {contracts.length &&
              contracts.map(({ id, knfmpNumber, sapNumber }) => (
                <Option key={id} value={knfmpNumber || sapNumber}>
                  {knfmpNumber || sapNumber}
                </Option>
              ))}
          </Select>
        </Col>
        <Col style={COL_STYLE}>
          <Select
            name="userName"
            label="Пользователь"
            placeholder="Не выбрано"
            formItemProps={{
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
            }}
          >
            {company &&
              company.users.map(({ name }) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col style={COL_STYLE}>
          <Select
            name="status"
            label="Статус"
            placeholder="Не выбрано"
            formItemProps={{
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
            }}
          >
            {statuses &&
              statuses.map(({ title, code }) => (
                <Option key={code} value={code}>
                  {title}
                </Option>
              ))}
          </Select>
        </Col>
        <Col style={COL_STYLE}>
          <Form.Item name="metalIds" label="Сплав металла" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Checkbox.Group defaultValue={checkedMetals}>
              <Row gutter={[1, 2]}>
                {metals &&
                  metals
                    .filter(item => item.id !== 4)
                    .map(({ id, name }) => (
                      <Col key={id} span={12}>
                        <Checkbox value={id.toString()}>{name}</Checkbox>
                      </Col>
                    ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
    </QueryFilters>
  );
};
