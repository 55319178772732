import React from 'react';
import { Icon as IconInterface } from './index';

export const Download: React.FC<IconInterface> = ({
  width = '1em',
  height = '1em',
  color = 'currentColor',
  ...args
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...args}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47721 1.33301C7.10902 1.33301 6.81055 1.63149 6.81055 1.99967V6.35775H5.01111C4.41214 6.35775 4.11702 7.08625 4.54711 7.50312L7.53069 10.395C7.78829 10.6447 8.19731 10.6457 8.4562 10.3974L11.4707 7.5055C11.9041 7.0897 11.6098 6.35775 11.0092 6.35775H9.19123V1.99967C9.19123 1.63148 8.89276 1.33301 8.52457 1.33301H7.47721ZM1.86719 13.8817C1.86719 13.4399 2.22536 13.0817 2.66719 13.0817H13.3339C13.7757 13.0817 14.1339 13.4399 14.1339 13.8817C14.1339 14.3235 13.7757 14.6817 13.3339 14.6817H2.66719C2.22536 14.6817 1.86719 14.3235 1.86719 13.8817Z"
        fill={color}
      />
    </svg>
  );
};
