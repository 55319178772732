import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface TooltipProps {
  children: string | JSX.Element | (string | JSX.Element)[];
  noMarginRight?: boolean;
}

export const Tooltip = ({ children, noMarginRight }: TooltipProps) => {
  return (
    <AntdTooltip title={children} overlayStyle={{ maxWidth: '310px', fontSize: '12px', fontWeight: 400 }}>
      <QuestionCircleOutlined width={20} height={20} style={{ margin: noMarginRight ? '0 0 0 10px' : '0 10px' }} />
    </AntdTooltip>
  );
};
