/* eslint-disable prettier/prettier */
import { RootState } from 'app/store/reducer';
import { createSelector } from '@reduxjs/toolkit';
import { coilResiduesAdapter } from './slice';

export const { selectById: selectResidueById, selectAll: selectAllResidues } =
  coilResiduesAdapter.getSelectors((state: RootState) => state.coilResidues);

export const selectResidueType = createSelector(
  (state: RootState) => state.coilResidues.editableResidue,
  editableResidue => editableResidue?.type,
);
