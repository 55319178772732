import React from 'react';
import { Checkbox, Form, Col, Row, Select as SelectAntd, Button } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { CompanyResponse } from 'app/types/company';
import { Contracts } from 'app/types/contracts';
import { Metal } from 'app/types/orders';
import { Filters } from 'components/Filters';
import { Input, Select } from 'components/Filters/Elements';
import * as S from './styled/Filter.styled';

const { Option } = SelectAntd;

type Props = {
  company: CompanyResponse | null;
  contracts: Contracts;
  metals: Metal[];
};

export const Filter = ({ contracts, company, metals }: Props) => {
  const query = useQuery();
  const checkedMetals = query.get('metals')?.split(',');

  return (
    <S.TableControl>
      <Filters layout="vertical" wrapperCol={{ offset: 0, span: 8 }}>
        <Row gutter={20}>
          <Col span={6}>
            <Input
              name="q"
              label="Поиск"
              placeholder="Введите номер заказа"
              formItemProps={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
            />
          </Col>
          <Col span={6}>
            <Select
              name="contractNumber"
              label="Договор"
              placeholder="Не выбрано"
              formItemProps={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
            >
              {contracts.length &&
                contracts.map(({ id, knfmpNumber, sapNumber }) => (
                  <Option key={id} value={knfmpNumber || sapNumber}>
                    {knfmpNumber || sapNumber}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              name="userName"
              label="Пользователь"
              placeholder="Не выбрано"
              formItemProps={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
            >
              {company &&
                company.users.map(({ name }) => (
                  <Option key={name} value={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <Form.Item name="metals" label="Сплав металла" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Checkbox.Group defaultValue={checkedMetals}>
                <Row gutter={[1, 2]}>
                  {metals &&
                    metals
                      .filter(item => item.id !== 4)
                      .map(({ id, name }) => (
                        <Col key={id} span={12}>
                          <Checkbox value={id.toString()}>{name}</Checkbox>
                        </Col>
                      ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Filters>
    </S.TableControl>
  );
};
