import { createAsyncThunk } from '@reduxjs/toolkit';
import * as SearchApi from 'app/services/search';

const searchProducts = createAsyncThunk(
  'search/searchProducts',
  async (payload: { search: string; page?: string | null }) => {
    const pageNumber = payload.page ? parseInt(payload.page) : 1;
    const { data, message, success } = await SearchApi.getProductsBySearchQuery(
      payload.search,
      pageNumber,
    );
    if (success) {
      const transformedData = {
        products: data.list,
        pagination: data.pagination,
      };
      return transformedData;
    } else throw new Error(message);
  },
);

export { searchProducts };
