import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const getStylesByType = (type: string) => {
  const defaultStyles = `
    background-color: transparent;
    font-weight: normal;
    color: #4f4f4f;
    border: 1px solid currentColor;
    :hover {
      color: #767676;
    }
    :focus {
      box-shadow: 0px 0px 4px #bbbbbb;
      color: inherit;
    }
    :active {
      color: #212529;
      box-shadow: none;
    }
    :disabled {
      color: #bbbbbb;
      cursor: not-allowed;
    }
  `;

  switch (type) {
    case 'primary': {
      return `
        background-color: #D77721;
        border: 1px dashed #D77721;
        font-weight: bold;
        color: #fff;
        
        :hover {
          background-color: #F08B30;
        }
        :focus {
          background-color: #F08B30;
          box-shadow: 0px 0px 4px rgba(215, 119, 33, 0.5);
        }
        :active {
          background-color: #BF691D;
          box-shadow: none;
        }
        :disabled {
          background-color: #E9B281;
          cursor: not-allowed;
        }
      `;
    }
    case 'outline': {
      return defaultStyles;
    }
    case 'dashed': {
      return `
        ${defaultStyles}
        border: 1px dashed currentColor;
      `;
    }
    case 'link': {
      return `
        background-color: transparent;
        font-weight: bold;
        border: none;
        :hover {
          color: #767676;
        }
        :active {
          color: #212529;
        }
        :disabled {
          color: #bbbbbb;
        }
      `;
    }
    default: {
      return defaultStyles;
    }
  }
};

const getFontSizeBySize = (size: string) => {
  switch (size) {
    case 'small':
      return 12;
    case 'medium':
      return 14;
    case 'large':
      return 32;
    default:
      return 18;
  }
};

const getMarginByDirection = (direction: 'left' | 'right' | undefined) => {
  return direction === 'left'
    ? 'margin-right: 0.28em;'
    : 'margin-left: 0.28em;';
};

export const Component = styled.div<{
  colorType: 'primary' | 'outline' | 'dashed' | 'link';
  size: 'small' | 'medium' | 'large';
  iconDirection?: 'left' | 'right';
  theme?: 'warning' | 'danger' | 'info' | 'success' | 'default';
  type?: string;
  href?: string;
  as: string;
}>`
  font-size: ${props => getFontSizeBySize(props.size)}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 1.14em 0.35em;
  border-radius: 3.57em;
  cursor: pointer;
  outline: none;

  ${props => getStylesByType(props.colorType)}
  svg {
    width: 1.14em;
    height: 1.14em;
    ${props =>
      props.colorType === 'link' ? 'font-size: 1.143em; color: #212529;' : ''}
    margin-bottom: -0.1em;
    ${props =>
      props.colorType === 'link'
        ? ''
        : getMarginByDirection(props.iconDirection)}
  }
  && {
    .loading {
      animation: ${loadingAnimation} 1s infinite linear;
      margin-left: 0.9em;
    }
  }

  transition: all 0.2s ease;
`;
