import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ContractsDetails } from './ContractsDetails';
import { BalanceDetails } from './BalanceDetails';
import { CompanyDetails } from './CompanyDetails';
import { ChangePasswordModal } from 'components/Modals/ChangePassword';

import { StateProps as ContractsInterface } from 'features/slices/contracts';
import { User as UserType } from 'app/types/user';
import { CompanyResponse as CompanyType } from 'app/types/company';

interface Props {
  user: UserType | null;
  contracts: ContractsInterface;
  company: CompanyType | null;
}

export const RegistrationData: React.FC<Props> = ({
  company,
  user,
  contracts,
}) => {
  const { t } = useTranslation('reset');
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <Row>
        <Col flex="auto">
          <Row style={{ marginBottom: '10px' }}>
            <Col flex="1 0 200px" style={{ marginBottom: '10px' }}>
              <CompanyDetails company={company} user={user} />
              <Row style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisibleModal(true);
                  }}
                >
                  {t('changePassword')}
                </Button>
              </Row>
            </Col>

            <Col flex="1 0 200px">
              <ContractsDetails contracts={contracts.contracts} />
            </Col>
          </Row>
        </Col>

        <Col flex="0 0 269px">
          <BalanceDetails balance={contracts.balance} />
        </Col>
      </Row>
      <ChangePasswordModal
        hideModal={() => {
          setVisibleModal(false);
        }}
        visible={visibleModal}
      />
    </>
  );
};
