import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'app/types/products';
import * as actions from './actions';

export interface ProductSliceProps {
  product: Product | null;
  loading: boolean;
  id: number | null;
}

export const productsInitialState: ProductSliceProps = {
  loading: false,
  product: null,
  id: null,
};

const productSlice = createSlice({
  name: 'productDetail',
  initialState: productsInitialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(
      actions.fetchProduct.fulfilled,
      (state, { payload: { data: product, id } }: PayloadAction<{ data: Product; id: number }>) => {
        //! Фильтрация по активности офферов, показываем только активные
        const filteredOffers = product?.offers.filter(item => item.active === true);
        state.product = { ...product, offers: filteredOffers };
        state.loading = false;
        state.id = id;
      },
    );
    addCase(actions.fetchProduct.pending, state => {
      state.loading = true;
    });
  },
});

export default productSlice.reducer;
