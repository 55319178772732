import { createAsyncThunk } from '@reduxjs/toolkit';
import * as WaitingListApi from 'app/services/admin/waitingLists';
import { DataForDeleteOffer, DataForGetOfferList } from 'app/types/waitingLists';
import download from 'downloadjs';

export const fetchWaitingLists = createAsyncThunk('waitingLists/fetchWaitingLists', async (search: string) => {
  const { message, success, data } = await WaitingListApi.getWaitingLists(search);
  if (success) return data;
  else throw new Error(message);
});

export const fetchWaitingListsExcel = createAsyncThunk(
  'waitingLists/fetchWaitingListsExcel',
  async (search: string) => {
    const { message, success, data } = await WaitingListApi.getWaitingListsExcel(search);
    if (success) {
      download(data.item.data, data.item.name, data.item.mimeType);
      return data;
    } else throw new Error(message);
  },
);

export const fetchOffersList = createAsyncThunk(
  'waitingLists/fetchOffersList',
  async (filters: DataForGetOfferList) => {
    const { data, success, message } = await WaitingListApi.getOffersList(filters);
    if (success) return { productId: filters.productId, data };
    else throw new Error(message);
  },
);

export const deleteOffer = createAsyncThunk(
  'waitingLists/deleteOffer',
  async ({ cartPositionId, productId }: DataForDeleteOffer) => {
    const { success, message } = await WaitingListApi.deleteOffer(cartPositionId);
    if (success) return { productId, cartPositionId };
    else throw new Error(message);
  },
);
