import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Banners } from '../components/Banners';
import { usePublicBanners } from 'app/ducks/publicBanners';
import { useModal } from 'app/ducks/modals';
import store from 'store';

export const MODAL_NAME = 'banners';

export const BannersContainer: React.FC = () => {
  const { pathname } = useLocation();
  const { visible, hideModal, showModal } = useModal(MODAL_NAME);

  const { state, fetchPubicBanners } = usePublicBanners();
  const { list, loading } = state;

  const newsShown = store.get('bannersShown') || [];

  const currentNews = list.filter(({ pages, active, id }) => {
    const isCurrentPlaces = pages ? pages.includes(pathname) : false;
    return isCurrentPlaces && active && !newsShown.includes(id);
  });

  useEffect(() => {
    fetchPubicBanners();
  }, []);

  useEffect(() => {
    if (!visible && currentNews.length !== 0) {
      showModal();
    } else if (visible && currentNews.length === 0) {
      hideModal();
    }
  }, [currentNews]);

  const closeModalHandler = () => {
    const ids = currentNews.map(({ id }) => id);
    store.set('bannersShown', [...newsShown, ...ids]);
    hideModal();
  };

  return (
    <Banners
      visible={visible}
      onCancel={closeModalHandler}
      list={currentNews}
      loading={loading}
    />
  );
};
