import * as actions from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { PaginationNew } from 'app/types/pagination';
import * as prefixes from './consts';
import { AdminMetalCostTemplate, AdminMetalCostList, AdminMetalCostOptions } from 'app/types/adminMetalCost';
import { addLoadingMatchers } from 'app/store/utils/addLoadingMatchers';

interface InitialState {
  loading: boolean;
  loadingUpload: boolean;
  loadingFile: boolean;
  list: AdminMetalCostList;
  pagination?: PaginationNew;
  filepath?: string | null;
  template?: AdminMetalCostTemplate;
  options: AdminMetalCostOptions;
}

export const initialState: InitialState = {
  loading: false,
  loadingUpload: false,
  loadingFile: false,
  list: [],
  options: {
    canUpdate: false,
    canStop: false,
  },
};

const metalCostSlice = createSlice({
  name: prefixes.ADMIN_METAL_COST_PREFIX,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actions.getMetalCosts.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
      state.filepath = payload.meta.data.filepath;
      state.template = payload.meta.data.template;
      state.options = payload.meta.data.options;
    });

    addLoadingMatchers(builder, prefixes.GET_ADMIN_METAL_COST, 'loading');
    addLoadingMatchers(builder, prefixes.ADD_ADMIN_METAL_COST, 'loading');
    addLoadingMatchers(builder, prefixes.STOP_ADMIN_METAL_COST, 'loading');
    addLoadingMatchers(builder, prefixes.UPLOAD_ADMIN_METAL_COST_TEMPLATE, 'loadingUpload');
    addLoadingMatchers(builder, prefixes.DELETE_ADMIN_METAL_COST_TEMPLATE, 'loadingUpload');
    addLoadingMatchers(builder, prefixes.CHECK_ADMIN_METAL_COST_FILELINK, 'loadingFile');
  },
});

export default metalCostSlice.reducer;
