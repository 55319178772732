import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateProductCategoryDto,
  UpdateProductCategoryDto,
} from 'app/types/productCategories';
import { message as antdMessage } from 'antd';
import { ProductCategoriesService } from 'app/services/admin/productCategories';

/* 
  TODO: Очень бесит дублировать проверки на success и rejectWithValue
  Найти способ не дублировать 
*/

export const getProductCategories = createAsyncThunk(
  'admin/productCategories/getProductCategories',
  async (_, { rejectWithValue }) => {
    const { data, success, message } = await ProductCategoriesService.getMany();

    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const createProductCategory = createAsyncThunk(
  'admin/productCategories/createProductCategory',
  async (payload: CreateProductCategoryDto, { rejectWithValue }) => {
    const { data, success, message } = await ProductCategoriesService.create(
      payload,
    );

    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateProductCategory = createAsyncThunk(
  'admin/productCategories/updateProductCategory',
  async (
    { id, payload }: { id: number; payload: UpdateProductCategoryDto },
    { rejectWithValue },
  ) => {
    const { data, success, message } = await ProductCategoriesService.update(
      id,
      payload,
    );

    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteProductCategory = createAsyncThunk(
  'admin/productCategories/deleteProductCategory',
  async (id: number, { rejectWithValue }) => {
    const { data, success, message } = await ProductCategoriesService.delete(
      id,
    );

    if (success && data) {
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
