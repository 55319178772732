import { DATE_WITH_TIME_FORMAT, DEFAULT_DATE_FORMAT, DEFAULT_TIME_ZONE } from 'constants/dateFormats';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const date = (utcString: string | dayjs.Dayjs | null, format = DEFAULT_DATE_FORMAT): string => {
  const value = utcString !== '' && utcString !== null ? utcString : undefined;
  return dayjs(value).format(format);
};

export const dateTimeWithTimeZone = (
  utcString: string | dayjs.Dayjs,
  timeZone: string = DEFAULT_TIME_ZONE,
  format: string = DATE_WITH_TIME_FORMAT,
) => {
  const value = utcString !== '' ? utcString : undefined;
  return dayjs(value)
    .tz(timeZone)
    .format(format);
};

export const number = (value: number, digits: number | [number, number] = 2) => {
  const digitsConfig =
    typeof digits === 'number'
      ? { minimumFractionDigits: digits, maximumFractionDigits: digits }
      : { minimumFractionDigits: digits[0], maximumFractionDigits: digits[1] };
  const formatter = new Intl.NumberFormat('ru', digitsConfig);
  return formatter.format(value);
};

export const declFromNum = (number: number, words: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};
