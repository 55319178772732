import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { fetchProduct } from 'app/ducks/productDetail/actions';
import {
  openProductDetail as $openProductDetail,
  openDetail as $openDetail,
  closeProductDetail as $closeProductDetail,
  resetState as $resetState,
  resetCategoryDetail as $resetCategoryDetail,
} from './slice';
import { show } from 'app/ducks/modals/slice';
import { UPDATE_CATEGORY_MODAL } from 'components/Modals/UpdateCategory';
import { UpdateCategory } from 'app/types/categories';

export const useCategories = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.categories);
  const categoryBadges = useSelector((state: RootState) => state.auth.categoryBadges);

  const fetchProductCategory = async ({ path, isForceUpdate }: { path: string; isForceUpdate?: boolean }) => {
    dispatch(actions.fetchProductCategory({ path, isForceUpdate }));
  };

  const showProductDetail = async (id: number) => {
    dispatch($openProductDetail());
    dispatch(fetchProduct(id));
  };

  const openCategoryDetail = async (id: number, showParams: 0 | 1) => {
    dispatch($openDetail({ id, detailShowParams: showParams }));
    dispatch(show(UPDATE_CATEGORY_MODAL));
  };

  const update = async ({ id, body }: UpdateCategory) => {
    dispatch(actions.update({ id, body }));
  };
  const closeProductDetail = async () => {
    dispatch($closeProductDetail());
  };
  const resetState = async () => {
    dispatch($resetState());
  };
  const resetCategoryDetail = async () => {
    dispatch($resetCategoryDetail());
  };
  return {
    state,
    categoryBadges,
    fetchProductCategory,
    showProductDetail,
    update,
    openCategoryDetail,
    resetState,
    closeProductDetail,
    resetCategoryDetail,
  };
};
