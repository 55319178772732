import { Table } from 'antd';
import { Tabs } from 'components/KrasUIKit';
import { Count } from '../../../Count';
import { ContentProps } from 'features/AdditionalProducts/types/Furniture';
import React, { useState, useEffect } from 'react';
import emptyPreview from 'images/png/tile-empty.png';
import { TableImg } from 'features/AdditionalProducts/styled/Common.styled';
import { useTranslation } from 'react-i18next';

export const Element = ({ products, onChange, onTabChange, productKey }: ContentProps) => {
  const { t } = useTranslation('additional');
  const [activeTab, setActiveTab] = useState<string | number>(1);

  const tabs = Object.values(products).map((tab, index) => {
    const dataSource = tab.products.map((product, index) => ({
      key: index,
      name: product.ruTitle,
      alloy: tab.alloy.name,
      weight: product.alloys.filter(alloy => alloy.id === tab.alloy.id)[0].massPerUnit,
      image: product.alloys.filter(alloy => alloy.id === tab.alloy.id)[0].imagePath,
      count: product.count,
    }));

    const columns = [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        render: (image: string) => <TableImg src={image ?? emptyPreview} />,
      },
      {
        title: t('table.size'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('table.weight'),
        dataIndex: 'weight',
        key: 'weight',
      },
      {
        title: t('table.count'),
        dataIndex: 'count',
        key: 'count',
        render(val: number, item: { key: number; weight: number }) {
          return (
            <Count
              count={val ?? 0}
              onChange={value => onChange(tab.alloy, productKey, item.key, value, value * item.weight)}
              article=""
              hideTenIncr={false}
            />
          );
        },
      },
      {
        title: t('table.selected'),
        dataIndex: 'count',
        key: 'count',
        render: (val: number) => val > 0 && val,
      },
    ];

    return {
      id: tab.alloy.id,
      title: tab.alloy.name,
      content: <Table columns={columns} dataSource={dataSource} pagination={false} />,
      count: tab.products.reduce((acc, item) => acc + item.count, 0),
    };
  });

  const handleTabChange = (key: number | string) => {
    setActiveTab(key);
    onTabChange && onTabChange(key);
  };

  useEffect(() => {
    if (tabs.length) {
      handleTabChange(activeTab);
    }
  }, []);

  const getTabContent = (tabIndex: number | string) => {
    const neededTab = tabs.find(tab => tab.id === tabIndex);
    if (!tabIndex || !neededTab) return tabs[0].content;

    return neededTab.content;
  };

  const activeTabContent = getTabContent(activeTab);

  return (
    <>
      <Tabs tabs={tabs} active={activeTab !== 0 ? activeTab : tabs[0].id} onChange={handleTabChange} />
      {activeTabContent}
    </>
  );
};
