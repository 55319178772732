import styled from 'styled-components';

export const BannerWrapper = styled.div`
  position: relative;
`;

export const Arrow = styled.div`
  min-width: 20px;
  width: 20px;
  height: 20px;
  opacity: 0.6;
  color: #1d1d1d;
  transition: opacity 0.15s;
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: linear-gradient(90deg, rgba(20, 20, 20, 0.2) 15%, transparent);
  transition: opacity 0.15s;
`;

export const ArrowWrapper = styled.div<{ position?: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 5%;
  height: 100%;

  ${({ position }) =>
    position === 'left'
      ? 'left: 0;'
      : `left: unset;
  right: 0;
  transform: rotate(180deg);`}

  ${Arrow} {
    transform: rotate(90deg);
  }

  :hover {
    cursor: pointer;
    ${Arrow} {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 1em;

  :hover {
    ${Shadow} {
      color: #fff;
      opacity: 0.6;
      :hover {
        opacity: 1;
      }
    }
  }
`;
