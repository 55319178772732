import styled from 'styled-components';

export const UserWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.25em;
  padding: 8px 12px;
  /* width: 2.26em; */
  /* height: 1.75em; */
  /* color: white; */
  color: #c9cdd1;
  border-radius: 50px;
  cursor: pointer;
  transition: color 0.2s linear;

  &:hover {
    color: white;
  }
  &[data-selected='true'] {
    color: white;
    background-color: rgba(33, 37, 41, 0.4);
  }

  @media (min-width: 1920px) {
    margin-right: 0.6em;
  }
`;

export const IconLetter = styled.span<{ size?: string }>`
  display: flex;
  align-items: center;

  width: ${({ size }) => (size ? size : '1em')};
  height: ${({ size }) => (size ? size : '1em')};

  svg {
    width: 100%;
    height: 100%;
  }
`;
