import { createSlice } from '@reduxjs/toolkit';
import { ActionLogs } from 'app/types/adminActionLogs';
import { PaginationNew } from 'app/types/pagination';
import * as actions from './action';

interface InitialType {
  loading: boolean;
  items: ActionLogs;
  pagination?: PaginationNew;
}

const initialState: InitialType = {
  loading: false,
  items: [],
};

const slice = createSlice({
  name: 'admin/actionLogs',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.fetchLogs.pending, state => {
      state.loading = true;
    });
    addCase(actions.fetchLogs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.pagination = payload.meta.pagination;
    });
  },
});

export default slice.reducer;
