import React from 'react';
import * as S from '../styled';
import { Progress, Table, TableProps } from 'antd';
import { number } from 'helpers/format';

type TableGeneralProps = {
  percentCompleted?: number;
} & TableProps;

export const TableGeneral = ({ percentCompleted, ...props }: TableGeneralProps) => {
  const formatRenderValueByKey = (value: number, key: string) => (key === 'weight' ? number(value) : value);

  return (
    <S.Wrapper>
      <S.Title>Общая сводка</S.Title>
      <Table {...props} pagination={false}>
        <Table.Column dataIndex="name" render={name => <b>{name}</b>} />
        <Table.Column
          title={<b>Оформлено в заказы</b>}
          dataIndex="completed"
          render={(value: number, record) => formatRenderValueByKey(value, record.key)}
        />
        <Table.Column
          title={<b>Остаток по автозаказу</b>}
          dataIndex="notCompleted"
          render={(value: number, record) => formatRenderValueByKey(value, record.key)}
        />
        <Table.Column
          title={<b>Заявлено</b>}
          dataIndex="all"
          render={(value: number, record) => formatRenderValueByKey(value, record.key)}
        />
      </Table>
      <Progress
        percent={Number(number(percentCompleted || 0, 0))}
        percentPosition={{ align: 'center', type: 'inner' }}
        size={['100%', 16]}
        strokeLinecap="butt"
      />
    </S.Wrapper>
  );
};
