import styled from 'styled-components';
import { Modal as AntModal, Col, Row } from 'antd';
import { Title } from 'components/Title';
import { Button as UiKitButton } from 'components/KrasUIKit';

export const Heading = styled(Title)`
  margin-bottom: 0.675em !important;
  font-family: Oswald !important;
  font-size: var(--s10);
  letter-spacing: 0.02em;
`;

export const Subheading = styled(Title)`
  margin-top: 0 !important;
  margin-bottom: 1.6em !important;
  font-size: var(--s5);
  white-space: pre-line;
  color: #757575 !important;
`;

export const Button = styled(UiKitButton)`
  padding: 0.45em 1.14em 0.5em;
  color: #ffffff;
  font-size: 15px;

  & > span {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  svg {
    width: 1.34em;
    height: 1.64em;
  }

  & :hover {
    color: #ffffff;
  }
`;

export const Banner = styled(Row)`
  display: flex;
  flex-flow: row nowrap;

  img {
    height: 472px;
  }
`;

export const Content = styled(Col)<{ isNew: boolean }>`
  display: flex !important;
  flex-flow: column;
  align-items: flex-start;
  padding: ${({ isNew }) => (isNew ? '113px 80px 0 54px' : '0 80px 0 54px')};
  justify-content: ${({ isNew }) => (isNew ? 'flex-start' : 'center')};

  ${Subheading} {
    max-width: ${({ isNew }) => (isNew ? '17em' : '21em')};
  }
`;
