import * as actions from './actions';
import * as newsTypes from 'app/types/news';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';

export const useNews = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.news);

  const fetchNews = () => {
    dispatch(actions.fetchNews());
  };
  const fetchNewsItem = (id: string) => {
    dispatch(actions.fetchNewsItem(id));
  };

  const updateNews = async (data: newsTypes.UpdateNewsData) => {
    const resultAction = await dispatch(actions.updateNews(data));
    return actions.updateNews.fulfilled.match(resultAction);
  };

  const deleteNews = (news: newsTypes.News) => {
    dispatch(actions.deleteNews(news));
  };

  const addNews = async (news: newsTypes.NewsForm) => {
    const resultAction = await dispatch(actions.addNews(news));
    return actions.addNews.fulfilled.match(resultAction);
  };

  const getTypesNews = () => {
    dispatch(actions.getTypesNews());
  };
  return {
    state,
    fetchNews,
    fetchNewsItem,
    updateNews,
    deleteNews,
    addNews,
    getTypesNews,
  };
};
