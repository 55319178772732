import * as actions from './actions';
import { editStart, removeStart, editEnd as $editEnd } from './slice';
import { useDispatch, useSelector, batch } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as RoleApi from 'app/services/admin/roles';

export const useRoles = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.roles);

  const getRoles = () => {
    dispatch(actions.getRoles());
  };

  const getPermissions = () => {
    dispatch(actions.getPermissions());
  };

  const getRoleDetail = (id: number) => {
    dispatch(actions.getRoleDetail(id));
  };

  const addRole = async (newRole: RoleApi.ChangeableRoleFields) => {
    const resultAction = await dispatch(actions.addRole(newRole));
    return actions.addRole.fulfilled.match(resultAction);
  };
  const removeRole = (id: number) => {
    dispatch(actions.removeRole(id));
  };
  const updateRole = async ({
    id,
    newRole,
  }: {
    id: number;
    newRole: RoleApi.ChangeableRoleFields;
  }) => {
    const resultAction = await dispatch(actions.updateRole({ id, newRole }));
    return actions.updateRole.fulfilled.match(resultAction);
  };

  const edititingRoleStart = (id: number) => {
    batch(() => {
      dispatch(editStart(id));
      dispatch(actions.getRoleDetail(id));
    });
  };

  const removingRoleStart = (id: number) => {
    dispatch(removeStart(id));
    dispatch(actions.removeRole(id));
  };

  const editEnd = () => {
    dispatch($editEnd());
  };

  return {
    state,
    getRoles,
    getPermissions,
    getRoleDetail,
    addRole,
    removeRole,
    updateRole,
    edititingRoleStart,
    removingRoleStart,
    editEnd,
  };
};
