/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import { Tabs as OriginalTabs, Card as OriginalCard } from 'antd';
import {
  Typography,
  List,
  Button as ButtonAntd,
  Divider as DividerAntd,
} from 'antd';
import { file, IconLetter } from '../KrasUIKit/icons';
import { Title as TitleUiKit } from 'components/Title';
import documentPrimary from 'images/svg/document.svg';
const { Text: OriginalText } = Typography;
const { Item: OriginalItem } = List;
import { laptop } from 'components/KrasUIKit/styles/media';
import { Button as ButtonUiKit } from 'components/KrasUIKit';

export const Tabs = styled(OriginalTabs)`
  .ant-tabs-nav-container {
    font-family: var(--ff);
    font-size: 18px;
    margin-left: 16px;
  }
`;

export const Text = styled(OriginalText)<{
  fontSize?: string;
  color?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '4px')};
  color: ${({ color }) => (color ? color : 'inherit')};
`;

export const Card = styled(OriginalCard)`
  &.ant-card {
    background-color: var(--gray);
  }
  .ant-card-body {
    padding: 16px;
  }
`;

export const Item = styled(OriginalItem)`
  &.ant-list-item {
    padding: 0;
    margin-bottom: 5px;
  }
`;

const getLevelStyles = (level: number) => {
  const isUpper = level === 0;
  const fz = level > 1 ? 16 : 21;
  return `
    font-size: ${fz}px;
    margin-bottom: 0.7em;
    ${
      isUpper &&
      ` text-transform: uppercase; 
        font-family: var(--ff-upper-title);
        font-size: 24px;
      `
    }
  `;
};
export const SectionTitle = styled.div<{ level: number }>`
  ${(props) => getLevelStyles(props.level)};
`;

export const Dwonload = styled(ButtonAntd).attrs({
  type: 'link',
  icon: <IconLetter>{file}</IconLetter>,
})`
  font-size: 16;
  padding-left: 0;
  color: var(--primary-c);
`;
export const PalmIcon = styled.span`
  width: 24px;
  height: 16px;
  margin-right: 5px;
  margin-top: -3px;
  display: inline-flex;
  vertical-align: middle;
  svg {
    width: 24px;
    height: 16px;
  }
`;

export const TitleWrap = styled.div`
  display: inline-grid;
  grid-column-gap: 1em;
  grid-template-columns: auto max-content;
  align-items: center;
`;
export const Section = styled.section`
  font-size: 1rem;
  padding: 0 1.5em 2.06em 2.4em;
  p {
    margin: 0;
  }
`;
export const A = styled.div`
  color: #4f4f4f;
  border-bottom: 1px #bbbbbb solid;
  :hover {
    border: none;
  }
`;
export const P = styled.p`
  &&& {
    margin-bottom: 1.5em;
  }
`;
export const BlockWithImage = styled.div`
  display: grid;
  grid-template-columns: 47.9fr 52.1fr;
  grid-column-gap: 2.5em;
  align-items: center;
  margin-top: 1.36em;
  font-size: 1rem;
`;

export const Icon = styled.img`
  width: auto;
`;
export const Points = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(20.8em, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3em 6.3em;
  @media all and (${laptop}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5em 2.8em;
  }
`;
export const Point = styled.div`
  &&& {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.06em;
    align-items: center;
    @media all and (${laptop}) {
      grid-auto-flow: column;
    }
  }
`;
export const Title = styled(TitleUiKit)`
  &&& {
    margin: 2em 0 1em;
    font-weight: 500;
  }
`;
export const MainTitle = styled(TitleUiKit)`
  &&& {
    margin: 1.2em 0 1.36em;
  }
`;

export const Footnote = styled.div`
  font-size: 0.875rem;
  background: #f4f4f4;
  padding: 2.28em 3.5em;
  margin: 1.7em 0;
`;

export const Ul = styled.ul`
  padding: 0;
`;
export const Li = styled.li`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  ::before {
    display: block;
    content: '';
    background-image: url(${documentPrimary});
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
  }
  :first-child {
    margin-top: 1em;
  }
`;
export const LimitedWidthBlock = styled.div`
  /* max-width: 60em; */
`;
export const Divider = styled(DividerAntd)`
  &&& {
    margin: 2.5em 0 2em;
  }
`;
export const ContactLinkWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const ContactLink = styled.a`
  color: #212529;
  border: none;
  margin-left: 0.5em;
`;
export const Button = styled(ButtonUiKit)`
  &&& {
    font-size: 1rem;
    margin-top: 0.5em;
  }
`;
