import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { CreatedFeedbacks, Feedback } from 'app/types/feedback';
import { getFeedbacks, sendFeedback } from 'app/services/feedback';
import { actionTypes, sendEvent } from 'logger';

export interface FeedbackSliceProps {
  loading?: boolean;
  list: CreatedFeedbacks;
}

const initialState: FeedbackSliceProps = {
  list: [],
};

export const saveFeedback = createAsyncThunk(
  'feedback/saveFeedbacks',
  async (data: Feedback) => {
    const { message, success } = await sendFeedback(data);
    if (success) {
      sendEvent(actionTypes.MODAL_SUPPORT_SEND_SUCCESS);
      return message;
    } else {
      sendEvent(actionTypes.MODAL_SUPPORT_SEND_FAILED);
      throw new Error('Неизвестная ошибка сервера');
    }
  },
);

export const fetchFeedbacks = createAsyncThunk(
  'feedback/fetchFeedbacks',
  async () => {
    const { message, success, data } = await getFeedbacks();

    if (success && data) {
      return data;
    } else throw new Error(message);
  },
);

const newsSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFeedbacks.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchFeedbacks.fulfilled,
      (state, { payload }: PayloadAction<CreatedFeedbacks>) => {
        state.list = payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchFeedbacks.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
    builder.addCase(saveFeedback.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      saveFeedback.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        antdMessage.success(payload);
        state.loading = false;
      },
    );
    builder.addCase(saveFeedback.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});

export default newsSlice.reducer;
