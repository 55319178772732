import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { ACTIVE_BANNERS_PATHS, EXCLUDE_CHANNELS } from '../consts';

export const useBanners = () => {
  const { pathname } = useLocation();
  const { companies } = useSelector((state: RootState) => state.auth);

  const activeCompanyChannels = companies?.find(({ active }) => active)?.channels;
  const hasExcludeChannel =
    activeCompanyChannels &&
    EXCLUDE_CHANNELS.map(excludeChannel => activeCompanyChannels.map(({ name }) => name).includes(excludeChannel)).some(
      value => value,
    );
  const hasValidPaths =
    !pathname.includes('admin') &&
    (pathname === '/' || ACTIVE_BANNERS_PATHS.map(activePath => pathname.includes(activePath)).some(value => value));
  const isBannersVisible = !hasExcludeChannel && hasValidPaths;

  return {
    isBannersVisible,
  };
};
