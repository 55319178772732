import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { Pagination } from 'app/types/pagination';
import { Orders, Order, OrdersMeta, OrdersPayload } from 'app/types/orders';
import * as actions from './actions';

export interface Props {
  meta: OrdersMeta;
  list: Orders;
  loading?: boolean;
  detail: Order | null;
  statusModal: Order | null;
  pagination: Pagination | null;
}

const initialState: Props = {
  meta: {
    showDiscount: false,
  },
  list: [],
  detail: null,
  statusModal: null,
  pagination: null,
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('personalOrders/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('personalOrders/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('personalOrders/') && action.type.endsWith('/fulfilled');

const personalOrdersSlice = createSlice({
  name: 'personalOrders',
  initialState,
  reducers: {
    resetOrdersDetail(state) {
      state.detail = null;
    },
    selectOrder(state, { payload }) {
      const [order] = state.list.filter(item => item.numberOrder === payload);

      state.statusModal = order as Order;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchOrders.fulfilled, (state, { payload }: PayloadAction<OrdersPayload>) => {
      state.meta = payload.meta;
      state.list = payload.list;
      state.pagination = payload.pagination;
    });
    addCase(actions.fetchOrder.fulfilled, (state, { payload }: PayloadAction<Order>) => {
      state.detail = payload;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) {
          antdMessage.error(action.error.message);
        }
        state.loading = false;
      },
    );
  },
});
export const { resetOrdersDetail, selectOrder } = personalOrdersSlice.actions;
export default personalOrdersSlice.reducer;
