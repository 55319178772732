import { Bonus, isWelcomeBonus, isSilverBonus } from '../utils/bonusTypeGuards';

export const useStageShowingConditions = (data: Bonus) => {
  const show = {
    start: false,
    accumulation: false,
    withdrawal: false,
    discount: false,
  };

  if (isWelcomeBonus(data)) {
    show.start = data.stage === 'start' && data.newCompany;
    show.accumulation = data.stage === 'accumulation' && data.newCompany;
    show.withdrawal = data.stage === 'withdrawal';
    show.discount = data.stage === 'discount';
  }
  if (isSilverBonus(data)) {
    show.start = !Boolean(data.balance);
    show.accumulation = data.stage === 'accumulation';
    show.withdrawal = data.stage === 'withdrawal';
    show.discount = data.stage === 'discount';
  }

  return show;
};
