import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux-observable';
import { fetchCartEpic, fetchContractsEpic, fetchFreeMetalsEpic } from './epics';
import { catchError } from 'rxjs/operators';
import { RootState } from './reducer';

export type AppEpic = Epic<Action, Action, RootState>;

const rootEpic: AppEpic = (action$, store$, dependencies) =>
  combineEpics(fetchCartEpic, fetchContractsEpic, fetchFreeMetalsEpic)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error('Global error handler: ', error);
      return source;
    }),
  );
export default rootEpic;
