import React from 'react';
import { Row, Col, List } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { date } from '../../../helpers/format';
import { Contracts as ContractsType } from '../../../app/types/contracts';

import * as S from '../styled';

interface Props {
  contracts: ContractsType;
}

export const ContractsDetails: React.FC<Props> = ({ contracts }) => {
  const { t } = useTranslation('personal');
  return (
    <>
      <S.Text fontSize="16px" strong>
        {t('contractDetailsTitle')}:
      </S.Text>
      <List
        split={false}
        dataSource={contracts}
        renderItem={(contract, indx) => (
          <S.Item key={indx}>
            <Row align="middle" gutter={15}>
              <Col>
                <FileTextOutlined style={{ fontSize: '32px' }} />
              </Col>

              <Col>
                <S.Text fontSize="16px" color="#d77721" strong>
                  № {contract.knfmpNumber}
                  {contract.contractStartDate && (
                    <>
                      {' '}
                      {t('from')} {date(contract.contractStartDate)}
                    </>
                  )}
                </S.Text>
                <br />
                <S.Text fontSize="16px">
                  {t('contractEndDateTitle')} {date(contract.contractEndDate)}
                </S.Text>
              </Col>
            </Row>
          </S.Item>
        )}
      />
    </>
  );
};
