import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { DocumentSectionEntity } from 'app/types/documentsSection';
import * as R from 'ramda';

export const sectionsSelector = (store: RootState) =>
  store.documentsSection.list;

export const groupedByTypes = R.pipe(
  R.sort(
    (a: DocumentSectionEntity, b: DocumentSectionEntity) => a.sort - b.sort,
  ),
  R.groupBy((position: DocumentSectionEntity) => position.type.code),
);

export const sectionsByTypesSelector = createSelector(
  sectionsSelector,
  (
    sections: DocumentSectionEntity[],
  ): Record<string, DocumentSectionEntity[]> => {
    return groupedByTypes(sections);
  },
);
