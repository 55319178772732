import styled from 'styled-components';
import { Menu as AntdMenu } from 'antd';

export const MenuItem = styled(AntdMenu.Item)`
  &&& {
    margin: 8px 0;
    width: 100%;
    height: auto;
    line-height: normal;

    ::after {
      border-inline-end-width: 3px;
    }
  }
`;
