import React from 'react';
import { Typography } from 'antd';
import { File } from 'app/types/documents';
import { useStorage } from 'hooks/useStorage';
import { actionTypes, createLoggerEvent, sendEvent } from 'logger';

export type Props = {
  name: string;
  file: File;
};

export const Document: React.FC<Props> = ({ name, file }) => {
  const trigger = useStorage(file);
  return (
    <div
      onClick={createLoggerEvent(() => trigger(), {
        ActionType: actionTypes.DOCUMENTS_DOWNLOAD,
        ContextKey: name,
      })}
      style={{ marginBottom: '0.5em' }}
    >
      <Typography.Link>{name}</Typography.Link>
    </div>
  );
};
