import React from 'react';
import {
  AccountDisabled,
  MODAL_NAME,
} from '../components/Modals/AccountDisabled';
import { useModal } from 'app/ducks/modals';

export const AccountDisabledContainer: React.FC<{}> = () => {
  const { visible, hideModal } = useModal(MODAL_NAME);

  return <AccountDisabled visible={visible} hideModal={hideModal} />;
};
