import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Title } from '../Title';
import { MODAL_NAME as FeedbackModalName } from 'components/Modals/Feedback';
import { useModal } from 'app/ducks/modals';

export const MODAL_NAME = 'accountDisabled';
export interface Props {
  visible?: boolean;
  hideModal: () => void;
}

export const AccountDisabled: React.FC<Props> = ({ visible, hideModal }) => {
  const { showModal } = useModal(FeedbackModalName);
  const { t } = useTranslation(['titles', 'errorMessages', 'modal']);

  return (
    <Modal
      open={visible}
      title={
        <Title style={{ marginBottom: 0 }} level={3} upper>
          {t('titles:accountDisabled')}
        </Title>
      }
      onCancel={hideModal}
      footer={
        <Row justify="end">
          <Col>
            <Button onClick={hideModal} type="link">
              {t('modal:cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                showModal();
                hideModal();
              }}
              type="primary"
            >
              {t('modal:writeUs')}
            </Button>
          </Col>
        </Row>
      }
    >
      {t('errorMessages:accountDisabled')}
    </Modal>
  );
};
