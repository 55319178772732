import { apiGet, apiPost } from 'app/services/connect';
import { AdditionalProductsResult, MetadataResult } from 'app/types/adminAdditional';
import { Result } from 'app/types/api';
import { getBasicHeader } from '../helper';

class AdditionalProductsFactory {
  public async getMetadata() {
    try {
      const { data } = await apiGet<MetadataResult>('/additional-products/metadata');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getProducts(params: Record<string, unknown>) {
    try {
      const { data } = await apiGet<AdditionalProductsResult>('/additional-products', params);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async createProduct(values: FormData) {
    try {
      const config = getBasicHeader();
      if (config.headers) {
        config.headers['Content-Type'] = 'multipart/form-data';
      } else {
        config.headers = {
          'Content-Type': 'multipart/form-data',
        };
      }

      const { data } = await apiPost<Result<unknown>>('/additional-products', values, config);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async updateProduct() {
    return null;
  }
}

export const AdditionalProductService = new AdditionalProductsFactory();
