import { createSlice, AnyAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AvailableSettings, Channels, Restrictions, StatusSettingsList } from 'app/types/company';
import { CompanyEntity } from 'app/types/adminCompanies';
import { PaginationNew } from 'app/types/pagination';
import { GetPromotionResponse } from 'models/api/promotions';

export interface CompaniesSliceProps {
  loading: boolean;
  isBannerVisible: boolean;
  companies: CompanyEntity[];
  channels: Channels[];
  disabledRestrictions: Restrictions[];
  pagination?: PaginationNew;
  availableSettings: AvailableSettings;
  statusSettings: StatusSettingsList;
  promotions: GetPromotionResponse | null;
}

export const companyInitialState: CompaniesSliceProps = {
  loading: true,
  isBannerVisible: false,
  companies: [],
  channels: [],
  disabledRestrictions: [],
  statusSettings: {} as StatusSettingsList,
  availableSettings: {},
  promotions: null,
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('companies/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('companies/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('companies/') && action.type.endsWith('/fulfilled');

const companiesSlice = createSlice({
  name: 'companies',
  initialState: companyInitialState,
  reducers: {
    showBanner(state) {
      state.isBannerVisible = true;
    },
    hideBanner(state) {
      state.isBannerVisible = false;
    },
    clearPromotions(state) {
      state.promotions = null;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.getChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.items;
    });
    addCase(actions.getAvailableSettings.fulfilled, (state, { payload }) => {
      state.availableSettings = payload;
    });
    addCase(actions.getDisabledRestrictions.fulfilled, (state, { payload }) => {
      state.disabledRestrictions = payload.items;
    });
    addCase(actions.updateCompany.fulfilled, (state, { payload }) => {
      const index = state.companies.findIndex(item => item.id === payload.item.id);
      if (index > -1) state.companies[index] = payload.item;
    });
    addCase(actions.deleteCompany.fulfilled, (state, { payload }) => {
      const index = state.companies.findIndex(item => item.id === payload);
      if (index > -1) state.companies.splice(index, 1);
    });
    addCase(actions.getPromotions.fulfilled, (state, { payload }) => {
      state.promotions = payload;
    });
    addCase(actions.getStatusSettings.fulfilled, (state, { payload }) => {
      const [statusSettings] = payload.filter(({ tag }) => tag === 'orderStatus');
      state.statusSettings = statusSettings.structure;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export const { clearPromotions, showBanner, hideBanner } = companiesSlice.actions;

export default companiesSlice.reducer;
