import { ItemResult, ItemsResultWithMeta, Result } from 'app/types/api';
import { apiDelete, apiGet } from './connect';
import { AutoOrderDetailEntity, AutoOrderEntity, AutoOrderMetaEntity } from 'app/types/autoOrders';

export const getAutoOrders = async (search: string) => {
  const { data } = await apiGet<ItemsResultWithMeta<AutoOrderEntity, AutoOrderMetaEntity>>(
    `/personal/auto-orders${search}`,
  );

  return data;
};

export const getAutoOrder = async (id: number) => {
  const { data } = await apiGet<ItemResult<AutoOrderDetailEntity>>(`/personal/auto-orders/${id}`);

  return data;
};

export const cancelAutoOrder = async (id: number) => {
  const { data } = await apiDelete<Result>(`/personal/auto-orders/${id}`);

  return data;
};
