import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import {
  CreateDocumentsSectionDto,
  UpdateDocumentsSectionDto,
  UpdateDocumentDto,
  CreateDocumentDto,
  DeleteDocumentDto,
} from 'app/types/documentsSection';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';

export const useDocumentsSection = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.documentsSection);

  const getDocumentsSections = () => {
    return dispatch(actions.getDocumentSections());
  };
  const getDocumentsSectionsTypes = () => {
    return dispatch(actions.getDocumentsSectionsTypes());
  };
  const getDocumentSectionsByCode = (code: string) => {
    return dispatch(actions.getDocumentSectionsByCode(code));
  };

  const updateDocumentsSections = async (
    id: number,
    payload: UpdateDocumentsSectionDto,
  ) => {
    return dispatch(actions.updateDocumentsSection({ id, payload }));
  };

  const createDocumentsSection = async (payload: CreateDocumentsSectionDto) => {
    const resultAction = await dispatch(
      actions.createDocumentsSection(payload),
    );
    return actions.createDocumentsSection.fulfilled.match(resultAction);
  };

  const deleteDocumentsSection = async (id: number) => {
    return dispatch(actions.deleteDocumentsSection(id));
  };

  const updateDocument = async (payload: UpdateDocumentDto) => {
    const resultAction = await dispatch(actions.updateDocument(payload));
    return actions.updateDocument.fulfilled.match(resultAction);
  };

  const createDocument = async (payload: CreateDocumentDto) => {
    const resultAction = await dispatch(actions.createDocument(payload));
    return actions.createDocument.fulfilled.match(resultAction);
  };

  const deleteDocument = async (payload: DeleteDocumentDto) => {
    return dispatch(actions.deleteDocument(payload));
  };

  return {
    state,
    createDocumentsSection,
    updateDocumentsSections,
    getDocumentsSections,
    deleteDocumentsSection,
    getDocumentSectionsByCode,
    getDocumentsSectionsTypes,
    updateDocument,
    createDocument,
    deleteDocument,
  };
};
