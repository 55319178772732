import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setDisabledAccount } from 'app/ducks/auth/slice';
import { Option as OptionAutocomplete } from 'components/Autocomplete';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { ValidProps } from 'components/Fields/Valid';
import { useTranslation } from 'react-i18next';

export type FormValuesType = {
  type: string;
  company: string;
  inn?: string;
  username: string;
  email: string;
  phone: string;
  additionalProducts?: string;
};

type Props = {
  onSubmit: (value: FormValuesType) => void;
};

export const useForm = ({ onSubmit }: Props) => {
  const authStore = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation(['layout', 'feedback', 'company', 'form', 'modal']);

  const shape = {
    company: Yup.string()
      .transform(value => value.trim())
      .required(t('form:required')),
    username: Yup.string()
      .transform(value => value.trim())
      .required(t('form:required')),
    email: Yup.string()
      .trim()
      .email(t('form:email'))
      .required(t('form:required')),
    phone: Yup.string()
      .transform(value => value.trim())
      .matches(/\b\d{1,}\b/g, 'Введите корректный номер телефона')
      .required(t('form:required')),
  };

  const initial: FormValuesType = {
    type: 'additionalProduct',
    company: authStore.company?.name || '',
    username: authStore.user ? `${authStore.user.name}` : '',
    email: authStore.user?.email || '',
    phone: '',
  };

  const FeedbackSchema = Yup.object().shape(shape);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initial,
    validationSchema: FeedbackSchema,
    onSubmit,
  });

  const formRowProps: Omit<ValidProps, 'component' | 'name'> = {
    form: formik,
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    labelAlign: 'left',
  };

  return {
    formik,
    formRowProps,
  };
};
