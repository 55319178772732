import styled from 'styled-components';
import { Divider as DividerAntd } from 'antd';

export const MetalWrap = styled.div`
  :not(:first-child) {
    margin-top: 1.25em;
  }
`;

export const Divider = styled(DividerAntd)`
  && {
    margin: 0.5em 0;
  }
`;

export const MetalItemWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleMetalWrap = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  height: 1.37em;
`;

export const TitleMetalItem = styled.span`
  display: flex;
  align-items: center;
`;

export const SubMetalList = styled.ul`
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style-type: none;
`;

export const SubMetalItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const TextWithoutMargin = styled.p`
  margin: 0;
`;
