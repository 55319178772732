import styled from 'styled-components';
import { Tag } from 'antd';

export const ClosableTag = styled(Tag)`
  &&& {
    white-space: pre-wrap;
    margin: 0.4em 0.2em;
    display: flex;
    justify-content: space-between;
  }

  .anticon-close {
    margin-top: 0.4em;
  }
`;

export const TagsControlWrap = styled.div`
  display: grid;
  grid-row-gap: 0.3em;
`;
