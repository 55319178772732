import { Divider, Row, InputNumber } from 'antd';
import React from 'react';
import { Title } from '../../styled/Common.styled';
import { Order } from '../../styled/Bars.styled';
import { useTranslation } from 'react-i18next';

interface BarsOrderProps {
  diameter: string;
  defaultValue: number | undefined;
  onChange?: (value: number) => void;
}

export const BarsOrder = ({ diameter, defaultValue, onChange }: BarsOrderProps) => {
  const { t } = useTranslation(['additional']);
  const handleChange = (value: number | null) => {
    onChange && onChange(value || 0);
  };

  return (
    <Order>
      <Title>⌀ {diameter}</Title>
      <Divider type="horizontal" />
      <Row>
        <label>{t('table.weight')}</label>
        <InputNumber
          style={{ display: 'block', width: '100%' }}
          type="number"
          min={0}
          placeholder={t('table.setWeight')}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      </Row>
    </Order>
  );
};
