import { DATE_API_FORMAT } from 'constants/dateFormats';
import dayjs from 'dayjs';
import { date } from 'helpers/format';
import { decline } from 'utils/decline';

export const getSearchWithCompanyId = (companyId: number, search?: string) => {
  const COMPANY_ID = 'companyId';
  if (!search) {
    return `?${COMPANY_ID}=${companyId}`;
  }
  return `${search}&${COMPANY_ID}=${companyId}`;
};

export const getDaysDiffTitle = (dateTo: string) => {
  const today = dayjs().format(DATE_API_FORMAT);
  const dateToNoTime = dayjs(dateTo).format(DATE_API_FORMAT);

  const daysLeft = dayjs(dateToNoTime).diff(dayjs(today), 'days');
  const isTodayLastDay = dayjs(today).isSame(dayjs(dateToNoTime), 'day');

  if (daysLeft > 0 && !isTodayLastDay) {
    return {
      title: `${daysLeft} ${decline(daysLeft, ['день', 'дня', 'дней'])}`,
      date: date(dateTo),
    };
  }

  if (isTodayLastDay) {
    return {
      title: 'сегодня',
      date: `${date(dateTo)}, в 23:59 по Красноярску`,
    };
  }

  return undefined;
};

/**
 * @param completed Сколько уже оформлено
 * @param all Сколько всего (100%)
 * @returns Возвращает сколько процентов оформлено
 */
export const getPercentCompleted = (completed: number, all: number) => {
  return (completed / all) * 100;
};
