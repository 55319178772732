import { initApp } from 'app/ducks/auth/actions';
import { removeToken } from 'app/services/auth';
import { RootState } from 'app/store/reducer';
import store from 'app/store/store';
import { useIsActiveTab } from 'hooks/useIsActiveTab';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

type SyncEventType = 'cart' | 'import' | 'contract' | 'logout' | 'company';

type Event = {
  scope: number;
  event: SyncEventType;
};

export const useSyncTabs = () => {
  // const isActiveTab = useIsActiveTab();

  const getCurrentScope = () => {
    const state = store.getState();
    return state.auth?.user?.id;
  };

  /* Триггер редакс события на основе эвента */
  const triggerDispatchByEvent = (event: Event) => {
    if (event.event === 'logout') {
      removeToken();
    }

    store.dispatch(initApp());
  };

  /* Преоброзование из ls строки в объект события */
  const transformToEvent = (value: string): Event | undefined => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return;
    }
  };

  /* Проверка на валидную структуру эвента */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkValidEvent = (event: any) => {
    const hasScope = event?.scope;
    const hasEvent = event?.event;

    return hasScope && hasEvent;
  };

  /* Проверка на область события */
  const checkEventScope = (event: Event) => {
    const currentScope = getCurrentScope();

    return currentScope === event.scope;
  };

  const handler = (event: StorageEvent) => {
    // if (isActiveTab) return;
    if (event.key !== 'syncEvent' || !event.newValue) return;

    const transformedEvent = transformToEvent(event.newValue);

    if (
      transformedEvent &&
      checkValidEvent(transformedEvent) &&
      checkEventScope(transformedEvent)
    ) {
      triggerDispatchByEvent(transformedEvent);
    }
  };

  const unsubscribe = () => window.removeEventListener('storage', handler);

  const subscribe = () => window.addEventListener('storage', handler);

  const syncAnyTabs = (event: SyncEventType) => {
    const scope = getCurrentScope();

    if (scope) {
      localStorage.setItem(
        'syncEvent',
        JSON.stringify({
          scope,
          event,
        }),
      );
    }
  };

  return { subscribe, unsubscribe, syncAnyTabs };
};
