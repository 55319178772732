import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { MetalReportCreateBody } from 'app/types/metalReport';

export const useMetalReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.metalReport);

  const getMetalReports = () => {
    return dispatch(actions.getMetalReports());
  };

  const createReportMetal = (payload: MetalReportCreateBody) => {
    return dispatch(actions.createReportMetal(payload));
  };

  return {
    state,
    getMetalReports,
    createReportMetal,
  };
};
