import { UploadFile } from 'antd/lib/upload/interface';
import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { CreateBannerDto, UpdateBannerDto } from 'app/types/banners';
import { AppDispatch } from 'app/store/store';

export const useBanners = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.banners);

  const fetchBanners = (search = '') => {
    return dispatch(actions.fetchBanners(search));
  };

  const updateBanner = async (updateData: UpdateBannerDto) => {
    const resultAction = await dispatch(actions.updateBanner(updateData));
    return actions.updateBanner.fulfilled.match(resultAction);
  };

  const deleteBanner = (id: number) => {
    return dispatch(actions.deleteBanner(id));
  };

  const uploadBannerImg = async (imgFile: UploadFile) => {
    const resultAction = await dispatch(actions.uploadBannerImg(imgFile));
    if (actions.uploadBannerImg.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload[0] : undefined;
    }
  };

  const createBanner = async (banner: CreateBannerDto) => {
    const resultAction = await dispatch(actions.createBanner(banner));
    if (actions.createBanner.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload : undefined;
    }
  };

  return {
    state,
    fetchBanners,
    updateBanner,
    deleteBanner,
    uploadBannerImg,
    createBanner,
  };
};
