import { MODAL_NAME } from 'components/Modals/RenderRequest';
import { useModal } from 'app/ducks/modals';
import { useDocuments } from 'app/ducks/documents';
import { usePersonalOrders } from 'app/ducks/personalOrders';
import { useRenderRequest } from 'app/ducks/renderRequest';

export const useDownloadFiles = (id: number) => {
  const { showModal } = useModal(MODAL_NAME);
  const { downloadSpecification } = useDocuments();
  const { fetchOrderImportExcel, fetchOrderExcel } = usePersonalOrders();
  const { renderRequestStart } = useRenderRequest();

  const onRenderRequest = () => {
    renderRequestStart(id);
    showModal();
  };

  const onDownloadExcel = () => {
    fetchOrderExcel(id.toString());
  };

  const onDownloadImport = () => {
    fetchOrderImportExcel(id.toString());
  };

  const onDownloadSpecification = (id: number) => {
    downloadSpecification(id);
  };

  return {
    onRenderRequest,
    onDownloadExcel,
    onDownloadImport,
    onDownloadSpecification,
  };
};
