import React, { useEffect } from 'react';
import { useModal } from 'app/ducks/modals';
import { Button, File } from 'components/KrasUIKit';
import { MODAL_METAL_REPORT } from 'components/Modals/MetalReportRequest';
import { Title } from 'components/Title';
import { actionTypes, sendEvent } from 'logger';
import { date } from 'helpers/format';
import { useMetalReport } from 'app/ducks/metalReport';
import { isNil } from 'ramda';
import { Spin } from 'antd';
import * as S from './MetalReport.styled';

export const MetalReport = () => {
  const { showModal } = useModal(MODAL_METAL_REPORT);
  const { state, getMetalReports } = useMetalReport();

  useEffect(() => {
    getMetalReports();
  }, []);

  return (
    <React.Fragment>
      <Title upper level={3}>
        Отчет об израсходовании драгоценных металлов
      </Title>
      <Button
        type="primary"
        onClick={() => {
          showModal();
          sendEvent(actionTypes.METAL_REPORT_PERSONAL_CLICK);
        }}
      >
        Запросить отчет об израсходовании металлов
      </Button>
      <Spin spinning={state.loading}>
        <S.FileWrapper>
          {state.list.map(({ dateFrom, dateTo, knfmpNumber, createdAt, filePath, status }, index) => {
            const isNew = status === 'new';
            return (
              <File
                key={index}
                name={`Отчет по договору ${knfmpNumber} от ${date(createdAt)}`}
                href={!isNil(filePath) ? filePath : undefined}
                description={`${date(dateFrom)} - ${date(dateTo)}`}
                buttonText={isNew ? 'Файл обрабатывается' : undefined}
                disabled={isNew}
                onClick={() => sendEvent(actionTypes.METAL_REPORT_PERSONAL_DOWNLOAD)}
              />
            );
          })}
        </S.FileWrapper>
      </Spin>
    </React.Fragment>
  );
};
