import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from 'app/types/pagination';
import { CompanyManagerResponse, User } from 'app/types/managerUsers';
import * as actions from './actions';

export interface CompaniesSliceProps {
  loading: boolean;
  users: User[];
  pagination?: Pagination;
}

export const companyInitialState: CompaniesSliceProps = {
  loading: true,
  users: [],
};

const companiesSlice = createSlice({
  name: 'managerUsers',
  initialState: companyInitialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.fetchManagerUsers.pending, state => {
      state.loading = true;
    });
    addCase(actions.fetchManagerUsers.rejected, state => {
      state.loading = false;
    });
    addCase(
      actions.fetchManagerUsers.fulfilled,
      (state, { payload }: PayloadAction<CompanyManagerResponse>) => {
        state.users = payload.items;
        state.pagination = payload.pagination;
        state.loading = false;
      },
    );
  },
});

export default companiesSlice.reducer;
