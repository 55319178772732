export function decline(count: number, [one, few, many]: string[]) {
  if (few === '') {
    if (count === 1) {
      return one;
    }
    return many;
  }
  const n = Math.abs(count) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return many;
  }
  if (n1 > 1 && n1 < 5) {
    return few;
  }
  if (n1 === 1) {
    return one;
  }
  return many;
}
