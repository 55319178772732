import { Result } from 'app/types/api';
import { apiPost } from './connect';

export const postConsultation = async (form: FormData) => {
  try {
    const { data } = await apiPost<{ success: boolean; message: string }>('/claims ', form);
    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};
