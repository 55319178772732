import React, { useMemo } from 'react';
import { SectionTitle } from './SectionTitle';
import { Table, Typography } from 'antd';
import { Count } from './Count';
import { Offers as OffersType, Offer, StockType } from '../../app/types/products';
import { number } from '../../helpers/format';
import { cart, waiting } from '../KrasUIKit/icons';
import { CountInBasker } from './styled';
import { OrderType as OrderStateType } from 'containers/ProductDetailModalContainer';
import { DetailOrderType } from '.';
import { useTranslation } from 'react-i18next';
import { useFormatSize } from 'hooks/useFormatSize';

export interface Props {
  list: OffersType;
  order: OrderStateType | null;
  title: string;
  orderType: DetailOrderType;
  setOrder: (order: OrderStateType | null) => void;
  isReady?: boolean;
  balance?: number;
  stockType?: StockType;
  article: string;
  typeCode: string;
  isDimensioned: boolean;
}

export const Offers: React.FC<Props> = ({
  list,
  order,
  setOrder,
  title,
  orderType,
  stockType,
  article,
  isDimensioned,
}) => {
  const { t } = useTranslation('orderParameters');
  const getOfferValue = (id: number): number => {
    return order?.[orderType]?.[id] || 0;
  };

  const offerChangeHandler = (value: number, length: number) => {
    const current = order?.[orderType] || null;
    setOrder({
      ...order,
      [orderType]: { ...current, [length]: value },
    });
  };

  const { formatSize } = useFormatSize();

  const columns = [
    {
      title: t('size'),
      dataIndex: 'length',
      key: 'length',
      render: (value: number) => formatSize(value, { isDimensioned }),
    },
    {
      title: t('weightOnly'),
      dataIndex: 'weight',
      key: 'weight',
      render: (value: number) => number(value, 2),
    },
    {
      title: t('amount'),
      dataIndex: 'count',
      key: 'count',
      render: (_: number, offer: Offer) => {
        // const max =
        //   orderType === 'products_coil'
        //     ? Math.floor(balance / offer.weight) + getOfferValue(offer.id)
        //     : offer.max;

        return (
          <Count
            article={article}
            max={stockType && offer.residues[stockType]}
            count={getOfferValue(offer.id)}
            onChange={value => offerChangeHandler(value, offer.id)}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'inBasket',
      key: 'inBasket',
      render: (_: number, offer: Offer) => {
        const cartCount =
          orderType === 'products_production'
            ? offer.countInProduction
            : orderType === 'products_coil'
            ? offer.countInCoils
            : orderType === 'products_finished'
            ? offer.countInFinished
            : orderType === 'products_finished_self_marked'
            ? offer.countInSelfMark
            : 0;

        return cartCount > 0 ? (
          <CountInBasker>
            {cart}
            {cartCount}
          </CountInBasker>
        ) : null;
      },
    },
    {
      title: '',
      dataIndex: 'inWaiting',
      key: 'inWaiting',
      render: (_: number, offer: Offer) => {
        return offer.countInWaitingList > 0 ? (
          <CountInBasker>
            {waiting}
            {offer.countInWaitingList}
          </CountInBasker>
        ) : null;
      },
    },
  ];

  stockType &&
    columns.push({
      title: '',
      dataIndex: 'max',
      key: 'max',
      render: (_: number, offer: Offer) => {
        const max = offer.residues[stockType];

        if (max) {
          return (
            <Typography.Text>
              {t('productDetail:max')}{' '}
              {max -
                getOfferValue(offer.id) -
                (orderType === 'products_finished'
                  ? offer.countInFinished
                  : orderType === 'products_finished_self_marked'
                  ? offer.countInSelfMark
                  : 0)}
            </Typography.Text>
          );
        }

        return null;
      },
    });

  return useMemo(
    () => (
      <>
        <SectionTitle style={{ marginBottom: 0 }}>{title}</SectionTitle>
        <Table dataSource={list} columns={columns} key={orderType} size="small" pagination={false} rowKey="length" />
      </>
    ),
    [list, order, setOrder, title],
  );
};
