import { useMemo } from 'react';
import dayjs from 'dayjs';
import { MenuProps } from 'antd';
import { Contracts, sapNumber } from 'app/types/contracts';

type UseContractsMenuProps = {
  contracts: Contracts;
  onChangeContract: (id: sapNumber) => void;
};

export const useContractsMenu = ({ contracts, onChangeContract }: UseContractsMenuProps) => {
  const activeContract = useMemo(() => contracts.find(({ active }) => active), [contracts]);

  const endedDate = (date: string) => {
    return dayjs(date).isBefore(dayjs());
  };

  const menuItems: MenuProps['items'] = contracts.map(({ sapNumber, knfmpNumber, name, contractEndDate }) => ({
    key: sapNumber,
    type: 'item',
    label: `${knfmpNumber} ${name !== 'Тестовый' ? `(${name})` : ''}`,
    disabled: endedDate(contractEndDate),
  }));

  const clickHandler: MenuProps['onClick'] = ({ key }) => {
    const selectedContract = contracts.find(({ sapNumber }) => sapNumber === (key as string | number));
    if (selectedContract && selectedContract.active !== activeContract?.active) {
      onChangeContract(selectedContract.sapNumber);
    }
  };

  const menu: MenuProps = {
    items: menuItems,
    selectedKeys: activeContract ? [String(activeContract.sapNumber)] : undefined,
    style: { maxWidth: '17em' },
    onClick: clickHandler,
  };

  return {
    menu,
  };
};
