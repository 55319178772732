import { apiGet } from './connect';
import { Contracts } from 'app/types/contracts';
import { ItemsResult, Result } from 'app/types/api';
import { sapNumber, Metal } from 'app/types/contracts';
import { CompanyId } from 'app/types/company';

export type ContractsResult = Result<Contracts | []>;
export type FreeMetalsResult = ItemsResult<Metal>;

export const getContracts = async (id: CompanyId) => {
  const { data } = await apiGet<ContractsResult>(`/contracts/company/${id}`);
  return data;
};

export const getContractsForEpic = (id: CompanyId) => {
  return apiGet<ContractsResult>(`/contracts/company/${id}`);
};

export const getFreeMetals = async (id: sapNumber | string | number) => {
  const { data } = await apiGet<FreeMetalsResult>(`/contracts/${id}/metals`);
  return data;
};

export const getFreeMetalsForEpic = (id: number) => {
  return apiGet<FreeMetalsResult>(`/contracts/${id}/metals`);
};

export const setActiveContract = async (contractSapNumber: sapNumber, companyId: CompanyId) => {
  const { data } = await apiGet<Result<null>>(`/companies/${companyId}/contracts/${contractSapNumber}/select`);
  return data;
};
