import { apiPost } from './connect';
import { Result } from 'app/types/api';

export interface DaDataCompony {
  name: string;
  address: string;
  inn: string;
}

export type DaDataComponiesResult = Result<DaDataCompony[]>;

export const getSuggestByDaData = async (search: string) => {
  const { data } = await apiPost<DaDataComponiesResult>('/feedback/suggest', {
    query: search,
    count: 5,
  });
  return data;
};
