import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { UnknownAction } from 'redux';

const isPendingAction = (action: UnknownAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/pending');
};

const isRejectedAction = (action: UnknownAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/rejected');
};

const isFulfilledAction = (action: UnknownAction, prefix: string) => {
  return action.type?.startsWith(`${prefix}/`) && action.type?.endsWith('/fulfilled');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addLoadingMatchers = (builder: ActionReducerMapBuilder<any>, prefix: string, path = 'loading'): void => {
  builder.addMatcher(
    action => isRejectedAction(action, prefix) || isFulfilledAction(action, prefix),
    state => ({
      ...state,
      [path]: false,
    }),
  );
  builder.addMatcher(
    action => isPendingAction(action, prefix),
    state => ({
      ...state,
      [path]: true,
    }),
  );
};
