import { apiPost } from './connect';
import { Result } from 'app/types/api';
import { CheckedImport, ImportForm } from 'app/types/imports';

export type ImportResult = Result<CheckedImport>;

export const importFile = async ({ uuid, importType: type, productType: orderType }: ImportForm) => {
  const { data } = await apiPost<ImportResult>('/import/check', {
    uuid,
    type,
    orderType,
  });

  return data;
};

// Execute ???
export const executeImportFile = async ({ uuid, importType: type, productType: orderType }: ImportForm) => {
  const { data } = await apiPost<ImportResult>('/import/execute', {
    uuid,
    type,
    orderType,
  });

  return data;
};

export type ImportOffersResult = Result<{ errors: string[] }>;

export const importOffers = async (uuid: string) => {
  try {
    const { data } = await apiPost<ImportOffersResult>('/import/offers', {
      uuid,
    });
    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};
