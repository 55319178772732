import * as actions from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { TechOrder, TechOrderForm } from 'app/types/techOrders';

export const useTechOrders = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.techOrders);

  const fetchTechOrders = () => {
    dispatch(actions.fetchTechOrders());
  };

  const addTechOrder = async (order: Partial<TechOrder>) => {
    const resultActions = await dispatch(actions.addTechOrder(order));
    return actions.addTechOrder.fulfilled.match(resultActions);
  };

  const deleteTechOrder = (id: number) => {
    dispatch(actions.deleteTechOrder(id));
  };

  const updateTechOrder = async (id: number, order: TechOrderForm) => {
    const resultActions = await dispatch(
      actions.updateTechOrder({ id, order }),
    );
    return actions.addTechOrder.fulfilled.match(resultActions);
  };

  const forceOrderSend = () => {
    dispatch(actions.forceOrderSend());
  };

  return {
    state,
    fetchTechOrders,
    addTechOrder,
    deleteTechOrder,
    updateTechOrder,
    forceOrderSend,
    techOrdersAdapter: actions.techOrdersAdapter,
  };
};
