import styled from 'styled-components';
import { Typography } from 'antd';
import { Props } from '.';

export const StyledTitle = styled(Typography.Title)<Props>`
  &&& {
    text-transform: ${props => (props.upper ? 'uppercase' : 'normal')};
    font-family: ${props =>
      props.upper ? 'var(--ff-upper-title)' : 'var(--ff)'};
    font-weight: ${props => (props.upper ? 'normal' : 'normal')};
  }
`;
