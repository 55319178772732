import React from 'react';
import * as S from '../styled/Table.styled';

type OrderNumberProps = {
  percentage: number;
  shipped: boolean;
  onClick: () => void;
};

export const OrderPercentage = ({ percentage, shipped = false, onClick }: OrderNumberProps) => {
  const ready = percentage === 100 && shipped;
  return (
    <S.OrderPercentageWrap onClick={() => (!ready ? onClick() : null)} disabled={ready}>
      {ready ? 'Выполнен' : `Готов на ${percentage}%`}
    </S.OrderPercentageWrap>
  );
};
