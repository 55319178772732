import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideBanner, showBanner } from 'app/ducks/companies/slice';
import { BannerType } from '../types/Slide';
import { usePromotions } from 'app/ducks/companies';
import { useAuth } from 'app/ducks/auth';

type BannerNameType = 'silverBonus' | 'welcomeBonus';

const WEEK_GAP = 7 * 24 * 60 * 60 * 1000;

const getBannerLastShownTimeStamp = (bannerName: BannerNameType) =>
  JSON.parse(localStorage.getItem(`${bannerName}BannerLastShownTimestamp`) || '{}');

export const useBanner = (bannerName: BannerNameType): BannerType | null => {
  const {
    state: { company },
  } = useAuth();
  const { promotions } = usePromotions();

  const location = useLocation();
  const dispatch = useDispatch();
  const [isBannerShown, setBannerShown] = useState(true);
  const { pathname } = useLocation();

  const currentStage = promotions?.[bannerName]?.stage;
  const isNew = currentStage !== 'withdrawal';

  useEffect(() => {
    if (!company?.id || !promotions || !promotions[bannerName]) return;

    const isManager = Boolean(sessionStorage.getItem('jwtAccess'));
    const BANNER_LAST_SHOWN_TIMESTAMP = getBannerLastShownTimeStamp(bannerName);

    if (!isManager) {
      if (Boolean(BANNER_LAST_SHOWN_TIMESTAMP[company.id]) && !pathname.includes('/catalog')) {
        dispatch(hideBanner());
        setBannerShown(true);
      } else if (!Boolean(BANNER_LAST_SHOWN_TIMESTAMP[company.id])) {
        dispatch(showBanner());
        setBannerShown(false);
      } else if (pathname.includes('/catalog')) {
        if (BANNER_LAST_SHOWN_TIMESTAMP[company.id]) {
          const previousTimestamp = Number(BANNER_LAST_SHOWN_TIMESTAMP[company.id]);
          const now = Date.now();
          if (now - previousTimestamp >= WEEK_GAP) {
            setBannerShown(false);
            dispatch(showBanner());
          }
        }
      }
    }
  }, [promotions?.[bannerName], location, company]);

  const hide = () => {
    dispatch(hideBanner());
    setBannerShown(true);
    if (!company?.id) return;

    localStorage.setItem(
      `${bannerName}BannerLastShownTimestamp`,
      JSON.stringify({
        ...getBannerLastShownTimeStamp(bannerName),
        [company.id]: String(Date.now()),
      }),
    );
  };

  return promotions?.[bannerName] ? { bannerName, isNew, isShown: isBannerShown, hide } : null;
};
