import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antMessage } from 'antd';
import { UpdateCompanyDto, CreateCompanyDto } from 'app/types/adminCompanies';
import { CompaniesService } from 'app/services/admin/companies';

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async (search: string, { rejectWithValue }) => {
    const { data, success } = await CompaniesService.getMany(search);
    if (success && data) {
      return data;
    } else {
      // TODO: убрать когда с бека будет приходить корректное сообщение
      antMessage.error('Список компаний не получен');
      return rejectWithValue(data);
    }
  },
);

export const getChannels = createAsyncThunk('companiesSettings/getChannels', async (_, { rejectWithValue }) => {
  const { success, data } = await CompaniesService.getChannels();
  if (success && data) {
    return data;
  } else {
    return rejectWithValue(data);
  }
});

export const getAvailableSettings = createAsyncThunk(
  'companiesSettings/getAvailableSettings',
  async (_, { rejectWithValue }) => {
    const { success, data } = await CompaniesService.getAvailableSettings();
    if (success && data) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  },
);

export const getDisabledRestrictions = createAsyncThunk(
  'companiesSettings/getDisabledRestrictions',
  async (_, { rejectWithValue }) => {
    const { success, data } = await CompaniesService.getDisabledRestrictions();
    if (success && data) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  },
);

export const getStatusSettings = createAsyncThunk(
  'companiesSettings/getStatusSettings',
  async (_, { rejectWithValue }) => {
    const { success, data } = await CompaniesService.getStatusSettings();
    if (success && data) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  },
);

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (updateCompanyData: UpdateCompanyDto, { rejectWithValue }) => {
    const { data, success, message } = await CompaniesService.update(updateCompanyData);
    if (success && data) {
      antMessage.success(message);
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (companyId: number, { rejectWithValue }) => {
    const { success, message } = await CompaniesService.delete(companyId);
    if (success) {
      // TODO: убрать когда с бека будет приходить корректное сообщение
      antMessage.success('Компания успешно удалена');
      return companyId;
    } else {
      antMessage.error(message);
      return rejectWithValue(companyId);
    }
  },
);

export const addCompany = createAsyncThunk(
  'companies/addCompany',
  async (company: CreateCompanyDto, { rejectWithValue }) => {
    const { data, success, message } = await CompaniesService.create(company);
    if (success && data) {
      antMessage.success(message);
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const getPromotions = createAsyncThunk(
  'companies/getPromotions',
  async (companyId: number, { rejectWithValue }) => {
    const { data, success, message } = await CompaniesService.getPromotions(companyId);
    if (success && data) {
      return data;
    }
    console.error(message);
    return rejectWithValue(data);
  },
);
