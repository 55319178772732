import React, { Fragment } from 'react';
import { Divider } from 'antd';
import { ImportLinks, ImportLinksProps } from '../ImportLinks/ImportLinks';
import { Specifications, SpecificationsProps } from '../Specifications/Specifications';

type OrderDownloadLinksProps = ImportLinksProps & SpecificationsProps;

export const OrderDownloadLinks = ({
  onDownloadExcel,
  onDownloadImport,
  onRenderRequest,
  onDownloadSpecification,
  specifications,
}: OrderDownloadLinksProps) => {
  const showSpecifications = Boolean(specifications.length);

  return (
    <Fragment>
      <ImportLinks
        onRenderRequest={onRenderRequest}
        onDownloadImport={onDownloadImport}
        onDownloadExcel={onDownloadExcel}
      />
      {showSpecifications && (
        <Fragment>
          <Divider style={{ margin: '5px 0' }} />
          <Specifications specifications={specifications} onDownloadSpecification={onDownloadSpecification} />
        </Fragment>
      )}
    </Fragment>
  );
};
