import React from 'react';
import { Route as BasicRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { ItemInterface } from 'app/pages/routes';
import { usePermission } from 'hooks/usePermission';

export const Route: React.FC<ItemInterface> = ({
  path,
  component: Component,
  privateRoute,
  exact,
  permissions,
}) => {
  const { login } = useSelector((state: RootState) => state.auth);
  const routeIsAvailable = usePermission(permissions, 'some');

  return (
    <BasicRoute
      exact={exact}
      path={path}
      render={props =>
        privateRoute && !login ? (
          <Redirect to={`/login?destination=${props.location.pathname}`} />
        ) : privateRoute && !routeIsAvailable ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
