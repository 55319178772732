import { Result } from 'app/types/api';
import { apiGet, apiPost } from './connect';
import { Order, OrdersPayload } from 'app/types/orders';

export const getOrders = async (search: string) => {
  const { data } = await apiGet<Result<OrdersPayload>>(`/personal/orders${search}`);

  return data;
};

export const getOrder = async (id: number) => {
  const { data } = await apiGet<Result<Order>>(`/personal/orders/${id}`);

  return data;
};

export const getOrderImportExcel = async (id: string) => {
  const { data } = await apiGet<
    Result<{
      name: string;
      data: string;
      mimeType: string;
    }>
  >(`/order/${id}/excel/import`);

  return data;
};

export const getOrderExcel = async (id: string) => {
  const { data } = await apiGet<
    Result<{
      name: string;
      data: string;
      mimeType: string;
    }>
  >(`/order/${id}/excel`);

  return data;
};

export const getForecastExcel = async () => {
  return await apiGet<Blob>('/export/forecast', {}, { responseType: 'blob' }).then(response => {
    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }

    return {
      data: {
        name: fileName,
        data: blob,
        mimeType: response.data.type,
      },
      success: true,
      message: '',
    };
  });
};

export const claimOrder = async (orderId: number, email: string | undefined, username: string | undefined) => {
  const { data } = await apiPost<Result<unknown>>('/claims', {
    type: 'pick_up',
    orderId,
    email,
    username,
  });
  return data;
};
