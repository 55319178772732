import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

export const Modal = styled(AntdModal)`
  &&& {
    .ant-modal-content {
      border-top: 5px solid #cd743d;
      padding: 40px 24px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .ant-modal-body {
      padding: 0;
      display: flex;
      column-gap: 12px;
    }
  }
`;

export const SuccessIcon = styled.div`
  &&& {
    min-width: 80px;
    width: 80px;
    height: 80px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #797979;
`;
