import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';

/**
 * Хук позволяющий проверить конкретную роль текущего пользователя
 * @param {string} role Роль которую нужно проверить
 * @return {boolean} есть ли входящая роль у текущего пользователя
 */

export const useIsRole = (role: string): boolean => {
  const { roles } = useSelector((state: RootState) => state.auth);
  return roles.includes(role);
};
