import React from 'react';
import { Button, Popover } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { createLoggerEvent, actionTypes } from 'logger';
import { Specification } from 'app/types/orders';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useDownloadFiles } from 'common/orders/hooks/useDownloadFiles';
import { OrderDownloadLinks } from 'common/orders/components/OrderDownloadLinks/OrderDownloadLinks';

const { ORDERS_DOWNLOAD_IMPORT, ORDERS_DOWNLOAD_EXCEL, ORDERS_DOWNLOAD_RENDERS } = actionTypes;

type ActionsProps = {
  orderId: number;
  specifications: Specification[];
};

type ActionType = 'ORDERS_DOWNLOAD_IMPORT' | 'ORDERS_DOWNLOAD_EXCEL' | 'ORDERS_DOWNLOAD_RENDERS';

export const Actions = ({ orderId, specifications }: ActionsProps) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const { onDownloadExcel, onDownloadImport, onDownloadSpecification, onRenderRequest } = useDownloadFiles(orderId);

  const createHandlerWithLoggerEvent = (handler: () => void, actionType: ActionType) => {
    return createLoggerEvent(handler, {
      ActionType: actionType,
      OrderId: orderId.toString(),
    });
  };

  const content = (
    <OrderDownloadLinks
      specifications={specifications}
      onDownloadSpecification={onDownloadSpecification}
      onDownloadImport={createHandlerWithLoggerEvent(onDownloadImport, ORDERS_DOWNLOAD_IMPORT)}
      onDownloadExcel={createHandlerWithLoggerEvent(onDownloadExcel, ORDERS_DOWNLOAD_EXCEL)}
      onRenderRequest={createHandlerWithLoggerEvent(onRenderRequest, ORDERS_DOWNLOAD_RENDERS)}
    />
  );

  if (isTablet) {
    return (
      <Popover trigger="hover" placement="bottomRight" content={content}>
        <Button type="link">
          <EllipsisOutlined style={{ fontSize: 16, color: '#1d1d1d' }} />
        </Button>
      </Popover>
    );
  }

  return content;
};
