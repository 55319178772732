import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Button } from 'components/KrasUIKit';
import { angleRight } from 'components/KrasUIKit/icons';
import { Title } from 'components/Title';
import * as S from './Header.styled';
import { getDaysDiffTitle } from 'features/AutoOrders/utils';

type HeaderProps = {
  id: string;
  dateReturnToCart?: string;
};

export const Header = ({ id, dateReturnToCart }: HeaderProps) => {
  const daysDiffTitle = dateReturnToCart ? getDaysDiffTitle(dateReturnToCart) : undefined;

  return (
    <Row justify="space-between" gutter={[30, 30]}>
      <Col>
        <S.TitleWrapper>
          <Title style={{ margin: 0 }} upper level={4}>
            Автозаказ {id}
          </Title>
          {daysDiffTitle && (
            <S.Description>
              Изделия из автозаказа автоматически вернутся в корзину {daysDiffTitle.title === 'сегодня' ? '' : 'через '}
              <b>{daysDiffTitle.title}</b>&nbsp;({daysDiffTitle.date})
            </S.Description>
          )}
        </S.TitleWrapper>
      </Col>
      <Col>
        <Link to="/personal/auto-orders">
          <Button icon={angleRight} iconDirection="right">
            Список заказов
          </Button>
        </Link>
      </Col>
    </Row>
  );
};
