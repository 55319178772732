import { Divider, InputNumber } from 'antd';
import styled from 'styled-components';
import { Title } from './Common.styled';

export const Order = styled.div`
  margin-bottom: 3.5em;
  & ${Title} {
    font-weight: 400;
  }
  label {
    margin: 0 0 20px;
  }
  .ant-divider-horizontal {
    margin: 16px 0;
  }
  .ant-input-number {
    margin-top: 0;
  }
`;
