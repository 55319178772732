import { createAsyncThunk } from '@reduxjs/toolkit';
import * as AutoOrdersApi from 'app/services/autoOrders';
import { message as antdMessage } from 'antd';
import { SLICE_NAME } from './consts';

export const getAutoOrders = createAsyncThunk(
  `${SLICE_NAME}/getAutoOrders`,
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await AutoOrdersApi.getAutoOrders(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const getAutoOrder = createAsyncThunk(`${SLICE_NAME}/getAutoOrder`, async (id: number, { rejectWithValue }) => {
  const { data, success, message } = await AutoOrdersApi.getAutoOrder(id);
  if (success && data) {
    return data;
  } else {
    antdMessage.error(message);
    return rejectWithValue(data);
  }
});

export const cancelAutoOrder = createAsyncThunk(
  `${SLICE_NAME}/cancelAutoOrder`,
  async (id: number, { rejectWithValue }) => {
    const { data, success, message } = await AutoOrdersApi.cancelAutoOrder(id);
    if (success) {
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
