import React from 'react';
import { Link } from 'react-router-dom';
import { date } from '../../../helpers/format';
import { useTranslation } from 'react-i18next';
import { ParentMenuItem } from 'app/types/layout';
import { useModal } from 'app/ducks/modals';
import { MODAL_NAME } from 'components/Modals/Feedback';
import Logo from 'components/Logo';
import { Phone } from '../Phone';
import * as S from './styled';
import { actionTypes, sendEvent } from 'logger';

export interface Props {
  items: ParentMenuItem[];
}

export const Footer: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation(['company', 'layout', 'feedback']);
  const { showModal } = useModal(MODAL_NAME);

  const hasHttp = (to: string) => {
    return to.search(/^http[s]?\:\/\//) !== -1;
  };

  const clickOnLink = (to: string) => {
    sendEvent({
      ActionType: actionTypes.FOOTER_CLICK_LINK,
      ContextKey: to,
    });
  };

  return (
    <S.Footer>
      <S.MainNav>
        <S.LogoWrap>
          <Link to="/" onClick={() => sendEvent(actionTypes.FOOTER_CLICK_LOGO)}>
            <Logo type="inverse" scalability={false} />
          </Link>
        </S.LogoWrap>
        <S.NavWrap>
          {items.map(({ title, subs }, index) => (
            <S.GroupNav key={title}>
              <S.Title>{title}</S.Title>
              {subs.map(({ title, to }) => (
                <S.NavItem key={to}>
                  {hasHttp(to) ? (
                    <a href={to} onClick={() => clickOnLink(to)}>
                      {title}
                    </a>
                  ) : (
                    <Link to={to} onClick={() => clickOnLink(to)}>
                      {title}
                    </Link>
                  )}
                </S.NavItem>
              ))}
              {index === items.length - 1 && (
                <>
                  <S.NavItem>
                    <a href="https://www.krastsvetmet.ru/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      {t('layout:privacyPolicy')}
                    </a>
                  </S.NavItem>
                  <S.FeedbackWrap>
                    <div>
                      <S.Clarification>{t('layout:jewelrySalesDepartment')}:</S.Clarification>
                      <Phone phone="8 800 500 51 05" type="inverse" />
                    </div>
                    <S.Button
                      type="primary"
                      onClick={() => {
                        showModal();
                        sendEvent(actionTypes.FOOTER_CLICK_SUPPORT);
                      }}
                    >
                      {t('feedback:title')}
                    </S.Button>
                  </S.FeedbackWrap>
                </>
              )}
            </S.GroupNav>
          ))}
        </S.NavWrap>
      </S.MainNav>
      <S.CompanyInfo>
        ©{t('company:name')}, 2007–{date('', 'YYYY')}
      </S.CompanyInfo>
    </S.Footer>
  );
};
