import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ManagerUsersService from 'app/services/managerUsers';
import * as AuthService from 'app/services/auth';
import { clearCompanies, loginStart } from 'app/ducks/auth/slice';
import { message as antMessage } from 'antd';
import { initApp } from '../auth/actions';
import { clearPromotions } from '../companies/slice';
import { resetContractsInfo } from 'features/slices/contracts';

export const fetchManagerUsers = createAsyncThunk('managerUsers/fetchManagerUsers', async (search: string) => {
  const { data, success, message } = await ManagerUsersService.getManagerCompanies(search);
  if (success) {
    return data;
  } else {
    throw new Error(message);
  }
});

export const loginByUser = createAsyncThunk(
  'managerUsers/loginByUser',
  async (payload: AuthService.ManagerLoginPayload, { dispatch }) => {
    const { data, success, message } = await AuthService.managerLogin(payload);
    if (success) {
      await dispatch(clearCompanies());
      await dispatch(resetContractsInfo());
      await dispatch(clearPromotions());
      await dispatch(loginStart());
      await dispatch(initApp());
      return data;
    } else {
      antMessage.error('Не удалось авторизоваться');
      throw new Error(message);
    }
  },
);
