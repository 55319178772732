/* eslint-disable @typescript-eslint/no-inferrable-types */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useUnitsConverter = () => {
  const { i18n } = useTranslation('productDetail');

  const toGrams = useCallback((milligrams: number) => {
    const grams = milligrams / 1000;
    const formatter = new Intl.NumberFormat(i18n.language, { style: 'unit', unit: 'gram', maximumFractionDigits: 3 });
    return formatter.format(grams);
  }, []);

  const toRubles = useCallback((pennies: number, withUnits: boolean = true) => {
    const rubles = pennies / 100;
    const showPennies = pennies % 100 > 0;
    const ruFormatter = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'RUB',
      maximumFractionDigits: showPennies ? 2 : 0,
    });
    return withUnits ? ruFormatter.format(rubles) : rubles;
  }, []);

  return {
    toGrams,
    toRubles,
  };
};
