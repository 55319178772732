import { UploadFile } from 'antd/lib/upload/interface';
import { apiPost, apiGet, apiDelete } from 'app/services/connect';
import {
  Result,
  ItemResult,
  ItemsResult,
  ItemsResultWithMeta,
  StorageFileEntity,
} from 'app/types/api';
import {
  BannerEntity,
  CreateBannerDto,
  UpdateBannerDto,
} from 'app/types/banners';

class BannersServiceFactory {
  public getMany = async (search: string) => {
    const { data } = await apiGet<ItemsResultWithMeta<BannerEntity>>(
      `/banners${search}`,
    );
    return data;
  };

  public getPublic = async (search: string) => {
    const { data } = await apiGet<ItemsResult<BannerEntity>>(
      `/banners/public${search}`,
    );
    return data;
  };

  public create = async (newBannerData: CreateBannerDto) => {
    const { data } = await apiPost<ItemResult<BannerEntity>>(
      '/banners',
      newBannerData,
    );
    return data;
  };

  public update = async ({ id, ...newBannerData }: UpdateBannerDto) => {
    const { data } = await apiPost<ItemResult<BannerEntity>>(
      `/banners/${id}`,
      newBannerData,
    );
    return data;
  };

  public uploadImg = async (file: UploadFile) => {
    const form = new FormData();
    const dataFile = (file as unknown) as File;
    form.set('files[0]', dataFile);
    const { data } = await apiPost<Result<StorageFileEntity[]>>(
      '/storage/upload',
      form,
    );
    return data;
  };

  public delete = async (bannerId: number) => {
    const { data } = await apiDelete<Result>(`/banners/${bannerId}`);
    return data;
  };
}
export const BannersService = new BannersServiceFactory();
