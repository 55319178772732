import React from 'react';
import { Result, Layout } from 'antd';
import { Title } from 'components/Title';
import Helmet from 'react-helmet';

const { Content } = Layout;
export const Page404: React.FC<{}> = () => {
  return (
    <Content
      style={{
        padding: 'var(--s8) var(--s8) 0',
        maxWidth: 1441,
        margin: '0 auto',
        minHeight: 'calc(100vh - 160px)',
      }}
    >
      <Helmet>
        <title>404 страница не найдена</title>
      </Helmet>
      <Result
        status="warning"
        title={<Title upper>404</Title>}
        subTitle={
          <Title level={3} upper>
            страница не найдена
          </Title>
        }
      />
    </Content>
  );
};
