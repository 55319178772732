/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { useQuery } from 'hooks/useQuery';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { convertQueryToValue, convertValueToQuery } from './helpers';

export type Props = {
  children: (
    form: Omit<FormProps, 'onFinish'> & {
      onFinish: (values: Store) => void;
    },
  ) => ReactElement | null;
};

/**
 * Компонент работающий с AntdForm и формирующий queries на основе Store
 * @remarks Компонент умеет работать с Switch, Input, Select (multiple), DatePicker
 * @param {Props} children Render Props паттер
 * @example
 * <QueryFilters>
 * {(form) => (
 *      <Form {...form}>
 *          <Form.Item>
 *              <Input/>
 *          </Form.Item>
 *          <Button htmlType="submit">Submit</Button>
 *          <Button htmlType="reset">Reset</Button>
 *      </Form>
 * )}
 * </QueryFilters>
 */

export const QueryFilters: React.FC<Props> = ({ children }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const queries = useQuery();

  const getInitialValues = () => {
    const base: { [key: string]: any } = {};
    for (const query of queries) {
      const key = query[0];
      base[key] = convertQueryToValue(query);
    }
    return base;
  };

  const initialValues: Store = getInitialValues();

  React.useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [queries]);

  const onFinish: (values: Store) => void = values => {
    const convertValues = convertValueToQuery(values);

    for (const key of queries.keys()) {
      if (key !== 'page' && !convertValues[key]) {
        queries.delete(key);
      }
    }

    for (const [key, value] of Object.entries(convertValues)) {
      if (queries.get(key) !== value.toString()) {
        queries.set('page', '1');
      }
      queries.set(key, value.toString());
    }

    history.push({
      search: queries.toString(),
    });
  };

  const onReset = async () => {
    const convertValues = convertValueToQuery(form.getFieldsValue());
    queries.set('page', '1');

    for (const key of Object.keys(convertValues)) {
      if (queries.get(key)) {
        queries.delete(key);
      }
    }

    await history.push({ search: queries.toString() });
    form.resetFields();
  };

  return children({ onFinish, initialValues, form, onReset });
};
