import React, { useEffect, useCallback } from 'react';
import { Documents } from '../components/Personal/Documents';
import { useDocuments } from 'app/ducks/documents';
import { useDocumentsSection } from 'app/ducks/documentsSection';

export const DocumentsContainer: React.FC = () => {
  const { state: documents, fetchActs, downloadDocument } = useDocuments();

  const { state: sections, getDocumentSectionsByCode } = useDocumentsSection();

  useEffect(() => {
    getDocumentSectionsByCode('personal');
  }, []);

  const getActs = useCallback(() => {
    fetchActs();
  }, []);

  const onDownload = (guuid: string) => {
    downloadDocument(guuid);
  };

  return (
    <Documents
      {...documents}
      sections={sections.list}
      sectionsLoading={sections.loading}
      onDownload={onDownload}
      getActs={getActs}
    />
  );
};
