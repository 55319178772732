import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { date, number } from 'helpers/format';
import { BonusTracker } from '../../BonusTracker';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import { Tariff } from 'models/api/promotions';
import * as S from '../../styled/Stage.styled';

export type AccumulationProps = {
  url: string;
  updateDate: string;
  balance: number;
  tariff: Tariff[];
  currentTariff?: Tariff;
  currentWeight: number;
  neededWeight: number;
  handleRulesClick: () => void;
  openRulesInNewTab: boolean;
  isHolding: boolean;
};

export const Accumulation = ({
  url,
  balance,
  tariff,
  currentTariff,
  currentWeight,
  neededWeight,
  updateDate,
  handleRulesClick,
  openRulesInNewTab,
  isHolding,
}: AccumulationProps) => {
  const { toRubles, toGrams } = useUnitsConverter();
  const showNotice = neededWeight - currentWeight > 0;

  return (
    <Fragment>
      <S.Head>
        <S.Title>Баланс бонусов: {toRubles(balance)}</S.Title>
        <S.SecondaryText>Обновлено {date(updateDate, 'DD.MM.YYYY')} (МСК)</S.SecondaryText>
      </S.Head>
      <S.Divider />
      {isHolding && (
        <Fragment>
          <S.Text>Начисление бонуса происходит в рамках холдинга</S.Text>
          <S.Divider />
        </Fragment>
      )}
      <S.Body>
        {showNotice && (
          <S.Text fontSize="16px" lineHeight="20px" color="#212529">
            Для получения {number((currentTariff?.sum ?? 0) / 100, 0)} бонусов оформите заказ еще на{' '}
            <b>{toGrams(neededWeight - currentWeight)}</b>
          </S.Text>
        )}
        <BonusTracker currentWeight={currentWeight} tariff={tariff} />
      </S.Body>
      <S.Divider />
      <S.Text fontSize="14px" lineHeight="16px" color="#4f4f4f">
        Подробнее о начислениях и списаниях можете ознакомиться{' '}
        <Link to={url} onClick={handleRulesClick} target={openRulesInNewTab ? '_blank' : '_self'}>
          в правилах акции
        </Link>
      </S.Text>
    </Fragment>
  );
};
