import { apiGet } from './connect';
import { Result } from 'app/types/api';
import { CompanyManagerResponse } from 'app/types/managerUsers';

export type UsersManagerResponse = Result<CompanyManagerResponse>;

export const getManagerCompanies = async (search: string) => {
  const { data } = await apiGet<UsersManagerResponse>(`/managers/companies${search}`, {
    filterBy: 'manager, company, client',
  });
  return data;
};
