import React from 'react';
import { Bar, Content } from './styled';
import { Button, Typography } from 'antd';
import { useCookieBar } from 'hooks/useCookieBar';

export type CookieBarProps = Record<never, never>;

export const CookieBar: React.FC<CookieBarProps> = () => {
  const { isOpened, handleAcceptPrivacyPolicy } = useCookieBar();

  return (
    <Bar open={isOpened} closable={false} closeIcon={null} placement="bottom" height="auto">
      <Content>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          Этот сайт использует файлы cookie, чтобы обеспечить удобную работу пользователей с&nbsp;ним. Нажимая кнопку
          «Я&nbsp;принимаю», вы даете согласие на&nbsp;обработку cookie в&nbsp;соответствии с&nbsp;
          <a href="https://www.krastsvetmet.ru/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Политикой в&nbsp;отношении обработки и&nbsp;защиты персональных данных
          </a>
          . В&nbsp;случае несогласия с&nbsp;обработкой вам следует отключить сохранение cookie в&nbsp;настройках вашего
          браузера или&nbsp;покинуть сайт.
        </Typography.Paragraph>
        <Button type="primary" size="large" onClick={handleAcceptPrivacyPolicy}>
          Я&nbsp;принимаю
        </Button>
      </Content>
    </Bar>
  );
};
