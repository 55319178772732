import React from 'react';
import { Badge } from 'antd';
import * as S from './styled';

export interface Props {
  id: number;
  name: string;
  onChange: (id: number) => void;
  active: number | null;
  count?: number;
}

export const Covering: React.FC<Props> = ({ id, name, onChange, active, count = 0 }) => {
  return (
    <S.CoveringButton active={active === id} shape="round" size="large" onClick={() => onChange(id)}>
      {name}
      <Badge showZero count={count} overflowCount={999} />
    </S.CoveringButton>
  );
};
