/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from 'react';
import { Typography, message as AntMessage, Select } from 'antd';
import * as S from './styled';
import { LoadingOutlined } from '@ant-design/icons';
import { LabeledValue } from 'antd/lib/select';

const { Option } = Select;
export interface Option {
  key: string;
  value: string | number;
  label: string;
  description: string;
}
export interface Props {
  formatResponse: (data: any) => Option[];
  placeholder?: string;
  dataRequest: (query: string) => any;
  onSelect?: (value: Option) => void;
  dropdownMatchSelectWidth?: number | boolean;
  showLabel?: boolean;
}

export const Autocomplete: React.FC<Props> = ({ formatResponse, dataRequest, onSelect, ...props }) => {
  const [result, setResult] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const timer = useRef<number | null>(null);

  const getResult = async (query: string) => {
    setLoading(true);
    try {
      const { data, success, message } = await dataRequest(query);
      if (success) {
        // TODO .slice(10)
        const formattedData = formatResponse(data);
        setResult(formattedData);
        setLoading(false);
      } else throw new Error(message);
    } catch (error) {
      //@ts-expect-error
      AntMessage.error(error.message);
      setLoading(false);
      setResult([]);
    }
  };

  const handleSearch = (value: string) => {
    if (timer.current) clearTimeout(timer.current);
    if (value.length !== 0 && value.trim().length !== 0) {
      timer.current = setTimeout(() => {
        getResult(value);
      }, 1000);
    } else setResult([]);
  };

  const selectHandler = (id: string | number | LabeledValue) => {
    if (onSelect) {
      const option = result.find(({ value }) => value === id);
      if (option) onSelect(option);
    }
  };

  const children = result.map(({ key, value, label, description }) => (
    <Option key={key} value={value}>
      <S.StyledOption>
        <div style={{ marginRight: '0.5em' }}>{label}</div>
        <Typography.Text type="secondary">{description}</Typography.Text>
      </S.StyledOption>
    </Option>
  ));

  return (
    <S.Wrapper>
      <Select
        showSearch
        style={{ width: '100%' }}
        onSearch={handleSearch}
        onSelect={selectHandler}
        filterOption={false}
        notFoundContent={null}
        {...props}
      >
        {children}
      </Select>
      {loading && (
        <S.Affix>
          <LoadingOutlined />
        </S.Affix>
      )}
    </S.Wrapper>
  );
};
