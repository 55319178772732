import React from 'react';
import { loading as loadingIcon } from '../../icons';
import * as S from './styled';

export type Props = Omit<React.ComponentPropsWithoutRef<'button'>, 'type'> & {
  icon?: JSX.Element;
  iconDirection?: 'left' | 'right';
  type?: 'primary' | 'outline' | 'dashed' | 'link';
  typeHtml?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  theme?: 'warning' | 'danger' | 'info' | 'success' | 'default';
} & React.ComponentPropsWithoutRef<'a'>;

export const Button: React.FC<Props> = ({
  icon,
  iconDirection = 'left',
  children,
  type = 'outline',
  typeHtml = 'button',
  size = 'medium',
  theme = 'default',
  loading,
  href,
  ...buttonProps
}) => {
  return (
    <S.Component
      as={href ? 'a' : 'button'}
      colorType={type}
      size={size}
      {...buttonProps}
      type={href ? undefined : typeHtml}
      iconDirection={iconDirection}
      theme={theme}
      href={href}
    >
      {iconDirection === 'left' && icon}
      <span>{children}</span>
      {iconDirection === 'right' && !loading && icon}
      {loading && loadingIcon}
    </S.Component>
  );
};
