import { useState } from 'react';
import { CompanyResponse } from 'app/types/company';
import { MenuProps } from 'antd';

type UseCompanyMenuProps = {
  currentCompany: CompanyResponse | null;
  companies: CompanyResponse[];
  onChangeCompany: (company: CompanyResponse) => void;
};

export const useCompanyMenu = ({ currentCompany, companies, onChangeCompany }: UseCompanyMenuProps) => {
  const [companySearch, setCompanySearch] = useState('');

  const filteredCompanies = companies.filter((option: CompanyResponse) =>
    option.name.toLowerCase().includes(companySearch.toLowerCase()),
  );

  const menuItems: MenuProps['items'] = filteredCompanies.map(({ name, id }) => ({
    key: id,
    type: 'item',
    label: name,
  }));

  const clickHandler: MenuProps['onClick'] = ({ key }) => {
    const selectedCompany = companies.find(({ id }) => id === Number(key));
    if (currentCompany && selectedCompany && currentCompany.id !== selectedCompany.id) {
      onChangeCompany(selectedCompany);
    }
  };

  const menu: MenuProps = {
    items: menuItems,
    defaultSelectedKeys: currentCompany ? [String(currentCompany.id)] : undefined,
    style: { maxWidth: '17em' },
    onClick: clickHandler,
  };

  return {
    menu,
    companySearch,
    setCompanySearch,
    showNotFound: filteredCompanies.length === 0,
  };
};
