import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { WaitingLists, WaitingListOffer, DataForDeleteOffer } from 'app/types/waitingLists';
import { Pagination } from 'app/types/pagination';
import * as actions from './actions';

export interface WaitingListsProps {
  loading?: boolean;
  waitingListData: WaitingLists;
  pagination?: Pagination;
}

export const waitingListsInitialState: WaitingListsProps = {
  loading: false,
  waitingListData: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('waitingLists/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('waitingLists/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('waitingLists/') && action.type.endsWith('/fulfilled');

const waitingListsSlice = createSlice({
  name: 'waitingLists',
  initialState: waitingListsInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchWaitingLists.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          items: WaitingLists;
          pagination: Pagination;
        }>,
      ) => {
        state.waitingListData = payload.items;
        state.pagination = payload.pagination;
      },
    );
    addCase(
      actions.fetchOffersList.fulfilled,
      (state, { payload }: PayloadAction<{ data: WaitingListOffer[]; productId: number }>) => {
        const index = state.waitingListData.findIndex(item => item.productId === payload.productId);
        state.waitingListData[index].offers = payload.data;
      },
    );
    addCase(actions.deleteOffer.fulfilled, (state, { payload }: PayloadAction<DataForDeleteOffer>) => {
      const indexProduct = state.waitingListData.findIndex(item => item.productId === payload.productId);
      const { offers } = state.waitingListData[indexProduct];

      if (offers) {
        if (offers.length <= 1) state.waitingListData.splice(indexProduct, 1);
        else {
          const indexOffer = offers.findIndex(item => item.cartPositionId === payload.cartPositionId);
          offers.splice(indexOffer, 1);
        }
      }
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) antdMessage.error(action.error.message);
        state.loading = false;
      },
    );
  },
});

const { reducer } = waitingListsSlice;

export default reducer;
