import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ManagerService from 'app/services/managers';
import { message as antMessage } from 'antd';
import * as types from 'app/types/managers';

export const fetchManagers = createAsyncThunk(
  'managers/fetchManagers',
  async (parameters: { page: string; search: string }) => {
    const { data, success, message } = await ManagerService.getAllManagers(
      parameters.page,
      parameters.search,
    );
    if (success) {
      return data;
    } else throw new Error(message);
  },
);

export const removeCompanyRelation = createAsyncThunk(
  'managers/removeCompanyRelation',
  async (company: types.CompanyControl) => {
    const {
      success,
      message,
      data,
    } = await ManagerService.removeCompanyRelation(company);
    if (success) {
      antMessage.success('Компания успешно удалена');
      return data;
    } else throw new Error(message);
  },
);

export const addCompanyRelation = createAsyncThunk(
  'managers/addCompanyRelation',
  async (company: types.CompanyControl) => {
    const { data, success, message } = await ManagerService.addCompanyRelation(
      company,
    );
    if (success) {
      antMessage.success('Компания успешно добавлена');
      return data;
    } else throw new Error(message);
  },
);
