import React, { useState, useEffect } from 'react';
import { Alert, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { Element } from './Tabs/Element';
import { Locks } from './Tabs/Locks';
import { Tweezers } from './Tabs/Tweezers';
import { ContentProps } from 'features/AdditionalProducts/types/Furniture';
import { updateFittings } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { AdditionalProductAlloy } from 'app/types/adminAdditional';
import { useTranslation } from 'react-i18next';

export const TabsContent: {
  [key: string]: ({ products, onChange }: ContentProps) => JSX.Element;
} = {
  element1: Element,
  element2: Element,
  connectingRing: Element,
  lock: Locks,
  tweezers: Tweezers,
};

interface FurnitureTabsProps {
  onTabChange: (productType: string, alloyId: string | number) => void;
}

export const FurnitureTabs = ({ onTabChange }: FurnitureTabsProps) => {
  const { t } = useTranslation('additional');
  const {
    metadata: { productsMeta },
  } = useSelector((state: RootState) => state.additionalProducts);
  const { fitting } = useSelector((state: RootState) => state.additionalProductsOrder);
  const [activeTabs, setActiveTabs] = useState<{ [key: string]: string | number }>({});

  const dispatch = useDispatch();

  const changeFittingItem = (
    alloy: AdditionalProductAlloy,
    fittingType: string,
    index: number,
    count: number,
    weight?: number,
  ) => {
    dispatch(
      updateFittings({
        alloy,
        fittingType,
        index,
        count,
        weight: weight || 0,
      }),
    );
  };

  const availableProductTypes = Object.keys(fitting);

  const tabs = availableProductTypes.map(key => {
    const [productInfo] = productsMeta.filter(item => item.code === key);

    return {
      label: productInfo.title,
      key,
      children: <div>{productInfo.title}</div>,
    };
  });

  const handleTabChange = (tabKey: string, alloyId: string | number) => {
    setActiveTabs({ ...activeTabs, [tabKey]: alloyId });
    onTabChange(tabKey, alloyId);
  };

  return (
    <>
      <Alert
        message=""
        description={t('alert', { silver: 300, gold: 150 })}
        type="info"
        showIcon
        style={{
          margin: '20px 0',
        }}
      />
      <Tabs defaultActiveKey={tabs.length ? tabs[0].key : ''} onChange={key => onTabChange(key, activeTabs[key] || 0)}>
        {tabs.map(tab => {
          const Component = TabsContent[tab.key];
          return (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              <Component
                products={fitting[tab.key]}
                onChange={changeFittingItem}
                onTabChange={(alloyId: string | number) => handleTabChange(tab.key, alloyId)}
                productKey={tab.key}
              />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};
