import React from 'react';
import { Alert } from 'components/KrasUIKit/components/Alert';
import { info, email, IconLetter } from 'components/KrasUIKit/icons';
import { fileOutlined } from 'components/KrasUIKit/icons';
import { Link } from 'react-router-dom';
import { Title } from 'components/Title';
import { actionTypes, sendEvent } from 'logger';
import ScrollAnimation from 'react-animate-on-scroll';
import * as S from './styled';

// images
import imgMain from 'images/png/returnMain.webp';
import imgMain2x from 'images/png/returnMain@2x.webp';
import imgMainPng from 'images/png/returnMain.png';
import stage1 from 'images/png/stage1.webp';
import stage12x from 'images/png/stage1@2x.webp';
import stage1Png from 'images/png/stage1.png';
import stage2 from 'images/png/stage2.webp';
import stage22x from 'images/png/stage2@2x.webp';
import stage2Png from 'images/png/stage2.png';
import stage3 from 'images/png/stage3.webp';
import stage32x from 'images/png/stage3@2x.webp';
import stage3Png from 'images/png/stage3.png';
// svg
import chainDefect from 'images/svg/chain_defect2.svg';
import chainDefect2 from 'images/svg/chain_defect.svg';
import chainPurchase from 'images/svg/chain_purchase.svg';
import term1 from 'images/svg/Frame999.svg';
import term2 from 'images/svg/Frame1000.svg';
import { File } from 'components/KrasUIKit/components/File';

export const ReturnConditions: React.FC = () => {
  return (
    <S.Section>
      {/* <S.MainTitle level={2}>
        Как вернуть ювелирные изделия с производственным браком?
      </S.MainTitle> */}
      <S.BlockWithImage>
        <picture>
          <source srcSet={`${imgMain}, ${imgMain2x} 2x`} type="image/webp" />
          <img src={imgMainPng} alt="Доставка ювелирных изделий" />
        </picture>
        <div>
          <S.P>
            Одна из важнейших задач Красцветмета – создавать продукт, который
            полностью соответствует вашим ожиданиям и быстро реагировать на
            любые ситуации, связанные с отклонением качества продукта.
          </S.P>
          <S.P>
            Ниже пошаговая инструкция по оформлению возврата ювелирных изделий с
            недостатками. Полные условия возврата описаны в{' '}
            <S.A as={Link} to="/documents">
              договоре.
            </S.A>
          </S.P>
        </div>
      </S.BlockWithImage>
      <ScrollAnimation animateIn="fadeIn" offset={250} animateOnce={true}>
        <S.Title level={3}>Причины возврата</S.Title>
        <S.Points>
          <S.Point>
            <S.Icon src={chainDefect2} />
            <p>
              изделия с явными недостатками (браком), обнаруженными в ходе
              приемки товара
            </p>
          </S.Point>
          <S.Point>
            <S.Icon src={chainDefect} />
            <p>
              изделия со скрытыми недостатками, установленными во время
              предпродажной подготовки
            </p>
          </S.Point>
          <S.Point>
            <S.Icon src={chainPurchase} />
            <p>
              изделия со скрытыми недостатками, обнаруженными после продажи
              товара розничному покупателю
            </p>
          </S.Point>
        </S.Points>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <S.Title level={3}> Сроки возврата </S.Title>
        <S.Points>
          <S.Point>
            <S.Icon src={term2} />
            <p>в течение 12 месяцев с момента получения партии товара</p>
          </S.Point>
          <S.Point>
            <S.Icon src={term1} />
            <p>
              в течение 6 месяцев с момента продажи товара розничному покупателю
            </p>
          </S.Point>
        </S.Points>
      </ScrollAnimation>
      <S.LimitedWidthBlock>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <S.Title level={3}>Порядок оформления возврата</S.Title>
          <picture>
            <source srcSet={`${stage1}, ${stage12x} 2x`} type="image/webp" />
            <img src={stage1Png} alt="Первый этап возврата изделий" />
          </picture>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <S.Title level={4}>Проверка документов</S.Title>
          <S.P>
            Для оформления возврата вам нужно подготовить сканы документов и
            отправить их для проверки на почту{' '}
            <S.A
              as="a"
              href="mailto:quality@krastsvetmet.ru?subject=Красцветмет. Проверка документов для возврата изделий"
            >
              quality@krastsvetmet.ru
            </S.A>
          </S.P>
          <File
            style={{ marginBottom: 10 }}
            href="/return/Бланк акта о скрытых недостатках.xlsx"
            name="Акт о скрытых недостатках по форме Красцветмета или акт о расхождениях в количестве или качестве товара, выявленных при приемке ТМЦ (ТОРГ-2)"
            description="В акте необходимо указать штрихкоды изделий к возврату. При&nbsp;формировании ТОРГ-2 штрихкоды изделий должны быть указаны в&nbsp;сопроводительном письме"
          />
          <File
            style={{ marginBottom: 10 }}
            href="/return/ТОРГ-12_УПД_М-15.zip"
            name="ТОРГ-12, Универсальный передаточный документ (УПД) или М-15"
          />
          <File
            style={{ marginBottom: 10 }}
            href="/return/Письмо о системе налогообложения.doc"
            name="Письмо о применяемой системе налогообложения"
          />
          <File
            style={{ marginBottom: 10 }}
            href="/return/Письмо о переносе денежных средств на действующий договор.doc"
            name="Письмо о переносе денежных средств с договора на договор (если у компании несколько договоров)"
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <S.Footnote>
            Образцы заполнения в разделе{' '}
            <S.A as={Link} to="/documents/blanks">
              Бланки документов
            </S.A>
            .<br /> Проверка документов занимает от <b>1 до 3 рабочих дней</b>.{' '}
            <br /> Мы отправим вам подтверждение на почту или попросим устранить
            ошибки в документах.
          </S.Footnote>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <S.Ul>
            При возврате изделий от розничного покупателя дополнительно
            требуется:
            <S.Li>Копия заявления покупателя о возврате</S.Li>
            <S.Li>Копия кассового или товарного чека</S.Li>
          </S.Ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Alert
            style={{ margin: '2.5em 0 1.7em' }}
            icon={info}
            title={
              <>
                <b>Документы</b> на возврат товара по разным договорам и разным
                драгоценным металлам <b>оформляются по отдельности</b>. В одном
                пакете документов не может быть совмещен возврат по золоту,
                серебру и платине, а также по договору поставки и договору
                подряда
              </>
            }
            alertType="info"
          />

          <S.P>
            Отправьте фотографии изделий с браком вместе с документами на
            проверку – это поможет нам разобраться в причинах брака и быстрее
            оформить возврат. На фотографиях должен просматриваться дефект.
          </S.P>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <picture>
            <source srcSet={`${stage2}, ${stage22x} 2x`} type="image/webp" />
            <img
              src={stage2Png}
              alt="Второй этап возврата изделий"
              style={{ marginTop: '1.5em' }}
            />
          </picture>
          <S.Title level={4}>Возврат изделий</S.Title>
          <S.P>
            После проверки документов менеджер компании свяжется с вами и
            согласует удобное время для организации возврата. Упакуйте изделия и
            оригиналы документов в пакет с надписью «Возврат в ЮП», в
            согласованное время пакет заберет курьер Спецсвязи.
          </S.P>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Alert
            style={{ margin: '1.7em 0 3.4em' }}
            icon={info}
            title={
              <b>
                Вы можете доставить изделия в Красцветмет самостоятельно или
                отправить вместе с металлом
              </b>
            }
            alertType="info"
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <picture>
            <source srcSet={`${stage3}, ${stage32x} 2x`} type="image/webp" />
            <img src={stage3Png} alt="Третий этап возврата изделий" />
          </picture>
          <S.Title level={4}>Проверка изделий на производстве</S.Title>
          <S.P>
            После проверки качества на производстве и подтверждения брака, мы
            подготовим корректировочные документы и отправим оригиналы по почте.
            Электронные версии документов можно увидеть в личном кабинете, в
            разделе «Возврат изделий. Корректировочные документы». Денежные
            средства и металл автоматически вернутся на баланс указанного
            договора.
          </S.P>
          <S.P>
            Если в результате проверки будет установлено, что недостатки изделий
            возникли не по вине производителя и не являются производственным
            браком, мы согласуем с вами сроки их возврата. Эти изделия вы можете
            забрать самостоятельно или получить со следующей отгрузкой.
          </S.P>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <S.Footnote>
            Проверка качества изделий занимает <b>15 рабочих дней</b> с момента
            поступления посылки на производство.
          </S.Footnote>

          <S.Button
            icon={fileOutlined}
            iconDirection="left"
            href="/returnConditions.pdf"
            rel="noopener noreferrer"
            size="medium"
            onClick={() => {
              sendEvent({
                ActionType: actionTypes.DOCUMENTS_DOWNLOAD,
                ContextKey: 'Инструкция по возврату изделий',
              });
            }}
          >
            Скачать инструкцию по возврату изделий
          </S.Button>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
          <S.Divider />
        </ScrollAnimation>
      </S.LimitedWidthBlock>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Title level={3} style={{ marginBottom: '0.33em' }}>
          Остались вопросы?
        </Title>
        <S.ContactLinkWrap>
          <IconLetter size="1.25em">{email}</IconLetter>
          <S.ContactLink href="mailto:quality@krastsvetmet.ru?subject=Красцветмет. Проверка документов для возврата изделий">
            quality@krastsvetmet.ru
          </S.ContactLink>
        </S.ContactLinkWrap>
      </ScrollAnimation>
    </S.Section>
  );
};
