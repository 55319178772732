import * as actions from './actions';
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { NewsList, News, NewsType } from 'app/types/news';
import { message as antMessage } from 'antd';

export interface NewsSliceProps {
  list: NewsList;
  loading: boolean;
  detail: News | null;
  types: NewsType[];
}

export const newsInitialState: NewsSliceProps = {
  list: [],
  loading: false,
  detail: null,
  types: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('news/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('news/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('news/') && action.type.endsWith('/fulfilled');

const newsSlice = createSlice({
  name: 'news',
  initialState: newsInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchNews.fulfilled,
      (state, { payload }: PayloadAction<NewsList>) => {
        state.list = payload;
      },
    );
    addCase(
      actions.getTypesNews.fulfilled,
      (state, { payload }: PayloadAction<NewsType[]>) => {
        state.types = payload;
      },
    );
    addCase(
      actions.fetchNewsItem.fulfilled,
      (state, { payload }: PayloadAction<News | null>) => {
        state.detail = payload;
      },
    );
    addCase(
      actions.updateNews.fulfilled,
      (state, { payload }: PayloadAction<News>) => {
        const index = state.list.findIndex(item => item.id === payload.id);
        if (index > -1) state.list[index] = payload;
      },
    );
    addCase(
      actions.addNews.fulfilled,
      (state, { payload }: PayloadAction<News>) => {
        state.list = [payload, ...state.list];
      },
    );
    addCase(
      actions.deleteNews.fulfilled,
      (state, { payload }: PayloadAction<News>) => {
        const index = state.list.findIndex(item => item.id === payload.id);
        if (index > -1) state.list.splice(index, 1);
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) antMessage.error(action.error.message);
        state.loading = false;
      },
    );
  },
});

export default newsSlice.reducer;
