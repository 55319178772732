import styled from 'styled-components';

export const Aside = styled.aside`
  padding: 15px;
  background-color: var(--gray);
`;

export const SecondaryText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;
