import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TooltipContent = styled.div<{ inverse: boolean }>`
  color: ${({ inverse }) => (inverse ? '#fff' : '#000')};
  font-size: 1rem;
  padding: 1em;
`;
export const TitleTooltip = styled.div`
  font-size: 1.125rem;
  margin-bottom: 0.67em;
`;
export const LinkTooltip = styled(Link)<{
  to: string;
}>`
  font-size: 0.87rem;
  color: #4f4f4f;
  font-weight: bold;
  margin-top: 1em;
  white-space: nowrap;
`;
export const TitleTooltipSimple = styled.div<{ inverse: boolean }>`
  color: ${({ inverse }) => (inverse ? '#fff' : '#000')};
  padding: 0.42em 0.5em;
`;
