import { Button, Input } from 'antd';
import styled from 'styled-components';

export const CountWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

export const CountAction = styled(Button).attrs(props => ({
  ...props,
  type: 'primary',
  size: 'small',
}))`
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #414042;
`;

export const CountInput = styled(Input).attrs(props => ({
  size: 'small',
  ...props,
}))`
  &&& {
    max-width: 48px;
    text-align: center;
  }
`;
