import { FileOutlined } from '@ant-design/icons';
import React from 'react';
import { DocumentLink, DocumentTitle, Wrapper } from '../styled/Document';

interface DocumentProps {
  text: string;
  link: string;
}

export const Document = ({ text, link }: DocumentProps) => {
  return (
    <Wrapper>
      <DocumentTitle>
        <FileOutlined style={{ marginRight: '8px' }} />
        {text}
      </DocumentTitle>
      <span>
        <DocumentLink href={link} download>
          Скачать
        </DocumentLink>
      </span>
    </Wrapper>
  );
};
