import React from 'react';
import * as S from './styled';
import { phone as phoneIcon } from 'components/KrasUIKit/icons';
import { actionTypes, sendEvent } from 'logger';

export interface Props {
  phone: string;
  type?: 'normal' | 'inverse';
}

export const Phone: React.FC<Props> = ({ phone, type = 'normal' }) => {
  return (
    <S.ButtonOutline onClick={() => sendEvent(actionTypes.FOOTER_CLICK_PHONE)}>
      <S.IconLetter size="1.5em" type={type}>
        {phoneIcon}
      </S.IconLetter>
      <S.Phone>{phone}</S.Phone>
    </S.ButtonOutline>
  );
};
