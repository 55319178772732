import styled from 'styled-components';

export const IconButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #767676;
  :hover,
  :focus {
    color: #4f4f4f;
  }
  :active {
    color: #212529;
  }
  :disabled {
    color: #bbbbbb;
    cursor: not-allowed;
  }
  svg {
    width: 1em;
    height: 1em;
  }
`;
