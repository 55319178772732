import React from 'react';
import { Title } from '../../Title';

export const StatutoryDocuments: React.FC = () => {
  return (
    <>
      <Title upper level={3}>
        Statutory document
      </Title>
      <ul>
        <li>Articles of association</li> <li>Tax ID certificate </li>
        <li>Registration certificate</li>
        <li>
          Registration Certificate change (the Primary State Registration Number
          was changed in 2013)
        </li>
        <li>Director designation protocol </li>
        <li> Letter of attorney Signatory passport</li>
      </ul>
    </>
  );
};
