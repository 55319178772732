import React from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Contracts, sapNumber, Balance } from 'app/types/contracts';
import { CompanyResponse } from 'app/types/company';
import { number } from 'helpers/format';
import { DropdownButtonLabel, DropdownButtonGroup } from '../Dropdown/Dropdown';
import {
  document,
  company as companyIcon,
  metall,
  IconLetter,
  number100,
  arrowBottomFilled,
} from 'components/KrasUIKit/icons';
import { CompanyMenu, ContractsMenu } from '../MenuComponents';
import { BalanceInfo } from '../BalanceInfo/BalanceInfo';
import * as S from './styled';
import { MetalCostInfo } from '../MetalCostInfo';
import { MetalCostEntity } from 'app/types/metalCost';
import { useCompanyMenu } from '../../hooks/useCompanyMenu';
import { useContractsMenu } from '../../hooks/useContractsMenu';
import { actionTypes, sendEvent } from 'logger';
import { useDebounceByOpen } from 'hooks/useDebounceByOpen';

export const getMetalIcon = (code: string, size = '1.6em') => (
  <IconLetter size={size} style={{ color: code === 'AU' ? '#E4B714' : '#BBBBBB' }}>
    {metall}
  </IconLetter>
);

export interface Props {
  contracts: Contracts;
  balance: Balance;
  contractsLoading?: boolean;
  metalsLoading?: boolean;
  onChangeContract: (id: sapNumber) => void;
  company: CompanyResponse | null;
  companies: CompanyResponse[];
  changeCompanyHandler: (company: CompanyResponse) => void;
  costMetal: MetalCostEntity;
  isLoadingMetalCost?: boolean;
  isLoadingMetalCostFile?: boolean;
  onCheckMetalCostFile: (payload: {
    filepath: string;
  }) => Promise<{
    filepath: string;
  }>;
}

export const CompanySettings: React.FC<Props> = ({
  contracts,
  balance,
  onChangeContract,
  contractsLoading,
  metalsLoading,
  company,
  companies,
  changeCompanyHandler,
  costMetal,
  isLoadingMetalCost,
  isLoadingMetalCostFile,
  onCheckMetalCostFile,
}) => {
  const { t } = useTranslation(['layout']);
  const activeIndex = contracts.findIndex(({ active }) => active);
  const activeItem = contracts[activeIndex];
  const contractNumber = activeItem?.knfmpNumber;
  const contractName = activeItem?.name;
  const isMenaVisible = costMetal && costMetal.menas.length > 0;
  const isPricesWithPrepaidVisible = costMetal && costMetal.pricesWithPrepaid.length > 0;

  const { debounceByOpen } = useDebounceByOpen();

  const { menu: companyMenu, showNotFound, companySearch, setCompanySearch } = useCompanyMenu({
    currentCompany: company,
    companies,
    onChangeCompany: changeCompanyHandler,
  });

  const { menu: contractsMenu } = useContractsMenu({
    contracts,
    onChangeContract,
  });

  return (
    <DropdownButtonGroup style={{ marginLeft: '1em' }}>
      <S.Dropdown
        label={t('feedback:company')}
        icon={companyIcon}
        disabled={companies.length <= 1}
        onOpenChange={open => {
          setCompanySearch('');
          debounceByOpen(() => sendEvent(actionTypes.HEADER_HOVER_COMPANY), open);
        }}
        menu={companyMenu}
        dropdownRender={menu => (
          <CompanyMenu
            menu={menu}
            companySearch={companySearch}
            setCompanySearch={setCompanySearch}
            showNotFound={showNotFound}
          />
        )}
      >
        {company?.name}
      </S.Dropdown>
      <Spin spinning={contractsLoading}>
        {contracts.length > 0 && (
          <S.Dropdown
            label={t('contract')}
            icon={document}
            disabled={contracts.length === 0}
            menu={{
              ...contractsMenu,
              items: contractsMenu.items?.map(item => {
                if (item && item.type === 'item') {
                  return {
                    ...item,
                    label: (
                      <S.MenuItemLabelWrapper>
                        {item.label}
                        {item.disabled && (
                          <S.MenuItemLabelDescription>{t('expiredContract')}</S.MenuItemLabelDescription>
                        )}
                      </S.MenuItemLabelWrapper>
                    ),
                  };
                }
                return item;
              }),
            }}
            dropdownRender={menu => <ContractsMenu menu={menu} />}
            onOpenChange={open => {
              debounceByOpen(() => sendEvent(actionTypes.HEADER_HOVER_CONTRACT), open);
            }}
          >
            {contractNumber && contractName
              ? `${contractNumber} ${contractName !== 'Тестовый' ? `(${contractName})` : ''}`
              : t('contractPlacegolder')}
          </S.Dropdown>
        )}
      </Spin>
      {balance.length > 0 && (
        <Spin spinning={metalsLoading}>
          <S.Dropdown content={<BalanceInfo balance={balance} />} label={t('balance')}>
            <S.MetalsLabel>
              {balance.map(({ metal, metalFree }) => (
                <S.MetalIconWrap key={metal.id}>
                  {getMetalIcon(metal.code)}
                  <DropdownButtonLabel>
                    <b>{number(+metalFree, 2)}</b> {t('units:g')} ({t('HCG')})
                  </DropdownButtonLabel>
                </S.MetalIconWrap>
              ))}
            </S.MetalsLabel>
          </S.Dropdown>
        </Spin>
      )}

      <Spin spinning={isLoadingMetalCost}>
        <S.PricesWrapper>
          {isMenaVisible && (
            <S.Dropdown
              content={
                <MetalCostInfo
                  menas={costMetal.menas}
                  prices={null}
                  withNds={costMetal.withNds}
                  isLoadingFile={isLoadingMetalCostFile}
                  onCheckFile={onCheckMetalCostFile}
                />
              }
              label="Условия взаимозачета"
              icon={arrowBottomFilled}
              popoverProps={{
                onOpenChange: open =>
                  debounceByOpen(() => sendEvent(actionTypes.METAL_COST_HEADER_EXCHANGE), open, 1000),
              }}
            >
              <b>{number(costMetal.menas.filter(({ code }) => code === 'buy')[0].price, 0)}</b> руб.
            </S.Dropdown>
          )}
          {isPricesWithPrepaidVisible && (
            <S.Dropdown
              content={
                <MetalCostInfo
                  prices={costMetal.pricesWithPrepaid}
                  menas={null}
                  withNds={costMetal.withNds}
                  isLoadingFile={isLoadingMetalCostFile}
                  onCheckFile={onCheckMetalCostFile}
                />
              }
              label="Условия поставки"
              icon={number100}
              popoverProps={{
                onOpenChange: open =>
                  debounceByOpen(() => sendEvent(actionTypes.METAL_COST_HEADER_PREPAYMENT), open, 1000),
              }}
            >
              <b>{number(costMetal.pricesWithPrepaid.filter(({ code }) => code === '100')[0].price, 0)}</b> руб.
            </S.Dropdown>
          )}
        </S.PricesWrapper>
      </Spin>
    </DropdownButtonGroup>
  );
};
