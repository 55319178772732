/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import {
  Block,
  Wrapper,
  OrderContent,
  FormWrapper,
  FormItemTitle,
  FormItemImg,
  OrderRow,
  OrderCell,
  Title,
  AlloysList,
} from 'features/AdditionalProducts/styled/Cart.styled';
import emptyPreview from 'images/png/tile-empty.png';
import { CartItemRow } from './CartRow';
import { WireItem } from 'features/AdditionalProducts/types/OrderItem';
import { Button } from 'antd';
import { AdditionalProductAlloy } from 'app/types/adminAdditional';
import { removeWires, setWires, updateWires } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { OrderForm } from '../../OrderForm';
import { ValidProps } from 'components/Fields/Valid';
import { useTranslation } from 'react-i18next';

interface CartContentProps {
  sortedByAlloy: ProductTab[];
  formRowProps: Omit<ValidProps, 'component' | 'name'>;
}

export const CartContent = ({ sortedByAlloy, formRowProps }: CartContentProps) => {
  const { t } = useTranslation(['additional']);
  const { wire } = useSelector((state: RootState) => state.additionalProductsOrder);
  const [alloysList, setAlloysList] = useState<AdditionalProductAlloy[]>([]);
  const [activeAlloy, setActiveAlloy] = useState<number>(0);

  useEffect(() => {
    const newList: AdditionalProductAlloy[] = [];

    sortedByAlloy.forEach(({ alloy }) => {
      const alloyItems = wire[alloy.id] ? Object.values(wire[alloy.id]) : [];

      const tabsCount = alloyItems.reduce((diameterAcc, diameterGroup) => {
        const diameterCount = diameterGroup.filter(item => item.weight > 0).length;
        return diameterAcc + diameterCount;
      }, 0);

      if (tabsCount > 0) {
        newList.push(alloy);
      }
    });

    setAlloysList(newList);
  }, [sortedByAlloy]);

  const dispatch = useDispatch();

  const handleChangeItem = (
    alloyId: number,
    diameterKey: string,
    item: WireItem,
    newValues: {
      diameter: number;
      weight: number;
    },
  ) => {
    const index = wire[alloyId][diameterKey].indexOf(item);
    if (item.diameter !== newValues.diameter) {
      dispatch(
        updateWires({
          alloyId,
          diameterKey,
          index,
          item: { ...item, weight: 0 },
        }),
      );
      dispatch(
        setWires({
          alloyId,
          diameterKey,
          item: { ...item, ...newValues },
        }),
      );
    } else {
      dispatch(
        updateWires({
          alloyId,
          diameterKey,
          index,
          item: { ...item, ...newValues },
        }),
      );
    }
  };

  const handleDeleteClick = (alloyId: number, diameterKey: string, item: WireItem) => {
    const index = wire[alloyId][diameterKey].indexOf(item);
    dispatch(
      removeWires({
        alloyId,
        diameterKey,
        index,
      }),
    );
  };

  return (
    <Wrapper>
      <OrderContent>
        <Block>
          <Title>{t('totalCount')}</Title>
          <AlloysList>
            <Button
              shape="round"
              size="small"
              htmlType="button"
              style={{ opacity: activeAlloy === 0 ? 1 : 0.5 }}
              onClick={() => setActiveAlloy(0)}
            >
              {t('all')}
            </Button>
            {alloysList.map(alloy => (
              <Button
                shape="round"
                size="small"
                htmlType="button"
                style={{ opacity: activeAlloy === alloy.id ? 1 : 0.5 }}
                onClick={() => setActiveAlloy(alloy.id)}
              >
                {alloy.name}
              </Button>
            ))}
          </AlloysList>
        </Block>

        {sortedByAlloy.map(({ alloy }) => {
          const alloyItems = wire[alloy.id] ? Object.values(wire[alloy.id]) : [];

          const tabsCount = alloyItems.reduce((diameterAcc, diameterGroup) => {
            const diameterCount = diameterGroup.filter(item => item.weight > 0).length;
            return diameterAcc + diameterCount;
          }, 0);

          const diameterKeys = Object.keys(wire[alloy.id]);

          return (
            tabsCount > 0 && (
              <Block visible={activeAlloy === 0 || activeAlloy === alloy.id}>
                <FormItemTitle>
                  <FormItemImg src={alloy.imagePath ?? emptyPreview} />
                  {alloy.name}
                </FormItemTitle>
                <OrderRow>
                  <OrderCell style={{ width: '25%' }}>{t('table.diameter')}</OrderCell>
                  <OrderCell style={{ width: '25%' }}>{t('weight')}</OrderCell>
                  <OrderCell style={{ width: '50%' }} />
                </OrderRow>

                {diameterKeys.map((diameter: string) => {
                  const items = Object.values(wire[alloy.id][diameter]);
                  return items.map(
                    (item, index) =>
                      item.weight > 0 && (
                        <CartItemRow
                          key={`${diameter}-${index}-${item.weight}`}
                          diameterKey={diameter}
                          diameter={item.diameter}
                          weight={item.weight}
                          onSave={newValues => handleChangeItem(alloy.id, diameter, item, newValues)}
                          onDelete={() => handleDeleteClick(alloy.id, diameter, item)}
                        />
                      ),
                  );
                })}
              </Block>
            )
          );
        })}
      </OrderContent>
      <FormWrapper>
        <Block decorated>
          <Title>{t('formTitle')}</Title>
          <OrderForm formRowProps={formRowProps} style={{ padding: '0 32px 32px' }} />
        </Block>
      </FormWrapper>
    </Wrapper>
  );
};
