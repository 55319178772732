import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { InputNumber, Select } from 'antd';
import { RootState } from 'app/store/reducer';
import { Button } from 'components/KrasUIKit';
import { OrderRow, OrderCell } from 'features/AdditionalProducts/styled/Cart.styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface CartItemRowProps {
  id: number;
  thickness: number;
  width: number;
  weight: number;
  onSave: ({ thickness, width, weight }: { thickness: number; width: number; weight: number }) => void;
  onDelete: () => void;
}

export const CartItemRow = ({ id, thickness, width, weight, onSave, onDelete }: CartItemRowProps) => {
  const { t } = useTranslation(['additional']);
  const { products } = useSelector((state: RootState) => state.additionalProducts);
  const [product] = products.filter(item => item.id === id);
  const [widths, minThickness, maxThickness, stepThickness] = product.features;

  const widthList = widths.value.map((width: number) => ({ value: width }));

  const [editing, setEditing] = useState<boolean>(false);
  const [itemState, setItemState] = useState({
    width,
    thickness,
    weight,
  });

  const handleCancelClick = () => {
    setEditing(false);
    setItemState({ thickness, width, weight });
  };

  const handleSave = () => {
    onSave(itemState);
    setEditing(false);
  };

  return (
    <OrderRow>
      <OrderCell style={{ width: '20%' }}>
        {editing ? (
          <Select
            defaultValue={width}
            options={widthList}
            style={{ width: '80%' }}
            onChange={value => {
              setItemState({
                ...itemState,
                width: value,
              });
            }}
          />
        ) : (
          width
        )}
      </OrderCell>
      <OrderCell style={{ width: '20%' }}>
        {' '}
        {editing ? (
          <InputNumber
            style={{ width: '80%' }}
            value={itemState.thickness}
            min={Number(minThickness.value)}
            max={Number(maxThickness.value)}
            step={Number(stepThickness.value)}
            keyboard={false}
            onStep={value => {
              setItemState({
                ...itemState,
                thickness: value,
              });
            }}
          />
        ) : (
          thickness
        )}
      </OrderCell>
      <OrderCell style={{ width: '20%' }}>
        {' '}
        {editing ? (
          <InputNumber
            value={itemState.weight}
            onChange={value => {
              setItemState({
                ...itemState,
                weight: value || 0,
              });
            }}
          />
        ) : (
          weight
        )}
      </OrderCell>
      {editing ? (
        <OrderCell style={{ width: '40%' }}>
          <Button onClick={handleSave} type="primary" style={{ marginRight: '12px' }}>
            {t('save')}
          </Button>
          <Button onClick={handleCancelClick}> {t('cancel')} </Button>
        </OrderCell>
      ) : (
        <OrderCell style={{ width: '40%' }}>
          <span onClick={() => setEditing(true)}>
            <EditOutlined />
          </span>
          <span style={{ marginLeft: '35px' }} onClick={onDelete}>
            <DeleteOutlined />
          </span>
        </OrderCell>
      )}
    </OrderRow>
  );
};
