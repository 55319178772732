import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { date } from 'helpers/format';
import { BonusTracker } from '../../BonusTracker';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import * as S from '../../styled/Stage.styled';

export type WithdrawalProps = {
  url: string;
  balance: number;
  currentWeight: number;
  neededWeight: number;
  updateDate: string;
  orderExpirationDate: string | null;
  bonusExpirationDate: string;
  handleRulesClick: () => void;
  openRulesInNewTab: boolean;
  isHolding: boolean;
};

export const Withdrawal = ({
  url,
  balance,
  currentWeight,
  neededWeight,
  updateDate,
  orderExpirationDate,
  bonusExpirationDate,
  handleRulesClick,
  openRulesInNewTab,
  isHolding,
}: WithdrawalProps) => {
  const { toRubles, toGrams } = useUnitsConverter();
  const showNotice = neededWeight - currentWeight > 0;
  return (
    <Fragment>
      <S.Head>
        <S.Title>Баланс бонусов: {toRubles(balance)}</S.Title>
        <S.SecondaryText>Обновлено {date(updateDate, 'DD.MM.YYYY')} (МСК)</S.SecondaryText>
      </S.Head>
      <S.Divider />
      {isHolding && (
        <Fragment>
          <S.Text>Начисление бонуса происходит в рамках холдинга</S.Text>
          <S.Divider />
        </Fragment>
      )}
      <S.Body>
        {showNotice && orderExpirationDate && (
          <S.Text fontSize="16px" lineHeight="20px" color="#212529">
            Для использования бонусов оформите заказ на <b>{toGrams(neededWeight - currentWeight)}</b> в период до{' '}
            {date(orderExpirationDate, 'DD.MM.YYYY')}
          </S.Text>
        )}
        <BonusTracker currentWeight={currentWeight} neededWeight={neededWeight} />
        <S.Text fontSize="16px" lineHeight="20px" color="#212529">
          Неиспользованные бонусы сгорают автоматически {date(bonusExpirationDate, 'DD.MM.YYYY')}
        </S.Text>
      </S.Body>
      <S.Divider />
      <S.Text fontSize="14px" lineHeight="16px" color="#4f4f4f">
        Подробнее о начислениях и списаниях можете ознакомиться{' '}
        <Link to={url} onClick={handleRulesClick} target={openRulesInNewTab ? '_blank' : '_self'}>
          в правилах акции
        </Link>
      </S.Text>
    </Fragment>
  );
};
