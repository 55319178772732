import styled from 'styled-components';
import { phone } from '../../styles/media';

const getStylesByType = (type: string) => {
  const defaultStyles = `
  #4087c9
  `;

  switch (type) {
    case 'info': {
      return defaultStyles;
    }
    case 'warning': {
      return `  
      #E4B714
      `;
    }
    case 'error': {
      return `   
      #DF4545
      `;
    }
    case 'notAvailable': {
      return `
      #4F4F4F
      `;
    }

    case 'success': {
      return `
      #4dbf7b
      `;
    }

    default: {
      return defaultStyles;
    }
  }
};

export const Alert = styled.div<{
  withActions?: boolean;
  alertType: 'success' | 'info' | 'warning' | 'error' | 'notAvailable';
}>`
  border: 1px solid ${props => getStylesByType(props.alertType)};
  font-size: 14px;
  color: #4f4f4f;
  border-radius: 0.214em;
  display: flex;
  justify-content: space-between;
  b {
    font-weight: bold;
  }
  @media all and (${phone}) {
    flex-direction: column;
  }
`;

export const Title = styled.div<{
  isSingleLine?: boolean;
}>`
  ${props => !props.isSingleLine && 'font-weight: bold;'}
`;

export const Description = styled.div`
  color: #767676;
  font-size: 0.857em;
`;

export const Icon = styled.div<{
  isSingleLine?: boolean;
  alertType: 'success' | 'info' | 'warning' | 'error' | 'notAvailable';
}>`
  flex: none;
  margin-right: 0.571em;
  width: ${props => (props.isSingleLine ? '1.714em' : '2.286em')};
  height: ${props => (props.isSingleLine ? '1.714em' : '2.286em')};
  color: ${props => getStylesByType(props.alertType)};
  @media all and (${phone}) {
    width: 1.714em;
    align-self: baseline;
  }
`;

export const Content = styled.div`
  margin-right: 2.857em;
`;

export const Body = styled.div`
  display: flex;
  align-items: start;
  padding: 1.143em 1.714em;
  @media all and (${phone}) {
    padding: 0.857em;
  }
`;

export const Notice = styled.div<{
  isSingleLine?: boolean;
  alertType: 'success' | 'info' | 'warning' | 'error' | 'notAvailable';
}>`
  padding: 0 1.714em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
  color: #fff;
  background-color: ${props => getStylesByType(props.alertType)};
  @media all and (${phone}) {
    padding: 0.857em;
    width: -webkit-fill-available;
  }
`;

export const Affix = styled.div`
  position: relative;
  vertical-align: top;
  align-items: center;
  align-self: center;
  flex: none;
  padding: 0 1.714em;
  @media all and (${phone}) {
    align-self: unset;
    padding-left: 2.286em;
    margin: -0.25em 0.857em 0.857em 0.857em;
  }
`;
