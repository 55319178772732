import styled from 'styled-components';

export const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px;
  background-color: var(--gray);
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const SecondaryText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #767676;
`;
