import React from 'react';
import { StyledTitle } from './styled';
import { BlockProps } from 'antd/lib/typography/Base';

declare const TITLE_ELE_LIST: [1, 2, 3, 4];

export interface Props
  extends Omit<
    BlockProps & {
      level?: typeof TITLE_ELE_LIST[number];
    },
    'strong'
  > {
  upper?: boolean;
}

export const Title: React.FC<Props> = props => {
  return <StyledTitle {...props} />;
};
