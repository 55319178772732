import { Result } from 'app/types/api';
import { apiGet } from './connect';
import { CatalogLevel } from 'app/types/RENAME__catalog';

export const getCategoriesByParentCode = async (parentCode: string) => {
  const { data } = await apiGet<Result<CatalogLevel>>(
    `/catalog/elements/${parentCode}`,
  );

  return data;
};
