import { createSlice, AnyAction } from '@reduxjs/toolkit';
import * as T from 'app/types/demoUsers';
import { PaginationNew } from 'app/types/pagination';
import * as actions from './actions';

interface InitialStateProps {
  loading: boolean;
  list: T.UserFullInfoEntity[];
  pagination?: PaginationNew;
}

export const demoUsersInitialState: InitialStateProps = {
  loading: false,
  list: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('demoUsers/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('demoUsers/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('demoUsers/') && action.type.endsWith('/fulfilled');

const demoUsersSlice = createSlice({
  name: 'demoUsers',
  initialState: demoUsersInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.getUsers.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.activateUser.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(({ user }) => user.id === payload.user.id);
      if (index > -1) state.list.splice(index, 1);
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default demoUsersSlice.reducer;
