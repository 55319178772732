import styled from 'styled-components';
import { phone } from '../../styles/media';

const getStylesByType = (type: boolean) => {
  const defaultStyles = `
  :hover {
    border: 1px solid currentColor;
  }
  :focus {
    box-shadow: 0px 0px 4px #bbbbbb;
  }
  `;
  if (type) {
    return defaultStyles;
  } else {
    return `
        color: #BBBBBB;
        cursor: not-allowed;
      `;
  }
};

export const File = styled.div<{
  disabled: true | false;
}>`
  overflow: auto;
  display: flex;
  padding: 1em 1.429em;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.214em;
  font-size: 14px;
  line-height: 1.286em;
  border: 1px solid #e0e0e0;
  ${props => getStylesByType(props.disabled)}
  @media all and (${phone}) {
    flex-direction: column;
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.span`
  flex: none;
  width: 1.571em;
  height: 1.571em;
  align-self: baseline;
`;

export const Content = styled.div<{
  disabled: true | false;
}>`
  display: flex;
  margin: 0 0.714em;
  flex-direction: column;
  color: ${({ disabled }) => (disabled ? '#4f4f4f' : '#BBBBBB')};
  @media all and (${phone}) {
    padding-bottom: 0.571em;
  }
`;

export const Name = styled.span`
  font-weight: normal;
`;

export const Description = styled.span<{
  disabled: true | false;
}>`
  font-size: 0.857em;
  line-height: 1em;
  color: ${({ disabled }) => (disabled ? '#767676' : '#BBBBBB')};
`;

export const Size = styled.span<{
  disabled: true | false;
}>`
  margin-right: 2.857em;
  margin-left: auto;
  color: ${({ disabled }) => (disabled ? '#767676' : '#BBBBBB')};
  @media all and (${phone}) {
    padding-top: 0.571em;
    margin: 0;
    order: 1;
  }
`;
