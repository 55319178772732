import React from 'react';
import { User as UserType } from '../../../app/types/user';
import { CompanyResponse as CompanyType } from '../../../app/types/company';
import { useTranslation } from 'react-i18next';
import * as S from '../styled';

interface Props {
  user: UserType | null;
  company: CompanyType | null;
}

export const CompanyDetails: React.FC<Props> = ({ user, company }) => {
  const { t } = useTranslation('personal');
  return (
    <>
      <S.Text fontSize="16px" strong>
        {t('companyDetailsTitle')}:
      </S.Text>
      <br />
      <S.Text fontSize="16px">{company?.fullName}</S.Text>
      <br />
      <S.Text fontSize="16px">{user?.name}</S.Text>
      <br />
      <S.Text fontSize="16px">
        {t('companyDetailsEmail')}: {user?.email}
      </S.Text>
    </>
  );
};
