import { useCallback, useState } from 'react';

const PRIVACY_POLICY_KEY = 'is-privacy-policy-accepted';

const getIsPrivacyPolicyAccepted = () => localStorage.getItem(PRIVACY_POLICY_KEY) === 'true';
const setIsPRivacyPolicyAccepted = (value: boolean) => localStorage.setItem(PRIVACY_POLICY_KEY, String(value));

export const useCookieBar = () => {
  const [isOpened, setIsOpened] = useState(!getIsPrivacyPolicyAccepted());

  const handleAcceptPrivacyPolicy = useCallback(() => {
    setIsPRivacyPolicyAccepted(true);
    setIsOpened(false);
  }, []);

  return {
    isOpened,
    handleAcceptPrivacyPolicy,
  };
};
