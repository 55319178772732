import React, { Fragment } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { SecondaryText, Divider } from './styled/Stage.styled';
import * as S from './styled/Overlay.styled';

type OverlayProps = {
  children: JSX.Element;
  title: string;
  onClose: () => void;
};

export const Overlay = ({ children, title, onClose }: OverlayProps) => {
  return (
    <S.Overlay>
      <Fragment>
        <SecondaryText>{title}</SecondaryText>
        <Divider />
      </Fragment>
      <S.CloseButton onClick={onClose}>
        <CloseOutlined />
      </S.CloseButton>
      {children}
    </S.Overlay>
  );
};
