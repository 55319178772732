import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderTypeHook } from '../types';
import { useResidues } from './useResidues';
import { CoilProductionPowerMode } from 'app/types/products';

export const useInCoils: OrderTypeHook = product => {
  const [count, setCount] = React.useState<number>(0);
  const { t } = useTranslation('productDetail');
  const orderType = 'products_coil';

  const getCoilProductionOffers = () => product?.offers.map(offer => ({ ...offer, max: null }));

  const isVisibleProductsCoil =
    product.coilProductionPower.mode !== CoilProductionPowerMode.EMPTY &&
    product.coilProductionPower.values.some(item => item.value > 0) &&
    getCoilProductionOffers().length !== 0 &&
    product?.login;

  const { residues } = useResidues(product, orderType);

  const productionPower = product.productionPower === null ? Infinity : product.productionPower;

  return {
    tabProps: { title: t('orderTypes:inCoils'), count },
    isVisible: isVisibleProductsCoil,
    content: {
      orderType,
      offers: getCoilProductionOffers(),
      requiredSetCovering: true,
      weightInBasket: product.weightInCoilInCart.total,
      weightInCoilInCart: product.weightInCoilInCart,
      balanceUnit: t('units:g'),
      setParentCounter: setCount,
      availableAddToCart: !product.notAvilableByContract,
      disabledPlatings: product.disabledPlatings,
      productionBalance: product.login
        ? productionPower / product.productionFactor - product.powerInProductionInBacket
        : undefined,
      inCoilsBalance: residues,
      productionPowerMode: product.coilProductionPower.mode,
    },
  };
};
