/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as S from './styled';
import { OrderRenders } from 'app/types/renders';
import { useTranslation, Trans } from 'react-i18next';
export const MODAL_NAME = 'RenderRequest';

export interface Props {
  visible?: boolean;
  loading?: boolean;
  onSubmit: (comment: OrderRenders) => void;
  onCancel: () => void;
}

export const RenderRequest: React.FC<Props> = ({ visible, onCancel, onSubmit, loading }) => {
  const [form] = Form.useForm();
  const onFinish = () => onSubmit(form.getFieldValue('comment'));

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);
  const { t } = useTranslation('downloadImages');

  return (
    <S.Modal open={visible} onCancel={onCancel} footer={null}>
      <S.Title level={3} upper>
        {t('title')}
      </S.Title>
      <S.Description>{t('description')}</S.Description>
      <S.Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              min: 5,
              message: `${t('form:requiredAndContains')} 5 ${t('form:orMoreSymbols')}`,
            },
          ]}
        >
          <S.TextArea
            placeholder={t('exampleTitle') + 'https://www.site.com/, https://vk.com/group, https://instagram.com/group'}
          ></S.TextArea>
        </Form.Item>
        <S.LinkCaption>
          <Trans
            t={t}
            i18nKey="downloadImages:instructionLink"
            components={[<S.Link href="/render-instruction.pdf" key="link" target="_blank"></S.Link>]}
          />
        </S.LinkCaption>
        <Button
          htmlType="submit"
          icon={<DownloadOutlined />}
          shape="round"
          loading={loading}
          type="primary"
          size="large"
        >
          {t('buttonName')}
        </Button>
      </S.Form>
    </S.Modal>
  );
};
