import React from 'react';
import { Row, Col, Menu } from 'antd';
import { Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { TessaDocument as TessaDocumentType } from 'app/types/documents';
import { DocumentSectionEntity } from 'app/types/documentsSection';
import { TessaDocuments } from './TessaDocuments';
import { Section } from '../../Documents/Section';
import { useTranslation } from 'react-i18next';
import { ReturnConditions } from 'components/ReturnConditions';
import { Page } from 'components/Page';
import * as S from './Return.styled';

export interface Props {
  sections: DocumentSectionEntity[];
  sectionsLoading?: boolean;
  tessaDocuments: TessaDocumentType[];
  loading?: boolean;
  getDocuments: () => void;
  onDownload: (guuid: string) => void;
}
export const Return: React.FC<Props> = ({ sections, sectionsLoading, getDocuments, tessaDocuments, onDownload }) => {
  const { t, i18n } = useTranslation(['personal']);
  const location = useLocation();
  const { language } = i18n;
  const isRu = language === 'ru';
  const sectionsInMenu = isRu
    ? sections.filter(({ parentId }) => parentId === null).sort((a, b) => a.sort - b.sort)
    : [];

  const menu = [
    ...sectionsInMenu.map(({ name, id }) => ({
      name,
      to: `/personal/return/${id}`,
      visible: isRu,
    })),
    {
      to: '/personal/return/invoices',
      name: t('correctionInvoices'),
      visible: isRu,
    },
    {
      to: '/personal/return/universal',
      name: t('universalCorrectionDocuments'),
      visible: isRu,
    },
    {
      to: '/personal/return/ar2',
      name: t('correctionAr2'),
      visible: isRu,
    },
  ];
  return (
    <Row gutter={20}>
      <Col span={5}>
        <Menu mode="inline" theme="light" selectedKeys={[location.pathname]} style={{ position: 'sticky', top: 0 }}>
          {menu
            .filter(({ visible }) => visible === undefined || visible === true)
            .map(({ name, to }) => (
              <S.MenuItem key={to}>
                <Link
                  style={{
                    padding: '10px 0',
                    whiteSpace: 'pre-wrap',
                    display: 'block',
                  }}
                  to={to}
                >
                  {name}
                </Link>
              </S.MenuItem>
            ))}
        </Menu>
      </Col>
      <Col span={19}>
        <Switch>
          <Route exact path={['/personal/return/invoices', '/personal/return/universal', '/personal/return/ar2']}>
            <TessaDocuments getDocuments={getDocuments} documents={tessaDocuments} onDownload={onDownload} />
          </Route>
          {isRu && (
            <Route path="/personal/return/17">
              <Page title="Как вернуть ювелирные изделия с производственным браком?">
                <ReturnConditions />
              </Page>
            </Route>
          )}
          {isRu && (
            <Route path="/personal/return/:id">
              <Section loading={sectionsLoading} data={sections} />
            </Route>
          )}
          <Route path="*">
            <Redirect to="/personal/return/17" />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};
