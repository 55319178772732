import { useSelector, useDispatch } from 'react-redux';
import { modalsSelector } from 'selectors/modal.selector';
import { hide, show, destroy } from 'app/ducks/modals/slice';

export const useModal = (name: string) => {
  const state = useSelector(modalsSelector);
  const dispatch = useDispatch();

  const visible = state[name];

  const hideModal = () => dispatch(hide(name));
  const showModal = () => dispatch(show(name));
  const destroyModal = () => dispatch(destroy(name));

  return { visible, showModal, hideModal, destroyModal };
};
