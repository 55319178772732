import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import { usePersonalOrders } from 'app/ducks/personalOrders';
import { RootState } from 'app/store/reducer';
import { Table } from './Table';
import { Aside } from './Aside';
import { Top } from './Top';

export const Order = () => {
  const { id } = useParams<{ id: string }>();
  const { state, fetchOrder, resetOrdersDetail } = usePersonalOrders();
  const { company } = useSelector((state: RootState) => ({
    company: state.auth.company,
  }));

  useEffect(() => {
    if (id) {
      fetchOrder(parseInt(id));
    }
    return () => {
      resetOrdersDetail();
    };
  }, []);

  const { detail, loading } = state;

  const spinStyle = {
    width: '100%',
    height: '20vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  if (detail) {
    const {
      comment,
      numberOrder,
      positions,
      dateCreate,
      dateEnd,
      dateRelease,
      order_type: orderType,
      product_type: productType,
      userName,
      specifications,
    } = detail;

    const showReleaseDate = dateRelease && company && company.settings.order_date_release;

    return (
      <Spin spinning={loading} style={spinStyle}>
        <Top numberOrder={numberOrder} />
        <Row gutter={30}>
          <Col span={18}>
            <Table positions={positions} />
          </Col>
          <Col span={6}>
            <Aside
              orderId={Number(id)}
              comment={comment}
              dateCreate={dateCreate}
              dateEnd={dateEnd}
              dateRelease={dateRelease}
              showReleaseDate={showReleaseDate}
              orderType={orderType}
              productType={productType}
              userName={userName}
              specifications={specifications}
            />
          </Col>
        </Row>
      </Spin>
    );
  }

  return <Spin spinning={loading} style={spinStyle} />;
};
