import { useEffect, useState, SetStateAction, useRef } from 'react';
import * as searchApi from 'app/services/search';
import { Suggestions } from '../app/types/search';
import { actionTypes, sendEvent } from 'logger';

let timer: number | undefined;
export const useProductSuggestions = (): [
  Suggestions | null,
  boolean,
  string,
  React.Dispatch<SetStateAction<string>>,
] => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Suggestions | null>(null);
  const isMounted = useRef(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data, message, success } = await searchApi.getProductSuggestions(
        search,
      );

      if (success) {
        setData(data);
        sendEvent({
          ActionType: actionTypes.INPUT_SEARCH_SHOW_HINT,
          ContextKey: search,
          SearchFind: data.total,
        });
      } else {
        throw new Error(message);
      }
      setLoading(false);
    } catch (error) {
      setData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      setSearch(search.replace(/-\-+/gi, '-'));
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (search !== '') {
          await sendEvent({
            ActionType: actionTypes.INPUT_SEARCH_STOPED_TYPING,
            ContextKey: search,
          });
        }
        fetchData();
      }, 400);
    } else isMounted.current = true;
  }, [search]);

  return [data, loading, search, setSearch];
};
