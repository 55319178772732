import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { question } from 'components/KrasUIKit/icons';
import * as S from '../styled';
import { CustomOffer } from '../CustomOffer';
import { SectionTitle } from '../SectionTitle';
import { number } from '../../../helpers/format';
import { useTranslation } from 'react-i18next';
import { DetailOrderType } from '..';
import { MassData } from 'app/types/products';
import round from 'lodash.round';
import { useFormatSize } from 'hooks/useFormatSize';

export interface Props {
  massData: MassData;
  onChange: (weight: number) => void;
  activeCovering: number | null;
  balance: number;
  orderType: DetailOrderType;
  current: number;
  isDimensioned: boolean;
}

export const Coil: React.FC<Props> = ({
  massData,
  onChange,
  activeCovering,
  orderType,
  balance,
  current,
  isDimensioned,
}) => {
  const { t } = useTranslation('productDetail');
  const [weight, setWeight] = useState<number>(current);
  const slope = massData.slope || 1;
  const intercept = massData.intercept || 0;

  useEffect(() => {
    onChange(weight);
  }, [weight]);

  useEffect(() => {
    setWeight(current);
  }, [current]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formatValue = Math.abs(parseFloat(value.replace(/\D/g, '')));

    setWeight(!Number.isNaN(formatValue) ? formatValue : 0);
  };

  const getSize = () => (weight !== 0 ? round((weight - intercept) / slope) : 0);

  const { formatSize } = useFormatSize();

  const data = [
    {
      key: '1',
      size: formatSize(getSize(), { isDimensioned }),
      weight,
      count: 10,
    },
  ];

  const columns: Record<string, unknown>[] = [
    {
      dataIndex: 'size',
      key: 'size',
      title: () => (
        <S.CellWidthIcon>
          {t('orderParameters:size')}
          <Tooltip title={t('lengthWarning')} placement="bottom">
            <S.QuestionIconWrap>{question}</S.QuestionIconWrap>
          </Tooltip>
        </S.CellWidthIcon>
      ),
    },
    {
      title: () => (
        <S.CellWidthIcon>
          {t('orderParameters:weightOnly')}
          <Tooltip title={t('coilWeightWarning')} placement="bottom">
            <S.QuestionIconWrap>{question}</S.QuestionIconWrap>
          </Tooltip>
        </S.CellWidthIcon>
      ),
      dataIndex: 'weight',
      key: 'weight',
      render: (value: number) => {
        return (
          <S.CountInput value={value} style={{ minWidth: '70px' }} suffix={t('units:g')} onChange={changeHandler} />
        );
      },
    },
  ];

  return (
    <>
      {activeCovering === 1 ? (
        <CustomOffer dataSource={data} columns={columns} title={t('coil')} message={t('abilityIndicateWeight')} />
      ) : (
        <>
          <SectionTitle style={{ marginTop: 16 }}>{t('coil')}</SectionTitle>
          <S.CustomProductWrapper style={{ minHeight: 98 }}>
            <S.CellWidthIcon style={{ padding: 5 }}>
              <S.QuestionIconWrap>{question}</S.QuestionIconWrap>
              {t('coilСoatingWarning')}
            </S.CellWidthIcon>
          </S.CustomProductWrapper>
        </>
      )}
    </>
  );
};
