import React from 'react';
import { Spin } from 'antd';
import * as S from './styled';
import { BannerEntity } from 'app/types/banners';
import { BannersCarousel } from './BannersCarousel';

export interface Props {
  visible?: boolean;
  loading?: boolean;
  onCancel: () => void;
  list: BannerEntity[];
}

export const Banners: React.FC<Props> = ({ visible, onCancel, list, loading }) => {
  return (
    <S.StyledModal
      title={<S.StyledTitle level={3}>Новости</S.StyledTitle>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width="100%"
    >
      <Spin spinning={loading}>
        <BannersCarousel list={list} />
      </Spin>
    </S.StyledModal>
  );
};
