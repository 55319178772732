import styled from 'styled-components';
import { Dropdown as AntdDropdown } from 'antd';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
`;

export const Dropdown = styled(AntdDropdown)`
  z-index: 950 !important;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #4f4f4f;
  border: 1px solid #4f4f4f;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;

  @media screen and (max-width: 1250px) {
    padding: 8px;
    grid-gap: 5px;
  }

  @media screen and (max-width: 1200px) {
    padding: 6px 8px;
    font-size: 0.875em;
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 24px;

  @media screen and (max-width: 1250px) {
    width: 24px;
  }
`;

export const AngleBottomIcon = styled.span`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
  }
`;
