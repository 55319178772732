import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { Button } from 'components/KrasUIKit';
import { OrderRow, OrderCell } from 'features/AdditionalProducts/styled/Cart.styled';
import { BarsItem } from 'features/AdditionalProducts/types/OrderItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CartItemRowProps {
  item: BarsItem;
  onSave?: (item: BarsItem) => void;
  onDelete?: () => void;
}

export const CartItemRow = ({ item, onSave, onDelete }: CartItemRowProps) => {
  const { t } = useTranslation(['additional']);
  const [editing, setEditing] = useState<boolean>(false);
  const [itemState, setItemState] = useState({ weight: item.weight });

  const handleCancelClick = () => {
    setEditing(false);
    setItemState({ weight: item.weight });
  };

  const handleSave = () => {
    onSave && onSave({ ...item, weight: itemState.weight });
    setEditing(false);
  };

  return (
    <OrderRow>
      <OrderCell style={{ width: '20%' }}>{item.diameter}</OrderCell>
      <OrderCell style={{ width: '20%' }}>
        {' '}
        {editing ? (
          <InputNumber
            value={itemState.weight}
            onChange={value => {
              setItemState({
                ...itemState,
                weight: value || 0,
              });
            }}
          />
        ) : (
          item.weight
        )}
      </OrderCell>
      {editing ? (
        <OrderCell style={{ width: '60%' }}>
          <Button onClick={handleSave} type="primary" style={{ marginRight: '12px' }}>
            {t('save')}
          </Button>
          <Button onClick={handleCancelClick}> {t('cancel')} </Button>
        </OrderCell>
      ) : (
        <OrderCell style={{ width: '60%' }}>
          <span onClick={() => setEditing(true)}>
            <EditOutlined />
          </span>
          <span style={{ marginLeft: '35px' }} onClick={() => onDelete && onDelete()}>
            <DeleteOutlined />
          </span>
        </OrderCell>
      )}
    </OrderRow>
  );
};
