import React from 'react';
import { Col } from 'antd';
import { actionTypes, sendEvent } from 'logger';
import { useTranslation } from 'react-i18next';
import Coins from 'images/png/coins.png';
import { CaretLeft } from 'components/Icon';
import * as S from '../styled/WelcomeBonus.styled';
import { SlideProps } from '../types/Slide';
import { usePromotions } from 'app/ducks/companies';

const WELCOME_BONUS_RULES_URL = '/welcome_bonus';

export const WelcomeBonus = ({ banner }: SlideProps) => {
  const { t } = useTranslation(['promotions']);
  const { promotions } = usePromotions();

  if (!promotions?.welcomeBonus || !banner) {
    return null;
  }

  const handleClick = (isNewCompany: boolean) => {
    sendEvent(isNewCompany ? actionTypes.WELCOME_BONUS_CLICK_BANNER_NEW : actionTypes.WELCOME_BONUS_CLICK_BANNER_OLD);
    banner.hide();
  };
  return (
    <div>
      <S.Banner wrap={false} justify="start">
        <Col span={8}>
          <img src={Coins}></img>
        </Col>
        <S.Content span={16} isNew={banner.isNew}>
          <S.Heading>
            {banner.isNew ? t('welcomeBonus.new.title', { sum: '10 000' }) : t('welcomeBonus.old.title')}
          </S.Heading>
          <S.Subheading level={4}>
            {banner.isNew
              ? t('welcomeBonus.new.subtitle', {
                  date: '15.11.2022',
                })
              : t('welcomeBonus.old.subtitle', {
                  date: '15.11.2022',
                  sum: '10 000',
                })}
          </S.Subheading>
          <S.Button
            href={banner.isNew ? `${WELCOME_BONUS_RULES_URL}/new` : `${WELCOME_BONUS_RULES_URL}/current`}
            type="primary"
            iconDirection="right"
            onClick={() => handleClick(banner.isNew)}
          >
            {t('welcomeBonus.linkText')} <CaretLeft />
          </S.Button>
        </S.Content>
      </S.Banner>
    </div>
  );
};
