import { YMetrica } from '../types';
import { isYMetricaDebug } from './isYMetricaDebug';

declare const window: Window & {
  readonly ym: YMetrica;
};

export const getYMetrica = (): YMetrica | undefined => {
  if (typeof window === 'undefined' || !window.ym) {
    if (isYMetricaDebug()) {
      console.log('sendReachGoal: ym is not defined in window.');
    }

    return;
  }

  return window.ym;
};
