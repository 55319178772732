import React from 'react';
import { Title } from '../Title';
import { BlockProps } from 'antd/lib/typography/Base';

export const SectionTitle: React.FC<BlockProps> = ({ children, ...props }) => {
  return (
    <Title level={4} upper {...props}>
      {children}
    </Title>
  );
};
