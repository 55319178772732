import React from 'react';
import * as S from '../styled';
import { Table, TableProps } from 'antd';
import { OrderNumber } from 'features/AutoOrders/components/OrderNumber';
import { date, number } from 'helpers/format';

type TableOrderProps = TableProps;

export const TableOrder = ({ ...props }: TableOrderProps) => {
  return (
    <S.Wrapper>
      <S.Title>Оформлено в заказы</S.Title>
      <Table {...props} pagination={false}>
        <Table.Column title={<b>№ заказа</b>} dataIndex="id" render={id => <OrderNumber id={id} type="order" />} />
        <Table.Column title={<b>Дата заказа</b>} dataIndex="dateCreate" render={dateCreate => date(dateCreate)} />
        <Table.Column title={<b>Договор</b>} dataIndex="knfmpNumber" />
        <Table.Column title={<b>Кол-во, шт</b>} dataIndex="count" />
        <Table.Column title={<b>Вес, г</b>} dataIndex="weight" render={(weight: number) => number(weight)} />
      </Table>
    </S.Wrapper>
  );
};
