import styled from 'styled-components';
import { Button as ButtonUiKit } from 'components/KrasUIKit';

export const FormContainer = styled.div`
  max-width: 1441px;
  margin: 0 auto;
  padding: 24px var(--s8);
`;

export const Button = styled(ButtonUiKit)`
  margin-left: 1.5em;
`;

export const Note = styled.span`
  display: inline-block;
  margin-right: 1.1em;
`;
