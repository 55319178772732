import { createAsyncThunk } from '@reduxjs/toolkit';
import download from 'downloadjs';
import { message as antdMessage } from 'antd';
import * as DocumentsService from 'app/services/documents';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { Acts, ActRequest } from 'app/types/documents';
import { actionTypes, sendEvent } from 'logger';
import { hide } from 'app/ducks/modals/slice';

export const fetchDocumentSections = createAsyncThunk('documents/fetchDocumentSections', async () => {
  const { data, message, success } = await DocumentsService.getDocumentSections();
  if (success) return data;
  else throw new Error(message);
});

export const fetchSectionContent = createAsyncThunk('documents/fetchSectionContent', async (id: number) => {
  const { data, message, success } = await DocumentsService.getDocuments(id);
  if (success) return data;
  else throw new Error(message);
});

export const fetchDocuments = createAsyncThunk('documents/fetchDocuments', async () => {
  const { data, message, success } = await DocumentsService.getTessaDocuments();
  if (success) {
    return data;
  } else throw new Error(message);
});

export const downloadDocument = createAsyncThunk('documents/downloadDocument', async (uuid: string) => {
  const { data, message, success } = await DocumentsService.downloadTessaDocument(uuid);
  if (success) {
    download('data:application/pdf;base64,' + data.data, data.name, 'application/pdf');
  } else throw new Error(message);
});

export const fetchActs = createAsyncThunk('documents/fetchActs', async (_: void, { getState }) => {
  const { auth } = getState() as RootState;
  const companyId = auth.company?.id || 0;
  const { data, message, success } = await DocumentsService.getSapActs(companyId);
  if (success) {
    return data;
  } else throw new Error(message);
});

export const submitActRequest = createAsyncThunk(
  'documents/submitActRequest',
  async (data: ActRequest, { dispatch }) => {
    const { message, success } = await DocumentsService.sendActRequest(data);
    if (success) {
      antdMessage.success(message);
      dispatch(hide('act-request'));
      dispatch(fetchActs());
      sendEvent({
        ActionType: actionTypes.MODAL_ACT_SEND_SUCCESS,
        ContextKey: `${data.dateFrom} - ${data.dateTo}`,
        Contract: data.contract_sap_id.toString(),
      });
    } else {
      sendEvent(actionTypes.MODAL_ACT_SEND_FAILD);
      throw new Error(message);
    }
  },
);

export const downloadSpecification = createAsyncThunk('documents/downloadSpecification', async (id: number) => {
  const { data, message, success } = await DocumentsService.getSpecifications(id);
  if (success) {
    download(data.data, data.name);
  } else throw new Error(message);
});
