import React from 'react';
import { number } from '../../helpers/format';
import { Button } from '../KrasUIKit';
import { cart as cartIcon, production } from '../KrasUIKit/icons';
import { InfoCircleFilled } from '@ant-design/icons';
import * as S from './styled';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { actionTypes, createLoggerEvent } from 'logger';

export interface Props {
  mass?: number;
  count: number;
  addToCart: () => void;
  isWishlist?: boolean;
  addingToCart?: boolean;
  wasAdded: null | 'waiting' | 'cart';
  article?: string;
  activeCovering: number | null;
}

export const Footer: React.FC<Props> = ({
  mass = 0,
  count,
  addToCart,
  isWishlist,
  addingToCart,
  wasAdded,
  article,
  activeCovering,
}) => {
  const { t } = useTranslation('productDetail');
  const history = useHistory();

  let textButton = '';
  let link = '';

  if (wasAdded) {
    textButton = wasAdded === 'waiting' ? t('goToWishlist') : t('goToCart');
    link = wasAdded === 'waiting' ? '/cart/waiting_list' : '/cart';
  } else {
    textButton = isWishlist ? t('addToWshlist') : t('addOrder');
    link = isWishlist ? '/cart/waiting_list' : '/cart';
  }

  const getLoggerPayload = (action: string) => ({
    ActionType: action,
    ContextKey: article,
    Query: activeCovering?.toString(),
    DetailOfferWeight: mass,
    DetailOfferCount: count,
  });

  const onAddToCart = createLoggerEvent(
    () => addToCart(),
    getLoggerPayload(
      isWishlist
        ? actionTypes.DETAIL_ADD_TO_WAITING_LIST
        : actionTypes.DETAIL_ADD_TO_CART,
    ),
  );

  const onGoToPage = createLoggerEvent(() => {
    history.push(link);
  }, getLoggerPayload(isWishlist ? actionTypes.DETAIL_GO_TO_WAITING_LIST : actionTypes.DETAIL_GO_TO_CART));

  return (
    <S.Footer>
      {mass === 0 ? (
        <S.FooterShape>
          <InfoCircleFilled /> {t('needSelectQuantity')}
        </S.FooterShape>
      ) : (
        <>
          <S.FooterShape>
            <Trans
              t={t}
              i18nKey="ligatureWeight"
              values={{
                count: number(mass, 2),
              }}
              components={{ b: <b /> }}
            />
          </S.FooterShape>
          <S.FooterShape>
            <Trans
              t={t}
              i18nKey="quantity"
              values={{
                count: count,
              }}
              components={{ b: <b /> }}
            />
          </S.FooterShape>
        </>
      )}

      <Button
        type="primary"
        style={{ fontSize: 17 }}
        disabled={mass === 0 && !wasAdded}
        onClick={wasAdded ? onGoToPage : onAddToCart}
        loading={addingToCart}
        icon={!isWishlist ? cartIcon : production}
      >
        {textButton}
      </Button>
    </S.Footer>
  );
};
