import React from 'react';
import * as S from 'components/KrasUIKit/components/Card/styled';
import { Link } from 'react-router-dom';

interface CardProps {
  link: string;
  title: string;
  image: string;
}

export const Card = ({ link, title, image }: CardProps) => {
  return (
    <Link to={link}>
      <S.Card horizontal={false}>
        <S.Image horizontal={false}>
          <img src={image} />
        </S.Image>
        <S.Horizontal horizontal={false}>
          <S.Description horizontal={false}></S.Description>
          <S.Title>{title}</S.Title>
          <S.Container horizontal={false}></S.Container>
        </S.Horizontal>
      </S.Card>
    </Link>
  );
};
