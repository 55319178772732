import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { sendEvent, actionTypes } from 'logger';
import { User } from 'app/types/user';
import { Contracts, sapNumber, Balance } from 'app/types/contracts';
import { CompanyResponse } from 'app/types/company';
import { ParentMenuItem } from 'app/types/layout';
import { useModal } from 'app/ducks/modals';
import { MODAL_NAME } from 'components/Modals/Feedback';
import { Button } from 'components/KrasUIKit/components/Button';
import { CompanySettings } from './components/CompanySettings/CompanySettings';
import { PageNav } from './components/PageNav/PageNav';
import { WelcomeBonus, SilverBonus } from 'features/Bonus';
import { Phone } from '../Phone';
import * as S from './styled';
import { MetalCostEntity } from 'app/types/metalCost';

export interface Props {
  user: User | null;
  isLogin: boolean;
  onChangeLocale: (lng: string) => void;
  isRu: boolean;
  pages: ParentMenuItem[];
  contracts: Contracts;
  balance: Balance;
  contractsLoading?: boolean;
  metalsLoading?: boolean;
  onChangeContract: (id: sapNumber) => void;
  company: CompanyResponse | null;
  companies: CompanyResponse[] | null;
  changeCompanyHandler: (company: CompanyResponse) => void;
  costMetal: MetalCostEntity;
  isLoadingMetalCost?: boolean;
  isLoadingMetalCostFile?: boolean;
  onCheckMetalCostFile: (payload: {
    filepath: string;
  }) => Promise<{
    filepath: string;
  }>;
}

export const TopNav: React.FC<Props> = ({
  isLogin,
  user,
  contracts,
  onChangeContract,
  balance,
  metalsLoading,
  contractsLoading,
  onChangeLocale,
  pages,
  isRu,
  company,
  companies,
  changeCompanyHandler,
  costMetal,
  isLoadingMetalCost,
  isLoadingMetalCostFile,
  onCheckMetalCostFile,
}) => {
  const { t } = useTranslation(['layout', 'units', 'feedback']);
  const { showModal } = useModal(MODAL_NAME);

  return (
    <S.TopNavContainer auth={Boolean(isLogin && user)} isRu={isRu}>
      <S.TopGroup>
        {!isLogin && !user && (
          <>
            <Button
              type="outline"
              onClick={() => {
                showModal();
                sendEvent(actionTypes.HEADER_CLICK_SUPPORT);
              }}
            >
              {t('feedback:title')}
            </Button>
            {isRu && <Phone phone="8 800 500 51 05" />}
          </>
        )}
        {isLogin && user && companies && (
          <S.CompanyInformation>
            <CompanySettings
              balance={balance}
              contracts={contracts}
              onChangeContract={onChangeContract}
              contractsLoading={contractsLoading}
              metalsLoading={metalsLoading}
              company={company}
              companies={companies}
              changeCompanyHandler={changeCompanyHandler}
              costMetal={costMetal}
              isLoadingMetalCost={isLoadingMetalCost}
              isLoadingMetalCostFile={isLoadingMetalCostFile}
              onCheckMetalCostFile={onCheckMetalCostFile}
            />
          </S.CompanyInformation>
        )}
      </S.TopGroup>
      <S.TopGroup>
        {isLogin && user && (
          <Fragment>
            <SilverBonus />
            <WelcomeBonus />
          </Fragment>
        )}
        <PageNav items={pages} size="small" variant="light" />
        <S.LangChanger>
          <S.LangButton onClick={() => onChangeLocale('ru')} active={isRu}>
            Ru
          </S.LangButton>
          <S.LangButton onClick={() => onChangeLocale('en')} active={!isRu}>
            En
          </S.LangButton>
        </S.LangChanger>
      </S.TopGroup>
    </S.TopNavContainer>
  );
};

export default TopNav;
