import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as actions from './actions';
import { clearPromotions } from '../companies/slice';
import { clearCompanies, loginStart as $loginStart } from './slice';
import * as AuthApi from 'app/services/auth';
import { SetPasswordPayload } from 'app/types/login';
import { jwtAccess } from 'app/services/connect';
import { resetContractsInfo } from 'features/slices/contracts';

export const useAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.auth);

  const loginStart = async () => {
    await dispatch($loginStart());
    if (!!localStorage.getItem(jwtAccess)) {
      dispatch(actions.initApp());
    } else {
      dispatch(actions.checkRefreshToken());
    }
  };

  const setUserCompany = async (companyId: number) => {
    await dispatch(clearPromotions());
    dispatch(actions.setUserCompany(companyId));
  };

  const fetchLogin = async (payload: AuthApi.UserLogin | { refreshToken: string }) => {
    const resultAction = await dispatch(actions.fetchLogin(payload));
    return actions.fetchLogin.fulfilled.match(resultAction);
  };

  const logOut = async () => {
    await dispatch($loginStart());
    await dispatch(clearPromotions());
    await dispatch(clearCompanies());
    await dispatch(resetContractsInfo());
    await dispatch(actions.logOut());
    await dispatch(actions.initApp());
  };

  const setPassword = async (payload: SetPasswordPayload) => {
    dispatch(actions.setPassword(payload));
  };

  const initApp = async () => {
    dispatch(actions.initApp());
  };

  return {
    state,
    setPassword,
    setUserCompany,
    logOut,
    fetchLogin,
    initApp,
    loginStart,
  };
};
