import { apiGet } from '../connect';
import { ItemsResultWithMeta, Result } from 'app/types/api';
import { ActionLogEntity } from 'app/types/adminActionLogs';

class ActionLogsServiceFactory {
  public async fetchLogs(params: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<ActionLogEntity>>(
        `/log${params}`,
      );
      return data;
    } catch (error) {
      return error as Result;
    }
  }
}

export const ActionLogsService = new ActionLogsServiceFactory();
