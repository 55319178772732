import React from 'react';
import { arrowBottom } from 'components/KrasUIKit/icons';
import * as S from '../Banners.styled';

type ArrowProps = {
  position?: 'left' | 'right';
  onClick: () => void;
};

export const Arrow = ({ position, onClick }: ArrowProps) => {
  return (
    <S.ArrowWrapper position={position} onClick={onClick}>
      <S.Shadow />
      <S.Arrow>{arrowBottom}</S.Arrow>
    </S.ArrowWrapper>
  );
};
