import React from 'react';
import { Carousel } from 'antd';
import { Modal as AntdModal } from '../styled/Modal.styled';
import { WelcomeBonus } from './WelcomeBonus';
import { SilverBonus } from './SilverBonus';
import { useBanner } from '../hooks/useBanner';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { nanoid } from '@reduxjs/toolkit';

export const PromotionsModal = () => {
  const welcomeBonusBanner = useBanner('welcomeBonus');
  const silverBonusBanner = useBanner('silverBonus');
  const { isBannerVisible } = useSelector((state: RootState) => state.companies);

  const createBannersList = () => {
    let hide;
    const bannerList = [];
    const welcomeBonusBannerComponent = <WelcomeBonus banner={welcomeBonusBanner} key={nanoid()} />;
    const silverBonusBannerComponent = <SilverBonus banner={silverBonusBanner} key={nanoid()} />;

    if (welcomeBonusBanner) {
      if (silverBonusBanner && !welcomeBonusBanner.isShown) {
        bannerList.push(welcomeBonusBannerComponent, silverBonusBannerComponent);
        hide = welcomeBonusBanner.hide;
      } else if (silverBonusBanner && welcomeBonusBanner.isShown) {
        bannerList.push(silverBonusBannerComponent, welcomeBonusBannerComponent);
        hide = silverBonusBanner.hide;
      } else {
        bannerList.push(welcomeBonusBannerComponent);
        hide = welcomeBonusBanner.hide;
      }
    } else if (silverBonusBanner) {
      bannerList.push(silverBonusBannerComponent);
      hide = silverBonusBanner.hide;
    }
    return { bannerList, hide };
  };

  const { bannerList, hide } = createBannersList();

  return welcomeBonusBanner || silverBonusBanner ? (
    <AntdModal open={isBannerVisible} onCancel={hide} footer={null}>
      <Carousel autoplay={true} autoplaySpeed={3000}>
        {bannerList}
      </Carousel>
    </AntdModal>
  ) : null;
};
