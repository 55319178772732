import { AnyAction, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { Pagination } from 'app/types/pagination';
import * as actions from './actions';
import * as types from 'app/types/residuesFinished';
import { AdminOffer } from 'app/types/products';
import { Coating } from 'app/types/products';
import { transformOffersData } from './helpers';

export interface ResiduesFinishedState {
  loading: boolean;
  list: types.Residue[];
  pagination: Pagination;
  typesResidues: types.ResiduesType[];
  lengths: number[];
  coatings: Coating[];
}

export const ResiduesFinishedInitialState: ResiduesFinishedState = {
  loading: false,
  list: [],
  typesResidues: [],
  lengths: [],
  coatings: [],
  pagination: { total: 0, perPage: 0, current: 1 },
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('residuesFinished/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('residuesFinished/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('residuesFinished/') && action.type.endsWith('/fulfilled');

const ResiduesFinishedSlice = createSlice({
  name: 'ResiduesFinished',
  initialState: ResiduesFinishedInitialState,
  reducers: {
    clearLengthsAndCoatings(state) {
      state.lengths = [];
      state.coatings = [];
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchResidues.fulfilled,
      (state, { payload: { items, pagination } }: PayloadAction<types.ResiduesResponse>) => {
        state.list = items;
        state.pagination = pagination;
      },
    );
    addCase(actions.fetchTypesResidues.fulfilled, (state, { payload }: PayloadAction<types.ResiduesType[]>) => {
      state.typesResidues = payload;
    });
    addCase(actions.updateResidue.fulfilled, (state, { payload }: PayloadAction<types.Residue>) => {
      const index = state.list.findIndex(item => item.id === payload.id);
      if (index > -1) state.list[index] = payload;
    });
    addCase(actions.deleteResidue.fulfilled, (state, { payload }: PayloadAction<number>) => {
      const index = state.list.findIndex(item => item.id === payload);
      if (index > -1) state.list.splice(index, 1);
    });
    addCase(actions.getOffersProduct.fulfilled, (state, { payload }: PayloadAction<AdminOffer[]>) => {
      const { lengths, coatings } = transformOffersData(payload);
      state.lengths = lengths;
      state.coatings = coatings;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      (state: ResiduesFinishedState) => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is PayloadAction<SerializedError> => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});
export const { clearLengthsAndCoatings } = ResiduesFinishedSlice.actions;
export default ResiduesFinishedSlice.reducer;
