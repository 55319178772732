import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as actions from './actions';
import { CreatePowerDto, UpdatePowerDto } from 'app/types/powers';

export const usePowers = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.powers);

  const fetchPowers = (search: string) => {
    return dispatch(actions.fetchPowers(search));
  };

  const updatePower = async (power: UpdatePowerDto) => {
    const resultAction = await dispatch(actions.updatePower(power));
    return actions.updatePower.fulfilled.match(resultAction);
  };

  const addPower = async (newPower: CreatePowerDto) => {
    const resultAction = await dispatch(actions.addPower(newPower));
    if (actions.addPower.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.item.code : undefined;
    }
  };

  const deletePower = (id: number) => {
    return dispatch(actions.deletePower(id));
  };

  return {
    state,
    fetchPowers,
    updatePower,
    addPower,
    deletePower,
  };
};
