import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Props {
  [key: string]: boolean;
}

const initialState: Props = {};

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    show(state, { payload: name }: PayloadAction<string>) {
      state[name] = true;
    },
    hide(state, { payload: name }: PayloadAction<string>) {
      state[name] = false;
    },
    destroy(state, { payload: name }: PayloadAction<string>) {
      delete state[name];
    },
  },
});

export const { show, hide, destroy } = modalSlice.actions;
export default modalSlice.reducer;
