import { apiGet } from './connect';
import { ItemResult, Result } from 'app/types/api';
import { MetalCostEntity } from 'app/types/metalCost';

class MetalCostServiceFactory {
  public async getActiveMetalCost() {
    try {
      const { data } = await apiGet<ItemResult<MetalCostEntity>>('/cost-metal/active');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async checkFileLink(payload: { filepath: string }) {
    try {
      const { data } = await apiGet<ItemResult<{ filepath: string }>>('/cost-metal/file', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const MetalCostService = new MetalCostServiceFactory();
