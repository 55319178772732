import { apiPost, apiGet, apiDelete } from '../connect';
import { Result, ItemsResultWithMeta, ItemResult, ItemsResult } from 'app/types/api';
import { UpdateCompanyDto, CreateCompanyDto, CompanyEntity } from 'app/types/adminCompanies';
import { Channels, Restrictions, AvailableSettings, StatusSettingsResponse } from 'app/types/company';
import { ContractEntity } from 'app/types/adminContracts';
import { GetPromotionResponse } from 'models/api/promotions';

class CompaniesServiceFactory {
  public async getMany(search: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<CompanyEntity>>(`/admin/companies${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async create(company: CreateCompanyDto) {
    try {
      const { data } = await apiPost<ItemResult<CompanyEntity>>('/companies', company);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async update(newCompanyData: UpdateCompanyDto) {
    try {
      const { data } = await apiPost<ItemResult<CompanyEntity>>(`/companies/${newCompanyData.id}`, newCompanyData);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async delete(categoryId: number) {
    try {
      const { data } = await apiDelete<Result<[]>>(`/companies/${categoryId}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getAvailableSettings() {
    try {
      const { data } = await apiGet<Result<AvailableSettings>>('/companies/settings');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getDisabledRestrictions() {
    try {
      const { data } = await apiGet<ItemsResult<Restrictions>>('/companies/disabledRestrictions');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getStatusSettings() {
    try {
      const { data } = await apiGet<Result<StatusSettingsResponse[]>>('/companies/options');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getChannels() {
    try {
      const { data } = await apiGet<ItemsResult<Channels>>('/companies/channels');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getCompanyContracts(companyId: number) {
    try {
      const { data } = await apiGet<ItemsResult<Omit<ContractEntity, 'company'>>>(`/companies/${companyId}/contracts`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getPromotions(companyId: number) {
    try {
      const { data } = await apiGet<Result<GetPromotionResponse>>(`companies/${companyId}/promotions`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getCompaniesFromAutocomplete(search: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<CompanyEntity>>('/admin/companies', {
        q: search,
      });
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const CompaniesService = new CompaniesServiceFactory();
