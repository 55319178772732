import { Result } from 'app/types/api';
import { apiGet, apiPost, apiDelete } from 'app/services/connect';
import { RoleEntity, Permission } from 'app/types/roles';

type ID = number | string;
export type ChangeableRoleFields = {
  name: string;
  title: string;
  permissions: number[];
};

export const getRoles = async () => {
  const { data } = await apiGet<Result<RoleEntity[]>>('/roles');
  return data;
};

export const getRoleById = async (id: ID) => {
  const { data } = await apiGet<Result<RoleEntity>>(`/role/${id}`);
  return data;
};

export const addRole = async (role: ChangeableRoleFields) => {
  const { data } = await apiPost<Result<RoleEntity>>('/role', role);
  return data;
};

export const removeRole = async (id: ID) => {
  const { data } = await apiDelete<Result<RoleEntity>>(`/role/${id}`);
  return data;
};

export const updateRole = async (id: ID, payload: ChangeableRoleFields) => {
  const { data } = await apiPost<Result<RoleEntity>>(`/role/${id}`, payload);
  return data;
};

export const getPermissions = async () => {
  const { data } = await apiGet<Result<Permission[]>>('/permissions');
  return data;
};

export const addPermissionToRole = async (roleId: ID, permissionId: ID) => {
  const { data } = await apiPost<Result>(
    `/role/${roleId}/permission/${permissionId}`,
  );
  return data;
};

export const removePermissionToRole = async (roleId: ID, permissionId: ID) => {
  const { data } = await apiDelete<Result>(
    `/role/${roleId}/permission/${permissionId}`,
  );
  return data;
};
