import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePromotions, useCompanies } from 'app/ducks/companies';
import { actionTypes } from 'logger';
import { Bonus } from './Bonus';
import { Stage } from './Stage/Stage';
import bonusIcon from 'images/svg/silver_bonus.svg';
import bonusIconCompact from 'images/svg/silver_bonus-compact.svg';
import { useMediaQuery } from 'hooks/useMediaQuery';

const SILVER_BONUS_RULES_URL = '/silver_bonus.pdf';

export const SilverBonus = () => {
  const [open, setOpen] = useState(false);
  const match = useRouteMatch({ path: '/', strict: true });
  const {
    state: { isBannerVisible, loading },
  } = useCompanies();
  const isTablet = useMediaQuery('(max-width: 1250px)');
  const { promotions } = usePromotions();
  const silverBonus = promotions?.silverBonus;
  const userHasWelcomeBonus = Boolean(promotions?.welcomeBonus);

  useEffect(() => {
    const show = !loading && !isBannerVisible && Boolean(match?.isExact) && !userHasWelcomeBonus;
    setOpen(show);
  }, [isBannerVisible, userHasWelcomeBonus, loading]);

  if (silverBonus) {
    return (
      <Bonus
        open={open}
        setOpen={setOpen}
        icon={isTablet ? bonusIconCompact : bonusIcon}
        title="Бонусы на изделия из серебра"
      >
        <Stage
          data={silverBonus}
          setOpen={setOpen}
          rulesUrl={SILVER_BONUS_RULES_URL}
          analyticsAction={actionTypes.AG_BONUS_CLICK_RULES}
          openRulesInNewTab
        />
      </Bonus>
    );
  }
  return null;
};
