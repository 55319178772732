import React from 'react';
import * as S from './styled';
import { minus, plus } from '../../icons';
import { IconButton } from '.././IconButton';
import { createLoggerEvent, actionTypes } from 'logger';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onChange' | 'max'
  > {
  value?: number;
  max?: number;
  onChange: (value: number) => void;
  productTitle?: string;
  type?: 'cart' | 'checkout';
  orderType?: string;
}

export const Quantity: React.SFC<Props> = ({
  value,
  onChange,
  disabled,
  max,
  productTitle,
  type = 'cart',
  orderType,
}) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value || value === '0') {
      onChange(Number(1));
    } else if (Number(value)) {
      onChange(Number(value));
    }
  };

  const decrement = createLoggerEvent(() => onChange(Number(value) - 1), {
    ActionType:
      type === 'cart'
        ? actionTypes.CART_MINUS_OFFER
        : actionTypes.CHECKOUT_MINUS_OFFER,
    ContextKey: productTitle && productTitle.trim().replace(/\s\s+/g, ' '),
    Query: orderType,
  });

  const increment = createLoggerEvent(() => onChange(Number(value) + 1), {
    ActionType:
      type === 'cart'
        ? actionTypes.CART_PLUS_OFFER
        : actionTypes.CHECKOUT_PLUS_OFFER,
    ContextKey: productTitle && productTitle.trim().replace(/\s\s+/g, ' '),
    Query: orderType,
  });

  return (
    <S.Container>
      <S.Prefix>
        <IconButton disabled={!!value && value <= 1} onClick={decrement}>
          {minus}
        </IconButton>
      </S.Prefix>
      <S.Input value={value} onChange={changeHandler} disabled={disabled} />
      <S.Affix>
        <IconButton
          disabled={value === max ? true : disabled}
          onClick={increment}
        >
          {plus}
        </IconButton>
      </S.Affix>
    </S.Container>
  );
};
