import { AnyAction, createSlice } from '@reduxjs/toolkit';
import {
  DocumentSectionEntity,
  DocumentSectionTypeEntity,
} from 'app/types/documentsSection';
import * as actions from './actions';

export interface InitialState {
  loading: boolean;
  list: DocumentSectionEntity[];
  types: DocumentSectionTypeEntity[];
}

export const initialState: InitialState = {
  loading: false,
  list: [],
  types: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documentsSection/') &&
  action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documentsSection/') &&
  action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documentsSection/') &&
  action.type.endsWith('/fulfilled');

const slice = createSlice({
  name: 'documentsSection',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.getDocumentSections.fulfilled, (state, { payload }) => {
      state.list = payload.items;
    });
    addCase(
      actions.getDocumentsSectionsTypes.fulfilled,
      (state, { payload }) => {
        state.types = payload.items;
      },
    );

    addCase(
      actions.getDocumentSectionsByCode.fulfilled,
      (state, { payload }) => {
        state.list = payload.items;
      },
    );

    addCase(actions.updateDocumentsSection.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(({ id }) => id === payload.item.id);
      state.list[index] = payload.item;
    });

    addCase(actions.createDocumentsSection.fulfilled, (state, { payload }) => {
      state.list.push(payload.item);
    });

    addCase(actions.deleteDocumentsSection.fulfilled, (state, { payload }) => {
      state.list = state.list.map(item => {
        const parentId = item.parentId === payload ? null : item.parentId;
        return {
          ...item,
          parentId,
        };
      });
      const index = state.list.findIndex(({ id }) => id === payload);

      if (index > -1) {
        state.list.splice(index, 1);
      }
    });

    addCase(actions.createDocument.fulfilled, (state, { payload }) => {
      const sectionIndex = state.list.findIndex(
        ({ id }) => id === payload.item.sectionId,
      );
      state.list[sectionIndex].documents.push(payload.item);
    });

    addCase(actions.deleteDocument.fulfilled, (state, { payload }) => {
      const sectionIndex = state.list.findIndex(
        ({ id }) => id === payload.sectionId,
      );
      const documentIndex = state.list[sectionIndex].documents.findIndex(
        ({ id }) => id === payload.documentId,
      );
      if (sectionIndex > -1 && documentIndex > -1) {
        state.list[sectionIndex].documents.splice(documentIndex, 1);
      }
    });

    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );

    addMatcher(
      (action): action is AnyAction => {
        return isPendingAction(action);
      },
      state => {
        state.loading = true;
      },
    );
  },
});

export default slice.reducer;
