import React from 'react';
import { Row, Alert } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Contract } from 'app/types/contracts';
import { Alert as KrasAlert } from '../KrasUIKit/components/Alert';
import { notAllowed } from 'components/KrasUIKit/icons';

export type Props = {
  login?: boolean;
  notAvilableByContract?: boolean;
  isDiscontinued?: boolean;
  inStock?: boolean;
  isClosed?: boolean;
  activeContract?: Contract;
  isOneContract: boolean;
};

export const Alerts: React.FC<Props> = ({
  login,
  notAvilableByContract,
  activeContract,
  isDiscontinued,
  inStock,
  isClosed,
  isOneContract,
}) => {
  const { t } = useTranslation('productDetail');

  if (!login) {
    return (
      <Alert
        style={{ width: '100%', marginBottom: 15 }}
        type="error"
        message={
          <Trans
            t={t}
            i18nKey="needForAuthorization"
            components={[<Link to="/login" key="login"></Link>]}
          />
        }
      />
    );
  }
  return (
    <Row>
      {!(inStock && !isOneContract) && (isDiscontinued || isClosed) ? (
        <KrasAlert
          style={{ width: '100%', marginBottom: 20 }}
          alertType="notAvailable"
          icon={notAllowed}
          title={t('cart:isDiscontinued')}
        />
      ) : (
        notAvilableByContract && (
          <Alert
            type="error"
            showIcon
            style={{ width: '100%', marginBottom: 15 }}
            message={
              <Trans
                t={t}
                i18nKey="availabilityByContract"
                values={{
                  contract:
                    activeContract?.contractType === 'no_metals'
                      ? t('work')
                      : t('supply'),
                }}
              />
            }
          />
        )
      )}
    </Row>
  );
};
