import React from 'react';
import { Form, DatePicker as DatePickerAntd, FormItemProps } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
const { Item: FormItem } = Form;

export type Props = DatePickerProps & {
  name: string;
  label: string;
  formItemProps?: FormItemProps;
};

export const DatePicker: React.FC<Props> = ({
  name,
  label,
  formItemProps,
  ...props
}) => {
  return (
    <FormItem name={name} label={label} {...formItemProps}>
      <DatePickerAntd inputReadOnly {...props} />
    </FormItem>
  );
};
