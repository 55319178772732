/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import {
  Block,
  Wrapper,
  OrderContent,
  FormWrapper,
  FormItemTitle,
  FormItemImg,
  OrderRow,
  OrderCell,
  Title,
  AlloysList,
} from 'features/AdditionalProducts/styled/Cart.styled';
import emptyPreview from 'images/png/tile-empty.png';
import { CartItemRow } from './CartRow';
import { BarsItem, StripesItem } from 'features/AdditionalProducts/types/OrderItem';
import {
  removeBars,
  removeRibbons,
  setRibbons,
  updateBars,
  updateRibbons,
} from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { Button } from 'antd';
import { AdditionalProductAlloy } from 'app/types/adminAdditional';
import { OrderForm } from '../../OrderForm';
import { ValidProps } from 'components/Fields/Valid';
import { useTranslation } from 'react-i18next';

interface CartContentProps {
  sortedByAlloy: ProductTab[];
  formRowProps: Omit<ValidProps, 'component' | 'name'>;
}

export const CartContent = ({ sortedByAlloy, formRowProps }: CartContentProps) => {
  const { t } = useTranslation(['additional']);
  const { bar } = useSelector((state: RootState) => state.additionalProductsOrder);
  const [alloysList, setAlloysList] = useState<AdditionalProductAlloy[]>([]);
  const [activeAlloy, setActiveAlloy] = useState<number>(0);

  useEffect(() => {
    const newList: AdditionalProductAlloy[] = [];

    sortedByAlloy.forEach(({ alloy }) => {
      const alloyItems = bar.filter((item: BarsItem) => item.alloyId === alloy.id);

      const tabsCount = alloyItems.length;

      if (tabsCount > 0) {
        newList.push(alloy);
      }
    });

    setAlloysList(newList);
  }, [bar]);

  const dispatch = useDispatch();

  const handleChangeItem = (newValues: BarsItem) => {
    dispatch(updateBars(newValues));
  };

  const handleDeleteClick = (item: BarsItem) => {
    dispatch(removeBars(item));
  };

  return (
    <Wrapper>
      <OrderContent>
        <Block>
          <Title>{t('totalCount')}</Title>
          <AlloysList>
            <Button
              size="small"
              style={{ opacity: activeAlloy === 0 ? 1 : 0.5 }}
              shape="round"
              htmlType="button"
              onClick={() => setActiveAlloy(0)}
            >
              {t('all')}
            </Button>
            {alloysList.map(alloy => (
              <Button
                size="small"
                style={{ opacity: activeAlloy === alloy.id ? 1 : 0.5 }}
                shape="round"
                htmlType="button"
                onClick={() => setActiveAlloy(alloy.id)}
              >
                {alloy.name}
              </Button>
            ))}
          </AlloysList>
        </Block>

        {sortedByAlloy.map(({ alloy }) => {
          const alloyItems = bar.filter((item: BarsItem) => item.alloyId === alloy.id);

          const tabsCount = alloyItems.length;

          return (
            tabsCount > 0 && (
              <Block visible={activeAlloy === 0 || activeAlloy === alloy.id}>
                <FormItemTitle>
                  <FormItemImg src={alloy.imagePath ?? emptyPreview} />
                  {alloy.name}
                </FormItemTitle>
                <OrderRow>
                  <OrderCell style={{ width: '20%' }}> {t('table.diameter')} </OrderCell>
                  <OrderCell style={{ width: '20%' }}> {t('table.weight')}</OrderCell>
                  <OrderCell style={{ width: '60%' }} />
                </OrderRow>

                {alloyItems.map(
                  (item, index) =>
                    item.weight > 0 && (
                      <CartItemRow
                        key={`${item.diameter}-${index}-${item.id}-${item.weight}`}
                        item={item}
                        onSave={newValues => handleChangeItem(newValues)}
                        onDelete={() => handleDeleteClick(item)}
                      />
                    ),
                )}
              </Block>
            )
          );
        })}
      </OrderContent>
      <FormWrapper>
        <Block decorated>
          <Title>{t('formTitle')}</Title>
          <OrderForm formRowProps={formRowProps} style={{ padding: '0 32px 32px' }} />
        </Block>
      </FormWrapper>
    </Wrapper>
  );
};
