import { actionTypes } from 'logger';
import agTripleBenefit from './images/ag-triple-benefit.svg';
import goldCombo from './images/gold-combo.svg';
import decor from './images/decor.svg';
import ptDegustation from './images/pt-degustation.svg';

export const banners = [
  {
    img: agTripleBenefit,
    link: 'https://disk.yandex.ru/d/zH-P3ief5s52gg',
    target: '_blank',
    eventName: actionTypes.BANNER_AG_TRIPLE_BENEFIT,
  },
  {
    img: goldCombo,
    link: 'https://jewelry.krastsvetmet.ru/catalog/products',
    target: '_self',
    eventName: actionTypes.BANNER_GOLD_COMBO,
  },
  {
    img: decor,
    link: 'https://disk.yandex.ru/i/6tQK8nFz1z4b5w',
    target: '_blank',
    eventName: actionTypes.BANNER_DECOR_TIME,
  },
  {
    img: ptDegustation,
    link: 'https://jewelry.krastsvetmet.ru/catalog/products/2807-platina-950',
    target: '_self',
    eventName: actionTypes.BANNER_PT_DEGUSTATION,
  },
];

export const ActiveBannerPathsEnum = {
  Catalog: 'catalog',
  PersonalOrders: 'personal/orders',
} as const;

export const ACTIVE_BANNERS_PATHS = Object.values(ActiveBannerPathsEnum);

export const ExcludeChannels = {
  KeyChannel: 'Ключевой канал',
  NearExport: 'Ближний экспорт',
  FarExport: 'Дальний экспорт',
} as const;

export const EXCLUDE_CHANNELS = Object.values(ExcludeChannels);
