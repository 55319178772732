import React, { useState, useEffect, useRef } from 'react';
import { Spin, Button } from 'antd';
import { useProductSuggestions } from '../../hooks/useProductSuggestions';
import { useHistory, useLocation } from 'react-router-dom';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { searchRight, IconLetter } from 'components/KrasUIKit/icons';
import * as S from './styled';
import { useTranslation, Trans } from 'react-i18next';
import { RefSelectProps } from 'antd/lib/select';
import { sendEvent, actionTypes } from 'logger';

export interface Props extends AutoCompleteProps {
  compact?: boolean;
  placeholder?: string;
}
export const ProductSearchAutocomplete: React.FC<Props> = ({ compact, placeholder, ...props }) => {
  const { t } = useTranslation(['main']);
  const history = useHistory();
  const [suggestions, loading, search, setSearch] = useProductSuggestions();
  const [open, setOpen] = useState(false);
  const searchInput = useRef<RefSelectProps>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (search.length !== 0) setSearch('');
  }, [pathname]);

  const getOptions = () => {
    const list = suggestions?.list;

    return list && search !== ''
      ? list.map(({ name, article }) => ({
          value: article,
          label: (
            <S.ItemSuggestions
              onClick={() =>
                sendEvent({
                  ActionType: actionTypes.INPUT_SEARCH_CLICK_HINT_OPTION,
                  ContextKey: article,
                })
              }
            >
              {`${compact ? '' : name} ${article}`}
            </S.ItemSuggestions>
          ),
        }))
      : [];
  };

  const selectHandler = (value?: string) => {
    value = value || search;
    if (!value) return;
    setSearch(value);
    history.push(`/search?query=${value}&page=1`);
    setOpen(false);
    if (searchInput.current) searchInput.current.blur();
  };

  const searchHandler = (str: string) => {
    setSearch(str);
    setOpen(true);
  };

  const handleOut = () => setOpen(false);

  const getDescription = () => {
    const articles = ['НЦ', 'НБ', 'НС', 'НХ', 'НП', 'НК'];
    if (getOptions().length === 0 && !loading)
      return <div className="empty-result_text">{t('searchNotFoundTitle')}</div>;

    if (isNaN(parseInt(search)) && !articles.includes(search.toUpperCase())) {
      return (
        <div>
          {t('main:nameFormat')}:<br />
          Гурмета или панцирь
        </div>
      );
    } else {
      return <div>{t('main:searchDetail')}</div>;
    }
  };

  const getNotFound = () => {
    if (search === '') return null;
    return (
      <div>
        <Trans
          t={t}
          i18nKey="main:searchNotFound"
          components={[
            <ul key="list">
              {['1', '2'].map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>,
          ]}
        />
      </div>
    );
  };

  const loggerPayload = {
    Query: compact ? 'compact' : 'full',
    ContextKey: search,
  };

  return (
    <S.ProductAutoComplete
      onBlur={handleOut}
      value={search}
      onSearch={searchHandler}
      onSelect={value => selectHandler(value as string | undefined)}
      options={getOptions()}
      showSearch
      open={open}
      ref={searchInput}
      notFoundContent={getNotFound()}
      dropdownRender={menu => (
        <div>
          <S.Description>{getDescription()}</S.Description>
          <S.AutocompleteDivider />
          {loading ? (
            <S.SpinWrap>
              <Spin />
            </S.SpinWrap>
          ) : (
            menu
          )}
          {suggestions && parseInt(suggestions.total) > 1 && (
            <S.FullView
              onMouseDown={() => {
                sendEvent(actionTypes.INPUT_SEARCH_HINT_CLICK_MORE);
                selectHandler();
              }}
            >
              <Trans t={t} i18nKey="main:countResult" values={{ count: suggestions?.total }} />
            </S.FullView>
          )}
        </div>
      )}
      {...props}
    >
      <S.SearchInput
        size="large"
        onPressEnter={() => {
          sendEvent({
            ActionType: actionTypes.INPUT_SEARCH_PRESS_ENTER,
            ...loggerPayload,
          });
          selectHandler();
        }}
        placeholder={placeholder ? placeholder : t('degustation:labelOfSearch')}
        suffix={
          compact ? (
            <S.SearchButton
              onClick={() => {
                sendEvent({
                  ActionType: actionTypes.INPUT_SEARCH_LOOP_CLICK,
                  ...loggerPayload,
                });
                selectHandler();
              }}
            >
              <IconLetter size="1.6em">{searchRight}</IconLetter>
            </S.SearchButton>
          ) : (
            <Button type="primary" onClick={() => selectHandler()}>
              {t('main:searchBtn')}
            </Button>
          )
        }
      />
    </S.ProductAutoComplete>
  );
};
