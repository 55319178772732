import React from 'react';
import { Row, Col, Menu } from 'antd';
import { Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Acts as ActsInterface } from 'app/types/documents';
import { Section } from '../../Documents/Section';
import { Acts } from './Acts';
import { useTranslation } from 'react-i18next';
import { StatutoryDocuments } from './StatutoryDocuments';
import { DocumentSectionEntity } from 'app/types/documentsSection';
import { MetalReport } from './MetalReport';
import * as S from './Documents.styled';

type MenuItem = { to: string; name: string; visible?: boolean };

export interface Props {
  sections: DocumentSectionEntity[];
  sectionsLoading?: boolean;
  onDownload: (guuid: string) => void;
  getActs: () => void;
  acts: ActsInterface;
}
export const Documents: React.FC<Props> = ({ sections, sectionsLoading, getActs, acts }) => {
  const { t, i18n } = useTranslation(['personal']);
  const location = useLocation();
  const { language } = i18n;
  const isRu = language === 'ru';
  const sectionsInMenu = isRu ? sections.filter(({ parentId }) => parentId === null) : [];

  const menu: MenuItem[] = [
    ...sectionsInMenu.map(({ name, id }) => ({
      name,
      to: `/personal/documents/${id}`,
    })),
    {
      to: '/personal/documents/metal-report',
      name: 'Отчет об израсходовании драгоценных металлов',
    },
    {
      to: '/personal/documents/acts',
      name: t('actsReconciliationSettlements'),
    },
    {
      to: '/personal/documents/statutory',
      name: 'Statutory documents',
      visible: !isRu,
    },
  ];
  return (
    <Row gutter={20}>
      <Col span={5}>
        <Menu mode="inline" theme="light" selectedKeys={[location.pathname]}>
          {menu
            .filter(({ visible }) => visible === undefined || visible === true)
            .map(({ name, to }) => (
              <S.MenuItem key={to}>
                <Link
                  style={{
                    padding: '10px 0',
                    whiteSpace: 'pre-wrap',
                    display: 'block',
                  }}
                  to={to}
                >
                  {name}
                </Link>
              </S.MenuItem>
            ))}
        </Menu>
      </Col>
      <Col span={19}>
        <Switch>
          <Route exact path="/personal/documents/acts">
            <Acts getActs={getActs} acts={acts} />
          </Route>
          <Route exact path="/personal/documents/statutory">
            <StatutoryDocuments />
          </Route>
          <Route exact path="/personal/documents/metal-report">
            <MetalReport />
          </Route>
          {isRu && (
            <Route path="/personal/documents/:id">
              <Section loading={sectionsLoading} data={sections} />
            </Route>
          )}
          <Route path="*">
            <Redirect to="/personal/documents/acts" />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};
