import React from 'react';
import { Alert, Row } from 'antd';
import { CoilProductionPowerMode } from 'app/types/products';
import { DetailOrderType } from '.';
import { Trans, useTranslation } from 'react-i18next';
import { number } from 'helpers/format';

export type Props = {
  orderType: DetailOrderType;
  productionPowerMode?: CoilProductionPowerMode;
  getLosePower: (type?: 'chain' | 'band') => number;
};

export const LimitAlerts = ({
  orderType,
  productionPowerMode = 0,
  getLosePower,
}: Props) => {
  const { t } = useTranslation('productDetail');

  const getAlert = (
    i18nKey: 'overcoil' | 'overpower' | 'overcoilChain' | 'overcoilBand',
    type?: 'chain' | 'band',
  ) => (
    <Row>
      <Alert
        style={{ width: '100%' }}
        showIcon
        type="warning"
        message={
          <Trans
            t={t}
            i18nKey={i18nKey}
            values={{
              count: number(Math.abs(getLosePower(type))),
            }}
            components={{ bold: <b /> }}
          />
        }
      />
    </Row>
  );

  if (orderType === 'products_production' && getLosePower() < 0) {
    return getAlert('overpower');
  }

  if (orderType === 'products_coil') {
    switch (productionPowerMode) {
      case CoilProductionPowerMode.EMPTY:
        if (getLosePower() < 0) {
          return getAlert('overcoil');
        }
        break;
      case CoilProductionPowerMode.OVERALL:
        if (getLosePower() < 0) {
          return getAlert('overcoil');
        }
        break;
      case CoilProductionPowerMode.TYPE_AND_COATING:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: '15px',
            }}
          >
            {getLosePower('chain') < 0 && getAlert('overcoilChain', 'chain')}
            {getLosePower('band') < 0 && getAlert('overcoilBand', 'band')}
          </div>
        );
      case CoilProductionPowerMode.ONLY_COATING:
        if (getLosePower() < 0) {
          return getAlert('overcoil');
        }
        break;
      case CoilProductionPowerMode.ONLY_TYPE:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: '15px',
            }}
          >
            {getLosePower('chain') < 0 && getAlert('overcoilChain', 'chain')}
            {getLosePower('band') < 0 && getAlert('overcoilBand', 'band')}
          </div>
        );
    }
  }

  return <></>;
};
