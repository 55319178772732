import React from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'hooks/useValidation';
import { RuleObject } from 'antd/lib/form';
import { AlertPasswordRequirements } from 'app/features/PasswordReset/components/AlertPasswordRequirements';
import { passwordRequirements } from 'app/features/PasswordReset/helper';
import { ChangePasswordPayload } from 'app/types/login';

const { Item } = Form;
const { Password } = Input;

export type ChangePasswordProps = {
  onChange: (payload: ChangePasswordPayload) => void;
  login: string | null;
};

export const ChangePasswordForm: React.FC<ChangePasswordProps> = ({
  onChange,
  login,
}) => {
  const { t } = useTranslation('reset');
  const [form] = Form.useForm();

  const handleSave = (values: ChangePasswordPayload) => {
    onChange(values);
  };

  const rules = passwordRequirements(login);
  const { validateByAllRules } = useValidation<string>(rules);

  // Вид валидатора, необходимый для анта
  const validator = async (_: RuleObject, value: string) => {
    const validatedFields = validateByAllRules(value);
    if (validatedFields.length !== rules.length) {
      throw new Error('Error');
    }
  };

  const validationRules = {
    rules: [{ validator, message: t('form:validationFailed') }],
    validateTrigger: ['onChange'],
  };

  return (
    <Form form={form} onFinish={handleSave} layout="horizontal">
      <Item shouldUpdate noStyle>
        {() => {
          // только к новому паролю предъявляем требования
          const value = form.getFieldValue('newPassword');
          const validCodes = validateByAllRules(value);
          return (
            <AlertPasswordRequirements rules={rules} validCodes={validCodes} />
          );
        }}
      </Item>
      <Item
        name="oldPassword"
        rules={[{ required: true, message: t('form:required') }]}
      >
        <Password placeholder={t('layout:oldPass')} />
      </Item>
      <Item name="newPassword" {...validationRules}>
        <Password placeholder={t('layout:newPass')} />
      </Item>
      <Item>
        <Button
          type="primary"
          ghost
          htmlType="submit"
          block
          style={{ verticalAlign: 'middle' }}
        >
          {t('changePassword')}
        </Button>
      </Item>
    </Form>
  );
};
