import * as AuthApi from 'app/services/auth';
import { message as AntMessage } from 'antd';
import { saveToken, removeToken } from 'app/services/auth';
import { sendEvent, actionTypes } from 'logger';
import { LoginService } from 'app/services/login';
import { SetPasswordPayload } from 'app/types/login';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginFailed, loginStart, loginSuccess, logOutSuccess, saveUserInfo, set503 } from './slice';
import { apiGet, refreshAccess } from 'app/services/connect';
import { fetchCart } from 'app/store/epics/actions/cart';
import { fetchContracts } from 'app/store/epics/actions/contracts';
import { getPromotions } from '../companies/actions';
import { clearPromotions } from '../companies/slice';

export const initApp = createAsyncThunk('auth/initApp', async (_: undefined, { dispatch }) => {
  try {
    const { data: response } = await apiGet<AuthApi.InitResult>('/init');
    const { data, success, code, message } = response;

    if (code === 503) {
      dispatch(set503());
    }

    if (success) {
      if (data.user && data.companies) {
        AntMessage.success(`С возвращением, ${data.user.name}!`);
        const activeCompanyId = data.companies.find(({ active }) => active)?.id;
        dispatch(saveUserInfo(data));
        dispatch(fetchCart());
        if (activeCompanyId) {
          dispatch(fetchContracts(activeCompanyId));
          dispatch(getPromotions(activeCompanyId));
        }
        dispatch(loginSuccess());
      } else {
        if (localStorage.getItem(refreshAccess)) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(checkRefreshToken());
          return;
        }
        removeToken();
        dispatch(saveUserInfo(data));
        dispatch(loginFailed());
        sendEvent(actionTypes.ENTERPOINT);
      }
    } else {
      AntMessage.error(message);
      dispatch(loginFailed());
    }
  } catch (error) {
    dispatch(loginFailed());
    AntMessage.error('Произошла ошибка сервера');
  }
});

export const setUserCompany = createAsyncThunk('auth/setUserCompany', async (companyId: number, { dispatch }) => {
  const { success, message } = await AuthApi.setUserCompany(companyId);
  if (success) {
    dispatch(clearPromotions());
    dispatch(loginStart());
    dispatch(initApp());
  } else {
    AntMessage.error(message);
  }
});

export const fetchLogin = createAsyncThunk(
  'auth/fetchLogin',
  async (payload: AuthApi.UserLogin | { refreshToken: string }, { dispatch, rejectWithValue }) => {
    try {
      const result = await AuthApi.login(payload);
      const { success, message, data, code } = result;

      if (success && !Array.isArray(data)) {
        sendEvent(actionTypes.AUTHORIZATION_SUCCESS);
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = data;
        saveToken(access_token, refresh_token);
        dispatch(loginSuccess());
        dispatch(initApp());
      } else {
        sendEvent(actionTypes.AUTHORIZATION_ERROR);
        removeToken();
        dispatch(loginFailed());

        if (code === 404) {
          return rejectWithValue(result);
        } else {
          AntMessage.error(message);
        }
      }
    } catch (error) {
      sendEvent(actionTypes.AUTHORIZATION_ERROR);
      dispatch(loginFailed());
      AntMessage.error('Произошла ошибка сервера');
    }
  },
);

export const logOut = createAsyncThunk('auth/logOut', async (_: undefined, { dispatch }) => {
  try {
    const { success, message } = await AuthApi.logout();
    if (success) {
      dispatch(logOutSuccess());
    } else {
      AntMessage.error(message);
      dispatch(loginFailed());
    }
  } catch (error) {
    dispatch(loginFailed());
    AntMessage.error('Произошла ошибка сервера');
  }
});

export const setPassword = createAsyncThunk('auth/setPassword', async (payload: SetPasswordPayload, { dispatch }) => {
  try {
    const { data, success, message } = await LoginService.setPassword(payload);
    dispatch(loginStart());
    if (success && data.item) {
      sendEvent(actionTypes.AUTHORIZATION_SUCCESS);
      // eslint-disable-next-line camelcase
      const { access_token, refresh_token } = data.item;
      saveToken(access_token, refresh_token);
      dispatch(loginSuccess());
      dispatch(initApp());
      AntMessage.success(message);
    } else {
      sendEvent(actionTypes.AUTHORIZATION_ERROR);
      removeToken();
      dispatch(loginFailed());
      AntMessage.error(message);
    }
  } catch (error) {
    dispatch(loginFailed());
    AntMessage.error('Произошла ошибка сервера');
  }
});

export const checkRefreshToken = createAsyncThunk('auth/checkRefreshToken', (_: undefined, { dispatch }) => {
  if (!!localStorage.getItem(refreshAccess)) {
    dispatch(
      fetchLogin({
        refreshToken: localStorage.getItem(refreshAccess) as string,
      }),
    );
  } else {
    dispatch(initApp());
  }
});
