import store from 'app/store/store';
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { RootState } from 'app/store/reducer';
import { nanoid } from '@reduxjs/toolkit';
import { history } from 'components/Router/history';

export const getSalt = async () => {
  const prev = localStorage.getItem('salt_id');
  if (prev) return prev;

  const fp = await fingerprintjs.load();
  const result = await fp.get();

  localStorage.setItem('salt_id', result.visitorId);

  return result.visitorId;
};

export const getUtms = () => {
  const queries = new URLSearchParams(history.location.pathname);

  return {
    UtmMedium: queries.get('utm_medium') || '',
    UtmSource: queries.get('utm_source') || '',
    UtmCampaign: queries.get('utm_campaign') || '',
    UtmTerm: queries.get('utm_term') || '',
  };
};

const PageInfo = () => {
  const parser = document.createElement('a');
  parser.href = document.referrer;

  let prevPathname = parser.pathname;
  let currentPathname = history.location.pathname;

  return () => {
    const HttpReferer = prevPathname;

    if (history.location.pathname !== currentPathname) {
      prevPathname = currentPathname;
      currentPathname = history.location.pathname;
    }

    return {
      PageUrl: window.location.href,
      HttpReferer,
      PageCode: history.location.pathname,
      SearchQuery: history.location.search,
    };
  };
};

const getConnection = () => {
  type Connection = {
    effectiveType: string;
    rtt: number;
  };

  type NavigatorConnect = {
    connection?: Connection;
    mozConnection?: Connection;
    webkitConnection?: Connection;
  };

  /* 
    TS не поддерживает свойство connection на данный момент, 
    поэтому при помощи хака овверайтим 
  */

  const customNavigator = (navigator as unknown) as NavigatorConnect;

  const { effectiveType: ConnectionType, rtt: ConnectionRTT } =
    customNavigator?.connection || customNavigator?.mozConnection || customNavigator?.webkitConnection || {};

  return { Connection: { ConnectionType, ConnectionRTT } };
};

const getDeviceViewport = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return {
    Resolution: {
      ResolutionWidth: width,
      ResolutionHeight: height,
    },
  };
};

const isShadowMode = () => {
  return Boolean(sessionStorage.getItem('jwtAccess'));
};

const getUserData = () => {
  const data: RootState = store.getState();

  const BitrixId = data.auth.user?.id || 0;
  const isManager = data.auth.permissions.includes('manager.*') || data.auth.permissions.includes('admin.*');

  const currentCompany = data.auth.companies?.find(({ active }) => active);
  const isMajor = currentCompany?.isMajor;
  const Channel = currentCompany?.channels.map(({ id }) => id).join(',');

  const currentContract = data.contracts?.contracts?.find(({ active }) => active);

  const Contract = currentContract?.sapNumber;
  const ContractType = currentContract?.contractType;

  return {
    BitrixId,
    isManager: isManager ? 1 : 0,
    isMajor: isMajor ? 1 : 0,
    Channel: Channel || '',
    ContractType,
    Contract,
  };
};

const getSession = () => {
  const prev = sessionStorage.getItem('session_id');
  if (prev) {
    return prev;
  } else {
    const sessionId = nanoid();
    sessionStorage.setItem('session_id', sessionId);

    return sessionId;
  }
};

const getPageInfo = PageInfo();
export const getLoggerBasePayload = async () => {
  const pageInfo = getPageInfo();
  const SaltHash = (await getSalt()) as string;
  return {
    isAdmin: isShadowMode(),
    SaltHash,
    State: getSession(),
    ...pageInfo,
    ...getConnection(),
    ...getUserData(),
    ...getDeviceViewport(),
    ...getUtms(),
  };
};
