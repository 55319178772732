import styled from 'styled-components';
import { Input } from 'antd';

export const MenuWrap = styled.div`
  background: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
`;

export const Menu = styled.div<{ size?: string }>`
  &&& {
    .ant-dropdown-menu {
      padding: 0.25rem 0;
      max-width: 17em;
      font-size: ${({ size }) => (size ? size : '0.875rem')};
      border-radius: 2px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
    }
    .ant-menu-item {
      line-height: 1em;
      padding: 0.5em 0.85em;
      display: flex;
      justify-content: start;
      margin: 0;
      white-space: pre-wrap;
      font-size: inherit;
      flex-direction: column;
    }
    .ant-dropdown-menu-title-content {
      font-size: initial;
    }
  }
`;

export const SearchCompanyInput = styled(Input)`
  margin: 0.57em 0.85em;
  width: auto;
  padding: 0.5em 0.8em;
  border-radius: 3px;
`;

export const SerchNotFound = styled.div`
  padding: 0 0.85em;
`;
