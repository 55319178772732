import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdditionalProductService } from 'app/services/admin/additionalProducts';
import { postClaim } from 'app/services/additionalProducts';
import { FormValues } from 'features/AdditionalProducts/components/Rhodium/Modal';

export const fetchMetadata = createAsyncThunk('additionalProducts/fetchMetadata', async () => {
  const { data } = await AdditionalProductService.getMetadata();
  return data;
});

export const fetchProducts = createAsyncThunk(
  'additionalProducts/fetchProducts',
  async (params: Record<string, unknown>) => {
    const { data } = await AdditionalProductService.getProducts(params);
    return data;
  },
);

export const sendClaim = createAsyncThunk('additionalProducts/claim', async (values: FormValues) => {
  const data = await postClaim(values);
  return data;
});
