import React from 'react';
import { Button } from 'components/KrasUIKit';
import { angleBottom, angleTop } from 'components/KrasUIKit/icons';
import { useTranslation } from 'react-i18next';
import { declFromNum } from 'helpers/format';

type ButtonExpandProps = {
  total: number;
  isExpanded: boolean;
  onClick: () => void;
};

export const ButtonExpand = ({ total, isExpanded, onClick }: ButtonExpandProps) => {
  const { t, i18n } = useTranslation('orderParameters');
  const { language } = i18n;
  const isRu = language === 'ru';

  return (
    <Button onClick={onClick} iconDirection="right" icon={isExpanded ? angleTop : angleBottom} type="dashed">
      {isExpanded
        ? t('checkout:hidePositions')
        : `${t('checkout:showAll')} ${total} ${
            isRu
              ? declFromNum(total, ['позицию', 'позиции', 'позиций'])
              : declFromNum(total, ['position', 'positions', 'positions'])
          }`}
    </Button>
  );
};
