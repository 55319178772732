import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  // we init with resources

  resources: {
    en: {
      translations: {
        welcomeTitle: 'About company',
        welcomeText: `Krastsvetmet exclusively produces machine-made chains and bracelets of gold 585, 750 (375 and 916 upon request), platinum 950 and silver 925 alloys. Our jewelry production has a full cycle, from making jewelry alloys to diamond cutting and finishing.

          The production complex was built in 1991, after 3 years we launched silver jewelry and two years later gold and platinum jewelry was introduced.
          
          Our assortment consists of 170 solid and hollow types of chains. While the gold chains differentiate between yellow, rose, white and green colors, the silver chains can be plated with white or black rhodium, yellow or red gilded.
          
          As the range of manufactured chains are constantly expanding and the technologies are improving, we would be pleased to have you at Krastsvetmet for an exciting excursion.`,
        searchShortPlaceholder: 'Search by article',
        searchDetailPlaceholder: 'Article or name',
        searchButton: 'Search',
        feedbackTitle: 'Feedback form',
        kcm: '© JSC «Krastsvetmet»',
        searchAlt:
          '<div>Enter the article in the format:</div><ul><li>12-002</li><li>12-002PG</li><li>002</li><li>Гурмета или панцирь</li></ul>',
      },
    },
    ru: {
      translations: {
        welcomeTitle: 'О Компании',
        welcomeText: `Красцветмет специализируется на производстве цепей и браслетов
          машинного плетения из сплавов золота 375, 585 и 750 пробы, платины
          950 пробы и серебра 925 пробы. Ассортимент продукции, выпускаемой
          ювелирным дивизионом, составляет 30 видов плетений базовых цепей, из
          которых изготавливается более 180 видов производных цепочек и
          браслетов. Налажен серийный выпуск 18 видов пустотелых цепей и
          браслетов из золота с алмазным гранением.`,
        searchShortPlaceholder: 'Поиск по артикулу',
        searchDetailPlaceholder: 'Артикул или название: 12-002, 12-002ПГ, 002, Гурмета или панцирь',
        searchButton: 'Найти',
        feedbackTitle: 'Напишите нам',
        kcm: '© ОАО «Красцветмет»',
        searchAlt:
          '<div>Введите артикул или название плетения в формате:</div><ul><li>12-002</li><li>12-002ПГ</li><li>002</li><li>Гурмета или панцирь</li></ul>',
      },
    },
  },
  fallbackLng: 'ru',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  lng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
