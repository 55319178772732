import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { date } from 'helpers/format';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import { OrdersTable } from '../../OrdersTable';
import { WelcomeBonusOrder } from 'models/api/promotions';
import * as S from '../../styled/Stage.styled';

export type DiscountProps = {
  url: string;
  balance: number;
  orders: WelcomeBonusOrder[];
  updateDate: string;
  handleRulesClick: () => void;
  openRulesInNewTab: boolean;
  isHolding: boolean;
};

export const Discount = ({
  url,
  balance,
  orders,
  updateDate,
  handleRulesClick,
  openRulesInNewTab,
  isHolding,
}: DiscountProps) => {
  const { toRubles } = useUnitsConverter();
  const usedBonuses = orders.reduce((acc, sum) => acc + sum.discount, 0);

  return (
    <Fragment>
      <S.Head>
        <S.Title>Баланс бонусов: {toRubles(balance)}</S.Title>
        <S.Text fontSize="16px" lineHeight="20px" color="#212529" mb="4px">
          Использовано: {toRubles(usedBonuses)}
        </S.Text>
        <S.SecondaryText>Обновлено {date(updateDate, 'DD.MM.YYYY')} (МСК)</S.SecondaryText>
      </S.Head>
      <S.Divider />
      <S.Body>
        <S.Text fontSize="16px" lineHeight="20px" color="#212529">
          Ваши бонусы были использованы в&nbsp;заказах{isHolding && ' по вашему холдингу'}:
        </S.Text>
        <OrdersTable orders={orders} isHolding={isHolding} />
      </S.Body>
      <S.Divider />
      <S.Text fontSize="14px" lineHeight="16px" color="#4f4f4f">
        Подробнее о начислениях и списаниях можете ознакомиться{' '}
        <Link to={url} onClick={handleRulesClick} target={openRulesInNewTab ? '_blank' : '_self'}>
          в правилах акции
        </Link>
      </S.Text>
    </Fragment>
  );
};
