import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClosedPositions } from 'app/services/closedPositions';

const fetchClosedPositions = createAsyncThunk(
  'closedPositions/fetchClosedPositions',
  async () => {
    const { message, success, data } = await getClosedPositions();
    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  },
);

export { fetchClosedPositions };
