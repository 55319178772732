import React from 'react';
import * as S from './styled';
import { close } from '../../icons';
import { IconButton } from '.././IconButton';
import { nextRight } from '../../icons';

export interface Props
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'> {
  typeHtml?: 'button' | 'submit' | 'reset';
  type?: 'primary' | 'dashed' | 'checkable';
  size?: 'medium' | 'large';
  border?: string;
  icon?: JSX.Element;
  closable?: boolean;
  arrow?: boolean;
  checked?: boolean;
  color?: string;
  fillColor?: string;
  onClose?: () => void;
}
export const Tag: React.FC<Props> = ({
  type = 'checkable',
  typeHtml = 'button',
  size = 'medium',
  icon,
  closable,
  checked = false,
  color,
  arrow = false,
  border = 'none',
  fillColor,
  disabled,
  children,
  onClick,
  onClose,
  ...props
}) => {
  // const clickHandler = () => {
  //   if (type === 'checkable') {
  //     checked ? (checked = false) : (checked = true);
  //   }
  // };
  return (
    <S.Tag
      {...props}
      disabled={disabled}
      fillColor={fillColor}
      color={color}
      typeTag={type}
      type={typeHtml}
      checked={checked}
      size={size}
      border={border}
    >
      {icon && <S.Icon size={size}>{icon}</S.Icon>}
      <S.Title
        onClick={disabled ? undefined : onClick}
        size={size}
        icon={icon ? true : false}
        closable={closable}
        arrow={arrow}
      >
        {children}
      </S.Title>
      {arrow && <S.Icon size={size}>{nextRight}</S.Icon>}
      {closable && (
        <S.Affix size={size}>
          <IconButton
            disabled={disabled}
            onClick={disabled ? undefined : onClose}
          >
            {close}
          </IconButton>
        </S.Affix>
      )}
    </S.Tag>
  );
};
