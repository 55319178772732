import styled from 'styled-components';
import { Dropdown as NavDropdown } from '../Dropdown/Dropdown';

export const MetalsLabel = styled.div`
  display: flex;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.57em;
  grid-template-columns: auto auto;
  @media screen and (max-width: 1200px) {
    font-size: 0.875em;
  }
`;

export const MetalIconWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.285em;
`;

export const Dropdown = styled(NavDropdown)`
  @media screen and (max-width: 1400px) {
    max-width: 250px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 170px;
    font-size: 0.875em;
  }
`;

export const MenuItemLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
`;

export const MenuItemLabelDescription = styled.div`
  font-size: 0.8em;
  line-height: 1em;
`;

export const PricesWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;
