import { apiGet, apiPost, apiDelete } from '../connect';
import { PowerEntity, CreatePowerDto, UpdatePowerDto, PowerEntityResponse } from 'app/types/powers';
import { ItemsResultWithMeta, Result } from 'app/types/api';

class PowersServiceFactory {
  public async getMany(search = '') {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<PowerEntity>>(`/powers/${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async create(power: CreatePowerDto) {
    try {
      const { data } = await apiPost<Result<PowerEntityResponse>>('/powers', power);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async update(payload: UpdatePowerDto) {
    try {
      const { id, ...newData } = payload;
      const { data } = await apiPost<Result<PowerEntityResponse>>(`/powers/${id}`, newData);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async delete(id: number) {
    try {
      const { data } = await apiDelete<Result<PowerEntity>>(`/powers/${id}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const PowersService = new PowersServiceFactory();
