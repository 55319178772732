import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'helpers/format';
import { BonusType } from 'features/Bonus/hooks/useBonusType';
import * as S from '../../styled/Stage.styled';

export type StartProps = {
  updateDate: string;
  handleRulesClick: () => void;
  url: string;
  openRulesInNewTab: boolean;
  bonusType?: BonusType;
};

const text = {
  WELCOME_BONUS: 'Скоро для вас будет рассчитан бонус, который вы сможете использовать при оформлении заказов.',
  SILVER_BONUS:
    'Если в период акции вы оформляли заказ, то скоро для вас будет рассчитан бонус, который вы сможете использовать при оформлении заказов.',
};

export const Start = ({ url, updateDate, handleRulesClick, openRulesInNewTab, bonusType }: StartProps) => {
  return (
    <S.StartContainer>
      <S.Head>
        <S.Title>Баланс бонусов скоро обновится</S.Title>
        <S.SecondaryText>Обновлено {date(updateDate, 'DD.MM.YYYY')} (МСК)</S.SecondaryText>
      </S.Head>
      {bonusType && (
        <S.Text fontSize="16px" lineHeight="20px" color="#212529">
          {text[bonusType]}
        </S.Text>
      )}
      <S.Text fontSize="16px" lineHeight="20px" color="#212529">
        Подробнее о начислениях и списаниях можете ознакомиться{' '}
        <Link to={url} onClick={handleRulesClick} target={openRulesInNewTab ? '_blank' : '_self'}>
          в правилах акции
        </Link>
      </S.Text>
    </S.StartContainer>
  );
};
