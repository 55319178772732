import { apiDelete, apiGet, apiPost } from '../connect';
import { ItemResult, ItemsResultWithMeta, Result } from 'app/types/api';
import {
  AdminMetalCostEntity,
  AddMetalCostShema,
  AdminMetalCostMetaData,
  AdminMetalCostTemplate,
} from 'app/types/adminMetalCost';

class MetalCostServiceFactory {
  public async getMetalCosts(params: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<AdminMetalCostEntity, AdminMetalCostMetaData>>(
        `/admin/cost-metal${params}`,
      );
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async addMetalCost(payload: AddMetalCostShema) {
    try {
      const { data } = await apiPost<ItemResult<AdminMetalCostEntity>>('/admin/cost-metal', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async stopMetalCost() {
    try {
      const { data } = await apiDelete<Result>('/admin/cost-metal');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async uploadTemplate(payload: FormData) {
    try {
      const { data } = await apiPost<ItemResult<AdminMetalCostTemplate>>('/admin/cost-metal/template', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async deleteTemplate() {
    try {
      const { data } = await apiDelete<Result>('/admin/cost-metal/template');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async checkFileLink(payload: { filepath: string }) {
    try {
      const { data } = await apiGet<ItemResult<{ filepath: string }>>('/admin/cost-metal/file', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const MetalCostService = new MetalCostServiceFactory();
