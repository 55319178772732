/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Autocomplete, Option } from 'components/Autocomplete';
import { getCategories } from 'app/services/admin/products';
import { CategoryAdmin } from 'app/types/products';

export interface Props {
  onSelect?: (option: Option) => void;
  filterOptions?: (data: Option[]) => Option[];
}

export const CategoriesAutocomplete: React.FC<Props> = ({ filterOptions, ...props }) => {
  const formatAutocompleteData = (data: CategoryAdmin[]) => {
    const formattedData = data.map<Option>(({ id, name }) => ({
      key: id.toString(),
      value: id,
      label: name,
      description: id.toString(),
    }));
    return filterOptions ? filterOptions(formattedData) : formattedData;
  };

  return (
    <Autocomplete
      placeholder="Введите категорию"
      formatResponse={formatAutocompleteData}
      dataRequest={getCategories}
      {...props}
    />
  );
};
