import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { ManagerLoginPayload } from 'app/services/auth';
import { AppDispatch } from 'app/store/store';

export const useManagerUser = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.managerUsers);

  const fetchManagerUsers = (search: string) => {
    dispatch(actions.fetchManagerUsers(search));
  };

  const loginByUser = async (payload: ManagerLoginPayload) => {
    const result = await dispatch(actions.loginByUser(payload));
    return actions.loginByUser.fulfilled.match(result);
  };

  return {
    state,
    fetchManagerUsers,
    loginByUser,
  };
};
