import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import * as prefixes from './consts';
import { MetalCostService } from 'app/services/admin/metalCost';
import { AddMetalCostShema } from 'app/types/adminMetalCost';

export const getMetalCosts = createAsyncThunk(
  prefixes.GET_ADMIN_METAL_COST,
  async (params: string, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.getMetalCosts(params);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const addMetalCost = createAsyncThunk(
  prefixes.ADD_ADMIN_METAL_COST,
  async (payload: AddMetalCostShema, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.addMetalCost(payload);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const stopMetalCost = createAsyncThunk(prefixes.STOP_ADMIN_METAL_COST, async (_, { rejectWithValue }) => {
  const { data, success, message } = await MetalCostService.stopMetalCost();
  if (success && data) {
    antdMessage.success(message);
    return data;
  } else {
    antdMessage.error(message);
    return rejectWithValue(data);
  }
});

export const uploadTemplate = createAsyncThunk(
  prefixes.UPLOAD_ADMIN_METAL_COST_TEMPLATE,
  async (payload: FormData, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.uploadTemplate(payload);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteTemplate = createAsyncThunk(
  prefixes.DELETE_ADMIN_METAL_COST_TEMPLATE,
  async (_, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.deleteTemplate();
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const checkFileLink = createAsyncThunk(
  prefixes.CHECK_ADMIN_METAL_COST_FILELINK,
  async (payload: { filepath: string }, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.checkFileLink(payload);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
