import { ItemInterface } from 'app/pages/routes';
import { lazy } from 'react';

const Serebro = lazy(() => import('./Serebro'));

export const routes: ItemInterface[] = [
  {
    path: '/peremeny-v-serebre-s-vygodoy',
    component: Serebro,
    exact: true,
  },
];
