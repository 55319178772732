import React from 'react';
import * as S from '../styled';
import { Table, TableProps } from 'antd';
import { number } from 'helpers/format';

type TableOfferProps = TableProps;

export const TableOffer = ({ ...props }: TableOfferProps) => {
  return (
    <S.Wrapper>
      <S.Title>Остаток по автозаказу</S.Title>
      <Table {...props} pagination={false}>
        <Table.Column title={<b>Артикул</b>} dataIndex="article" />
        <Table.Column title={<b>Покрытие</b>} dataIndex="coating" />
        <Table.Column title={<b>Диаметр</b>} dataIndex="diameter" />
        <Table.Column title={<b>Размер</b>} dataIndex="length" />
        <Table.Column title={<b>Вес, г</b>} dataIndex="weight" render={(weight: number) => number(weight)} />
        <Table.Column
          title={<b>Общий вес, г</b>}
          dataIndex="totalWeight"
          render={(totalWeight: number) => number(totalWeight)}
        />
        <Table.Column title={<b>Остаток, шт</b>} dataIndex="count" />
      </Table>
    </S.Wrapper>
  );
};
