import React, { Children, PropsWithChildren } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import { QueryFilters } from 'components/QueryFilters';
import * as S from './styled';

interface FiltersProps extends FormItemInputProps {
  disabled?: boolean;
  layout?: FormLayout;
  onOk?: (values: unknown) => void;
}

/**
 * @param onOk - обработчик параллельно с onFinish. Например можно передать событие аналитики
 */

export const Filters = ({
  disabled = false,
  layout = 'horizontal',
  onOk,
  children,
  ...buttonProps
}: PropsWithChildren<FiltersProps>) => {
  return (
    <QueryFilters>
      {form => (
        <Form
          name="filter"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 8 }}
          {...form}
          autoComplete="off"
          layout={layout}
          onFinish={values => {
            form.onFinish(values);
            onOk?.(values);
          }}
        >
          {Children.map(children, filter => (
            <>{filter}</>
          ))}
          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Row>
              <Col md={buttonProps?.wrapperCol?.span ?? 8}>
                <S.ButtonWrap>
                  <Button type="primary" htmlType="submit" disabled={disabled}>
                    Применить
                  </Button>
                  <Button type="default" htmlType="reset" disabled={disabled}>
                    Сбросить
                  </Button>
                </S.ButtonWrap>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
    </QueryFilters>
  );
};
