import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as T from 'app/types/demoUsers';

export const useDemoUsers = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.demoUsers);

  const getUsers = (search: string) => {
    return dispatch(actions.getUsers(search));
  };

  const activateUser = async (data: T.ActivateUserDto) => {
    const resultAction = await dispatch(actions.activateUser(data));
    if (actions.activateUser.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload : undefined;
    }
  };

  const createUser = async (data: T.CreateUserDto) => {
    const resultAction = await dispatch(actions.createUser(data));
    if (actions.createUser.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.user.name : undefined;
    }
  };

  return { state, getUsers, activateUser, createUser };
};
