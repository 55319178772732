import React from 'react';
import { LinkProps, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ParentMenuItem } from 'app/types/layout';
import { sendEvent, actionTypes } from 'logger';
import { IconLetterLeft } from '../../styled';
import * as S from './styled';

export type MenuItem = ParentMenuItem & {
  icon?: React.ReactNode;
  suffix?: React.ReactNode;
  id?: number;
};
export interface PageNavProps {
  items: MenuItem[];
  size?: 'small' | 'medium';
  variant?: 'light' | 'dark';
  inverse?: boolean;
}
const linkResetStyles = {
  borderBottom: 0,
};
export const PageNav = ({ items, inverse = false, variant = 'dark', size = 'medium' }: PageNavProps) => {
  const location = useLocation();

  const renderTitle = (item: Omit<MenuItem, 'subs'>) => {
    return (
      <S.MenuItemTitle hideSm={item.id === 5}>
        {item.icon && <IconLetterLeft size="22px">{item.icon}</IconLetterLeft>}
        <S.MenuItemName>{item.title}</S.MenuItemName>
        {item.suffix}
      </S.MenuItemTitle>
    );
  };

  const selectedKeys = items.filter(item => location.pathname.includes(item.to)).map(item => item.to);

  const renderLink = ({ to, children, ...props }: LinkProps) =>
    typeof to === 'string' && to.includes('http') ? (
      <a href={to} onClick={props.onClick}>
        {children}
      </a>
    ) : (
      <Link to={to} {...props}>
        {children}
      </Link>
    );

  return (
    <S.Nav mode="horizontal" selectedKeys={selectedKeys} inverse={inverse}>
      {items.map(({ title, subs, to, icon, suffix, id }) =>
        subs.length > 0 ? (
          <S.SubMenuStyled
            key={to}
            title={renderTitle({ title, icon, suffix, to })}
            style={linkResetStyles}
            size={size}
            variant={variant}
            active={location.pathname.includes(to)}
          >
            {subs.map(({ to, title }) => (
              <S.SubNavItem
                key={to}
                style={linkResetStyles}
                size={size}
                variant={variant}
                active={location.pathname.includes(to)}
              >
                {renderLink({
                  to,
                  children: title,
                  onClick: () =>
                    sendEvent({
                      ActionType: actionTypes.HEADER_MENU_CLICK_NAV,
                      ContextKey: to,
                    }),
                })}
              </S.SubNavItem>
            ))}
          </S.SubMenuStyled>
        ) : (
          <S.NavItem
            key={to}
            style={linkResetStyles}
            size={size}
            active={location.pathname.includes(to)}
            variant={variant}
          >
            {to.includes('http') ? (
              <a
                href={to}
                onClick={() =>
                  sendEvent({
                    ActionType: actionTypes.HEADER_MENU_CLICK_NAV,
                    ContextKey: to,
                  })
                }
              >
                {renderTitle({ title, icon, suffix, to, id })}
              </a>
            ) : (
              renderLink({
                to,
                children: renderTitle({ title, icon, suffix, to, id }),
                onClick: () =>
                  sendEvent({
                    ActionType: actionTypes.HEADER_MENU_CLICK_NAV,
                    ContextKey: to,
                  }),
              })
            )}
          </S.NavItem>
        ),
      )}
    </S.Nav>
  );
};
