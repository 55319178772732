import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Pagination, Row, Spin } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { usePersonalOrders } from 'app/ducks/personalOrders';
import { useProducts } from 'app/ducks/productsAdmin';
import { RootState } from 'app/store/reducer';
import { Filter } from './Filter';
import { Table } from './Table';
import { actionTypes, createLoggerEvent, sendEvent } from 'logger';
import { MODAL_NAME as STATUS_MODAL, StatusModal } from './StatusModal';
import { MODAL_NAME as SUCCESS_MODAL, ShippingSuccessModal } from './SuccessModal';
import { useModal } from 'app/ducks/modals';
import { message as antdMessage } from 'antd';
import { claimOrder } from 'app/services/orders';
import { useAuth } from 'app/ducks/auth';

export const Orders = () => {
  const { search } = useLocation();
  const { state: authState } = useAuth();
  const { state: productsState, getMetals } = useProducts();
  const { state, fetchOrders, selectStatusModalOrder, fetchForecastExcel } = usePersonalOrders();

  const [shippingLoading, setShippingLoading] = useState<boolean>(false);
  const { showModal, hideModal, visible } = useModal(STATUS_MODAL);
  const { showModal: showSuccess, hideModal: hideSuccess, visible: successVisible } = useModal(SUCCESS_MODAL);

  const query = useQuery();
  const history = useHistory();
  const { company, contracts, orderStatus } = useSelector((state: RootState) => ({
    contracts: state.contracts.contracts,
    company: state.auth.company,
    orderStatus: state.auth.options.orderStatus,
  }));

  useEffect(() => {
    getMetals();
  }, []);

  useEffect(() => {
    fetchOrders(search);
  }, [search]);

  const { metals } = productsState;
  const { meta, list, loading, pagination } = state;

  const onChangePage = (page: number) => {
    query.set('page', page.toString());
    history.push({ search: query.toString() });
    sendEvent({
      ActionType: actionTypes.PAGINATION,
      ContextKey: page,
      Query: +`${pagination && Math.ceil(pagination.total / pagination.perPage)}`,
    });
  };

  const handleStatusClick = createLoggerEvent((orderId: number) => {
    selectStatusModalOrder(orderId);
    showModal();
  }, actionTypes.STATUS_ORDER_MODAL);

  const handleShippingClick = createLoggerEvent(async (orderId: number) => {
    setShippingLoading(true);
    const { success, message } = await claimOrder(orderId, authState.user?.email, authState.user?.name);

    if (success) {
      hideModal();
      showSuccess();
      fetchOrders(search);
    } else {
      antdMessage.error(message);
    }
  }, actionTypes.STATUS_ORDER_SHIPMENT);

  const handleDownloadClick = createLoggerEvent(() => {
    fetchForecastExcel();
  }, actionTypes.STATUS_ORDER_FORECAST);

  return (
    <Spin
      spinning={loading}
      style={{
        width: '100%',
        height: '20vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Filter metals={metals} company={company} contracts={contracts} />
      {orderStatus && orderStatus.allowForecastShow && (
        <Row justify="end" style={{ marginBottom: '2em' }}>
          <Col md={6}>
            <Button
              ghost
              htmlType="button"
              onClick={handleDownloadClick}
              style={{ background: '#D9D9D9', border: 'none', color: '#2a2a2a' }}
            >
              Скачать прогноз готовности по заказам
            </Button>
          </Col>
        </Row>
      )}
      <Table
        showDiscount={meta?.showDiscount}
        orders={list}
        company={company}
        orderStatusOptions={orderStatus}
        onStatusClick={handleStatusClick}
      />
      {pagination && (
        <Row align="middle" justify="center" style={{ padding: 10 }}>
          <Pagination
            total={pagination.total}
            pageSize={pagination.perPage}
            current={pagination.current}
            hideOnSinglePage
            showSizeChanger={false}
            onChange={onChangePage}
          />
        </Row>
      )}
      <StatusModal
        visible={visible}
        onCancel={hideModal}
        onShippingClick={handleShippingClick}
        loading={shippingLoading}
      />
      <ShippingSuccessModal visible={successVisible} onCancel={hideSuccess} />
    </Spin>
  );
};
