/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import {
  Block,
  Wrapper,
  OrderContent,
  FormWrapper,
  FormItemTitle,
  FormItemImg,
  OrderRow,
  OrderCell,
  Title,
  AlloysList,
} from 'features/AdditionalProducts/styled/Cart.styled';
import emptyPreview from 'images/png/tile-empty.png';
import { CartItemRow } from './CartRow';
import { FittingItem } from 'features/AdditionalProducts/types/OrderItem';
import { updateFittings } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { Button } from 'antd';
import { OrderForm } from '../../OrderForm';
import { ValidProps } from 'components/Fields/Valid';
import { TabCount } from 'components/KrasUIKit/components/Tabs/styled';
import { useTranslation } from 'react-i18next';

interface CartContentProps {
  formRowProps: Omit<ValidProps, 'component' | 'name'>;
}

export const CartContent = ({ formRowProps }: CartContentProps) => {
  const { t } = useTranslation('additional');
  const {
    metadata: { productsMeta, fittings: fittingsCodes },
  } = useSelector((state: RootState) => state.additionalProducts);
  const { fitting } = useSelector((state: RootState) => state.additionalProductsOrder);
  const [activeType, setActiveType] = useState<string>(fittingsCodes[0] as string);

  const dispatch = useDispatch();

  const handleChangeItem = (fittingType: string, item: FittingItem, value: number) => {
    const index = fitting[fittingType][item.alloyId].products.indexOf(item);
    const [itemAlloy] = item.alloys.filter(alloy => alloy.id === item.alloyId);
    dispatch(
      updateFittings({
        alloy: itemAlloy,
        fittingType,
        index,
        count: value,
        weight: itemAlloy.massPerUnit * value,
      }),
    );
  };

  const handleDeleteClick = (fittingType: string, item: FittingItem) => {
    const index = fitting[fittingType][item.alloyId].products.indexOf(item);
    const [itemAlloy] = item.alloys.filter(alloy => alloy.id === item.alloyId);

    dispatch(
      updateFittings({
        alloy: itemAlloy,
        fittingType,
        index,
        count: 0,
        weight: 0,
      }),
    );
  };

  const calculateCountByType = (fittingType: string) => {
    const result: { [key: string]: number } = {};
    const products = fitting[fittingType];
    if (!products) return { total: 0 };

    Object.keys(products).forEach((alloyId: string) => {
      const alloyProducts = products[Number(alloyId)].products;
      result[`${alloyId}`] = alloyProducts.length ? alloyProducts.reduce((acc, product) => acc + product.count, 0) : 0;
    });

    result.total = Object.values(result).reduce((acc, count) => acc + count, 0);

    return result;
  };

  return (
    <Wrapper>
      <OrderContent>
        <Block>
          <Title>{t('totalCount')}</Title>
          <AlloysList>
            {fittingsCodes.map(item => {
              const [fittingType] = productsMeta.filter(metaItem => metaItem.code === item);
              const count = calculateCountByType(fittingType.code);

              return count?.total && count.total > 0 ? (
                <Button
                  shape="round"
                  size="small"
                  htmlType="button"
                  style={{ opacity: activeType === fittingType.code ? 1 : 0.5, height: 'auto', padding: '6px 12px' }}
                  onClick={() => setActiveType(fittingType.code)}
                >
                  {fittingType.title} <TabCount>{count.total}</TabCount>
                </Button>
              ) : null;
            })}
          </AlloysList>
        </Block>

        {fitting[activeType] &&
          Object.values(fitting[activeType]).map(({ alloy, products }) => {
            const nonEmptyProducts = products.filter(product => product.count > 0);
            if (nonEmptyProducts.length > 0) {
              return (
                <Block>
                  <FormItemTitle>
                    <FormItemImg src={alloy.imagePath ?? emptyPreview} />
                    {alloy.name}
                  </FormItemTitle>
                  <OrderRow>
                    <OrderCell style={{ width: '15%' }} />
                    <OrderCell style={{ width: '25%' }}>{t('table.size')}</OrderCell>
                    <OrderCell style={{ width: '10%' }}>{t('table.weight')}</OrderCell>
                    <OrderCell style={{ width: '20%' }}>{t('table.count')}</OrderCell>
                    <OrderCell style={{ width: '30%' }} />
                  </OrderRow>

                  {nonEmptyProducts.map(product => (
                    <CartItemRow
                      item={product}
                      onChange={(value: number) => handleChangeItem(activeType, product, value)}
                      onDelete={() => handleDeleteClick(activeType, product)}
                    />
                  ))}
                </Block>
              );
            }
          })}
      </OrderContent>
      <FormWrapper>
        <Block decorated>
          <Title>{t('formTitle')}</Title>
          <OrderForm formRowProps={formRowProps} style={{ padding: '0 32px 32px' }} />
        </Block>
      </FormWrapper>
    </Wrapper>
  );
};
