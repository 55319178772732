import {
  CONTRACTS_FETCHCONTRACTS,
  CONTRACTS_FETCFREEMETALS,
} from './actionTypes';
import { sapNumber } from 'app/types/contracts';

export const fetchContracts = (companyId: number | void) => ({
  type: CONTRACTS_FETCHCONTRACTS,
  payload: companyId,
});

export const fetchFreeMetals = (activeContractId: sapNumber | void) => ({
  type: CONTRACTS_FETCFREEMETALS,
  payload: activeContractId,
});
