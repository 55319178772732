/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import { TFunction } from 'i18next';
import { OrderType } from 'app/types/cart';

export const getOrderTypeTitles = (t: TFunction): { [key in OrderType]: string } => ({
  union: t('orderTypes:union'),
  products_coil: t('orderTypes:inCoils'),
  products_finished: t('orderTypes:finished'),
  products_finished_self_marked: t('orderTypes:selfMark'),
  products_production: t('orderTypes:inProduction'),
  waiting_list: t('orderTypes:waitingList'),
});
