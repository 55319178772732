import { apiDelete, apiGet, apiPost } from 'app/services/connect';
import { ItemsResultWithPagination, Result } from 'app/types/api';
import * as types from 'app/types/coilResidues';

class CoilResiduesServiceFactory {
  public async getMany(search = '') {
    try {
      const { data } = await apiGet<ItemsResultWithPagination<types.ResidueEntity>>(`/residues/coils${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getTypes() {
    try {
      const { data } = await apiGet<Result<types.ResidueType[]>>('/residues/coils/types');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async createOrUpdate(payload: types.CreateOrUpdateResidueDto) {
    try {
      const { data } = await apiPost<Result<types.ResidueEntity>>('/residues/coils', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async delete(id: number, payload: types.DeleteResiduePayload) {
    try {
      const { data } = await apiDelete<Result>(`/residues/coils/${id}`, undefined, {
        data: {
          withCoating: payload.withCoating,
          productType: payload.productType,
        },
      });
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const CoilResiduesService = new CoilResiduesServiceFactory();
