import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Button } from 'components/KrasUIKit';
import { angleRight } from 'components/KrasUIKit/icons';
import { Title } from 'components/Title';
import * as S from './styled/Top.styled';

type TopProps = {
  numberOrder: number;
};

export const Top = ({ numberOrder }: TopProps) => {
  const { t } = useTranslation(['orderTypes']);
  return (
    <Row justify="space-between" gutter={[30, 30]} style={{ marginBottom: 16 }}>
      <Col>
        <S.TitleWrap>
          <Title style={{ margin: 0 }} upper level={4}>
            {t('orderParameters:order')} {numberOrder}{' '}
          </Title>
        </S.TitleWrap>
      </Col>
      <Col>
        <Link to="/personal/orders">
          <Button icon={angleRight} iconDirection="right">
            {t('orderParameters:orderList')}
          </Button>
        </Link>
      </Col>
    </Row>
  );
};
