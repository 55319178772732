import { YMETRICA_DEBUG_FLAG } from '../constants';

export const isYMetricaDebug = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  const query = window.location.search;

  return query.includes(YMETRICA_DEBUG_FLAG);
};
