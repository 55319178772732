import styled from 'styled-components';
import { CaretDownFilled as CaretDownFilledAntd } from '@ant-design/icons';

export const DropdownWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownButtonGroup = styled.div`
  display: flex;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 18px;

  @media (max-width: 1217px) {
    grid-column-gap: 0.56em;
  }
`;

export const DropdownButton = styled.button`
  color: #212529;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  outline: none;
  :not(:disabled) {
    cursor: pointer;
  }
  span:not(:last-child) {
    margin-right: 0.285em;
  }
  margin-top: 0.41em;
`;

export const DropdownButtonLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownLabel = styled.div`
  font-size: 0.75rem;
  color: #767676;
`;

//TODO fix any, ошибка появилась после обновления @types/react@17.0.0
//Ошибка фиксится обновлением styled-components >=5v
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DropdownDate = styled(DropdownLabel as any)`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

export const CaretDownFilled = styled(CaretDownFilledAntd)`
  color: #767676;
  width: 0.86em;
`;
