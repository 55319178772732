import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'app/pages/routes';
import { usePermission } from 'hooks/usePermission';
import * as S from './styled';

export const AdminNavigation: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const adminRoutes = routes.filter(route => route.path.includes('/admin'));
  return (
    <S.Sider theme="light" width={200}>
      <S.Menu mode="inline" defaultSelectedKeys={[pathname]}>
        {adminRoutes.map(({ path, title, permissions }) => {
          const hasAccess = usePermission(permissions, 'some');
          return hasAccess ? (
            <S.MenuItem key={path}>
              <Link to={path}>{title}</Link>
            </S.MenuItem>
          ) : null;
        })}
      </S.Menu>
    </S.Sider>
  );
};

export default AdminNavigation;
