import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

export interface Props {
  loading?: boolean;
  lastOrderId?: number;
}

const initialState: Props = {};

const rendersSlice = createSlice({
  name: 'renderRequest',
  initialState,
  reducers: {
    renderRequestStart(state, { payload }) {
      state.lastOrderId = payload;
    },
    renderRequestEnd(state) {
      state.lastOrderId = undefined;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(actions.downloadOrderRenders.pending, state => {
      state.loading = true;
    });
    addCase(actions.downloadOrderRenders.fulfilled, state => {
      state.loading = false;
    });
    addCase(actions.downloadOrderRenders.rejected, state => {
      state.loading = false;
    });
  },
});

export const { renderRequestStart, renderRequestEnd } = rendersSlice.actions;
export default rendersSlice.reducer;
