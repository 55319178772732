import * as React from 'react';
import { FormikValues } from 'formik';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
const { Item } = Form;

export interface ValidProps extends Omit<FormItemProps, 'children'> {
  name: string;
  component: typeof React.Component | React.FunctionComponent;
  form: FormikValues;
  placeholder?: string | React.ReactElement;
  readOnly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (val: any) => void;
  onChange?: (val: any) => void;
}

export const Valid: React.FC<ValidProps> = ({
  name,
  component: Field,
  form,
  placeholder,
  readOnly = false,
  onSelect,
  onChange,
  ...args
}) => {
  const { touched, values, errors, setFieldTouched } = form;
  const isDate = name.includes('date') || name.includes('period');
  const isTouched = (): boolean => touched[name];
  const hasError = (): boolean => errors[name];
  const getValue = (): string => values[name];

  const blurHandler = (): void => {
    //TODO какого то хера onBlur ломает календари, потому что вызывается при кажом действии в них.
    if (isDate) {
      return;
    }

    setFieldTouched(name);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlerChange = (event: any) => {
    const value = typeof event === 'string' || !event?.target ? event : event?.target?.value;
    form.setFieldValue(name, value);
  };
  return (
    <Item
      hasFeedback={isTouched()}
      help={isTouched() && hasError()}
      validateStatus={isTouched() && hasError() ? 'error' : 'success'}
      {...args}
    >
      <Field
        onSelect={onSelect}
        name={name}
        value={getValue()}
        onChange={handlerChange}
        onBlur={blurHandler}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Item>
  );
};
