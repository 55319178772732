import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/KrasUIKit';
import { angleRight } from 'components/KrasUIKit/icons';
import { Title } from 'components/Title';
import { Col, Row } from 'antd';

type EmptyProps = {
  isLoading?: boolean;
};

export const Empty = ({ isLoading }: EmptyProps) => {
  if (isLoading) {
    return null;
  }
  return (
    <Row justify="space-between" gutter={[30, 30]}>
      <Col>
        <Title upper level={4}>
          Автозаказ не найден
        </Title>
      </Col>
      <Col>
        <Link to="/personal/auto-orders">
          <Button icon={angleRight} iconDirection="right">
            Список заказов
          </Button>
        </Link>
      </Col>
    </Row>
  );
};
