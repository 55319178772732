import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';
import { Spin as OriginalSpin } from 'antd';

export const Wrapper = styled(AntdLayout.Content)`
  padding: var(--s8) var(--s8) 0;
  max-width: 1441px;
  margin: 0 auto;
  min-height: calc(100vh - 150px);
  min-width: 1000px;
`;

export const Layout = styled(AntdLayout)`
  background-color: #fff;
`;

export const Spin = styled(OriginalSpin)`
  position: absolute !important;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;
