import React from 'react';
import { Modal, Row, Col } from 'antd';
import { Paragraph, SuccessIcon, Title as SuccessTitle } from '../../AdditionalProducts/styled/Common.styled';

export const MODAL_NAME = 'shipping-success';

interface ShippingSuccessModalProps {
  visible: boolean;
  onCancel: () => void;
}

export const ShippingSuccessModal = ({ visible, onCancel }: ShippingSuccessModalProps) => {
  return (
    <Modal open={visible} title={null} footer={null} width="366px" style={{ paddingTop: '40px' }} onCancel={onCancel}>
      <Row justify="center" style={{ textAlign: 'center' }}>
        <Col md={24}>
          <SuccessIcon style={{ marginTop: '18px' }} />
        </Col>
        <SuccessTitle>Ваш запрос на отгрузку готовых изделий отправлен на согласование</SuccessTitle>
        <Paragraph style={{ fontSize: '14px' }}>
          Мы свяжемся с&nbsp;вами позднее для&nbsp;уточнения условий отгрузки
        </Paragraph>
      </Row>
    </Modal>
  );
};
