import React from 'react';
import { success } from 'components/KrasUIKit/icons';
import * as S from '../styled';

type Props = {
  text: string;
  isValid?: boolean;
};

export const ValidationListItem: React.FC<Props> = ({
  text,
  isValid = false,
}) => {
  return (
    <S.Li isValid={isValid}>
      <span>{text}</span>
      <S.ValidIcon>{isValid && success}</S.ValidIcon>
    </S.Li>
  );
};
