import { useCallback } from 'react';
import download from 'downloadjs';
import { message } from 'antd';
import { apiGet } from 'app/services/connect';
import { Result } from 'app/types/api';

export const useTessaStorage = (uuid: string, mimeType = 'application/pdf') => {
  const trigger = useCallback(async () => {
    try {
      const { data: response } = await apiGet<
        Result<{
          data: string;
          name: string;
        }>
      >(`/tessa/acts/${uuid}`);
      const { data, message, success } = response;

      if (success) {
        download(`data:${mimeType};base64,` + data.data, data.name, mimeType);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      //@ts-expect-error
      message.error(error.message);
    }
  }, [uuid, mimeType]);
  return trigger;
};
