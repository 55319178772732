import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store/store';
import { message as antdMessage } from 'antd';
import { getFreeMetals, getContracts, setActiveContract } from 'app/services/contracts';
import { sapNumber, Contracts, Balance } from '../../app/types/contracts';
import { CompanyId } from '../../app/types/company';

export interface StateProps {
  metalsLoading?: boolean;
  contractsLoading?: boolean;
  balance: Balance;
  contracts: Contracts;
}

const initialState: StateProps = {
  balance: [],
  contracts: [],
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    getFreeMetalsStart(state) {
      state.metalsLoading = true;
    },
    getFreeMetalsSuccess(state, { payload: balance }: PayloadAction<{ items: Balance }>) {
      state.balance = balance.items;
      state.metalsLoading = false;
    },
    getFreeMetalsFailed(state) {
      state.metalsLoading = false;
    },

    getContractsStart(state) {
      state.contractsLoading = true;
    },
    getContractsSucces(state, { payload: contracts }: PayloadAction<Contracts>) {
      state.contracts = contracts;
      if (contracts.length === 0) state.balance = [];
      state.contractsLoading = false;
    },
    getContractsFailed(state) {
      state.contractsLoading = false;
    },

    changeContractStart(state) {
      state.contractsLoading = true;
    },

    changeContractSuccess(state, { payload }: PayloadAction<{ contractId: sapNumber; balance: { items: Balance } }>) {
      const { contractId, balance } = payload;
      state.contractsLoading = false;

      state.contracts = state.contracts.map(({ sapNumber, ...itemParams }) => ({
        ...itemParams,
        sapNumber,
        active: sapNumber === contractId,
      }));
      state.balance = balance.items;
    },

    changeContractFailed(state) {
      state.contractsLoading = false;
    },

    resetContractsInfo(state) {
      state.balance = [];
      state.contracts = [];
    },
  },
});

const { actions, reducer } = contractsSlice;

export const {
  getFreeMetalsStart,
  getFreeMetalsSuccess,
  getFreeMetalsFailed,

  getContractsStart,
  getContractsSucces,
  getContractsFailed,

  changeContractStart,
  changeContractSuccess,
  changeContractFailed,

  resetContractsInfo,
} = actions;

export const fetchFreeMetals = (id: sapNumber | string | number): AppThunk => async dispatch => {
  try {
    dispatch(getFreeMetalsStart());

    const { data, message, success } = await getFreeMetals(id);
    if (success) {
      dispatch(getFreeMetalsSuccess(data));
    } else {
      antdMessage.error(message);
      dispatch(getFreeMetalsFailed());
    }
  } catch (error) {
    dispatch(getFreeMetalsFailed());
    //@ts-expect-error
    throw new Error(error.message);
  }
};

export const fetchContracts = (id: CompanyId, hasUpdateMetals?: boolean): AppThunk => async dispatch => {
  try {
    !hasUpdateMetals && dispatch(getContractsStart());

    const { data, message, success } = await getContracts(id);

    if (success) {
      dispatch(getContractsSucces(data));
      const activeContractId = data.find(({ active }) => active)?.sapNumber;

      if (hasUpdateMetals && activeContractId) {
        dispatch(fetchFreeMetals(activeContractId));
      }
    } else {
      antdMessage.error(message);
      dispatch(getContractsFailed());
    }
  } catch (error) {
    dispatch(getContractsFailed());
    //@ts-expect-error
    throw new Error(error.message);
  }
};

export const changeContract = (contractSapNumber: sapNumber, companyId: CompanyId): AppThunk => async dispatch => {
  try {
    dispatch(changeContractStart());

    const { message, success } = await setActiveContract(contractSapNumber, companyId);
    const { data, success: metalSuccess } = await getFreeMetals(contractSapNumber);
    if (success && metalSuccess) {
      dispatch(changeContractSuccess({ contractId: contractSapNumber, balance: data }));
    } else {
      antdMessage.error(message);
      dispatch(changeContractFailed());
    }
  } catch (error) {
    antdMessage.error('Неизвестная ошибка сервера!');
    dispatch(changeContractFailed());
  }
};

export default reducer;
