import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadChangeParam, UploadProps, RcFile } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';

export interface Props extends Omit<UploadProps, 'onChange'> {
  maxFileSize?: number;
  onChange?: (uid: string) => void | ((response: any) => void);
  action?: string;
  name?: string;
  fullResponse?: boolean;
}

export const UploadFiles: React.FC<Props> = ({
  onChange,
  children,
  maxFileSize = 5,
  name = 'files[0]',
  action = '/api/v1/storage/upload',
  fullResponse,
  ...props
}) => {
  const changeHandler = (info: UploadChangeParam) => {
    const { status, name, response } = info.file;
    if (onChange && status === 'done' && response.success) {
      message.success(`${name} файл успешно загружен.`);
      onChange(fullResponse ? response.data[0] : response.data[0].uuid);
    } else if (status === 'error' || (response && !response.success)) {
      message.error(`${name} не удалось загрузить.`);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const checkedMaxSize = file.size / 1024 / 1024 < maxFileSize;

    if (!checkedMaxSize) {
      message.error(`Максимально допустимый размер файла ${maxFileSize}!`);
      return false;
    }

    return true;
  };

  return (
    <Upload
      name={name}
      action={action}
      onChange={changeHandler}
      beforeUpload={beforeUpload}
      {...props}
    >
      <Button>
        <UploadOutlined /> {children}
      </Button>
    </Upload>
  );
};
