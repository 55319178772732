import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { CompanyControl } from 'app/types/managers';

export const useManagers = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.managers);

  const fetchManagers = (page: string, search: string) => {
    dispatch(actions.fetchManagers({ page, search }));
  };

  const addCompanyRelation = async (company: CompanyControl) => {
    const resultActionAddCompanyRelation = await dispatch(
      actions.addCompanyRelation(company),
    );
    return actions.addCompanyRelation.fulfilled.match(
      resultActionAddCompanyRelation,
    );
  };

  const removeCompanyRelation = (company: CompanyControl) => {
    dispatch(actions.removeCompanyRelation(company));
  };

  return {
    state,
    fetchManagers,
    removeCompanyRelation,
    addCompanyRelation,
  };
};
