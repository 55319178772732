/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Autocomplete, Option } from 'components/Autocomplete';
import { getSuggestByDaData, DaDataCompony } from 'app/services/company';

export interface Props {
  onSelect?: (option: Option) => void;
}

export const AutocompleteCompany: React.FC<Props> = props => {
  const formatAutocompleteData = (data: DaDataCompony[]) => {
    const formattedData = data.map(({ inn, name }) => ({
      key: inn,
      value: name,
      label: name,
      description: inn,
    }));
    return formattedData;
  };

  return (
    <Autocomplete
      dataRequest={getSuggestByDaData}
      formatResponse={formatAutocompleteData}
      placeholder="Компании"
      {...props}
    />
  );
};
