import { actionTypes, sendEvent } from 'logger';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const PageLogger: React.FC = () => {
  const location = useLocation();
  const prevPathname = useRef<string | null>(null);
  useEffect(() => {
    if (prevPathname?.current && prevPathname?.current !== location.pathname) {
      sendEvent({
        ActionType: actionTypes.PAGE_CHANGED,
        HttpReferer: prevPathname?.current,
        PageCode: location.pathname,
      });
    }
    prevPathname.current = location.pathname;
  }, [location]);
  return null;
};
