import React from 'react';
import { RegistrationData } from '../components/Personal/RegistrationData';
import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';
export const PersonalContainer = () => {
  const { contracts, auth } = useSelector((state: RootState) => state);
  const { user, company } = auth;

  return (
    <RegistrationData user={user} company={company} contracts={contracts} />
  );
};
