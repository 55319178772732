import styled from 'styled-components';
import { AutoComplete, Divider, Button as ButtonAntd, Input as InputAntd } from 'antd';

export const FullView = styled.div`
  font-size: 1rem;
  padding: 5px 12px;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  :hover {
    background-color: #f6f6f6;
  }
`;
export const Description = styled.div`
  padding: 8px;
  color: var(--description);
  font-size: 0.89rem;
  line-height: 18px;
`;
export const ItemSuggestions = styled.div`
  font-size: 1rem;
`;
export const ProductAutoComplete = styled(AutoComplete)`
  min-width: 195px;
  height: 100%;
  width: 100%;
`;
export const SpinWrap = styled.div`
  text-align: center;
  padding: var(--s12);
`;
export const AutocompleteDivider = styled(Divider)`
  && {
    margin: 5px 0px;
  }
`;
export const Button = styled(ButtonAntd)`
  background: white;
  width: 2.875em;
  height: 2.1em;
  margin: 0;
  padding: 0;
  color: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//TODO fix any, ошибка появилась после обновления @types/react@17.0.0
//Ошибка фиксится обновлением styled-components >=5v
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchButton = styled(Button as any)`
  width: 2em;
  justify-content: flex-end;
`;

export const SearchInput = styled(InputAntd)`
  max-height: 2.5em;
`;
