import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &,
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;
