import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { ActRequest } from 'app/types/documents';

export const useDocuments = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.documents);

  const fetchDocumentSections = () => {
    dispatch(actions.fetchDocumentSections());
  };

  const fetchSectionContent = (id: number) => {
    dispatch(actions.fetchSectionContent(id));
  };

  const fetchDocuments = () => {
    dispatch(actions.fetchDocuments());
  };

  const downloadDocument = (uuid: string) => {
    dispatch(actions.downloadDocument(uuid));
  };

  const fetchActs = () => {
    dispatch(actions.fetchActs());
  };

  const submitActRequest = (data: ActRequest) => {
    dispatch(actions.submitActRequest(data));
  };

  const downloadSpecification = (id: number) => {
    dispatch(actions.downloadSpecification(id));
  };

  return {
    state,
    fetchDocumentSections,
    fetchSectionContent,
    fetchDocuments,
    downloadDocument,
    fetchActs,
    submitActRequest,
    downloadSpecification,
  };
};
