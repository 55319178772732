import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';
import { actionTypes, sendEvent, createLoggerEvent } from 'logger';
import { arrowBottom, user as userIcon } from 'components/KrasUIKit/icons';
import { UserMenu } from '../MenuComponents';
import * as S from './styled';
import { useUserMenu } from '../../hooks/useUserMenu';
import { useTranslation } from 'react-i18next';
import { useDebounceByOpen } from 'hooks/useDebounceByOpen';

export type UserNavProps = {
  onLogout: () => void;
};

export const UserNav = ({ onLogout }: UserNavProps) => {
  const { t } = useTranslation('main');
  const { menu, isSelected } = useUserMenu();
  const { debounceByOpen } = useDebounceByOpen();
  const menuItems = menu.items || [];

  const onLogoutHandler = createLoggerEvent(() => onLogout(), actionTypes.HEADER_CLICK_LOGOUT);

  return (
    <Dropdown
      overlayStyle={{ minWidth: 'auto' }}
      placement="bottom"
      arrow={true}
      menu={{
        ...menu,
        items: [
          ...menuItems.map(item => {
            if (item && item.type === 'item') {
              if (item.disabled) {
                return null;
              }
              return {
                ...item,
                label: <Link to={item.key as string}>{item.label}</Link>,
              };
            }
            return item;
          }),
          {
            key: 'logout',
            type: 'item',
            label: t('layout:exit'),
            onClick: onLogoutHandler,
          },
        ],
      }}
      dropdownRender={menu => <UserMenu menu={menu} />}
      onOpenChange={open => debounceByOpen(() => sendEvent(actionTypes.HEADER_HOVER_PERSONAL), open)}
    >
      <S.UserWrap data-selected={isSelected}>
        <S.IconLetter size="24px"> {userIcon}</S.IconLetter>
        <S.IconLetter size="0.75em"> {arrowBottom}</S.IconLetter>
      </S.UserWrap>
    </Dropdown>
  );
};
