import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
// import { cacheAdapterEnhancer } from 'axios-extensions';
import { getBasicHeader } from './helper';

export const jwtAccess = 'jwtAccess';
export const refreshAccess = 'refreshAccess';
export const apiPath = '/api/v1';

const apiCall = axios.create({
  baseURL: '/api/v1',
  // adapter: axios.defaults.adapter
  //   ? cacheAdapterEnhancer(axios.defaults.adapter)
  //   : undefined,
});

let apiCallResponseInterceptor: number;
let apiCallRequestInterceptor: number;

export const apiCallEject = () => {
  apiCall.interceptors.response.eject(apiCallResponseInterceptor);
  apiCall.interceptors.request.eject(apiCallRequestInterceptor);
};

export const apiCallInit = (dispatch: Dispatch, lang = 'ru') => {
  apiCallResponseInterceptor = apiCall.interceptors.response.use(
    res => {
      // any 2xx status
      const { data } = res;
      switch (data.code) {
        case 401:
          console.error('Got 401 in response. Re-initiate.');
          break;
        case 500:
          console.error('Got 500 in response');
          console.log(res);
          break;
      }
      return res;
    },
    error => {
      // any status >= 3xx
      console.log('bad response: ', error);
    },
  );
  apiCallRequestInterceptor = apiCall.interceptors.request.use(
    config => {
      const language = lang === 'ru-RU' ? 'ru-RU' : lang;
      if (config.headers) config.headers['X-Localization'] = language;

      return config;
    },
    error => {
      // any status >= 3xx
      console.log('bad response: ', error);
    },
  );
};

export const apiGet = <T>(url: string, params?: object, options: AxiosRequestConfig = {}) => {
  return apiCall.get<T>(url, {
    params,
    ...getBasicHeader(),
    ...options,
  });
};

export const apiDelete = <T>(url: string, params?: object, options: AxiosRequestConfig = {}) => {
  return apiCall.delete<T>(url, {
    params,
    ...getBasicHeader(),
    ...options,
  });
};

export const apiPost = <T>(url: string, data?: object, options: AxiosRequestConfig = {}) => {
  return apiCall.post<T>(url, data, {
    ...getBasicHeader(),
    ...options,
  });
};

export const apiPut = <T>(url: string, data?: object, options: AxiosRequestConfig = {}) => {
  return apiCall.put<T>(url, data, {
    ...getBasicHeader(),
    ...options,
  });
};
