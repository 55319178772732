import { createAsyncThunk } from '@reduxjs/toolkit';
import download from 'downloadjs';
import { RootState } from 'app/store/reducer';
import { OrderRenders } from 'app/types/renders';
import * as RenderRequestService from 'app/services/renderRequest';

const downloadOrderRenders = createAsyncThunk<
  void,
  OrderRenders,
  { state: RootState }
>('renders/fetchOrderRenders', async (comment: OrderRenders, { getState }) => {
  const { renderRequest } = getState();
  const id = renderRequest.lastOrderId || 0;
  const { data } = await RenderRequestService.getOrderRenders({ comment, id });

  download(data.data, data.name, 'application/zip');
});

export { downloadOrderRenders };
