import styled from 'styled-components';
import { Badge, Button, Carousel, Input, Tabs, Row, Col } from 'antd';
import ReactImageMagnify from 'react-image-magnify';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const CountWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

export const CountAction = styled(Button).attrs(props => ({
  ...props,
  type: 'primary',
  size: 'small',
}))`
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #414042;
`;

export const CountInput = styled(Input).attrs(props => ({
  size: 'small',
  ...props,
}))`
  &&& {
    max-width: 48px;
    text-align: center;
  }
`;

export const CustomProductWrapper = styled.div`
  border: 1px solid var(--c-field-border);
  border-radius: 5px;
  padding: 5px;
  &&& .ant-table th {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-color: transparent;
  }
  &&& .ant-table td {
    border-bottom-color: transparent;
  }
`;

export const Property = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 1em;
  margin-bottom: 10px;
`;

export const PropertyValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;
export const PropertyDivider = styled.div`
  height: 1px;
  border-bottom: 1px dashed #eee;
  width: 100%;
  position: relative;
  top: 50%;
  margin: 0px 5px;
  margin-top: -1px;
  font-size: var(--fz);
`;

export const ProductCarousel = styled(Carousel)`
  &&& {
    user-select: none;
    padding-bottom: 90px;
    .slick-dots {
      overflow-y: auto;
      justify-content: flex-start;
    }
    .slick-dots li {
      border: 1px solid #eee;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
    .slick-dots li.slick-active {
      width: 50px;
      border-color: #d77721;
    }
  }
`;

export const Faker = styled.img`
  opacity: 1;
  position: relative;
  z-index: -1;
`;

export const CoveringButton = styled(Button)<{ active?: boolean }>`
  &&& {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--black-3)')};
    color: ${({ active }) => (active ? 'var(--primary)' : 'var(--black-3)')};
  }
  .ant-badge {
    margin-left: 5px;
  }
  .ant-badge-count {
    background-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--black-3)')};
  }
`;
export const IconLetter = styled.div`
  margin-right: 0.3em;
  display: flex;
  align-items: center;
  svg {
    height: 1em;
  }
`;
export const QuestionIconWrap = styled.span`
  color: #414141;
  width: 1.45em;
  display: flex;
  align-items: center;
  svg {
    width: 100%;
  }
`;
export const Coverings = styled.div`
  padding: 16px 0 6px;
`;

export const BlockedContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    ` background-color: rgba(242, 242, 242, 0.8);
      opacity: 0.2;
      user-select: none;
      position: relative;
      :after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }`}
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
`;

export const FooterShape = styled.div`
  background-color: #f4f4f4;
  padding: 8px 16px;
  color: #4f4f4f;
  margin-right: 15px;
`;

export const ImageMagnify = styled(ReactImageMagnify)`
  &&&&& {
    min-width: 100%;
  }
`;

export const CountInBasker = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Warning = styled.div`
  margin: 2.4em 0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 1.2em 1.7em;
  position: relative;
`;
export const QuestionCircleOutlinedStyled = styled(QuestionCircleOutlined)`
  position: absolute;
  left: -0.5em;
  top: -0.5em;
  color: #d0d0d0;
  background-color: white;
  padding: 0.3em;
`;

export const ProductCounter = styled(Badge)`
  .ant-badge-count {
    background-color: #767676;
  }
`;

export const OrderTypes = styled(Tabs)`
  &&& {
    .ant-tabs-nav {
      background-color: #fff;
    }

    .ant-tabs-nav::before {
      border-bottom-color: #212529;
    }

    .ant-tabs-nav .ant-tabs-tab {
      border-color: #e0e0e0;
      border-bottom-color: #212529;
      background-color: transparent;
      :not(:last-of-type) {
        margin-right: 0;
      }

      &:hover,
      &:focus,
      &:active {
        color: #767676;
      }
    }

    .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
      border-color: #212529;
      border-bottom-color: #fff;
      color: #4f4f4f;
      max-width: 230px;
      white-space: pre-line;
      :not(:last-of-type) {
        margin-right: 0;
      }

      ${ProductCounter} {
        .ant-badge-count {
          background-color: #4f4f4f;
        }
      }
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-weight: bold;
      color: #767676;
      &:hover,
      &:focus,
      &:active {
        color: #767676;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #4f4f4f;
      &:hover,
      &:focus,
      &:active {
        color: #4f4f4f;
      }
    }
  }
`;
export const CellWidthIcon = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
`;

export const InBasket = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.28em;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 50px;
  font-weight: bold;
  padding: 0.7em 1.14em;
  cursor: pointer;
`;

export const Balance = styled(Col)`
  text-align: right;
`;

export const ContentFooter = styled(Row)`
  &&& {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 10px;
  }
`;

export const ProductDescription = styled.div`
  white-space: pre-wrap;
`;
