import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { FormValues } from 'features/AdditionalProducts/components/Rhodium/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';

export const useAdditionalProducts = () => {
  const state = useSelector((state: RootState) => state.additionalProducts);
  const productBadges = useSelector((state: RootState) => state.auth.productBadges);

  const dispatch: AppDispatch = useDispatch();

  const requestMetadata = () => {
    dispatch(actions.fetchMetadata());
  };

  const requestProducts = () => {
    dispatch(actions.fetchProducts({ withPagination: 0 }));
  };

  const requestAll = async () => {
    await dispatch(actions.fetchMetadata());
    await dispatch(actions.fetchProducts({ withPagination: 0 }));
  };

  const postClaim = async (values: FormValues) => {
    const resultAction = await dispatch(actions.sendClaim(values));
    if (actions.sendClaim.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload;
    }
  };

  return {
    state,
    productBadges,
    requestMetadata,
    requestProducts,
    requestAll,
    postClaim,
  };
};
