import React from 'react';
import { Tooltip } from 'antd';
import { question } from 'components/KrasUIKit/icons';
import * as S from '../styled';
import { CustomOffer } from '../CustomOffer';
import { Count } from '../Count';
import { number } from '../../../helpers/format';
import { useTranslation } from 'react-i18next';
import { DetailOrderType } from '..';
import { AddingOffer } from 'app/types/cart';
import { MassData } from 'app/types/products';
import round from 'lodash.round';
import { useFormatSize } from 'hooks/useFormatSize';

export interface Props {
  massData: MassData;
  balance: number;
  orderType: DetailOrderType;
  onChange: (value: [number, number]) => void;
  current?: AddingOffer;
  article: string;
  isDimensioned: boolean;
}

export const CustomLength: React.FC<Props> = ({ massData, onChange, isDimensioned, current, article }) => {
  const { t } = useTranslation('productDetail');
  const { length = 10, count = 0 } = current || {};
  const slope = massData.slope || 1;
  const intercept = massData.intercept || 0;

  const getWeight = () => round(length * slope + intercept, 2);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formatValue = Math.abs(parseFloat(value.replace(/\D/g, '')));
    const len = !Number.isNaN(formatValue) ? formatValue : 0;
    onChange([len, count]);
  };

  const changeCountHandler = (value: number) => {
    onChange([length, value]);
  };

  const { getUnit } = useFormatSize();

  const columns = [
    {
      title: t('orderParameters:size'),
      dataIndex: 'size',
      key: 'size',
      render: () => (
        <>
          <S.CountInput
            value={length}
            style={{ minWidth: '70px' }}
            onChange={changeHandler}
            suffix={getUnit({ isDimensioned })}
          />
        </>
      ),
    },
    {
      title: () => (
        <S.CellWidthIcon>
          {t('orderParameters:weightOnly')}
          <Tooltip title={t('weightWarning')} placement="bottom">
            <S.QuestionIconWrap>{question}</S.QuestionIconWrap>
          </Tooltip>
        </S.CellWidthIcon>
      ),
      dataIndex: 'weight',
      key: 'weight',
      render: () => `${number(getWeight())} ${t('units:g')}.`,
    },
    {
      title: t('orderParameters:count'),
      dataIndex: 'count',
      key: 'count',
      render: () => {
        // const max =
        //   orderType === 'products_coil'
        //     ? Math.floor(balance / getWeight()) + count
        //     : Infinity;
        return <Count article={article} onChange={changeCountHandler} count={count} max={Infinity} />;
      },
    },
  ];

  const data = [
    {
      key: '1',
      size: 18,
      weight: 15.09,
      count: 10,
    },
  ];
  return (
    <CustomOffer dataSource={data} columns={columns} title={t('customLength')} message={t('possibilityСhooseLength')} />
  );
};
