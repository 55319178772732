import styled from 'styled-components';
import { Modal as AntModal, Col, Row } from 'antd';

export const Modal = styled(AntModal)`
  width: 857px !important;

  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    top: 16px;
    right: 17px;
  }
`;
