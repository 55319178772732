import React, { useEffect, useState } from 'react';
import { Router as CustomRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Root } from './Root';
import { routes, pathsWithoutLayout } from 'app/pages/routes';
import { HeaderContainer } from 'containers/HeaderContainer';
import { FeedbackFormContainer } from '../../containers/FeedbackFormContainer';
import { FooterContainer } from '../../containers/FooterContainer';
import { AccountDisabledContainer } from 'containers/AccountDisabledContainer';
import { ProductDetailModalContainer } from '../../containers/ProductDetailModalContainer';
import { BannersContainer } from '../../containers/BannersContainer';
import { RootState } from 'app/store/reducer';
import { ChangePasswordModal } from 'components/Modals/ChangePassword';

import * as S from './styled';
import { Page503 } from 'app/pages/503';
import { UpdateCategory } from 'components/Modals/UpdateCategory';
import { PageLogger } from 'components/PageLogger';
import { history } from './history';
import { PromotionsModal } from 'features/PromotionsBanner';
import { ConsultationFormContainer } from 'containers/ConsultationFormContainer';
import { CookieBar } from 'components/Layout/CookieBar';

export const VisibleWrapper: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const needLayout = !pathsWithoutLayout.includes(pathname);

  return needLayout ? <>{children}</> : null;
};

export const Router: React.FC = () => {
  const { loading, show503, user } = useSelector((store: RootState) => store.auth);
  const [visibleModal, setVisibleModal] = useState(false);
  const isNotAuthManager = !sessionStorage.getItem('jwtAccess');

  useEffect(() => {
    if (isNotAuthManager && user && user.passwordResetRequired) {
      setVisibleModal(true);
    }
  }, [user]);

  if (loading) {
    return <S.Spin size="large" />;
  }

  if (show503) return <Page503 />;

  return (
    <CustomRouter history={history}>
      <HeaderContainer />
      {/* <VisibleWrapper>
      </VisibleWrapper> */}
      <S.Wrapper>
        <Root routes={routes} />
        {/*
          Проблема с лаяотом
          Todo:
            решить через clearfix(кастомный класс)
            или через нативные средства библиотеки
          */}
        <br />
      </S.Wrapper>
      <VisibleWrapper>
        <FooterContainer />
      </VisibleWrapper>
      <FeedbackFormContainer />
      <ConsultationFormContainer />
      <AccountDisabledContainer />
      <PromotionsModal />
      <ProductDetailModalContainer />
      <BannersContainer />
      <ChangePasswordModal
        forcedPasswordСhange={true}
        hideModal={() => {
          setVisibleModal(false);
        }}
        visible={visibleModal}
      />
      <PageLogger />
      <UpdateCategory />
      <CookieBar />
    </CustomRouter>
  );
};
