import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Link = styled(ReactRouterLink)`
  display: inline-block;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  color: #2f3133;
  border-bottom: 1px solid #d7dce0;
`;

export const Discount = styled.span`
  text-align: right;
`;

export const CompanyName = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 92px;
  cursor: pointer;
`;
