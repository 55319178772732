import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ProductCategoryEntity } from 'app/types/productCategories';

import {
  createProductCategory,
  getProductCategories,
  updateProductCategory,
  deleteProductCategory,
} from './actions';

export interface InitialState {
  loading: boolean;
  list: ProductCategoryEntity[];
}

export const initialState: InitialState = {
  loading: true,
  list: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('admin/productCategories/') &&
  action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('admin/productCategories/') &&
  action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('admin/productCategories/') &&
  action.type.endsWith('/fulfilled');

const slice = createSlice({
  name: 'admin/productCategories',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(getProductCategories.fulfilled, (state, { payload }) => {
      state.list = payload.items;
    });

    addCase(updateProductCategory.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(({ id }) => id === payload.item.id);

      if (index > -1) {
        state.list[index] = payload.item;
      }
    });

    addCase(createProductCategory.fulfilled, (state, { payload }) => {
      state.list.push(payload.item);
    });

    addCase(deleteProductCategory.fulfilled, (state, { payload }) => {
      state.list = state.list.map(item => {
        const parentId = item.parentId === payload ? null : item.parentId;
        return {
          ...item,
          parentId,
        };
      });
      const index = state.list.findIndex(({ id }) => id === payload);

      if (index > -1) {
        state.list.splice(index, 1);
      }
    });

    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );

    addMatcher(
      (action): action is AnyAction => {
        return isPendingAction(action);
      },
      state => {
        state.loading = true;
      },
    );
  },
});

export default slice.reducer;
