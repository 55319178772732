import { DeleteOutlined } from '@ant-design/icons';
import { Typography, Button, Col, Divider, InputNumber, Row, Alert } from 'antd';
import { RootState } from 'app/store/reducer';
import { Tabs } from 'components/KrasUIKit';
import { TabCount } from 'components/KrasUIKit/components/Tabs/styled';
import { removeWires, setWires, updateWires } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { WireItem } from 'features/AdditionalProducts/types/OrderItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '../Tooltip';

interface AlloyTabsProps {
  sortedByAlloy: ProductTab[];
  onTabChange?: (tabIndex: number) => void;
}

export const AlloysTabs = ({ sortedByAlloy, onTabChange }: AlloyTabsProps) => {
  const { t } = useTranslation(['additional']);

  const [activeTab, setActiveTab] = useState<string | number>(1);
  const dispatch = useDispatch();
  const { wire } = useSelector((state: RootState) => state.additionalProductsOrder);

  const createDiameter = (alloyId: number, diameterKey: string, item: WireItem) => {
    dispatch(
      setWires({
        alloyId,
        diameterKey,
        item,
      }),
    );
  };

  const removeDiameterItem = (alloyId: number, diameterKey: string, index: number) => {
    dispatch(
      removeWires({
        alloyId,
        diameterKey,
        index,
      }),
    );
  };

  const changeThicknessItem = (alloyId: number, diameterKey: string, index: number, item: WireItem) => {
    dispatch(
      updateWires({
        alloyId,
        diameterKey,
        index,
        item,
      }),
    );
  };

  const tabs = sortedByAlloy.map((tab, index) => {
    const alloyItems = wire[tab.alloy.id] ? Object.values(wire[tab.alloy.id]) : [];

    const tabsCount = alloyItems.reduce((acc, diameterGroup) => {
      return acc + diameterGroup.filter(item => item.weight > 0).length;
    }, 0);

    return {
      id: index + 1,
      count: tabsCount,
      title: tab.alloy.name,
      content: (
        <>
          {tab.products.map(product => {
            const {
              features: [minDiameter, maxDiameter, stepDiameter, minDiameter2, maxDiameter2, stepDiameter2],
            } = product;
            const diameterKey = `${minDiameter.value}-${maxDiameter.value}-${stepDiameter.value}-${product.id}-${tab.alloy.id}`;
            const diameterKey2 = `${minDiameter2.value}-${maxDiameter2.value}-${stepDiameter2.value}-${product.id}-${tab.alloy.id}`;

            const diameterItems = wire?.[tab.alloy.id] && wire?.[tab.alloy.id]?.[diameterKey];
            const diameterItems2 = wire?.[tab.alloy.id] && wire?.[tab.alloy.id]?.[diameterKey2];

            const isSameDiameter = diameterKey === diameterKey2;

            const thicknessCount = diameterItems && diameterItems.filter(item => item.weight > 0).length;
            const thicknessCount2 = diameterItems2 && diameterItems2.filter(item => item.weight > 0).length;

            if (!diameterItems) {
              const item = {
                id: product.id,
                alloyId: tab.alloy.id,
                diameter: minDiameter.value,
                weight: 0,
              };
              dispatch(
                setWires({
                  alloyId: tab.alloy.id,
                  diameterKey,
                  index: 0,
                  item,
                }),
              );
            }

            if (!isSameDiameter && !diameterItems2) {
              const item = {
                id: product.id,
                alloyId: tab.alloy.id,
                diameter: minDiameter2.value,
                weight: 0,
              };
              dispatch(
                setWires({
                  alloyId: tab.alloy.id,
                  diameterKey: diameterKey2,
                  index: 0,
                  item,
                }),
              );
            }

            return (
              <div style={{ marginTop: '20px' }} key={product.id}>
                <Row gutter={25}>
                  <Col md={12}>
                    <Typography.Text>
                      {t('diameter', {
                        minDiameter: minDiameter.value,
                        maxDiameter: maxDiameter.value,
                        stepDiameter: stepDiameter.value,
                      })}
                      <Tooltip>
                        <>
                          {t('diameterTooltip', {
                            diameterList: `${minDiameter.value}; ${minDiameter.value +
                              stepDiameter.value}; ${minDiameter.value + stepDiameter.value * 2}; ... ${
                              maxDiameter.value
                            }`,
                          })}
                        </>
                      </Tooltip>
                      {thicknessCount > 0 ? <TabCount>{thicknessCount}</TabCount> : null}{' '}
                    </Typography.Text>
                    <Divider type="horizontal" style={{ margin: '16px 0' }} />
                    {diameterItems &&
                      diameterItems.map((item, index) => {
                        return (
                          <Row
                            key={`${diameterKey}-${item.diameter}-${index}-${item.id}`}
                            gutter={20}
                            style={{ marginBottom: '20px' }}
                            align="middle"
                          >
                            <Col md={11}>
                              <InputNumber
                                value={
                                  item.diameter > maxDiameter.value
                                    ? maxDiameter.value.toFixed(2)
                                    : item.diameter.toFixed(2)
                                }
                                min={minDiameter.value}
                                max={maxDiameter.value}
                                step={stepDiameter.value}
                                style={{ width: '100%' }}
                                onChange={value => {
                                  changeThicknessItem(tab.alloy.id, diameterKey, index, {
                                    ...item,
                                    diameter: value,
                                  });
                                }}
                              />
                            </Col>
                            <Col md={11}>
                              <InputNumber
                                placeholder={t('table.setWeight')}
                                defaultValue={item.weight || undefined}
                                min={0}
                                style={{ width: '100%' }}
                                onChange={value => {
                                  changeThicknessItem(tab.alloy.id, diameterKey, index, {
                                    ...item,
                                    weight: value || 0,
                                  });
                                }}
                              />
                            </Col>
                            {diameterItems.length > 1 && (
                              <DeleteOutlined onClick={() => removeDiameterItem(tab.alloy.id, diameterKey, index)} />
                            )}
                          </Row>
                        );
                      })}
                    <Button
                      style={{
                        marginBottom: '40px',
                      }}
                      shape="round"
                      onClick={() => {
                        const item = {
                          id: product.id,
                          alloyId: tab.alloy.id,
                          diameter: diameterItems.length
                            ? diameterItems[diameterItems.length - 1].diameter + stepDiameter.value
                            : minDiameter2.value,
                          weight: 0,
                        };
                        createDiameter(tab.alloy.id, diameterKey, item);
                      }}
                    >
                      {t('addThickness')}
                    </Button>
                  </Col>
                  {!isSameDiameter && (
                    <Col md={12}>
                      <Typography.Text>
                        {t('diameter', {
                          minDiameter: minDiameter2.value,
                          maxDiameter: maxDiameter2.value,
                          stepDiameter: stepDiameter2.value,
                        })}
                        <Tooltip>
                          <>
                            {t('diameterTooltip', {
                              diameterList: `${minDiameter2.value}; ${minDiameter2.value +
                                stepDiameter2.value}; ${minDiameter2.value + stepDiameter2.value * 2}; ... ${
                                maxDiameter2.value
                              }`,
                            })}
                          </>
                        </Tooltip>
                        {thicknessCount2 > 0 ? <TabCount>{thicknessCount2}</TabCount> : null}
                      </Typography.Text>
                      <Divider type="horizontal" style={{ margin: '16px 0' }} />
                      {diameterItems2 &&
                        diameterItems2.map((item, index) => {
                          return (
                            <Row
                              key={`${diameterKey2}-${item.diameter}-${index}-${item.id}`}
                              gutter={20}
                              style={{ marginBottom: '20px' }}
                              align="middle"
                            >
                              <Col md={11}>
                                <InputNumber
                                  value={
                                    item.diameter > maxDiameter2.value
                                      ? maxDiameter2.value.toFixed(2)
                                      : item.diameter.toFixed(2)
                                  }
                                  min={minDiameter2.value}
                                  max={maxDiameter2.value}
                                  step={stepDiameter2.value}
                                  style={{ width: '100%' }}
                                  onChange={value => {
                                    changeThicknessItem(tab.alloy.id, diameterKey2, index, {
                                      ...item,
                                      diameter: value,
                                    });
                                  }}
                                />
                              </Col>
                              <Col md={11}>
                                <InputNumber
                                  defaultValue={item.weight || undefined}
                                  min={0}
                                  style={{ width: '100%' }}
                                  onChange={value => {
                                    changeThicknessItem(tab.alloy.id, diameterKey2, index, {
                                      ...item,
                                      weight: value || 0,
                                    });
                                  }}
                                />
                              </Col>
                              <Col md={2}>
                                {diameterItems2.length > 1 && (
                                  <DeleteOutlined
                                    onClick={() => removeDiameterItem(tab.alloy.id, diameterKey2, index)}
                                  />
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                      <Button
                        style={{
                          marginBottom: '40px',
                        }}
                        shape="round"
                        onClick={() => {
                          const item = {
                            id: product.id,
                            alloyId: tab.alloy.id,
                            diameter: diameterItems2.length
                              ? diameterItems2[diameterItems2.length - 1].diameter + stepDiameter2.value
                              : minDiameter2.value,
                            weight: 0,
                          };
                          createDiameter(tab.alloy.id, diameterKey2, item);
                        }}
                      >
                        {t('addThickness')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
        </>
      ),
    };
  });

  const getTabContent = (tabIndex: string | number) => {
    const neededTab = tabs.find(tab => tab.id === tabIndex);
    if (!tabIndex || !neededTab) return null;
    return neededTab.content;
  };

  const handleTabChange = (tabIndex: string | number) => {
    setActiveTab(tabIndex);
    onTabChange && onTabChange(Number(tabIndex));
  };

  return (
    <>
      <Alert
        message=""
        description={t('alert', { silver: 300, gold: 150 })}
        type="info"
        showIcon
        style={{
          margin: '20px 0',
        }}
      />
      <Tabs tabs={tabs} active={activeTab} onChange={handleTabChange} />
      {getTabContent(activeTab)}
    </>
  );
};
