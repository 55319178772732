import React from 'react';
import { Link } from 'react-router-dom';
// import { sendEvent, actionTypes } from 'logger';

type OrderNumberProps = {
  id: number;
  type: 'order' | 'autoOrder';
};

export const OrderNumber = ({ id, type }: OrderNumberProps) => {
  const linkVariants = {
    order: {
      link: 'orders',
      name: 'Заказ',
    },
    autoOrder: {
      link: 'auto-orders',
      name: 'Автозаказ',
    },
  };

  return (
    <Link
      to={`/personal/${linkVariants[type].link}/${id}`}
      // onClick={() =>
      //   sendEvent({
      //     ActionType: actionTypes.ORDERS_CLICK_ORDER,
      //     OrderId: numberOrder.toString(),
      //   })
      // }
    >
      {linkVariants[type].name}&nbsp;{id}
    </Link>
  );
};
