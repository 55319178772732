import React from 'react';
import { Tabs } from 'antd';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { FileTextOutlined, FolderOpenOutlined, UserOutlined } from '@ant-design/icons';
import { arrowReturn, IconLetter } from '../KrasUIKit/icons';
import { useTranslation } from 'react-i18next';
import { DocumentsContainer } from 'containers/Documents.container';
import { PersonalContainer } from 'containers/PersonalContainer';
import { Orders } from 'features/Orders';
import { Order } from 'features/Order';
import { ReturnContainer } from 'containers/ReturnContainer';
import { sendEvent, actionTypes } from 'logger';
import { AutoOrder, AutoOrders } from 'features/AutoOrders';
import { useIsAutoOrderVisible } from 'hooks/useIsAutoOrderVisible';

const { TabPane } = Tabs;

type GetPanesProps = {
  isAutoOrderVisible?: boolean;
};

export const getPanes = ({ isAutoOrderVisible }: GetPanesProps) => {
  const base = [
    {
      to: '/personal/orders',
      name: 'myOrders',
      icon: <FileTextOutlined style={{ marginRight: '5px' }} />,
      sort: 100,
    },
    {
      to: '/personal/auto-orders',
      name: 'autoOrders',
      icon: <FileTextOutlined style={{ marginRight: '5px' }} />,
      sort: 115,
      hideInDropDown: !isAutoOrderVisible,
      hideInPanes: !isAutoOrderVisible,
    },
    {
      to: '/personal/return',
      name: 'return',
      icon: (
        <IconLetter size="1.1em" style={{ marginRight: '5px' }}>
          {arrowReturn}
        </IconLetter>
      ),
      sort: 120,
      hideInDropDown: true,
    },
    {
      to: '/personal/documents',
      name: 'documents',
      icon: <FolderOpenOutlined style={{ marginRight: '5px' }} />,
      sort: 200,
    },
    {
      to: '/personal/registration',
      name: 'registrationData',
      icon: <UserOutlined style={{ marginRight: '5px' }} />,
      sort: 300,
    },
  ];

  return base;
};

const Personal: React.FC = () => {
  const { t } = useTranslation(['personal']);
  const location = useLocation();
  const isAutoOrderVisible = useIsAutoOrderVisible();

  const panes = getPanes({ isAutoOrderVisible }).filter(({ hideInPanes }) => !hideInPanes);
  const activeKey = panes.find(({ to }) => location.pathname.includes(to))?.to;

  return (
    <div>
      <Tabs size="large" activeKey={activeKey}>
        {panes
          .slice()
          .sort((a, b) => a.sort - b.sort)
          .map(({ to, name, icon }) => (
            <TabPane
              style={{ padding: 0 }}
              tab={
                <Link
                  to={to}
                  onClick={() =>
                    sendEvent({
                      ActionType: actionTypes.PERSONAL_CLICK_TAB,
                      ContextKey: name,
                    })
                  }
                >
                  {icon}
                  {t(name)}
                </Link>
              }
              key={to}
            />
          ))}
      </Tabs>
      <Switch>
        {isAutoOrderVisible && (
          <Route exact path="/personal/auto-orders">
            <AutoOrders />
          </Route>
        )}
        {isAutoOrderVisible && (
          <Route exact path="/personal/auto-orders/:id">
            <AutoOrder />
          </Route>
        )}
        <Route path="/personal/documents">
          <DocumentsContainer />
        </Route>
        <Route path="/personal/return">
          <ReturnContainer />
        </Route>
        <Route path="/personal/registration">
          <PersonalContainer />
        </Route>
        <Route exact path="/personal/orders">
          <Orders />
        </Route>
        <Route exact path="/personal/orders/:id">
          <Order />
        </Route>
        <Route path="*">
          <Redirect to="/personal/orders" />
        </Route>
      </Switch>
    </div>
  );
};

export default Personal;
