import React from 'react';
import { Form, FormItemProps, Select as SelectAntd } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface Props extends SelectProps<string> {
  name: string;
  label: string;
  formItemProps?: FormItemProps;
}

export const Select: React.FC<Props> = ({
  name,
  label,
  formItemProps,
  children,
  ...props
}) => {
  return (
    <Form.Item name={name} label={label} {...formItemProps}>
      <SelectAntd {...props}>{children}</SelectAntd>
    </Form.Item>
  );
};
