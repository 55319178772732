import React from 'react';
import { Form } from 'antd';
import {
  Autocomplete as AutocompleteComponent,
  Props as PropsAutocomplete,
} from 'components/Autocomplete';

interface Props extends PropsAutocomplete {
  name: string;
  label: string;
}

export const Autocomplete: React.FC<Props> = ({ name, label, ...props }) => {
  return (
    <Form.Item name={name} label={label}>
      <AutocompleteComponent {...props} />
    </Form.Item>
  );
};
