import { createAsyncThunk } from '@reduxjs/toolkit';
import { DemoUsersService } from 'app/services/admin/demoUsers';
import * as T from 'app/types/demoUsers';
import { message as antMessage } from 'antd';

export const getUsers = createAsyncThunk('demoUsers/getUsers', async (search: string, { rejectWithValue }) => {
  const { data, success, message } = await DemoUsersService.getMany(search);
  if (success && data) {
    return data;
  } else {
    antMessage.error(message);
    return rejectWithValue(data);
  }
});

export const activateUser = createAsyncThunk(
  'demoUsers/updateUser',
  async (user: T.ActivateUserDto, { rejectWithValue }) => {
    const { data, success, message } = await DemoUsersService.activate(user);
    if (success && data) {
      antMessage.success(message);
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const createUser = createAsyncThunk(
  'demoUsers/createUser',
  async (user: T.CreateUserDto, { rejectWithValue }) => {
    const { data, success, message } = await DemoUsersService.create(user);
    if (success && data) {
      antMessage.success(message);
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
