import React, { useState, useRef, useCallback } from 'react';
import { Image } from 'app/types/products';
import * as S from './styled';
import { actionTypes, sendEvent } from 'logger';

export const ProductSlider: React.FC<{
  main: Image;
  list: Image[];
  lazyLoad?: 'ondemand' | 'progressive';
}> = ({ main, list, lazyLoad = undefined }) => {
  const [dimension, setDimension] = useState<number>(1);
  const [current, setCurrent] = useState(0);
  const dimensionsImage = useRef<HTMLImageElement>(null);
  const ZOOM_WIDTH = 2000;

  const images = [main, ...list];

  const loadHandler = () => {
    const image = dimensionsImage.current;

    if (image) {
      setDimension(image.offsetHeight / image.offsetWidth);
    }
  };

  const handleSlideChange = useCallback((nextIndex: number) => {
    sendEvent({
      ActionType: actionTypes.DETAIL_CLICK_ADDITIONAL_IMAGE,
      DetailAdditionalImageIndex: nextIndex,
    });

    setCurrent(nextIndex);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
        <S.Faker
          ref={dimensionsImage}
          style={{ height: 'auto', maxWidth: '100%', objectFit: 'cover' }}
          src={images[current] ? images[current].preview : ''}
          onLoad={loadHandler}
        />
      </div>
      <S.ProductCarousel
        afterChange={handleSlideChange}
        customPaging={index => {
          return (
            <img
              width={50}
              height={50}
              style={{
                display: 'block',
                objectFit: 'cover',
              }}
              src={images[index] ? images[index].preview : ''}
            />
          );
        }}
        lazyLoad={lazyLoad}
      >
        {images.map(item => (
          <S.ImageMagnify
            key={item.detail}
            smallImage={{
              isFluidWidth: true,
              src: item ? item.preview : '',
            }}
            largeImage={{
              src: item ? item.detail : '',
              width: ZOOM_WIDTH,
              height: ZOOM_WIDTH * dimension,
            }}
            enlargedImagePosition="over"
            enlargedImageStyle={{
              backgroundColor: '#fff',
            }}
          />
        ))}
      </S.ProductCarousel>
    </div>
  );
};
