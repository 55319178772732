import React from 'react';
import logoUrlEn from 'images/svg/eng-grey.svg';
import logoUrlEnWhite from 'images/svg/eng-white.svg';
import logoSmall from 'images/svg/small.svg';
import { LogoImg } from './styled';
import { useWindowWidth } from '@react-hook/window-size';

export interface Props {
  type?: 'normal' | 'inverse';
  scalability?: boolean;
}
const images: { [key in 'normal' | 'inverse']?: string | undefined } = {
  normal: logoUrlEn,
  inverse: logoUrlEnWhite,
};

export const Logo: React.FC<Props> = ({
  type = 'normal',
  scalability = true,
}) => {
  const onlyWidth = useWindowWidth();
  const img = scalability && onlyWidth <= 1200 ? logoSmall : images[type];

  return <LogoImg src={img} alt="Красцветмет - ОАО «Красцветмет»" />;
};

export default Logo;
