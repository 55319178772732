import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

export type ImportLinksProps = {
  onRenderRequest: (params: unknown) => void;
  onDownloadImport: (params: unknown) => void;
  onDownloadExcel: (params: unknown) => void;
};

export const ImportLinks = ({ onRenderRequest, onDownloadImport, onDownloadExcel }: ImportLinksProps) => {
  const { t } = useTranslation(['personal']);
  return (
    <>
      <div>
        <Button
          style={{ paddingLeft: 0, color: 'inherit' }}
          onClick={onDownloadImport}
          type="link"
          icon={<FileTextOutlined />}
        >
          {t('downloadImportOrder')}
        </Button>
      </div>
      <div>
        <Button
          style={{ paddingLeft: 0, color: 'inherit' }}
          onClick={onDownloadExcel}
          type="link"
          icon={<FileTextOutlined />}
        >
          {t('downloadExcel')}
        </Button>
      </div>
      <div>
        <Button
          style={{ paddingLeft: 0, color: 'inherit' }}
          onClick={onRenderRequest}
          type="link"
          icon={<FileTextOutlined />}
        >
          {t('downloadImages')}
        </Button>
      </div>
    </>
  );
};
