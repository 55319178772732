import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { ConsultationData } from 'app/types/consultation';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './slice';

export const useConsultation = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.consultation);

  const requestConsultation = async (data: ConsultationData) => {
    const resultSaveFeedback = await dispatch(actions.requestConsultation(data));
    return actions.requestConsultation.fulfilled.match(resultSaveFeedback);
  };

  return {
    state,
    requestConsultation,
  };
};
