/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Property } from './Property';
import { SectionTitle } from './SectionTitle';
import {
  AdditionalProductParams,
  AdditionalProductParam,
} from '../../app/types/products';
import * as S from './styled';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export interface Props {
  list: AdditionalProductParams;
  fullDescr: string;
}

/* Справочник исключений */
export const Warnings: {
  [key: string]: (param: AdditionalProductParam, t: TFunction) => string;
} = {
  lock: (param, t) => t('lockConfigurationWarning'),
  equipment: (param, t) => t('productPackagingWarning'),
  weight_fluctuations: (param, t) =>
    `${t('actualWeightWarning')} ${param.value} ${t('units:g')}.`,
  chain: (param, t) => t('chainConfigurationWarning'),
};

const getWarningsAndProperties = (
  params: AdditionalProductParams,
  t: TFunction,
) => {
  return params
    .filter(({ show }) => show !== undefined && show !== 0)
    .reduce<{ warnings: string[]; properties: AdditionalProductParams }>(
      (acc, param) => {
        const hasWarning = Boolean(Warnings[param.code]);
        const type = hasWarning ? 'warnings' : 'properties';
        const prop = hasWarning ? Warnings[param.code](param, t) : param;
        return { ...acc, [type]: [...acc[type], prop] };
      },
      { warnings: [], properties: [] },
    );
};
export const Properties: React.FC<Props> = ({ list, fullDescr }) => {
  const { t, i18n } = useTranslation('productDetail');
  const { language } = i18n;
  const isRu = language === 'ru';

  const { warnings, properties } = getWarningsAndProperties(list, t);
  return (
    <>
      {warnings.map((text, index) => (
        <S.Warning key={index}>
          <S.QuestionCircleOutlinedStyled />
          {text}
        </S.Warning>
      ))}
      <SectionTitle>
        {t('features')}{' '}
        <Tooltip
          title={t('featuresDetail')}
          overlayStyle={{ maxWidth: '310px', fontSize: '12px' }}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </SectionTitle>
      {properties.map((property, index) => (
        <Property key={index} {...property} />
      ))}
      {isRu && fullDescr.length !== 0 && (
        <>
          <SectionTitle style={{ marginTop: 15 }}>
            {t('description')}
          </SectionTitle>
          {fullDescr}
        </>
      )}
    </>
  );
};
