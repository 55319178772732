import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { BannersService } from 'app/services/banners';

export const fetchPublicBanners = createAsyncThunk(
  'publicBanners/fetchBanners',
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await BannersService.getPublic(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
