export const typesEn = [
  'Product quality',
  'Assortment',
  'Packing an order',
  'Personal account',
  'The work of a manager',
  'Mailings',
  'The work of a transport company',
  'assortment',
  'Arbitrary',
];

export const typesRu = [
  'Не могу дозвониться',
  'Качество продукции',
  'Ассортимент',
  'Работа менеджера',
  'Личный кабинет',
  'Доставка',
  'Упаковка заказа',
  'Документооборот',
  'Финансовые расчеты',
  'Произвольное',
];

export const sectionsEn = ['Consultation', 'Wish', 'Complaint', 'Gratitude'];

export const sectionsRu = [
  'Консультация',
  'Пожелание',
  'Жалоба',
  'Благодарность',
];
