import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { date } from 'helpers/format';
import { Specification } from 'app/types/orders';

export type SpecificationsProps = {
  specifications: Specification[];
  onDownloadSpecification: (id: number) => void;
};

export const Specifications = ({ specifications, onDownloadSpecification }: SpecificationsProps) => {
  const { t } = useTranslation(['personal']);
  const content = (
    <Fragment>
      {specifications.map(({ id, extension, createdAt, additionalType }) => (
        <div key={id}>
          <Button
            type="link"
            id={String(id)}
            onClick={() => onDownloadSpecification(id)}
            icon={<FileTextOutlined />}
            style={{ paddingLeft: 0, color: 'inherit' }}
          >
            {`${extension.toUpperCase()} ${t('specification')}${
              additionalType ? ` ${t('for')} ${additionalType}` : ''
            } ${t('from')} ${date(createdAt)}`}
          </Button>
        </div>
      ))}
    </Fragment>
  );

  return (
    <Popover content={content}>
      <Button type="link" icon={<FileTextOutlined />} style={{ paddingLeft: 0, color: 'inherit' }}>
        {t('specifications')}
      </Button>
    </Popover>
  );
};
