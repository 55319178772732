import { Feedback, CreatedFeedbacks } from 'app/types/feedback';
import { Result } from 'app/types/api';
import { apiPost, apiGet } from './connect';

export type SendFeedbackResult = Result<Feedback | []>;
export type FeedbacksResult = Result<CreatedFeedbacks | []>;

export const sendFeedback = async (form: Feedback) => {
  try {
    const { data } = await apiPost<SendFeedbackResult>('/feedback', form);
    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};

export const getFeedbacks = async () => {
  try {
    const { data } = await apiGet<FeedbacksResult>('/feedback');

    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};
