import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';

export const Title = styled.h5`
  margin: 0;
  margin-bottom: 4px;
  width: calc(100% - 24px);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #212529;
`;

export const SecondaryText = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #767676;
`;

export const Text = styled.p<{
  fontSize?: string;
  lineHeight?: string;
  color?: string;
  mb?: string;
}>`
  margin: 0;
  margin-bottom: ${({ mb }) => mb ?? '0'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: ${({ lineHeight }) => lineHeight ?? '100%'};
  color: ${({ color }) => color ?? 'inherit'};
`;

export const Head = styled.div`
  position: relative;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const Divider = styled(AntDivider)`
  margin: 16px 0 !important;
  border-color: var(--c-field-border) !important;
`;

export const StartContainer = styled.div`
  min-width: 325px;
`;
