import { useCallback } from 'react';

/**
 * Хук позволяющий валидировать поля ввода
 * @param {passwordRequirements: Rule[]} массив объектов с правилами и сообщениями, которые нужно выводить
 * @return {string[]} массив code из Rule[], для пунктов, по которым прошла валидация
 * @example Пример использования хука для создания валидатора для формы antd:
 *  const validator = async (_: RuleObject, value: string) => {
      const validatedFields = validateByAllRules(value);
      if (validatedFields.length !== passwordRequirements.length) {
        throw new Error('Error');
      }
    };
 */

export type Rule<T> = {
  code: string;
  validator: (arg: T) => boolean;
};

export const useValidation = <T>(rules: Rule<T>[]) => {
  const validateByAllRules = useCallback(
    (value: T) => {
      const validationInfo = rules.reduce<string[]>((codes, rule) => {
        if (!value) return [...codes];
        if (!rule.validator(value)) {
          return [...codes, rule.code];
        }
        return [...codes];
      }, []);
      return validationInfo;
    },
    [rules],
  );
  return { validateByAllRules };
};
