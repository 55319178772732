import { Tabs } from 'components/KrasUIKit';
import { SuccessIcon as FormSuccessIcon } from 'components/Modals/ConsultationForm/styled';
import { ProductSlider } from 'components/ProductDetail/ProductSlider';
import { ProductCarousel } from 'components/ProductDetail/styled';
import styled from 'styled-components';

export const Paragraph = styled.p`
  margin-bottom: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
`;

export const Title = styled(Paragraph)`
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 700;
  color: #212529;
`;

export const FormWrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 15;
  width: 100%;

  &:before {
    position: absolute;
    left: calc((-100vw + 100%) / 2);
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(99, 111, 117, 0.04), 0px -4px 16px rgba(99, 111, 117, 0.08);
    content: '';
  }
`;

export const SuccessIcon = styled(FormSuccessIcon)`
  margin: 0 auto 20px;
`;

export const TableImg = styled.img`
  margin-left: 30px;
  width: 40px;
  height: 40px;
`;
