import React from 'react';
import Tooltip from 'rc-tooltip';
export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  placement?: string;
  description?: string;
}
export const Wrapper: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Tooltip
      {...props}
      placement={props.placement}
      trigger={['hover']}
      destroyTooltipOnHide
      overlayClassName={className}
      overlay={
        <div>
          {props.description ? (
            <span>
              <b>{props.title}</b>
            </span>
          ) : (
            <span>{props.title}</span>
          )}
          <span>{props.description}</span>
        </div>
      }
    >
      <span>Hello</span>
    </Tooltip>
  );
};
