import { Result } from 'app/types/api';
import { apiGet, apiPost } from '../connect';
import { UserFullInfoEntity, CreateUserDto, ActivateUserDto } from 'app/types/demoUsers';
import { PaginationNew } from 'app/types/pagination';

export type GetManyResponse = Result<{ items: UserFullInfoEntity[]; meta: { pagination: PaginationNew } }>;

// TODO: implement proper error handling mechanism
class DemoUsersServiceFactory {
  public async getMany(search: string): Promise<GetManyResponse> {
    try {
      const { data } = await apiGet<GetManyResponse>(`/users/demo/${search}`);

      return data;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return error.response.data as Result;
    }
  }
  public async activate({ id, ...newData }: ActivateUserDto) {
    try {
      const { data } = await apiPost<Result<UserFullInfoEntity>>(`/users/demo/activate/${id}`, newData);
      return data;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return error.response.data as Result;
    }
  }

  public async create(user: CreateUserDto) {
    try {
      const { data } = await apiPost<Result<UserFullInfoEntity>>('/users/demo', user);
      return data;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return error.response.data as Result;
    }
  }
}

export const DemoUsersService = new DemoUsersServiceFactory();
