/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { ProductModal } from 'components/Modals/ProductModal';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { useCategories } from 'app/ducks/categories';
import { useLocation } from 'react-router-dom';
import { useCart, flatPositionsSelector } from 'app/ducks/cart';
import {
  AddingOffer,
  AddingProduct,
  CartPosition,
  OrderType as TypeOrder,
} from 'app/types/cart';
import { Product } from 'app/types/products';
import { unwrapResult } from '@reduxjs/toolkit';

const getOfferCountInCart = (
  id: number,
  position: CartPosition | undefined,
  currentOfferType?: TypeOrder,
) => {
  if (!position) return 0;
  const offer = position.offers.find(
    ({ offerId, positions }) =>
      offerId === id &&
      (currentOfferType ? positions.orderType === currentOfferType : true),
  );
  return offer?.count || 0;
};

const getPositionsByOrderType = (positionst: CartPosition[]) => ({
  products_coil: positionst.find(
    ({ orderType }) => orderType === 'products_coil',
  ),
  products_production: positionst.find(
    ({ orderType }) => orderType === 'products_production',
  ),
  products_finished: positionst.find(
    ({ orderType }) => orderType === 'products_finished',
  ),
  products_finished_self_marked: positionst.find(
    ({ orderType }) => orderType === 'products_finished_self_marked',
  ),
  union: positionst.find(({ orderType }) => orderType === 'union'),
  waiting_list: positionst.find(
    ({ orderType }) => orderType === 'waiting_list',
  ),
});

const mapBasketOnProduct = (
  cart: CartPosition[],
  productId: number | null,
  product: Product | null,
) => {
  const filteredPositions = cart.filter(
    ({ productId: id }) => id === productId,
  );
  const {
    waiting_list,
    products_production,
    products_coil,
    products_finished,
    products_finished_self_marked,
    union,
  } = getPositionsByOrderType(filteredPositions);

  if (product) {
    const newProduct = {
      ...product,
    };
    newProduct.offers = product.offers.map(offer => ({
      ...offer,
      countInWaitingList: getOfferCountInCart(offer.id, waiting_list),
      countInProduction: getOfferCountInCart(offer.id, products_production),
      countInCoils:
        getOfferCountInCart(offer.id, products_coil) +
        getOfferCountInCart(offer.id, union, 'products_coil'),
      countInFinished:
        getOfferCountInCart(offer.id, products_finished) +
        getOfferCountInCart(offer.id, union, 'products_finished'),
      countInSelfMark: getOfferCountInCart(
        offer.id,
        products_finished_self_marked,
      ),
    }));

    return newProduct;
  }
  return product;
};
export type OrderTypeCart = {
  [key: number]: number;
  coil: number;
  custom: AddingOffer[];
};

export type OrderType = {
  products_production?: OrderTypeCart;
  products_finished?: OrderTypeCart;
  products_coil?: OrderTypeCart;
  products_finished_self_marked?: OrderTypeCart;
};

export const ProductDetailModalContainer: React.FC = () => {
  const { closeProductDetail, state: categories } = useCategories();
  const [order, setOrder] = useState<OrderType | null>(null);

  const { addToCart, state: cart } = useCart();
  const { productDetail, auth, contracts } = useSelector(
    (state: RootState) => state,
  );

  const activeContract = contracts.contracts.find(({ active }) => active);
  const isOneContract = contracts.contracts.length === 1;
  const [wasAdded, setWasAdded] = useState<null | 'waiting' | 'cart'>(null);

  const positions = useSelector(flatPositionsSelector);
  const location = useLocation();

  useEffect(() => {
    closeProductDetail();
  }, [location]);

  useEffect(() => {
    if (order) {
      setOrder(null);
      setWasAdded(null);
    }
  }, [productDetail]);

  const closeModalHandler = () => {
    closeProductDetail();
  };

  const addToCardHandler = async (
    product: Omit<AddingProduct, 'product_id'>,
  ) => {
    type DetailOrderType =
      | 'products_production'
      | 'products_finished'
      | 'products_coil'
      | 'products_finished_self_marked';

    const isOrderType = (x: any): x is DetailOrderType => {
      const types: DetailOrderType[] = [
        'products_production',
        'products_finished',
        'products_coil',
        'products_finished_self_marked',
      ];

      return types.includes(x);
    };

    if (productDetail.id) {
      const resultAction = await addToCart({
        product_id: productDetail.id,
        ...product,
      });
      const { success } = unwrapResult(resultAction);
      if (success) {
        if (isOrderType(product.order_type)) {
          setOrder({ ...order, [product.order_type]: undefined });
        } else if (product.order_type === 'waiting_list') {
          setOrder({ ...order, products_production: undefined });
        }
        setWasAdded(isOrderType(product.order_type) ? 'cart' : 'waiting');
      }
    }
  };

  return (
    <ProductModal
      addingToCart={cart.loading}
      product={mapBasketOnProduct(
        positions,
        productDetail.id,
        productDetail.product,
      )}
      visible={categories.productModal}
      onCancel={closeModalHandler}
      loading={productDetail.loading}
      login={auth.login}
      onAddToCart={addToCardHandler}
      order={order}
      setOrder={setOrder}
      activeContract={activeContract}
      wasAdded={wasAdded}
      setWasAdded={setWasAdded}
      isOneContract={isOneContract}
    />
  );
};
