import React from 'react';
import * as S from './styled';
import { Tooltip as TooltipAntd } from 'antd';
import { nextRight, IconLetter } from '../KrasUIKit/icons';

export interface Props {
  title: string;
  description?: JSX.Element | string;
  textLink?: string;
  simple?: boolean;
  inverse?: boolean;
}

export const Tooltip: React.FC<Props> = ({
  title,
  description,
  textLink,
  children,
  simple = false,
  inverse = false,
}) => {
  const color = inverse ? '#000' : '#fff';
  return simple ? (
    <TooltipAntd
      title={
        <S.TitleTooltipSimple inverse={inverse}>{title}</S.TitleTooltipSimple>
      }
      color={color}
    >
      <span>{children}</span>
    </TooltipAntd>
  ) : (
    <TooltipAntd
      overlayStyle={{ maxWidth: '260px' }}
      title={
        <S.TooltipContent inverse={inverse}>
          <S.TitleTooltip>{title}</S.TitleTooltip>
          {description && <div>{description}</div>}
          {textLink && (
            <S.LinkTooltip to="">
              {textLink} <IconLetter>{nextRight}</IconLetter>
            </S.LinkTooltip>
          )}
        </S.TooltipContent>
      }
      color={color}
    >
      <span>{children}</span>
    </TooltipAntd>
  );
};
