import { UploadFile } from 'antd/lib/upload/interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BannersService } from 'app/services/banners';
import { message as antdMessage } from 'antd';
import { UpdateBannerDto, CreateBannerDto } from 'app/types/banners';

export const fetchBanners = createAsyncThunk(
  'banners/fetchBanners',
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await BannersService.getMany(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateBanner = createAsyncThunk(
  'banners/updateBanner',
  async (payload: UpdateBannerDto, { rejectWithValue }) => {
    const { data, success, message } = await BannersService.update(payload);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const uploadBannerImg = createAsyncThunk(
  'banners/uploadBannerImg',
  async (payload: UploadFile, { rejectWithValue }) => {
    const { data, success, message } = await BannersService.uploadImg(payload);
    if (success && data) {
      antdMessage.success('Изображение успешно добавлено');
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteBanner = createAsyncThunk(
  'banners/deleteBanner',
  async (id: number, { rejectWithValue }) => {
    const { success, message } = await BannersService.delete(id);
    if (success) {
      antdMessage.success(message);
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(id);
    }
  },
);
export const createBanner = createAsyncThunk(
  'banners/createBanner',
  async (payload: CreateBannerDto, { rejectWithValue }) => {
    const { success, message, data } = await BannersService.create(payload);
    if (success && data) {
      antdMessage.success(message);
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
