import React from 'react';
import { Icon as IconInterface } from './index';

export const CaretLeft: React.FC<IconInterface> = ({
  width = '1.0675em',
  height = '1em',
  color = 'currentColor',
  ...args
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...args}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.498 13.8047C5.23765 13.5444 5.23765 13.1223 5.498 12.8619L10.3599 8L5.498 3.13807C5.23765 2.87772 5.23765 2.45561 5.498 2.19526C5.75835 1.93491 6.18046 1.93491 6.44081 2.19526L11.7741 7.52859C12.0345 7.78894 12.0345 8.21105 11.7741 8.4714L6.44081 13.8047C6.18046 14.0651 5.75835 14.0651 5.498 13.8047Z"
        fill={color}
      />
    </svg>
  );
};
