import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { unwrapResult } from '@reduxjs/toolkit';
import * as T from 'app/types/adminContracts';

export const useAdminContracts = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.adminContracts);

  const fetchContracts = (search: string) =>
    dispatch(actions.fetchContracts(search));

  const updateContract = async (payload: T.UpdateContractDto) => {
    const resultAction = await dispatch(actions.updateContract(payload));
    return actions.updateContract.fulfilled.match(resultAction);
  };

  const fetchContractTypes = () => dispatch(actions.fetchContractTypes());

  const addContract = async (contract: T.CreateContractDto) => {
    const resultAction = await dispatch(actions.addContract(contract));
    if (actions.addContract.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.item.sapNumber : null;
    }
  };

  return {
    state,
    fetchContracts,
    updateContract,
    fetchContractTypes,
    addContract,
  };
};
