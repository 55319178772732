import * as actions from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { BannerEntity } from 'app/types/banners';

interface InitialState {
  loading: boolean;
  list: BannerEntity[];
}

export const initialState: InitialState = {
  loading: false,
  list: [],
};

const publicBannersSlice = createSlice({
  name: 'publicBanners',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.fetchPublicBanners.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.loading = false;
    });
    addCase(actions.fetchPublicBanners.pending, state => {
      state.loading = true;
    });
    addCase(actions.fetchPublicBanners.rejected, state => {
      state.loading = false;
    });
  },
});

export default publicBannersSlice.reducer;
