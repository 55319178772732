import React from 'react';
import * as S from './styled';

export interface ContractsMenuProps {
  menu: React.ReactNode;
}

export const ContractsMenu = ({ menu }: ContractsMenuProps) => {
  return <S.MenuWrap>{React.cloneElement(menu as React.ReactElement)}</S.MenuWrap>;
};
