import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antMessage } from 'antd';
import { ContractsService } from 'app/services/admin/contracts';
import * as T from 'app/types/adminContracts';

export const fetchContracts = createAsyncThunk(
  'adminContracts/fetchContracts',
  async (search: string, { rejectWithValue }) => {
    const { data, success } = await ContractsService.getMany(search);
    if (success && data) {
      return data;
    } else {
      antMessage.error('Список договоров не получен');
      return rejectWithValue(data);
    }
  },
);

export const updateContract = createAsyncThunk(
  'adminContracts/updateContract',
  async (payload: T.UpdateContractDto, { rejectWithValue }) => {
    const { data, message, success } = await ContractsService.update(payload);
    if (success && data) {
      //TODO: исправить когда бек начнет присылать корректные сообщения в ответе
      antMessage.success('Успешное обновление договора');
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchContractTypes = createAsyncThunk(
  'adminContracts/fetchContractTypes',
  async (_, { rejectWithValue }) => {
    const { data, success } = await ContractsService.getContractTypes();
    if (success && data) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  },
);

export const addContract = createAsyncThunk(
  'adminContracts/addContract',
  async (contract: T.CreateContractDto, { rejectWithValue }) => {
    const { data, success, message } = await ContractsService.create(contract);
    if (success && data) {
      //TODO: исправить когда бек начнет присылать корректные сообщения в ответе
      antMessage.success('Договор успешно добавлен');
      return data;
    } else {
      antMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
