import { Button, Col, Divider, Modal, Row } from 'antd';
import { Title } from 'components/Title';
import React from 'react';
import { BlockDescription, BlockTitle, BlockValue, OrderProgress } from './styled/Table.styled';
import { Tooltip } from 'features/AdditionalProducts';
import { usePersonalOrders } from 'app/ducks/personalOrders';
import { useAuth } from 'app/ducks/auth';

export const MODAL_NAME = 'status-modal';

interface StatusModalProps {
  visible: boolean;
  onCancel: () => void;
  onShippingClick: (orderNumber: number) => void;
  loading: boolean;
}

export const StatusModal = ({ visible, onCancel, onShippingClick, loading }: StatusModalProps) => {
  const {
    state: { statusModal },
  } = usePersonalOrders();

  const {
    state: { options },
  } = useAuth();

  if (!statusModal) return <></>;

  const hasStatusSettings = options ? options.hasOwnProperty('orderStatus') : false;
  const allowProvisionalDateShow = options && hasStatusSettings ? options.orderStatus.allowProvisionalDateShow : false;
  const allowShippingBtnShow = options && hasStatusSettings ? options.orderStatus.allowShipment : false;
  const readyPercentIsMatch =
    options && hasStatusSettings
      ? options.orderStatus.shipmentAllowedPercentage <= statusModal.orderReadyPercent
      : false;

  return (
    <Modal
      open={visible}
      title={
        <Title style={{ marginBottom: 0 }} level={4}>
          Заказ {statusModal.numberOrder} от {new Date(statusModal.dateCreate).toLocaleDateString()}{' '}
          {statusModal.shipped ? '(остаток Вашего заказа)' : ''}
        </Title>
      }
      footer={null}
      width="593px"
      onCancel={onCancel}
    >
      <Row justify="space-between">
        <Col md={4}>
          <BlockTitle>№ договора</BlockTitle>
          <BlockValue>{statusModal.contractNumber}</BlockValue>
        </Col>
        <Col md={4}>
          <BlockTitle>Металл</BlockTitle>
          <BlockValue>{statusModal.metal.name}</BlockValue>
        </Col>

        <Col md={5}>
          <BlockTitle>Кол-во изделий</BlockTitle>
          <BlockValue>{statusModal.modalCount}</BlockValue>
        </Col>
        <Col md={4}>
          <BlockTitle>Вес, г.</BlockTitle>
          <BlockValue>{statusModal.modalWeight}</BlockValue>
        </Col>
      </Row>
      <Divider type="horizontal" />

      {allowProvisionalDateShow && (
        <>
          <Row>
            <Col md={12}>
              <BlockTitle>
                Предварительная дата готовности{' '}
                <Tooltip>
                  Данные сроки являются расчетными <br /> и не гарантируют готовности заказа
                </Tooltip>
              </BlockTitle>
              <BlockValue>
                {statusModal.provisionalDate ? new Date(statusModal.provisionalDate).toLocaleDateString() : '-'}
              </BlockValue>
            </Col>
            <Col md={10} push={1}>
              <BlockTitle>Фактическая дата готовности</BlockTitle>
              <BlockValue>
                {statusModal.actualDate ? new Date(statusModal.actualDate).toLocaleDateString() : '-'}
              </BlockValue>
            </Col>
          </Row>
          <Divider type="horizontal" />
        </>
      )}

      <Row>
        <Col md={24}>
          <BlockTitle>
            Готовность заказа
            {statusModal.shipped && (
              <Tooltip>Степень готовности указывается на основании остатка Вашего заказа</Tooltip>
            )}
          </BlockTitle>
          <BlockValue>
            <OrderProgress percent={statusModal.orderReadyPercent} />
          </BlockValue>
          <BlockDescription>
            Последнее обновление статуса:{' '}
            {new Date(statusModal.lastStatusUpdateDate || Date.now()).toLocaleDateString()}
          </BlockDescription>
        </Col>
      </Row>
      <Divider type="horizontal" />

      {allowShippingBtnShow && (
        <Row>
          <Col md={24} style={{ textAlign: 'center' }}>
            {!readyPercentIsMatch && !statusModal.shipped && (
              <BlockTitle>Данная заявка не может быть отгружена, т.к. не готов допустимый объем</BlockTitle>
            )}
            {statusModal.shipped ? (
              <Button
                size="large"
                type="primary"
                style={{ width: '100%', minHeight: '40px', height: 'auto', whiteSpace: 'normal' }}
                disabled
              >
                Данная степень готовности указана <br /> по остатку заказа и не может быть отгружена
              </Button>
            ) : (
              <Button
                size="large"
                type="primary"
                style={{ width: '100%', minHeight: '40px', height: 'auto', whiteSpace: 'normal' }}
                disabled={!readyPercentIsMatch || loading || statusModal.claimed}
                onClick={() => onShippingClick(statusModal.numberOrder)}
              >
                {statusModal.claimed ? 'Ваша заявка на отгрузку изделий отправлена' : 'Забрать готовые изделия'}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};
