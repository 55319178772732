import { createSlice } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { AdminRenderRequest } from 'app/types/renderRequests';
import { PaginationNew } from 'app/types/pagination';
import { SLICE_NAME } from './consts';
import * as actions from './actions';

export interface ProductSliceProps {
  list: AdminRenderRequest[];
  loading: boolean;
  pagination?: PaginationNew;
}
export const renderRequestsInitialState: ProductSliceProps = {
  loading: false,
  list: [],
};

const renderRequestsSlice = createSlice({
  name: SLICE_NAME,
  initialState: renderRequestsInitialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.fetchRenderRequests.pending, state => {
      state.loading = true;
    });
    addCase(actions.fetchRenderRequests.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
      state.loading = false;
    });
    addCase(actions.fetchRenderRequests.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});

export default renderRequestsSlice.reducer;
