import React from 'react';
import { Col, Row } from 'antd';
import { FormContainer, Button, Note } from '../../styled/Footer.styled';
import { FormWrapper } from '../../styled/Common.styled';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AdditionalProductAlloy, AdditionalProductType } from 'app/types/adminAdditional';
import { BarsItem, WireItem, StripesItem } from 'features/AdditionalProducts/types/OrderItem';
import { Tooltip } from '../Tooltip';
import { Trans, useTranslation } from 'react-i18next';

interface FooterProps {
  onСlick: () => void;
  type: AdditionalProductType;
  cartVisible: boolean;
}
type Metals = { [key: string]: { metal: Partial<AdditionalProductAlloy>; alloys: number[]; weight: number } };

const GOLD_CODE = 'AU';
const SILVER_CODE = 'AG';

const getUniqueAlloys = (alloys: AdditionalProductAlloy[]) => {
  const ids: number[] = [];
  const uniqueAlloys: AdditionalProductAlloy[] = [];
  alloys.forEach(alloy => {
    if (ids.includes(alloy.id)) return;
    ids.push(alloy.id);
    uniqueAlloys.push(alloy);
  });

  return uniqueAlloys;
};

const getUniqueMetals = (alloys: AdditionalProductAlloy[]) => {
  const metals: Metals = {};
  alloys.forEach(alloy => {
    const { metal } = alloy;
    if (!metals[metal.code]) {
      metals[metal.code] = {
        metal: metal,
        alloys: [],
        weight: 0,
      };
      metals[metal.code].alloys.push(alloy.id);
    } else {
      metals[metal.code].alloys.push(alloy.id);
    }
  });

  return metals;
};

const calculateWeights = (metals: Metals, orders: (BarsItem | WireItem | StripesItem)[]) => {
  orders.forEach(({ alloyId, weight }) => {
    if (metals[GOLD_CODE].alloys.includes(alloyId)) {
      metals[GOLD_CODE].weight += weight;
    } else if (metals[SILVER_CODE].alloys.includes(alloyId)) {
      metals[SILVER_CODE].weight += weight;
    }
  });
  return metals;
};

export const Footer = ({ onСlick, type, cartVisible }: FooterProps) => {
  const { t } = useTranslation(['additional']);
  const state = useSelector((state: RootState) => state.additionalProducts);
  const { bar } = useSelector((state: RootState) => state.additionalProductsOrder);
  const {
    products,
    metadata: { minWeights: minWeightsList },
  } = state;

  const alloys: AdditionalProductAlloy[] = [];
  const filteredProductsByType = products.filter(product => product.type === type);
  filteredProductsByType.forEach(({ alloys: productAlloys }) => {
    productAlloys.forEach(alloy => alloys.push(alloy));
  });

  const uniqueAlloys = getUniqueAlloys(alloys);
  const uniqueMetals = getUniqueMetals(uniqueAlloys);
  const metalsWithWeights = calculateWeights(uniqueMetals, bar as BarsItem[]);
  const [minWeights] = minWeightsList.filter(item => item.code === type);

  const isDisabled = () => {
    const notEnoughGold = metalsWithWeights[GOLD_CODE]
      ? metalsWithWeights[GOLD_CODE].weight >= 0 && metalsWithWeights[GOLD_CODE].weight < minWeights?.weightAu
      : true;
    const notEnoughSilver = metalsWithWeights[SILVER_CODE]
      ? metalsWithWeights[SILVER_CODE].weight >= 0 && metalsWithWeights[SILVER_CODE].weight < minWeights?.weightAg
      : true;
    return notEnoughGold && notEnoughSilver;
  };

  return (
    <FormWrapper>
      <FormContainer>
        <Row justify="start" align="middle">
          <Col md={4}>
            {t('totalCount')}: <strong>{bar.length}</strong>
          </Col>
          <Col md={7}>
            {metalsWithWeights[GOLD_CODE] && metalsWithWeights[GOLD_CODE].weight > 0 && (
              <>
                <span>
                  <Trans
                    t={t}
                    i18nKey="goldCount"
                    values={{
                      weight: metalsWithWeights[GOLD_CODE].weight.toFixed(2),
                      minWeight: minWeights?.weightAu,
                    }}
                    components={[<strong key={metalsWithWeights[GOLD_CODE].weight} />]}
                  />
                </span>
                <br />
              </>
            )}
            {metalsWithWeights[SILVER_CODE] && metalsWithWeights[SILVER_CODE].weight > 0 && (
              <span>
                <Trans
                  t={t}
                  i18nKey="silverCount"
                  values={{
                    weight: metalsWithWeights[SILVER_CODE].weight.toFixed(2),
                    minWeight: minWeights?.weightAg,
                  }}
                  components={[<strong key={metalsWithWeights[SILVER_CODE].weight} />]}
                />
              </span>
            )}
          </Col>
          <Col md={12} push={1} style={{ textAlign: 'right' }}>
            <Note>
              <Tooltip>
                <>{t('tooltip.decription')}</>
              </Tooltip>
              {t('tooltip.title')}
            </Note>
            <Button type="primary" onClick={onСlick} disabled={isDisabled()}>
              {!cartVisible ? t('goToSubmit') : t('submit')}
            </Button>
          </Col>
        </Row>
      </FormContainer>
    </FormWrapper>
  );
};
