import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Affix = styled.div`
  width: 32px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
`;
