import * as actions from './actions';
import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './consts';
import { MetalReportEntity } from 'app/types/metalReport';

interface InitialState {
  loading: boolean;
  list: MetalReportEntity[];
}

export const initialState: InitialState = {
  loading: true,
  list: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/fulfilled');

const metalCostSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.getMetalReports.fulfilled, (state, { payload }) => {
      state.list = payload.items;
    });
    addCase(actions.createReportMetal.fulfilled, (state, { payload }) => {
      state.list = [payload.item, ...state.list];
    });
    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default metalCostSlice.reducer;
