import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePromotions, useCompanies } from 'app/ducks/companies';
import { actionTypes } from 'logger';
import { Bonus } from './Bonus';
import { Stage } from './Stage/Stage';
import bonusIcon from 'images/svg/welcome_bonus.svg';
import bonusIconCompact from 'images/svg/welcome_bonus-compact.svg';
import { useMediaQuery } from 'hooks/useMediaQuery';

const WELCOME_BONUS_RULES_URL = '/welcome_bonus';

export const WelcomeBonus = () => {
  const [open, setOpen] = useState(false);
  const match = useRouteMatch({ path: '/', strict: true });
  const {
    state: { isBannerVisible, loading },
  } = useCompanies();
  const isTablet = useMediaQuery('(max-width: 1250px)');
  const { promotions } = usePromotions();
  const welcomeBonus = promotions?.welcomeBonus;

  useEffect(() => {
    const show = !loading && !isBannerVisible && Boolean(match?.isExact);
    setOpen(show);
  }, [isBannerVisible, loading]);

  if (welcomeBonus) {
    const rulesUrl = `${WELCOME_BONUS_RULES_URL}${welcomeBonus.newCompany ? '/new' : '/current'}`;

    return (
      <Bonus
        open={open}
        setOpen={setOpen}
        icon={isTablet ? bonusIconCompact : bonusIcon}
        title="Бонусы на изделия из золота"
      >
        <Stage
          data={welcomeBonus}
          setOpen={setOpen}
          analyticsAction={actionTypes.WELCOME_BONUS_CLICK_SPECIAL_PAGE}
          rulesUrl={rulesUrl}
        />
      </Bonus>
    );
  }
  return null;
};
