import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  &,
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
