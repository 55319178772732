import React, { Fragment } from 'react';
import { MetalInfo, MetalAssays } from 'app/types/contracts';
import { useTranslation } from 'react-i18next';
import { number } from 'helpers/format';
import { getMetalIcon } from '../CompanySettings/CompanySettings';
import * as S from './styled';

interface MetalsBlockProps {
  metals: {
    metal: MetalInfo;
    mass: number;
    assays: MetalAssays[];
  }[];
  title: string;
}

export const MetalsBlock: React.FC<MetalsBlockProps> = ({ metals, title }) => {
  const { t } = useTranslation(['layout']);
  return metals.length ? (
    <S.MetalWrap>
      <b>{title}</b>
      {metals.map(({ metal, mass, assays }, i) => (
        <Fragment key={i}>
          <S.Divider />
          <S.MetalItemWrap>
            <>
              <S.TitleMetalWrap>
                <S.TitleMetalItem>
                  {getMetalIcon(metal.code, '1.37em')}
                  {metal.name} ({t('HCG')}):&nbsp;
                </S.TitleMetalItem>
                <S.TitleMetalItem>
                  {number(mass, 2)} {t('units:g')}
                </S.TitleMetalItem>
              </S.TitleMetalWrap>
              <S.SubMetalList>
                {assays.map(({ assay, mass }, i) => (
                  <S.SubMetalItem key={i}>
                    <S.TextWithoutMargin>
                      {assay} {t('hallmark')}
                    </S.TextWithoutMargin>
                    <S.TextWithoutMargin>
                      {number(+mass, 2)} {t('units:g')}
                    </S.TextWithoutMargin>
                  </S.SubMetalItem>
                ))}
              </S.SubMetalList>
            </>
          </S.MetalItemWrap>
        </Fragment>
      ))}
    </S.MetalWrap>
  ) : null;
};
