import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessModal, SuccessIcon, SuccessTitle } from './styled';

export const MODAL_NAME = 'consultation_success';

export interface Props {
  visible?: boolean;
  hideModal: () => void;
}

export const ConsultationSuccess: React.FC<Props> = ({
  hideModal,

  visible,
}) => {
  const { t } = useTranslation(['layout', 'consultation', 'company', 'form', 'modal']);

  return (
    <SuccessModal open={visible} onCancel={hideModal} title={null} footer={null}>
      <Row align="middle">
        <SuccessIcon />
        <SuccessTitle level={3}>
          Заявка принята,
          <br /> мы перезвоним вам
        </SuccessTitle>
      </Row>
    </SuccessModal>
  );
};
