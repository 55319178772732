import { YMETRICA_COUNTER } from '../constants';
import { getYMetrica } from './getYMetrica';

export type SendReachGoalParams = {
  readonly targetName: string;
  readonly counter?: number;
};

export const sendReachGoal = (params: SendReachGoalParams): void => {
  const { targetName, counter = YMETRICA_COUNTER } = params;
  const ym = getYMetrica();

  if (!ym) {
    return;
  }

  ym(counter, 'reachGoal', targetName);
};
