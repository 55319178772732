/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Valid } from '../Fields/Valid';
import { Modal, Select as AntdSelect, Button, Row, Col, DatePicker, Form } from 'antd';
import { Title } from '../Title';
import { Alert } from '../KrasUIKit';
import { info } from '../KrasUIKit/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectProps } from 'antd/lib/select';
import { Contracts, sapNumber as TSapNumber } from 'app/types/contracts';
import { DateRangeWrap } from './styled';
import { ActRequestForm } from 'app/types/documents';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { actionTypes, sendEvent } from 'logger';

export const MODAL_NAME = 'act-request';

const { Option } = AntdSelect;

export interface Props {
  visible?: boolean;
  loading?: boolean;
  contracts: Contracts;
  onSubmit: (data: ActRequestForm) => void;
  onCancel: () => void;
}

export const ActRequest: React.FC<Props> = ({ visible, onCancel, onSubmit, loading, contracts }) => {
  const { t } = useTranslation(['personal']);
  const schema = Yup.object().shape({
    dateTo: Yup.string()
      .typeError(t('form:required'))
      .required(t('form:required')),
    dateFrom: Yup.string()
      .typeError(t('form:required'))
      .required(t('form:required')),
    contract_sap_id: Yup.string().required(t('form:contract')),
  });

  const formik = useFormik<ActRequestForm>({
    initialValues: {
      contract_sap_id: '',
      dateTo: '',
      dateFrom: '',
    },
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
  }, [visible]);

  interface CustomSelect extends SelectProps<string> {
    variants: { label: string; value: TSapNumber }[];
  }

  const Select: React.FC<CustomSelect> = ({ variants, ...props }) => {
    return (
      <AntdSelect {...props} style={{ width: '100%' }}>
        <Option disabled value={0}>
          {t('form:selectContract')}
        </Option>
        {variants.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </AntdSelect>
    );
  };

  const dateHandler = (name: 'dateFrom' | 'dateTo', method: 'startOf' | 'endOf') => {
    if (
      formik.values[name] &&
      formik.values[name] !== '' &&
      dayjs(formik.values[name]).diff(dayjs(formik.values[name])[method]('month'), 'day') !== 0
    ) {
      formik.setFieldValue(name, dayjs(formik.values[name])[method]('month'));
    }
  };

  useEffect(() => {
    dateHandler('dateFrom', 'startOf');
  }, [formik.values.dateFrom, dateHandler]);

  useEffect(() => {
    dateHandler('dateTo', 'endOf');
  }, [formik.values.dateTo, dateHandler]);

  const { handleSubmit } = formik;
  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel();
        sendEvent(actionTypes.MODAL_ACT_CLOSE);
      }}
      okText={t('modal:request')}
      footer={
        <Row justify="end">
          <Col>
            <Button
              onClick={() => {
                onCancel();
                sendEvent(actionTypes.MODAL_ACT_CANCEL);
              }}
              type="link"
            >
              {t('modal:cancel')}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => formik.submitForm()} loading={loading} type="primary">
              {t('modal:request')}
            </Button>
          </Col>
        </Row>
      }
      title={
        <Title style={{ marginBottom: 0 }} level={3} upper>
          {t('actModalTitle')}
        </Title>
      }
    >
      <Alert icon={info} alertType="info" title={t('actModalWarning')} />
      <form onSubmit={handleSubmit}>
        <Form layout="vertical" style={{ marginTop: 20 }}>
          <Form.Item label={t('form:period')} style={{ marginBottom: 0 }}>
            <DateRangeWrap>
              <Valid
                name="dateFrom"
                form={formik}
                component={props => (
                  <DatePicker
                    {...props}
                    style={{ width: '100%' }}
                    inputReadOnly
                    format="DD.MM.YYYY"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    disabledDate={(current: any) =>
                      current &&
                      (current > dayjs(formik.values.dateTo) ||
                        current >
                          dayjs()
                            .startOf('month')
                            .subtract(1))
                    }
                  />
                )}
              />
              <Valid
                name="dateTo"
                form={formik}
                component={props => (
                  <DatePicker
                    {...props}
                    style={{ width: '100%' }}
                    inputReadOnly
                    format="DD.MM.YYYY"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    disabledDate={(current: any) =>
                      current &&
                      (current < dayjs(formik.values.dateFrom) ||
                        current >
                          dayjs()
                            .startOf('month')
                            .subtract(1))
                    }
                  />
                )}
              />
            </DateRangeWrap>
          </Form.Item>
          <Valid
            name="contract_sap_id"
            label={t('layout:contract')}
            form={formik}
            placeholder={t('form:selectContract')}
            component={props => (
              <Select
                {...props}
                variants={contracts.map(({ name, sapNumber, knfmpNumber }) => ({
                  label: `${knfmpNumber} ${name !== 'Тестовый' ? `(${name})` : ''}`,
                  value: sapNumber,
                }))}
              />
            )}
          />
        </Form>
      </form>
    </Modal>
  );
};
