import { createAsyncThunk } from '@reduxjs/toolkit';
import * as CategoriesService from 'app/services/categories';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { CatalogLevel, ProductCategory } from 'app/types/RENAME__catalog';
import { Result } from 'app/types/api';
import { getCategoriesByParentCode } from 'app/services/RENAME__catalog';
import { UpdateCategory, FetchProductData } from 'app/types/categories';

export const fetchProducts = createAsyncThunk('categories/fetchProducts', async (id: number) => {
  const { data, success, message } = await CategoriesService.getProducts(id);
  if (success) {
    return { data, id };
  } else {
    throw new Error(message);
  }
});

export const fetchProductCategory = createAsyncThunk<
  ProductCategory[],
  FetchProductData,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('categories/fetchProductCategory', async ({ path, isForceUpdate }, { dispatch, getState }) => {
  const { categories } = getState();
  const { renameCategory } = categories;
  const ids = path.split('/');

  const checkChildByCode = (code: string) => {
    const current = renameCategory.find(({ code: itemCode }) => itemCode === code);
    const id = code === 'products' ? 1 : current?.id;

    if (!id) return true;

    return !renameCategory.some(({ parentId }) => {
      return parentId === id;
    });
  };

  const getChildCategoryByCode = (code: string): CatalogLevel => {
    const id = code === 'products' ? 1 : renameCategory.find(({ code: categoryCode }) => categoryCode === code)?.id;

    if (!id) return [];

    return renameCategory.filter(({ parentId }) => {
      return parentId === id;
    });
  };

  const fakeApiRequest = async (code: string): Promise<Result<CatalogLevel>> => ({
    data: getChildCategoryByCode(code),
    success: true,
    message: '',
    code: 200,
  });

  try {
    const results = await Promise.all(
      ids.map(code => {
        if (checkChildByCode(code) || isForceUpdate) {
          return getCategoriesByParentCode(code);
        }
        return fakeApiRequest(code);
      }),
    );

    if (results[results.length - 1].data.length === 0) {
      const { data: prevData } = results[results.length - 2];
      const productCategory = prevData.find(({ code }) => code === ids[results.length - 1]);

      if (productCategory) {
        await dispatch(fetchProducts(productCategory.id));
      }
    }

    return results
      .reduce<CatalogLevel>((list, { data }) => [...list, ...data], [])
      .filter(({ active }) => active)
      .sort((a, b) =>
        a.alterName && a.alterName !== ''
          ? a.alterName.localeCompare(b.alterName && b.alterName !== '' ? b.alterName : b.name)
          : a.name.localeCompare(b.alterName && b.alterName !== '' ? b.alterName : b.name),
      )
      .sort((a, b) => a.sort - b.sort);
  } catch (error) {
    return [];
  }
});

export const update = createAsyncThunk('categories/update', async ({ id, body }: UpdateCategory) => {
  await CategoriesService.updateCategory(id, body);
  document.location.reload();
});
