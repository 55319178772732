/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from 'antd/lib/form/interface';
import { isNil, isEmpty, anyPass, reject } from 'ramda';
import dayjs from 'dayjs';

export const dateFormat = 'YYYY-MM-DD HH:mm:ss Z';

const BOOLEAN_CHECK_PATTERN = /true|false/;

const checkArray = (value: string) => value.split(',').length > 1;

const checkDate = (date: string) => dayjs(date, dateFormat, true).isValid();

const BOOLEANS: { [key: string]: boolean } = {
  true: true,
  false: false,
};

type Pattern = {
  check: (value: string) => boolean;
  converter: (value: string) => any;
  excludes?: string[];
};
/* 
    Набор кастомных обработок, 
    позволяющих "умно" конвертировать строки в значения
*/
const patterns: Pattern[] = [
  {
    check: (value: string) => BOOLEAN_CHECK_PATTERN.test(value),
    converter: (value: string) => BOOLEANS[value] || false,
  },
  {
    check: checkArray,
    converter: (value: string) => value.split(','),
  },
  {
    check: checkDate,
    converter: (value: string) => dayjs(value, dateFormat),
  },
];

export const convertQueryToValue = ([key, value]: [string, string]) => {
  const pattern = patterns.find(
    ({ check, excludes = ['q', 'search', 'myKey', 'page'] }) => check(value) && !excludes.includes(key),
  );

  return pattern ? pattern?.converter(value) : value;
};

export const convertValueToQuery = (values: Store) => {
  const isReallyEmpty = anyPass([isNil, isEmpty]);
  const clearValues = reject(isReallyEmpty)(values);
  for (const key in clearValues) {
    if (dayjs.isDayjs(clearValues[key])) {
      clearValues[key] = dayjs(clearValues[key]).format(dateFormat);
    }
  }
  return clearValues;
};
