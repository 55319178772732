import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2`
  padding: 20px 32px 24px;
  font-weight: 400;
  font-size: 22px;
`;

export const AlloysList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 0 32px 32px;
`;

export const OrderContent = styled.div`
  width: calc(66.666% - 20px);
`;

export const FormWrapper = styled.div`
  width: calc(33.333% - 20px);
`;

export const Block = styled.div<{ decorated?: boolean; visible?: boolean }>`
  margin-bottom: 28px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 3px;
  display: ${({ visible }) => (visible === false ? 'none' : 'block')};
  border-top: ${({ decorated }) => (decorated ? '3px solid #D77721' : '3px solid transparent')};
`;

export const FormItemTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 32px;
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
`;

export const FormItemImg = styled.img`
  width: 85px;
  height: 64px;
  margin-right: 10px;
`;

export const OrderCell = styled.span`
  display: inline-flex;
  align-items: center;
  height: 56px;
`;

export const OrderRow = styled.p`
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  padding: 0 32px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: 0;
  }

  ${OrderCell} {
    padding: 10px 0;

    &:last-child {
      justify-content: flex-end;
    }
  }
`;
