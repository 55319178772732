import styled from 'styled-components';

export const BalanceWrap = styled.div`
  font-size: 1rem;
  padding: 0.5em 0.25em;
  min-width: 17em;
`;

export const RefreshIcon = styled.span`
  width: 1em;
  height: 1em;
  color: #bbb;
  margin: 0px 0.5em 0px 0.25em;
`;
