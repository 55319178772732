import React, { useCallback, useEffect } from 'react';
import { Return } from 'components/Personal/Return';
import { useDocuments } from 'app/ducks/documents';
import { useDocumentsSection } from 'app/ducks/documentsSection';

export const ReturnContainer: React.FC = () => {
  const { state: documents, fetchDocuments, downloadDocument } = useDocuments();
  const { tessaDocuments, loading } = documents;

  const { state: sections, getDocumentSectionsByCode } = useDocumentsSection();

  useEffect(() => {
    getDocumentSectionsByCode('blanks');
  }, []);

  const getDocuments = useCallback(() => {
    fetchDocuments();
  }, []);

  const onDownload = (uuid: string) => {
    downloadDocument(uuid);
  };

  return (
    <Return
      sections={sections.list}
      sectionsLoading={sections.loading}
      tessaDocuments={tessaDocuments}
      loading={loading}
      getDocuments={getDocuments}
      onDownload={onDownload}
    />
  );
};
