import * as actions from './actions';
import {
  renderRequestStart as $renderRequestStart,
  renderRequestEnd as $renderRequestEnd,
} from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { OrderRenders } from 'app/types/renders';

export const useRenderRequest = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.renderRequest);

  const downloadOrderRenders = async (comment: OrderRenders) => {
    const resultAction = await dispatch(actions.downloadOrderRenders(comment));
    return actions.downloadOrderRenders.fulfilled.match(resultAction);
  };

  const renderRequestStart = (id: number | string) => {
    dispatch($renderRequestStart(id));
  };

  const renderRequestEnd = () => {
    dispatch($renderRequestEnd());
  };

  return {
    state,
    downloadOrderRenders,
    renderRequestStart,
    renderRequestEnd,
  };
};
