import React from 'react';
import { Table as AntTable, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { picture } from 'components/KrasUIKit/icons';
import { ColumnsType } from 'antd/lib/table';
import { OrderPosition } from 'app/types/orders';
import { useCategories } from 'app/ducks/categories';
import { number } from 'helpers/format';
import { useFormatSize } from 'hooks/useFormatSize';

type TableProps = {
  positions: OrderPosition[];
};

export const Table = ({ positions }: TableProps) => {
  const { t } = useTranslation(['orderTypes']);
  const { showProductDetail } = useCategories();

  const getDetail = (id: number) => {
    showProductDetail(id);
  };

  const { formatSize } = useFormatSize();

  const columns: ColumnsType<OrderPosition> = [
    {
      title: t('orderParameters:vendorCode'),
      dataIndex: 'article',
      render: (_, { article, img, id }) => (
        <span style={{ cursor: 'pointer' }}>
          <Popover
            placement="right"
            content={<img width="300" height="300" src={img} style={{ objectFit: 'cover' }} />}
          >
            <span
              style={{
                display: 'inline-block',
                width: 16,
                height: 16,
                verticalAlign: 'middle',
              }}
            >
              {picture}
            </span>
          </Popover>
          <span onClick={() => getDetail(id)} style={{ verticalAlign: 'middle' }}>
            {' '}
            {article}
          </span>
        </span>
      ),
    },
    {
      title: 'Покрытие',
      dataIndex: 'coating',
      align: 'center',
      render: value => {
        return <div style={{ whiteSpace: 'pre-wrap' }}>{value.title}</div>;
      },
    },
    {
      title: t('orderParameters:diameter'),
      dataIndex: 'diameter',
      align: 'right',
      render: value => {
        return value === 0 ? '-' : value;
      },
    },
    {
      title: t('orderParameters:size'),
      dataIndex: 'length',
      align: 'right',
      render: (value, { isDimensioned }) => formatSize(value, { isDimensioned }),
    },
    {
      title: t('orderParameters:weight'),
      dataIndex: 'weight',
      align: 'right',
      render: (_, item) => number(item.weight),
      sorter: (a, b) => a.weight - b.weight,
    },
    {
      title: t('orderParameters:totalWeight'),
      align: 'right',
      dataIndex: 'totalWeight',
      render: (_, item) => number(item.weight * item.count, 2),
    },
    {
      title: t('orderParameters:declared'),
      dataIndex: 'count',
      align: 'right',
      sorter: (a, b) => a.count - b.count,
    },
  ];

  return <AntTable pagination={false} columns={columns} dataSource={positions} size="small" />;
};
