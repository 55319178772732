import { Result, ItemsResultWithMeta, ItemsResult, ItemResult } from 'app/types/api';
import { apiGet, apiPost } from '../connect';
import { ContractEntity, CreateContractDto, UpdateContractDto } from 'app/types/adminContracts';
import { ContractTypeAdmin } from 'app/types/contracts';

class ContractsServiceFactory {
  public async getMany(search: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<ContractEntity>>(`/admin/contracts${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
  public async update({ sapNumber, ...newData }: UpdateContractDto) {
    try {
      const { data } = await apiPost<ItemResult<ContractEntity>>(`/contracts/${sapNumber}`, newData);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
  public async getContractTypes() {
    try {
      const { data } = await apiGet<ItemsResult<ContractTypeAdmin>>('/contracts/types');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async create(contract: CreateContractDto) {
    try {
      const { data } = await apiPost<ItemResult<ContractEntity>>('/contracts', contract);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async searchContracts(q: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<ContractEntity>>('/admin/contracts', {
        q,
      });
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const ContractsService = new ContractsServiceFactory();
