import { adminPermissions } from '../../../constants';
import { lazy } from 'react';
import { ItemInterface } from '../routes';

const Feedbacks = lazy(() => import('./Feedbacks'));
const CompaniesPage = lazy(() => import('./Companies'));
const DocumentSectionsPage = lazy(() => import('./DocumentSectionsPage'));
const AdminNewsPage = lazy(() => import('./AdminNews'));
const BannersPage = lazy(() => import('./Banners'));
const UsersPage = lazy(() => import('./Users'));
const DemoUsersPage = lazy(() => import('./DemoUsers'));
const Products = lazy(() => import('./Products'));
const OrdersPage = lazy(() => import('./OrdersPage'));
const ContractsPage = lazy(() => import('./Contracts'));
const RenderRequests = lazy(() => import('./RenderRequests'));
const TechOrdersPage = lazy(() => import('./TechOrders'));
const RolesPage = lazy(() => import('./Roles'));
const WaitingLists = lazy(() => import('./WaitingLists'));
const UpdatingCO = lazy(() => import('./UpdatingCO'));
const AdminMain = lazy(() => import('./AdminMain'));
const LeadRequest = lazy(() => import('./LeadRequest'));
const Managers = lazy(() => import('./Managers'));
const UploadUsers = lazy(() => import('./UploadUsers'));
const PowersPage = lazy(() => import('./Powers'));
const CoilResidues = lazy(() => import('./CoilResidues'));
const ResiduesFinished = lazy(() => import('./ResiduesFinished'));
const ProductCategories = lazy(() => import('./ProductCategories'));
const ActionsLog = lazy(() => import('./ActionsLog'));
const AdditionalProducts = lazy(() => import('./Additional'));
const MetalCostPage = lazy(() => import('./MetalCostPage'));

export const adminRoutes: ItemInterface[] = [
  {
    path: '/admin',
    component: AdminMain,
    exact: true,
    privateRoute: true,
    clearLayout: true,
    title: 'Панель управления',
    permissions: Object.values(adminPermissions),
  },
  {
    path: '/admin/UploadUsers',
    component: UploadUsers,
    privateRoute: true,
    exact: true,
    title: 'Создание пользователей',
    permissions: [adminPermissions.full, adminPermissions.users],
  },
  {
    path: '/admin/companies',
    component: CompaniesPage,
    privateRoute: true,
    exact: true,
    title: 'Компании',
    permissions: [adminPermissions.full, adminPermissions.companies],
  },
  {
    path: '/admin/users',
    component: UsersPage,
    privateRoute: true,
    exact: true,
    title: 'Пользователи',
    permissions: [adminPermissions.full, adminPermissions.users],
  },
  {
    path: '/admin/demousers',
    component: DemoUsersPage,
    privateRoute: true,
    exact: true,
    title: 'Демо-пользователи',
    permissions: [adminPermissions.full, adminPermissions.users],
  },
  {
    path: '/admin/metal-cost',
    component: MetalCostPage,
    privateRoute: true,
    exact: true,
    title: 'Стоимость металла',
    permissions: [adminPermissions.full, adminPermissions.costMetal],
  },
  {
    path: '/admin/contracts',
    component: ContractsPage,
    privateRoute: true,
    exact: true,
    title: 'Договоры',
    permissions: [adminPermissions.full, adminPermissions.contracts],
  },
  {
    path: '/admin/managers',
    component: Managers,
    privateRoute: true,
    title: 'Менеджеры',
    permissions: [adminPermissions.full, adminPermissions.managers],
  },
  {
    path: '/admin/products/categories',
    component: ProductCategories,
    privateRoute: true,
    exact: true,
    title: 'Товарные категории',
    permissions: [adminPermissions.full, adminPermissions.catalog],
  },
  {
    path: '/admin/catalog/products',
    component: Products,
    privateRoute: true,
    exact: false,
    title: 'Товары',
    permissions: [adminPermissions.full, adminPermissions.products],
  },
  {
    path: '/admin/powers',
    component: PowersPage,
    privateRoute: true,
    exact: false,
    title: 'Мощности',
    permissions: [adminPermissions.full, adminPermissions.powers],
  },
  {
    path: '/admin/residues/coils',
    component: CoilResidues,
    privateRoute: true,
    exact: false,
    title: 'Остатки на катушках',
    permissions: [adminPermissions.full, adminPermissions.residuesCoil],
  },
  {
    path: '/admin/ResiduesFinished',
    component: ResiduesFinished,
    privateRoute: true,
    exact: false,
    title: 'Остатки ГП',
    permissions: [adminPermissions.full, adminPermissions.residuesProduct],
  },
  {
    path: '/admin/updateCloseOpen',
    component: UpdatingCO,
    privateRoute: true,
    exact: true,
    title: 'Обновление CO',
    permissions: [adminPermissions.full, adminPermissions.closeOpen],
  },
  {
    path: '/admin/tech-orders',
    component: TechOrdersPage,
    title: 'Технические заявки',
    privateRoute: true,
    exact: true,
    permissions: [adminPermissions.full, adminPermissions.techOrders],
  },
  {
    path: '/admin/waitingLists',
    component: WaitingLists,
    privateRoute: true,
    exact: true,
    title: 'Листы ожидания',
    permissions: [adminPermissions.full, adminPermissions.waitingList],
  },
  {
    path: '/admin/leadRequest',
    component: LeadRequest,
    privateRoute: true,
    exact: true,
    title: 'Запрос лидов',
    permissions: [adminPermissions.full],
  },
  {
    path: '/admin/order/renders',
    component: RenderRequests,
    privateRoute: true,
    exact: false,
    title: 'Запросы рендеров',
    permissions: [adminPermissions.full],
  },
  {
    path: '/admin/feedback',
    component: Feedbacks,
    privateRoute: true,
    clearLayout: true,
    title: 'Список обращений',
    permissions: [adminPermissions.full, adminPermissions.feedback],
  },
  {
    path: '/admin/document/sections',
    component: DocumentSectionsPage,
    privateRoute: true,
    exact: true,
    title: 'Разделы документов',
    permissions: [adminPermissions.full, adminPermissions.documentsSections],
  },
  {
    path: '/admin/news',
    component: AdminNewsPage,
    privateRoute: true,
    exact: true,
    title: 'Новости',
    permissions: [adminPermissions.full, adminPermissions.news],
  },
  {
    path: '/admin/banners',
    component: BannersPage,
    privateRoute: true,
    title: 'Баннеры',
    permissions: [adminPermissions.full, adminPermissions.banners],
  },
  {
    path: '/admin/orders',
    component: OrdersPage,
    privateRoute: true,
    exact: true,
    title: 'Заказы',
    permissions: [adminPermissions.full, adminPermissions.orders],
  },
  {
    path: '/admin/roles',
    component: RolesPage,
    privateRoute: true,
    exact: true,
    title: 'Роли',
    permissions: [adminPermissions.full, adminPermissions.roles],
  },
  {
    path: '/admin/log',
    component: ActionsLog,
    privateRoute: true,
    exact: true,
    title: 'Лог действий',
    permissions: [adminPermissions.full, adminPermissions.log],
  },
  {
    path: '/admin/additional',
    component: AdditionalProducts,
    privateRoute: true,
    exact: true,
    title: 'Растворы и полуфабрикаты',
    permissions: [adminPermissions.full, adminPermissions.catalog],
  },
];
