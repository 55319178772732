import React, { useEffect, useRef } from 'react';
import { sendEvent } from 'logger';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { banners } from './consts';
import { getRandomInt } from './utils';
import * as S from './Banners.styled';
import { useBanners } from './hooks/useBanners';
import { Arrow } from './Arrow';

export const Banners = () => {
  const { isBannersVisible } = useBanners();

  const sliderRef = useRef<CarouselRef>(null);
  const isMoreThanOneBanner = banners.length > 1;

  useEffect(() => {
    isBannersVisible && isMoreThanOneBanner && sliderRef.current?.goTo(getRandomInt(banners.length));
  }, [isBannersVisible]);

  if (!isBannersVisible) {
    return null;
  }

  return (
    <S.Wrapper>
      <Carousel autoplay effect="fade" autoplaySpeed={5000} ref={sliderRef} dots={isMoreThanOneBanner}>
        {banners.map(({ img, link, target, eventName }) => (
          <S.BannerWrapper key={link}>
            {isMoreThanOneBanner && <Arrow position="left" onClick={() => sliderRef.current?.prev()} />}
            <a href={link} onClick={() => sendEvent(eventName)} target={target} rel="noopener noreferrer">
              <img src={img} />
            </a>
            {isMoreThanOneBanner && <Arrow position="right" onClick={() => sliderRef.current?.next()} />}
          </S.BannerWrapper>
        ))}
      </Carousel>
    </S.Wrapper>
  );
};
