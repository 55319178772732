import React, { useRef } from 'react';
import { Tariff } from 'models/api/promotions';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import useSize from '@react-hook/size';
import * as S from './styled/BonusTracker.styled';

export type Props = {
  currentWeight: number;
  tariff?: Tariff[];
  neededWeight?: number;
};

export const BonusTracker = ({ currentWeight, tariff, neededWeight }: Props) => {
  const railRef = useRef<HTMLDivElement | null>(null);
  const pointRef = useRef<HTMLDivElement | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const [railWidth] = useSize(railRef);
  const [pointWidth] = useSize(pointRef);
  const [trackWidth] = useSize(trackRef);
  const { toGrams, toRubles } = useUnitsConverter();
  const maxWeight = tariff?.[tariff.length - 1].weight ?? neededWeight ?? 0;
  const percentsPerGram = maxWeight !== 0 ? 100 / maxWeight : 0;

  const getLeftOffset = (weight: number) => {
    const maxOffset = percentsPerGram * maxWeight;
    const leftOffset = percentsPerGram * weight;
    return leftOffset > maxOffset ? maxOffset : leftOffset;
  };

  const getPointTranslate = () => {
    if (pointWidth / 2 + trackWidth >= railWidth) {
      return 0;
    }
    if (pointWidth / 2 >= trackWidth) {
      const percentsPerPixel = 100 / pointWidth;
      return 100 - percentsPerPixel * trackWidth;
    }
    return 50;
  };

  return (
    <div>
      <S.Slider>
        <S.Rail ref={railRef} />
        <S.Track ref={trackRef} width={getLeftOffset(currentWeight)}>
          <S.Point ref={pointRef} translateX={getPointTranslate()}>
            {toGrams(currentWeight)}
          </S.Point>
        </S.Track>
      </S.Slider>
      <S.MarksContainer>
        {tariff &&
          tariff.map(({ weight, sum }) => (
            <S.ScaleMark key={weight} left={getLeftOffset(weight)}>
              <b>{toGrams(weight)}</b>
              <span>{toRubles(sum)}</span>
            </S.ScaleMark>
          ))}
        {neededWeight && (
          <>
            <S.ScaleMark left={0} mark={false}>
              <b>{toGrams(0)}</b>
            </S.ScaleMark>
            <S.ScaleMark left={getLeftOffset(neededWeight)}>
              <b>{toGrams(neededWeight)}</b>
            </S.ScaleMark>
          </>
        )}
      </S.MarksContainer>
    </div>
  );
};
