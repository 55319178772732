import { RootState } from 'app/store/reducer';
import { useSelector } from 'react-redux';

/**
 * Функция-хелпер генерирующая вложенные доступы на основе пермишена
 * @param {string} permission
 * @return {string[]} permission[]
 * @example
 * generateNestedPermissions('some.word.white.card.*')
 * output: [some.*, some.word.*, some.word.white.*, some.word.white.card.*]
 */

export const generateNestedPermissions = (permission: string): string[] => {
  const splited = permission.split('.');
  const permissions: string[] = [];

  for (const index in splited) {
    const word = splited[index];

    if (word !== '*') {
      const previusPath = splited.slice(0, +index).join('.');
      const start = previusPath.length > 0 ? `${previusPath}.` : '';
      const end = +index === splited.length - 1 ? '' : '.*';

      permissions.push(`${start}${word}${end}`);
    }
  }

  return permissions;
};

/**
 * Хук позволяющий проверить на доступность пользователя
 * @param {string | string[]} permissionOrPermissionList есть возможность передать конкретный пермишен либо список
 * @param {every | some} strategy есть возможность передать стратегию доступа: some - true если есть хоть одно совпадение, every - true только если все совпало
 * @return {boolean} доступны ли у пользователя все переданные permissionOrPermissionList
 */

export const usePermission = (
  permissionOrPermissionList?: string | string[],
  strategy: 'every' | 'some' = 'every',
): boolean => {
  if (!permissionOrPermissionList) return true;

  const { permissions } = useSelector((state: RootState) => state.auth);

  const isAllow = (permission: string) => {
    const nested = generateNestedPermissions(permission);
    return nested.some(variant => permissions.includes(variant));
  };

  return Array.isArray(permissionOrPermissionList)
    ? permissionOrPermissionList[strategy](isAllow)
    : isAllow(permissionOrPermissionList);
};
