import { apiGet } from './connect';
import { Result } from 'app/types/api';
import { BlankListType } from 'features/Blanks';

export type BlanksResult = Result<BlankListType>;

export const getBlanks = async () => {
  try {
    const { data: response } = await apiGet<BlanksResult>('/catalog/files');
    const { data, success, message, code } = response;
    return {
      data,
      success,
      message,
      code,
    };
  } catch (error) {
    return {
      data: {
        items: [],
      },
      success: false,
      message: 'Неизвестная ошибка сервера!',
      code: 500,
    };
  }
};
