import { LeftOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import { Title } from 'components/Title';
import React from 'react';

interface CartProps {
  title: string;
  goBack: () => void;
  breadcrumbs: JSX.Element;
  children: JSX.Element;
}

export const Cart = ({ title, goBack, breadcrumbs, children }: CartProps) => {
  return (
    <Col md={24} style={{ minHeight: 'calc(100vh - 150px)' }}>
      <Button shape="round" onClick={goBack} style={{ marginBottom: '36px' }}>
        <LeftOutlined /> Вернуться к странице изделия
      </Button>
      {breadcrumbs}
      <Title upper level={4} style={{ marginBottom: '32px' }}>
        {title}: оформление заявки
      </Title>
      {children}
    </Col>
  );
};
