import { Result } from 'app/types/api';
import { FormValues } from 'features/AdditionalProducts/components/Rhodium/Modal';
import { apiPost } from './connect';

export const postClaim = async (form: FormValues) => {
  try {
    const { data } = await apiPost<{ success: boolean; message: string }>('/claims ', form);
    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};
