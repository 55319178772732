import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from 'app/types/residuesFinished';
import * as ResiduesFinishedService from 'app/services/admin/residuesFinished';
import { message as antdMessage } from 'antd';

export const fetchResidues = createAsyncThunk(
  'residuesFinished/fetchResidues',
  async (search: string, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await ResiduesFinishedService.getResidues(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const fetchTypesResidues = createAsyncThunk(
  'residuesTypesFP/getTypesResidues',
  async (_, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await ResiduesFinishedService.getTypesResidues();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const addResidue = createAsyncThunk(
  'residuesFinished/addResidue',
  async (residue: types.ResiduePayload, { rejectWithValue }) => {
    const { data, success, message } = await ResiduesFinishedService.addResidue(
      residue,
    );
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const updateResidue = createAsyncThunk(
  'residuesFinished/updateResidue',
  async (residue: types.ResiduePayloadWithId, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await ResiduesFinishedService.updateResidue(residue);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const deleteResidue = createAsyncThunk(
  'residuesFinished/deleteResidue',
  async (id: number, { rejectWithValue }) => {
    const { success, message } = await ResiduesFinishedService.deleteResidue(
      id,
    );
    if (success) {
      return id;
    } else {
      antdMessage.error(message);
      return rejectWithValue(id);
    }
  },
);

export const getOffersProduct = createAsyncThunk(
  'residuesFinishedOffers/fetchLengthsProduct',
  async (productId: number, { rejectWithValue }) => {
    const {
      data,
      success,
      message,
    } = await ResiduesFinishedService.getOffersProduct(productId);
    if (success && data) {
      return data.data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
