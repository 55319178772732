import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Helmet from 'react-helmet';
import { RootState } from 'app/store/reducer';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/Title';

interface Props extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  titleCode?: string;
  hideTitle?: boolean;
}
export const Page: React.FC<Props> = ({
  children,
  titleCode,
  title = '',
  hideTitle = false,
}) => {
  const { t } = useTranslation(['titles']);

  const userName = useSelector(
    (state: RootState) => state.auth.user?.name,
    shallowEqual,
  );
  const isAuthManager = !!sessionStorage.getItem('jwtAccess');
  const currentTitle = titleCode ? t(titleCode) : title;
  return (
    <>
      <Helmet titleTemplate={isAuthManager ? userName : undefined}>
        <title>{currentTitle}</title>
      </Helmet>
      {!hideTitle && (
        <Title level={3} upper>
          {currentTitle}
        </Title>
      )}
      {children}
    </>
  );
};
