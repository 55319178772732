/* eslint-disable camelcase */
import { apiGet, apiPost } from './connect';
import { Result, ItemsResult } from 'app/types/api';
import {
  Section,
  DocumentsTree,
  TessaDocument,
  Acts,
  ActRequest,
} from 'app/types/documents';
import { LeadRequestForm } from 'app/types/leadRequest';

export const getDocumentSections = async () => {
  const { data } = await apiGet<Result<Section[]>>('/document/sections');

  return data;
};

export const getDocuments = async (id: number) => {
  const { data } = await apiGet<Result<DocumentsTree>>(
    `/document/sections/${id}`,
  );

  return data;
};

export const sendActRequest = async (act: ActRequest) => {
  const { data } = await apiPost<Result<[]>>('/sap/act', act);

  return data;
};

export const getTessaDocuments = async () => {
  const { data } = await apiGet<ItemsResult<TessaDocument>>('tessa/documents');

  return data;
};

export const downloadTessaDocument = async (uuid: string) => {
  const { data } = await apiGet<
    Result<{
      data: string;
      name: string;
    }>
  >(`/tessa/documents/${uuid}`);

  return data;
};

export const getSapActs = async (companySapId: number) => {
  const { data } = await apiGet<Result<Acts>>(`sap/acts/${companySapId}`);
  return data;
};

export const getSpecifications = async (id: string | number) => {
  const { data } = await apiGet<Result<{ data: string; name: string }>>(
    `specification/receive/${id}`,
  );
  return data;
};

export const getLeadRequest = async (period: LeadRequestForm) => {
  const { data } = await apiGet<
    Result<{
      name: string;
      data: string;
      mimeType: string;
    }>
  >('/leads/export', period);
  return data;
};
