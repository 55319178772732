import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'resize-observer-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n/index';

const PROD_HOSTNAME = 'jewelry.krastsvetmet.ru';

if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === PROD_HOSTNAME
) {
  Sentry.init({
    dsn:
      'https://b805f64832d34df6afc11eff59acc1f1@o457531.ingest.sentry.io/5453632',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
