import styled from 'styled-components';
import { Drawer } from 'antd';

export const Bar = styled(Drawer)``;

export const Content = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1190px;
  margin: auto;

  & > :last-child {
    margin-left: 32px;
  }
`;
