import React from 'react';
import { CoilProductionPowerMode } from 'app/types/products';
import { useTranslation } from 'react-i18next';
import { DetailOrderType } from '.';
import * as S from './styled';

export type Props = {
  orderType: DetailOrderType;
  productionPowerMode?: CoilProductionPowerMode;
  getBalanceValue: (type?: 'chain' | 'band') => string | 0;
  balanceUnit?: string;
};

export const ResiduesBalance = ({
  orderType,
  getBalanceValue,
  productionPowerMode = 0,
  balanceUnit,
}: Props) => {
  const { t } = useTranslation('productDetail');

  if (orderType === 'products_production') {
    return (
      <S.Balance span={12}>
        {`${t('availableInProduction')} `}
        <b>{getBalanceValue()}</b>&nbsp;
        {`${balanceUnit}.`}
      </S.Balance>
    );
  }

  if (orderType === 'products_coil') {
    switch (productionPowerMode) {
      case CoilProductionPowerMode.EMPTY:
        return (
          <S.Balance span={12}>
            {`${t('availableInProduction')} `}
            <b>{getBalanceValue()}</b>&nbsp;
            {`${balanceUnit}.`}
          </S.Balance>
        );
      case CoilProductionPowerMode.OVERALL:
        return (
          <S.Balance span={12}>
            {`${t('availableInCoils')} `}
            <b>{getBalanceValue()}</b>&nbsp;
            {`${balanceUnit}.`}
          </S.Balance>
        );
      case CoilProductionPowerMode.TYPE_AND_COATING:
        return (
          <>
            <S.Balance span={12}>
              {`${t('availableForChain')} `}
              <b>{getBalanceValue('chain')}</b>&nbsp;
              {`${balanceUnit}.`}
            </S.Balance>
            <S.Balance span={24}>
              {`${t('availableForBracelet')} `}
              <b>{getBalanceValue('band')}</b>&nbsp;
              {`${balanceUnit}.`}
            </S.Balance>
          </>
        );
      case CoilProductionPowerMode.ONLY_COATING:
        return (
          <S.Balance span={12}>
            {`${t('availableInCoils')} `}
            <b>{getBalanceValue()}</b>&nbsp;
            {`${balanceUnit}.`}
          </S.Balance>
        );
      case CoilProductionPowerMode.ONLY_TYPE:
        return (
          <>
            <S.Balance span={12}>
              {`${t('availableForChain')} `}
              <b>{getBalanceValue('chain')}</b>&nbsp;
              {`${balanceUnit}.`}
            </S.Balance>
            <S.Balance span={24}>
              {`${t('availableForBracelet')} `}
              <b>{getBalanceValue('band')}</b>&nbsp;
              {`${balanceUnit}.`}
            </S.Balance>
          </>
        );
    }
  }
  return <></>;
};
