export const AUTH_INITAPP = 'auth/initApp';
export const AUTH_FETCHLOGIN = 'auth/fetchLogin';
export const AUTH_SAVEUSERINFO = 'auth/saveUserInfo';
export const AUTH_CHECKREFRESHTOKEN = 'auth/checkRefreshToken';

export const CART_FETCHCART = 'cart/fetchCart';

export const CONTRACTS_FETCHCONTRACTS = 'contracts/fetchContracts';
export const CONTRACTS_FETCFREEMETALS = 'contracts/fetchFreeMetals';

export const SET_503 = 'auth/set503';
export const SET_404 = 'auth/set404';
