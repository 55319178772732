import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import { AppDispatch } from 'app/store/store';
import { CreateCompanyDto, UpdateCompanyDto } from 'app/types/adminCompanies';
import { unwrapResult } from '@reduxjs/toolkit';
import { companiesSelector } from '../selectors';

export const useCompanies = () => {
  const dispatch: AppDispatch = useDispatch();

  const state = useSelector(companiesSelector);

  const fetchCompanies = (search: string) => dispatch(actions.fetchCompanies(search));

  const addCompany = async (company: CreateCompanyDto) => {
    const resultAction = await dispatch(actions.addCompany(company));
    if (actions.addCompany.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.item.name : undefined;
    }
  };

  const updateCompany = async (company: UpdateCompanyDto) => {
    const resultAction = await dispatch(actions.updateCompany(company));
    return actions.updateCompany.fulfilled.match(resultAction);
  };

  const deleteCompany = (companyId: number) => {
    return dispatch(actions.deleteCompany(companyId));
  };

  const getChannels = () => {
    return dispatch(actions.getChannels());
  };

  const getAvailableSettings = () => {
    return dispatch(actions.getAvailableSettings());
  };

  const getDisabledRestrictions = () => {
    return dispatch(actions.getDisabledRestrictions());
  };

  const getStatusSettings = () => {
    return dispatch(actions.getStatusSettings());
  };

  const getPromotions = useCallback((companyId: number) => {
    return dispatch(actions.getPromotions(companyId));
  }, []);

  return {
    state,
    fetchCompanies,
    updateCompany,
    deleteCompany,
    addCompany,
    getChannels,
    getDisabledRestrictions,
    getAvailableSettings,
    getStatusSettings,
    getPromotions,
  };
};
