import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Router } from '../components/Router';
import { apiCallInit, apiCallEject } from 'app/services/connect';
import { useCategories } from 'app/ducks/categories';
import { useSyncTabs } from 'hooks/useSyncTabs';
import { useAuth } from 'app/ducks/auth';

export const LayoutContainer = () => {
  const dispatch = useDispatch();
  const { resetState } = useCategories();
  const { i18n } = useTranslation();
  const {
    subscribe: subscribeSyncAnyTabs,
    unsubscribe: unsubscribeSyncAnyTabs,
  } = useSyncTabs();
  const { loginStart } = useAuth();
  const { language } = i18n;

  if (language === 'ru-RU') {
    i18n.changeLanguage('ru');
  }

  useEffect(() => {
    subscribeSyncAnyTabs();
    apiCallEject();
    apiCallInit(dispatch, language);
    loginStart();
    resetState();

    return () => {
      unsubscribeSyncAnyTabs();
      apiCallEject();
    };
  }, [language]);

  return <Router />;
};

export default LayoutContainer;
