import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { SLICE_NAME } from './consts';
import { RenderRequestService } from 'app/services/admin/renderRequests';

export const fetchRenderRequests = createAsyncThunk(
  `${SLICE_NAME}/fetchRenderRequests`,
  async (search: string, { rejectWithValue }) => {
    const { data, success, message } = await RenderRequestService.getRenderRequests(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
