import * as actions from './actions';
import { resetOrdersDetail as $resetOrdersDetail, selectOrder as $selectOrder } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';

export const usePersonalOrders = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.personalOrders);

  const fetchOrders = (search = '') => {
    dispatch(actions.fetchOrders(search));
  };

  const fetchOrder = (id: number) => {
    dispatch(actions.fetchOrder(id));
  };

  const fetchOrderExcel = (id: string) => {
    dispatch(actions.fetchOrderExcel(id));
  };

  const fetchOrderImportExcel = (id: string) => {
    dispatch(actions.fetchOrderImportExcel(id));
  };
  const resetOrdersDetail = () => {
    dispatch($resetOrdersDetail());
  };

  const selectStatusModalOrder = (id: number) => {
    dispatch($selectOrder(id));
  };

  const fetchForecastExcel = () => {
    dispatch(actions.fetchForecastExcel());
  };

  return {
    state,
    fetchOrders,
    fetchOrder,
    fetchOrderExcel,
    fetchOrderImportExcel,
    resetOrdersDetail,
    selectStatusModalOrder,
    fetchForecastExcel,
  };
};
