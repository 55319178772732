import styled from 'styled-components';
import { Modal as AntdModal, Form as AntdForm, Input } from 'antd';
import { Title as OldTitle } from '../Title';
import { Button as AntButton } from 'antd';

export const Modal = styled(AntdModal)`
  min-width: 650px;
`;

export const Title = styled(OldTitle)`
  margin-bottom: 0;
  margin-top: 20px;
`;

export const Form = styled(AntdForm)`
  &&& {
    margin-top: 20px;
  }
`;

export const Description = styled.div`
  margin-top: 36px;
  line-height: 18px;
`;

export const TextArea = styled(Input.TextArea)`
  &&& {
    min-height: 112px;
  }
`;

export const LinkCaption = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 18px;
`;

export const Link = styled.a`
  text-decoration: underline;
`;
export const DateRangeWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
`;

export const Button = styled(AntButton)`
  line-height: 100%;
  border-radius: 3.57em;
`;

export const Text = styled.p`
  text-align: center;
  font-size: 0.857em;
  color: #767676;
`;
