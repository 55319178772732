import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { AdditionalProductItem, MetadataType } from 'app/types/adminAdditional';
import { fetchMetadata, fetchProducts, sendClaim } from './actions';

interface InitialStateType {
  metadata: MetadataType;
  products: AdditionalProductItem[];
  loading: boolean;
  submitting: boolean;
}

const initialState: InitialStateType = {
  products: [],
  metadata: {
    productsMeta: [],
    fittings: [],
    minWeights: [],
    withAlloys: [],
  },
  loading: true,
  submitting: false,
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('additionalProducts/fetch') && action.type.endsWith('/pending');

const AdditionalProductsSlice = createSlice({
  name: 'additionalProducts',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(fetchMetadata.fulfilled, (state, { payload }) => {
      if (payload) {
        state.metadata = payload;
      }
      state.loading = false;
    });
    addCase(fetchProducts.fulfilled, (state, { payload }) => {
      if (payload) {
        state.products = payload?.items;
      }
      state.loading = false;
    });
    addCase(sendClaim.pending, state => {
      state.submitting = true;
    });
    addCase(sendClaim.fulfilled, (state, { payload }) => {
      state.submitting = false;
    });
    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
  },
});

const { reducer } = AdditionalProductsSlice;

export { reducer as additionalProducts };
