import React, { Fragment } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import * as S from '../styled';
import { Balance } from '../../../app/types/contracts';
import { useTranslation } from 'react-i18next';

interface Props {
  balance: Balance;
}

export const BalanceDetails: React.FC<Props> = ({ balance }) => {
  const { t } = useTranslation('personal');
  return (
    <S.Card bordered={false}>
      <S.Title level={3}>{t('metalBalanceHeader')}:</S.Title>
      {balance.length > 0 &&
        balance.map(({ metal, metalFree }) => (
          <Fragment key={metal.id}>
            <S.Text fontSize="18px" strong>
              {`${metal.name} - ${Number(metalFree).toFixed(2)}`}
            </S.Text>
            <br />
          </Fragment>
        ))}

      <div style={{ marginTop: '15px' }}>
        <InfoCircleFilled style={{ marginRight: '5px' }} />
        {t('metalBalanceWarning')}.
      </div>
    </S.Card>
  );
};
