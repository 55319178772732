import { useMemo } from 'react';
import { Residues } from '../OrderTypeTabs/orderTypes/useResidues';
import { getOffersByFilters, getOrderTotal } from '../helpers';
import { OrderType } from 'containers/ProductDetailModalContainer';
import { DetailOrderType } from '..';
import {
  Offers as OffersType,
  CoilProductionPowerMode,
  Offer,
} from '../../../app/types/products';
import { number } from 'helpers/format';
import { assertNever } from 'helpers/typeChecks';
import { WITHOUT_COVERING_ID } from '../Content';

export type Props = {
  order: OrderType | null;
  orderType: DetailOrderType;
  offers: OffersType;
  activeCovering: number | null;
  inCoilsBalance?: Residues;
  balanceUnit?: string;
  chainMinLength?: number;
  productionBalance?: number;
  productionPowerMode: CoilProductionPowerMode;
};

type OffersListMap = {
  mode: CoilProductionPowerMode;
  list: OffersType;
}[];

export const useResiduesBalance = ({
  order,
  orderType,
  offers,
  activeCovering,
  chainMinLength = 0,
  inCoilsBalance,
  productionBalance,
  productionPowerMode,
}: Props) => {
  const getFilteredOffers = (type?: 'chain' | 'band') => {
    let typeId: 1 | 2 | null = null;

    if (type) {
      typeId = type === 'chain' ? 1 : 2;
    }

    const listMap: OffersListMap = [
      {
        mode: 0,
        list: offers
          .filter(offer => (typeId ? offer.typeId === typeId : offer))
          .filter(offer => offer.coveringId === activeCovering),
      },
      {
        mode: 1,
        list: offers,
      },
      {
        mode: 2,
        list: offers
          .filter(offer => (typeId ? offer.typeId === typeId : offer))
          .filter(offer =>
            activeCovering === WITHOUT_COVERING_ID
              ? offer.coveringId === WITHOUT_COVERING_ID
              : offer.coveringId !== WITHOUT_COVERING_ID,
          ),
      },
      {
        mode: 3,
        list: offers.filter(offer =>
          activeCovering === WITHOUT_COVERING_ID
            ? offer.coveringId === WITHOUT_COVERING_ID
            : offer.coveringId !== WITHOUT_COVERING_ID,
        ),
      },
      {
        mode: 4,
        list: offers.filter(offer =>
          typeId ? offer.typeId === typeId : offer,
        ),
      },
    ];

    return (
      listMap.find(item => item.mode === productionPowerMode)?.list ??
      listMap[0].list
    );
  };

  const total = useMemo(() => {
    const currentOrder = order?.[orderType] || null;
    const list = getFilteredOffers();
    return getOrderTotal({
      order: currentOrder,
      offers: list,
      chainMinLength,
      orderType,
      activeCovering,
    });
  }, [order, orderType, activeCovering]);

  const getTotalByType = (type: 'chain' | 'band') => {
    const currentOrder = order?.[orderType] || null;
    const list = getFilteredOffers(type);
    return getOrderTotal({
      order: currentOrder,
      offers: list,
      chainMinLength,
      type,
      orderType,
      activeCovering,
    });
  };

  const getBalance = (type?: 'chain' | 'band') => {
    if (orderType === 'products_production') {
      return productionBalance;
    }

    if (orderType === 'products_coil') {
      if (inCoilsBalance === undefined) return null;

      switch (inCoilsBalance.mode) {
        case CoilProductionPowerMode.EMPTY:
          return inCoilsBalance.residues.balance;
        case CoilProductionPowerMode.OVERALL: {
          return inCoilsBalance.residues.overall;
        }
        case CoilProductionPowerMode.TYPE_AND_COATING: {
          if (type) {
            const { withCoating, withoutCoating } = inCoilsBalance.residues;
            return activeCovering === 1
              ? withoutCoating[type]
              : withCoating[type];
          }
          break;
        }
        case CoilProductionPowerMode.ONLY_COATING: {
          const { withCoating, withoutCoating } = inCoilsBalance.residues;
          return activeCovering === 1 ? withoutCoating : withCoating;
        }
        case CoilProductionPowerMode.ONLY_TYPE: {
          const balanceValue = inCoilsBalance.residues;
          return type ? balanceValue[type] : null;
        }
        default:
          return assertNever(inCoilsBalance);
      }
    }
    return null;
  };

  const getLosePower = (type?: 'chain' | 'band') => {
    const selectedWeight = !type ? total.weight : getTotalByType(type).weight;
    const balanceValue = getBalance(type);

    return balanceValue ? balanceValue - selectedWeight : 0;
  };

  const getBalanceValue = (type?: 'chain' | 'band') => {
    return getLosePower(type) < 0 ? 0 : number(getLosePower(type));
  };

  return {
    total,
    getBalanceValue,
    getLosePower,
  };
};
