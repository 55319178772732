import { Alert, Divider } from 'antd';
import { RootState } from 'app/store/reducer';
import { Tabs } from 'components/KrasUIKit';
import { removeBars, setBars, updateBars } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { BarsItem, OrderItem } from 'features/AdditionalProducts/types/OrderItem';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BarsOrder } from './Order';

interface AlloyTabsProps {
  sortedByAlloy: ProductTab[];
  onTabChange?: (tabIndex: number) => void;
}

export const AlloysTabs = ({ sortedByAlloy, onTabChange }: AlloyTabsProps) => {
  const { t } = useTranslation(['additional']);
  const [activeTab, setActiveTab] = useState<string | number>(1);
  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.additionalProductsOrder);

  const handleChange = (orderItem: BarsItem) => {
    const { id, alloyId, weight } = orderItem;
    const finded = order.bar.filter(item => item.id === id && item.alloyId === alloyId);

    if (!weight) {
      dispatch(removeBars(orderItem));
      return null;
    }

    if (!finded.length) {
      dispatch(setBars(orderItem));
    } else {
      dispatch(updateBars(orderItem));
    }
  };

  useEffect(() => {
    onTabChange && onTabChange(Number(activeTab));
  }, []);

  const handleTabChange = (tabIndex: string | number) => {
    setActiveTab(tabIndex);
    onTabChange && onTabChange(Number(tabIndex));
  };

  const tabs = sortedByAlloy.map((tab, index) => ({
    id: index + 1,
    count: order.bar.filter(item => item.alloyId === tab.alloy.id).length,
    title: tab.alloy.name,
    content: (
      <>
        {tab.products.map(product => {
          const [finded] = order.bar.filter(item => item.id === product.id && item.alloyId === tab.alloy.id);
          return (
            <BarsOrder
              key={`${tab.alloy.id}-${product.id}`}
              diameter={product.features[0].value}
              defaultValue={finded ? finded.weight : undefined}
              onChange={(value: number) => {
                const orderItem = {
                  id: product.id,
                  diameter: Number(product.features[0].value),
                  weight: value,
                  alloyId: tab.alloy.id,
                };
                handleChange(orderItem);
              }}
            />
          );
        })}
      </>
    ),
  }));

  const getTabContent = (tabIndex: string | number) => {
    const neededTab = tabs.find(tab => tab.id === tabIndex);
    if (!tabIndex || !neededTab) return null;
    return neededTab.content;
  };
  return (
    <>
      <Alert
        message=""
        description={t('alert', { silver: 300, gold: 150 })}
        type="info"
        showIcon
        style={{
          margin: '20px 0',
        }}
      />
      <Tabs tabs={tabs} active={activeTab} onChange={handleTabChange} />
      {getTabContent(activeTab)}
    </>
  );
};
