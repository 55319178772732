import styled, { keyframes } from 'styled-components';
import { success } from 'components/KrasUIKit/icons';

const ZoomIn = keyframes`
 0% {
   transform: scale(0);
   opacity: 0;
 }
 100% {
   transform: scale(1);
   opacity: 1;
 }
`;

export const ExpiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ExpiredMessage = styled.div`
  font-size: 1rem;
  margin: 1em 0 1.5em;
`;
export const Li = styled.li<{ isValid: boolean }>`
  position: relative;
  list-style-type: ${({ isValid }) => (isValid ? 'none' : 'disc')};
`;
export const ValidIcon = styled.span`
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: 0.8em;
  transform: translate(0, -50%);
  left: -1.25em;
  color: #52c41a;
  svg {
    animation: ${ZoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
`;
