import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { CatalogLevel } from 'app/types/RENAME__catalog';
import { message as antdMessage } from 'antd';
import * as types from 'app/types/categories';

export interface CategoriesState {
  loading: boolean;
  searchLoading?: boolean;
  list: types.Categories;
  ccatrogies: types.CCategories;
  renameCategory: CatalogLevel;
  products: types.Products;
  lastCategory?: number;
  searchedProducts: types.Products;
  pagination: {
    total: number;
    perPage: number;
    current: number;
  } | null;
  relations: { [key: number]: number[] };
  productModal?: boolean;
  detail: null | number;
  detailShowParams: null | 0 | 1;
}

export const categoriesInitialState: CategoriesState = {
  loading: false,
  list: [],
  ccatrogies: [],
  products: [],
  searchedProducts: [],
  relations: {},
  pagination: null,
  renameCategory: [],
  detail: null,
  detailShowParams: null,
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('categories/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('categories/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('categories/') && action.type.endsWith('/fulfilled');

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState,
  reducers: {
    resetState() {
      return categoriesInitialState;
    },
    resetCategoryDetail(state) {
      state.detail = null;
      state.detailShowParams = null;
    },
    openProductDetail(state) {
      state.productModal = true;
    },
    openDetail(
      state,
      {
        payload,
      }: PayloadAction<{ id: number; detailShowParams: null | 1 | 0 }>,
    ) {
      state.detail = payload.id;
      state.detailShowParams = payload.detailShowParams;
    },
    closeProductDetail(state) {
      state.productModal = false;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchProducts.fulfilled,
      (
        state,
        {
          payload: { data: products, id },
        }: PayloadAction<{ data: types.Products; id: number }>,
      ) => {
        state.products = products;
        state.lastCategory = id;
      },
    );
    addCase(
      actions.fetchProductCategory.fulfilled,
      (state, { payload: categories }: PayloadAction<CatalogLevel>) => {
        state.renameCategory = categories;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      (state: CategoriesState) => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) antdMessage.error(action.error.message);
        state.loading = false;
      },
    );
  },
});

export const {
  openProductDetail,
  closeProductDetail,

  resetState,
  resetCategoryDetail,

  openDetail,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
