import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as actions from './actions';

export const useRenderRequests = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.adminRenderRequests);

  const fetchRenderRequests = (search: string) => {
    return dispatch(actions.fetchRenderRequests(search));
  };

  return {
    state,
    fetchRenderRequests,
  };
};
