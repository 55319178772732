import React, { useEffect, useState } from 'react';
import { refresh } from 'components/KrasUIKit/icons';
import * as S from './MetalCostInfo.styled';
import dayjs from 'dayjs';
import { dateTimeWithTimeZone, number } from 'helpers/format';
import { MOSCOW_ZONE } from 'constants/dateFormats';
import {
  MENA_BUY_DESCRIPTION,
  MENA_SELL_DESCRIPTION,
  METAL_COST_WITH_NDS,
  metalCostInfoMap,
  PRICES_DESCRIPTION,
} from './consts';
import { MenaCodes, MenaEntity, PriceEntity, PricesCodes } from 'app/types/metalCost';
import { sendEvent } from 'logger';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { executeLink } from 'utils/executeLink';

type MetalCostInfoProps = {
  menas: MenaEntity[] | null;
  prices: PriceEntity[] | null;
  withNds: boolean;
  isLoadingFile?: boolean;
  onCheckFile: (payload: {
    filepath: string;
  }) => Promise<{
    filepath: string;
  }>;
};

export const MetalCostInfo = ({ menas, prices, withNds, isLoadingFile, onCheckFile }: MetalCostInfoProps) => {
  const [lastPageUpdate, setLastPageUpdate] = useState<dayjs.Dayjs>();
  const [downloadingId, setDownloadingId] = useState<string>();

  const getPureCost = (value: number) => number((value / 587) * 1000, 0);
  const ndsCaption = withNds ? ' ' + METAL_COST_WITH_NDS : '';

  const actualList = menas || prices || [];

  const downloadFileHandler = async (filepath: string, code: MenaCodes | PricesCodes) => {
    setDownloadingId(code);
    const response = await onCheckFile({ filepath });
    if (response.filepath) {
      executeLink(response.filepath);

      code && sendEvent(String(metalCostInfoMap[code].eventName));
    } else {
      message.error(message);
    }
    setDownloadingId(undefined);
  };

  useEffect(() => {
    setLastPageUpdate(dayjs());
  }, []);

  return (
    <S.Wrapper>
      {[...actualList]
        .sort((a, b) => a.sort - b.sort)
        .map(({ code, price, filepath }) => (
          <React.Fragment key={code}>
            <S.CostWrapper>
              <S.Title>
                <S.CostIcon>{metalCostInfoMap[code].icon}</S.CostIcon>
                {metalCostInfoMap[code].title}:
              </S.Title>
              <S.Cost>
                {number(price, 0)} руб.
                <S.CostSecondary>(585 проба{ndsCaption})</S.CostSecondary>
              </S.Cost>
              {(code === 'buy' || code === 'sell') && (
                <S.CostDescription>
                  {getPureCost(price)} руб. (ХЧГ{ndsCaption})
                </S.CostDescription>
              )}
              {filepath && (
                <S.LinkWrapper>
                  <S.LinkButton
                    type="link"
                    disabled={isLoadingFile}
                    onClick={() => downloadFileHandler(filepath, code)}
                  >
                    {metalCostInfoMap[code].linkTitle}
                  </S.LinkButton>
                  <Spin
                    spinning={isLoadingFile && code === downloadingId}
                    indicator={<LoadingOutlined spin />}
                    size="small"
                  />
                </S.LinkWrapper>
              )}
            </S.CostWrapper>
            <S.Divider />
          </React.Fragment>
        ))}
      {menas && (
        <React.Fragment>
          <S.Description>{MENA_BUY_DESCRIPTION}</S.Description>
          <S.Description>{MENA_SELL_DESCRIPTION}</S.Description>
        </React.Fragment>
      )}
      {prices && <S.Description>{PRICES_DESCRIPTION}</S.Description>}
      {prices && (
        <S.FooterText>
          Минимальный заказ от <b>200 гр.</b>
        </S.FooterText>
      )}
      <S.FooterText>
        <S.UpdateIcon>{refresh}</S.UpdateIcon>
        {!!lastPageUpdate ? `обновлено ${dateTimeWithTimeZone(lastPageUpdate, MOSCOW_ZONE)} (МСК)` : 'обновление...'}
      </S.FooterText>
    </S.Wrapper>
  );
};
