import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Table as AntTable } from 'antd';
import { Orders } from 'app/types/orders';
import { CompanyResponse, StatusSettings } from 'app/types/company';
import { number } from 'helpers/format';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import { OrderItem } from 'app/types/orders';
import { OrderNumber } from './OrderNumber';
import { Tracking } from './Tracking';
import { Actions } from './Actions';
import * as S from '../styled/Table.styled';
import { Order } from 'features/AdditionalProducts/styled/Bars.styled';
import { OrderPercentage } from './OrderPercentage';
import { UserAccount } from 'app/types/user';

const { Column } = AntTable;

type TableProps = {
  orders: Orders;
  company: CompanyResponse | null;
  orderStatusOptions: StatusSettings;
  showDiscount?: boolean;
  onStatusClick: (orderNumber: number) => void;
};

type RenderFunction = {
  (value: unknown, record: OrderItem): React.ReactNode;
};

export const Table = ({ orders, company, orderStatusOptions, showDiscount = false, onStatusClick }: TableProps) => {
  const { i18n, t } = useTranslation(['personal']);
  const { toRubles } = useUnitsConverter();

  const { language } = i18n;
  const isRu = language === 'ru';

  const allowModalShow = orderStatusOptions ? orderStatusOptions.allowModalShow : false;

  const renderOrderNumber: RenderFunction = (_, { numberOrder, order_type: orderType }) => (
    <OrderNumber numberOrder={numberOrder} orderType={orderType} />
  );

  const renderBonus: RenderFunction = (_, { discount }) =>
    discount ? (
      <Fragment>
        <S.SecondaryText>Использовано</S.SecondaryText>
        {toRubles(discount)}
      </Fragment>
    ) : (
      ''
    );

  const renderTracking: RenderFunction = (_, { dateCreate, dateRelease }) => {
    const showReleaseDate = (dateRelease && company && company.settings.order_date_release) ?? null;
    return <Tracking dateCreate={dateCreate} dateRelease={dateRelease} showReleaseDate={showReleaseDate} />;
  };

  const renderWeight: RenderFunction = (_, { weight }) => number(weight);

  const renderMetal: RenderFunction = (_, { metal }) => metal.name;

  const renderComment: RenderFunction = (_, { comment, userName }) => (
    <Fragment>
      <S.MainText>{userName}</S.MainText>
      <S.SecondaryText>{comment}</S.SecondaryText>
    </Fragment>
  );

  const renderActions: RenderFunction = (_, { numberOrder, specifications }) => (
    <Actions orderId={numberOrder} specifications={specifications} />
  );
  const renderPercentage: RenderFunction = (_, { status, numberOrder, orderReadyPercent }) => {
    if (status !== 'percent') {
      if (status === 'waiting') return <span> Заказ обрабатывается</span>;
      if (status === 'in_progress') return <span> В работе </span>;
      if (status === 'done') return <span>Выполнено</span>;
    } else {
      return (
        <OrderPercentage
          onClick={() => onStatusClick(numberOrder)}
          percentage={orderReadyPercent ?? 0}
          shipped={false}
        />
      );
    }
  };

  return (
    <AntTable pagination={false} dataSource={orders} size="middle">
      <Column title={t('orderNumber')} dataIndex="id" render={renderOrderNumber} />
      {isRu && showDiscount && <Column title="Бонусы" dataIndex="discount" render={renderBonus} />}
      <Column title={t('tracking')} dataIndex="tracking" render={renderTracking} />
      {allowModalShow && <Column title="Статус заказа" dataIndex="readyPercentage" render={renderPercentage} />}
      <Column
        title={t('quantity')}
        dataIndex="count"
        align="right"
        sorter={(a: OrderItem, b: OrderItem) => a.count - b.count}
      />
      <Column
        title={t('weight')}
        dataIndex="weight"
        align="right"
        sorter={(a: OrderItem, b: OrderItem) => a.weight - b.weight}
        render={renderWeight}
      />
      <Column title={t('metal')} dataIndex="metal" render={renderMetal} />
      <Column title={t('contractNumber')} dataIndex="contractNumber" />
      <Column title={t('сomment')} dataIndex="сomment" width={150} render={renderComment} />
      <Column dataIndex="actions" render={renderActions} />
    </AntTable>
  );
};
