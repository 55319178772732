import React, { useEffect } from 'react';
import * as S from './AutoOrders.styled';
import { Table } from './Table';
import { Filters } from './Filters';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { useAutoOrders } from 'app/ducks/autoOrders';
import { Pagination, Row, Spin } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { useHistory, useLocation } from 'react-router-dom';
import { getSearchWithCompanyId } from '../utils';

export const AutoOrders = () => {
  const {
    list,
    pagination,
    statuses,
    company,
    contracts,
    metals,
    isLoading,
    getAutoOrders,
    cancelAutoOrder,
  } = useAutoOrders();

  const query = useQuery();
  const history = useHistory();
  const { search } = useLocation();

  const changePageHandler = (page: number) => {
    query.set('page', page.toString());
    history.push({ search: query.toString() });
  };

  useEffect(() => {
    if (!!company) {
      const searchWithCompanyId = getSearchWithCompanyId(company.id, search);
      getAutoOrders(searchWithCompanyId);
    }
  }, [search, company]);

  return (
    <S.Wrapper>
      <Spin spinning={isLoading}>
        <Filters company={company} contracts={contracts} statuses={statuses} metals={metals} />
        <Table dataSource={list} pagination={false} rowKey="id" onCancelOrder={cancelAutoOrder} />
        {pagination && (
          <Row align="middle" justify="center" style={{ padding: 10 }}>
            <Pagination
              total={pagination.total}
              pageSize={pagination.perPage}
              current={pagination.currentPage}
              hideOnSinglePage
              showSizeChanger={false}
              onChange={changePageHandler}
            />
          </Row>
        )}
      </Spin>
    </S.Wrapper>
  );
};
