/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import * as types from 'app/types/products';
import { PaginationNew } from 'app/types/pagination';
import { Metal } from 'app/types/orders';
import { message as antdMessage } from 'antd';

export interface ProductsAdminState {
  products: types.AdminProduct[];
  pagination?: PaginationNew;
  loading: boolean;
  coatingList: types.Coating[];
  metals: Metal[];
  features: types.Feature[];
  alloys: types.Alloys[];
  detail: types.Detail;
  offerTypes: types.ProductType[];
  details: types.AdminProductsDetails;
  productBadgeId: number | null;
  badgeDateEnd: string | null;
}

export const productsInitialState: ProductsAdminState = {
  loading: false,
  products: [],
  coatingList: [],
  metals: [],
  features: [],
  alloys: [],
  offerTypes: [],
  detail: undefined,
  details: {
    manufacturingTypes: [],
  },
  productBadgeId: null,
  badgeDateEnd: null,
};
const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('productsAdmin/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('productsAdmin/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('productsAdmin/') && action.type.endsWith('/fulfilled');

const productsSlice = createSlice({
  name: 'productsAdmin',
  initialState: productsInitialState,
  reducers: {
    setProductDetail(state, { payload }: PayloadAction<types.Detail>) {
      state.detail = payload;
    },
    saveOffers(state, { payload }: PayloadAction<types.AdminOffer[]>) {
      if (state.detail) state.detail.offers = payload;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchProducts.pending, state => {
      state.loading = true;
    });
    addCase(actions.fetchProducts.fulfilled, (state, { payload }) => {
      state.products = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.addProduct.fulfilled, (state, { payload }) => {
      if (payload.item) {
        state.detail = payload.item;
      }
    });
    addCase(actions.updateProduct.fulfilled, (state, { payload }) => {
      const index = state.products.findIndex(item => item.id === payload.item?.id);
      if (payload.item) {
        state.products[index] = payload.item;
      }
    });
    addCase(actions.getCoatings.fulfilled, (state, { payload: coatings }: PayloadAction<types.Coating[]>) => {
      state.coatingList = coatings;
    });
    addCase(actions.getMetals.fulfilled, (state, { payload: metals }: PayloadAction<Metal[]>) => {
      state.metals = metals;
    });
    addCase(actions.getFeatures.fulfilled, (state, { payload: features }) => {
      state.features = features.items;
    });
    addCase(actions.getAlloys.fulfilled, (state, { payload: alloys }) => {
      state.alloys = alloys.items;
    });
    addCase(actions.getOfferTypes.fulfilled, (state, { payload }) => {
      state.offerTypes = payload;
    });
    addCase(actions.updateOffer.fulfilled, (state, { payload }: PayloadAction<types.AdminOffer>) => {
      if (state.detail && state.detail.offers !== null) {
        const indexOffer = state.detail.offers.findIndex(item => item.id === payload.id);
        if (indexOffer > -1) {
          state.detail.offers[indexOffer] = payload;
        }
      }
    });
    addCase(actions.addOffer.fulfilled, (state, { payload }: PayloadAction<types.AdminOffer>) => {
      if (state.detail && state.detail.offers !== null) {
        state.detail.offers[0] = payload;
      }
    });
    addCase(actions.setProductImage.fulfilled, (state, { payload }: PayloadAction<types.ProductImagePayload>) => {
      if (state.detail) {
        const { productId, ...data } = payload;
        state.detail.images = data;
      }
    });
    addCase(actions.setCoatingImage.fulfilled, (state, { payload }: PayloadAction<types.ImageCoatingPayload>) => {
      if (state.detail && state.detail.covering) {
        const { coatingId, ...data } = payload;
        const coatingIndex = state.detail.covering.findIndex(item => item.id === coatingId);
        state.detail.covering[coatingIndex].images = data;
      }
    });
    addCase(actions.setAdditionalImage.fulfilled, (state, { payload }: PayloadAction<types.ImageAdditionalPayload>) => {
      if (state.detail) {
        // TODO: пересмотреть
        const { ...data } = payload.item;
        state.detail.additionalImages.push(data);
      }
    });
    addCase(actions.deleteAdditionalImage.fulfilled, (state, { payload }: PayloadAction<number>) => {
      if (state.detail) {
        const imageIndex = state.detail.additionalImages.findIndex(item => item.id === payload);
        if (imageIndex > -1) state.detail.additionalImages.splice(imageIndex, 1);
      }
    });
    addCase(actions.getDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
    });

    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) {
          antdMessage.error(action.error.message);
        }
        state.loading = false;
      },
    );
  },
});
export const { setProductDetail, saveOffers } = productsSlice.actions;
export default productsSlice.reducer;
