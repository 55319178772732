import styled from 'styled-components';

const getStylesByType = (
  typeTag?: string,
  checked?: boolean,
  color?: string,
  fillColor?: string,
  disabled?: boolean,
  border?: string,
) => {
  const defaultStyles = `
    background-color: transparent;
    font-size: 14px;
    border: ${border};
  ${disabled ? 'cursor: not-allowed; color: #4f4f4f;' : 'color: #4f4f4f;'}
  `;

  switch (typeTag) {
    case 'dashed': {
      return `
        ${defaultStyles}
        ${
          disabled
            ? `cursor: not-allowed; border: 1px dashed  ${
                fillColor ? fillColor : 'currentColor'
              };
              color: ${color ? color : '#4f4f4f'} ;`
            : `
              border: 1px dashed  ${fillColor ? fillColor : '#4f4f4f'};
              color: ${color ? color : '#4f4f4f'}
              :hover {
                opacity: 0.8;
              }
            `
        }
      `;
    }
    case 'primary': {
      return `
        color: ${color ? color : '#4f4f4f'} 
        background-color: ${fillColor ? fillColor : '#f4f4f4'};  
        border: ${border};
        ${disabled ? 'cursor: not-allowed;' : ''}
      `;
    }
    case 'checkable': {
      return `
        ${defaultStyles}
        ${
          checked
            ? 'background-color: #f4f4f4;'
            : 'background-color: transparent;'
        }
      `;
    }
    default: {
      return defaultStyles;
    }
  }
};

const getStylesBySize = (
  size?: string,
  closable?: boolean,
  icon?: boolean,
  arrow?: boolean,
) => {
  switch (size) {
    case 'medium':
      return `
        padding: 0.133em ${closable || arrow ? '0' : '0.4em'} 0.133em
        ${icon ? '0' : '0.4em'};
        font-size: 0.857em;
        // line-height: 1.167em;
    `;
    case 'large':
      return `
        padding: 0.571em ${closable || arrow ? '0' : '0.857em'} 0.571em
        ${icon ? '0' : '0.857em'};
        font-size: 1em;
        // line-height: 1.143em;
      `;
    default:
      return `
        padding: 0.571em ${closable || arrow ? '0' : '0.857em'} 0.571em
        ${icon ? '0' : '0.857em'};
        font-size: 1em;
        // line-height: 1.143em;
    `;
  }
};

const getStylesBySizeAffix = (size?: string) => {
  switch (size) {
    case 'medium':
      return `
        padding-right: 0.375em;
        font-size: 1em;
    `;
    case 'large':
      return `
        padding-right:0.857em;
        font-size: 1.143em;
      `;
    default:
      return `
        padding-right:0.857em;
        font-size: 1.143em;
    `;
  }
};

const getStylesBySizeIcon = (size?: string) => {
  switch (size) {
    case 'medium':
      return `
        padding-left: 0.375em;
        svg {
          // width: 1em;
          height: 1em;
        }
    `;
    case 'large':
      return `
        padding-left:0.857em;
        svg {
          // width: 1.143em;
          height: 1.143em;
        }
      `;
    default:
      return `
        padding-left:0.857em;
        svg {
          // width: 1.143em;
          height: 1.143em;
        }
    `;
  }
};

export const Icon = styled.div<{
  disabled?: boolean;
  size?: string;
}>`
  display: flex;
  align-self: center;
  margin-right: 0.286em;
  ${props => getStylesBySizeIcon(props.size)}
`;

export const Tag = styled.button<{
  typeTag?: string;
  size?: string;
  checked?: boolean;
  color?: string;
  fillColor?: string;
  disabled?: boolean;
  border: string;
}>`
  display: inline-flex;
  padding: 0;
  cursor: pointer;
  border-radius: 3px;
  ${props =>
    getStylesByType(
      props.typeTag,
      props.checked,
      props.color,
      props.fillColor,
      props.disabled,
      props.border,
    )}
`;

export const Title = styled.span<{
  size?: string;
  closable?: boolean;
  icon?: boolean;
  arrow?: boolean;
}>`
  font-weight: bold;
  ${props =>
    getStylesBySize(props.size, props.closable, props.icon, props.arrow)}
`;

export const Affix = styled.div<{
  disabled?: boolean;
  size?: string;
}>`
  display: flex;
  align-self: center;
  margin-left: 0.286em;
  ${props => getStylesBySizeAffix(props.size)}
  button {
    color: currentColor;
    font-size: 1em;
    :hover {
      color: currentColor;
    }
    :disabled {
      color: currentColor;
      :hover {
        color: currentColor;
      }
    }
  }
`;
