import React, { useState } from 'react';
import { Alert, Tabs } from 'components/KrasUIKit';
import { Alerts } from 'components/ProductDetail/Alerts';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

type RhoduimTab = {
  id: number;
  content: JSX.Element;
  count?: number;
  title: string;
  productId: number;
};

export type RhodiumOrderProps = {
  tabs: RhoduimTab[];
  activeTab: number | string;
  onTabChange: (id: number) => void;
};

export const RhodiumOrder = ({ tabs, activeTab, onTabChange }: RhodiumOrderProps) => {
  const tabClickHandler = (id: number | string) => {
    onTabChange(Number(id));
  };

  const getTabContent = (tabIndex: string | number) => {
    const neededTab = tabs.find(tab => tab.id === tabIndex);
    if (!tabIndex || !neededTab) return null;

    return neededTab.content;
  };

  const activeTabContent = getTabContent(activeTab);
  return (
    <div style={{ padding: '10px 0 0 0' }}>
      <Tabs active={activeTab} onChange={tabClickHandler} tabs={tabs} />
      {activeTabContent}
    </div>
  );
};
