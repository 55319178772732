import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { switchMap, catchError, pluck } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { getContractsForEpic, getFreeMetalsForEpic } from 'app/services/contracts';

import {
  getContractsFailed,
  getContractsSucces,
  getFreeMetalsFailed,
  getFreeMetalsSuccess,
} from 'features/slices/contracts';
import { message as AntMessage } from 'antd';

import { AppEpic } from '../epic';
import { fetchContracts, fetchFreeMetals } from './actions/contracts';
import { actionTypes, sendEvent } from 'logger';

//TODO: fix ts error
//@ts-ignore
export const fetchContractsEpic: AppEpic = action$ => {
  const enterpointAction = () => {
    sendEvent(actionTypes.ENTERPOINT);
    return { type: '' };
  };
  return action$.pipe(
    ofType(fetchContracts().type),
    pluck<Action, number>('payload'),
    switchMap(companyId =>
      from(getContractsForEpic(companyId)).pipe(
        switchMap(({ data: response }) => {
          const { data, success, message } = response;

          if (success) {
            const activeContractId = data.find(({ active }) => active)?.sapNumber;

            if (activeContractId) {
              return of(getContractsSucces(data), fetchFreeMetals(activeContractId), enterpointAction());
            }
            return of(getContractsSucces(data), enterpointAction());
          } else {
            AntMessage.info(message);
            return of(getContractsFailed(), enterpointAction());
          }
        }),
        catchError(() => AntMessage.error('Ошибка сети при инициализации контрактов.')),
      ),
    ),
  );
};

//TODO: fix ts error
//@ts-ignore
export const fetchFreeMetalsEpic: AppEpic = action$ => {
  return action$.pipe(
    ofType(fetchFreeMetals().type),
    pluck<Action, number>('payload'),
    switchMap(activeContractId =>
      from(getFreeMetalsForEpic(activeContractId)).pipe(
        switchMap(({ data: response }) => {
          const { success, data, message } = response;

          if (success) {
            return of(getFreeMetalsSuccess(data));
          } else {
            AntMessage.info(message);
            return of(getFreeMetalsFailed());
          }
        }),
        catchError(() => AntMessage.error('Ошибка сети при инициализации баланса металлов.')),
      ),
    ),
  );
};
