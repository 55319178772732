import React from 'react';
import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

export const OrderTerm: React.FC = () => {
  const { t } = useTranslation('productDetail');
  return (
    <Text>
      <InfoCircleOutlined /> {t('orderTime')}
    </Text>
  );
};
