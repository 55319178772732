import { apiGet } from './connect';
import { Result } from 'app/types/api';
import { ClosedPositionsDateType, ReasonList } from 'app/types/closedPositions';
export type ClosedPositionsResult = Result<{
  date: ClosedPositionsDateType;
  reasons: ReasonList;
}>;

export const getClosedPositions = async () => {
  const { data } = await apiGet<ClosedPositionsResult>('/closeopen');
  return data;
};
