import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';

export const useMetalCost = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.metalCost);

  const getActiveMetalCost = () => {
    return dispatch(actions.getActiveMetalCosts());
  };

  const checkFileLink = async (payload: { filepath: string }) => {
    return dispatch(actions.checkFileLink(payload))
      .unwrap()
      .then(res => res.item);
  };

  return {
    state,
    getActiveMetalCost,
    checkFileLink,
  };
};
