/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { apiGet, apiDelete, apiPost } from './connect';
import { Categories, CCategories, Products, CCategory } from 'app/types/categories';
import { Result } from 'app/types/api';
import { keysToCamel } from './helper';
import { Pagination } from 'app/types/pagination';

export type CCategoryResult = Result<CCategory>;
export type CategoriesResult = Result<Categories>;
export type CCategoriesResult = Result<CCategories>;
export type ProductsResult = Result<Products>;
export type FindProductData = {
  list: Products;
  pagination: Pagination;
};
export type FindProductsResult = Result<FindProductData>;

export type SuggestionsResult = Result<{
  total: number;
  count: number;
  list: [];
}>;

export const getCategoriesTree = async () => {
  try {
    const { data: response } = await apiGet<CategoriesResult>('/catalog/tree_sections');
    const { data, success, message, code } = response;

    return {
      data,
      success,
      message,
      code,
    };
  } catch (error) {
    return {
      data: [],
      success: false,
      message: 'Неизвестная ошибка сервера!',
      code: 500,
    };
  }
};

export const getCategories = async () => {
  try {
    const { data: response } = await apiGet<CCategoriesResult>('/catalog/sections');
    const { data, success, message, code } = response;

    return keysToCamel({
      data,
      message,
      success,
      code,
    });
  } catch (error) {
    return {
      data: [],
      success: false,
      message: 'Неизвестная ошибка сервера!',
      code: 500,
    };
  }
};

export const getProducts = async (id: number) => {
  const { data: response } = await apiGet<ProductsResult>(`products/category/${id}`);
  const { data, success, message } = response;

  return keysToCamel({
    data,
    message,
    success,
  });
};

export const findProducts = async (query: string, page = 1) => {
  try {
    const { data: response } = await apiGet<FindProductsResult>('/products/search', {
      query,
      page,
    });
    const { data, success, message } = response;

    return keysToCamel({
      data,
      message,
      success,
    });
  } catch (error) {
    throw new Error('qwe');
  }
};

export const getProductSuggestions = async (query: string) => {
  try {
    const { data: response } = await apiGet<SuggestionsResult>('/products/search', {
      query,
    });
    const { data, success, message } = response;

    return keysToCamel({
      data,
      message,
      success,
    });
  } catch (error) {
    throw new Error('qwe');
  }
};

export const deleteCategory = async (categoryId: number) => {
  const { data } = await apiDelete<Result<[]>>(`/catalog/section/${categoryId}`);
  return data;
};

export const updateCategory = async (id: number, body: { image?: string; show_params?: number }) => {
  const { data } = await apiPost<CCategoryResult>(`/catalog/section/${id}`, body);
  return data;
};

export const addCategory = async (category: CCategory) => {
  const { data } = await apiPost<CCategoryResult>('/catalog/section', {
    ...category,
    // eslint-disable-next-line @typescript-eslint/camelcase
    parent_id: category.parentId ? category.parentId : undefined,
  });
  return data;
};
