import React from 'react';
import { Row } from 'antd';
import { Count } from 'components/ProductDetail/Count';
import { FormContainer, Button, Note } from '../../styled/Footer.styled';
import { FormWrapper } from '../../styled/Common.styled';
import { Tooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

interface FormProps {
  value: number | undefined;
  onChange: (value: number) => void;
  onSubmit: () => void;
  disabled: boolean;
}

export const Footer = ({ value, onChange, onSubmit, disabled }: FormProps) => {
  const { t } = useTranslation(['additional']);
  return (
    <FormWrapper>
      <FormContainer>
        <Row justify="end" align="middle">
          <Note>
            <Tooltip>
              <>{t('tooltip.decription')}</>
            </Tooltip>
            {t('tooltip.title')}
          </Note>
          <Count count={value ?? 0} onChange={onChange} article="" hideTenIncr={true} />
          <Button type="primary" onClick={onSubmit} disabled={disabled}>
            {t('submit')}
          </Button>
        </Row>
      </FormContainer>
    </FormWrapper>
  );
};
