/* eslint-disable prettier/prettier */
import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import arrayToTree from 'array-to-tree';
import { Document } from './Document';
import { DocumentSectionEntity } from 'app/types/documentsSection';
import * as S from '../Personal/styled';

type TreeSection = DocumentSectionEntity & { childrenList?: TreeSection[] };
export interface Props {
  data?: TreeSection[];
  level?: number;
  loading?: boolean;
  id?: number;
}

export const Section: React.FC<Props> = ({
  loading,
  data = [],
  id: sectionId,
}) => {
  const id = sectionId || useParams<{ id: string }>().id;
  const sectionTree = useMemo(
    () =>
      arrayToTree(data, {
        parentProperty: 'parentId',
        childrenProperty: 'childrenList',
      }),
    [id, data],
  );

  const activeSection = sectionTree.find((section) => section.id === +id);

  if (loading) {
    return (
      <Spin
        style={{
          width: '100%',
          height: '20vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  const renderSection = (
    data: TreeSection,
    level: number,
  ): JSX.Element | null => {
    if (data.documents.length === 0 && !data.childrenList) return null;
    return (
      <Fragment key={data.id}>
        <S.SectionTitle level={level}>{data.name}</S.SectionTitle>
        <div style={{ marginBottom: 20, paddingLeft: level > 1 ? 20 : 0 }}>
          {data.documents.map(({ file, name }) =>
            file ? <Document key={file.uuid} name={name} file={file} /> : null,
          )}
        </div>
        {data.childrenList &&
          data.childrenList.map((sectionChild) =>
            renderSection(sectionChild, level + 1),
          )}
      </Fragment>
    );
  };

  return activeSection ? renderSection(activeSection, 0) : null;
};
