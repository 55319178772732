import { StockType } from 'app/types/products';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderTypeHook } from '../types';

export const useInFinished: OrderTypeHook = product => {
  const [count, setCount] = React.useState<number>(0);
  const { t } = useTranslation('productDetail');
  const orderType = 'products_finished';
  const stockKey: StockType = 'finished';

  const getOffers = () =>
    product.offers.filter(({ residues }) => {
      const stock = residues[stockKey];

      return stock && stock > 0;
    });

  const isVisibleProductsFinished = getOffers().length > 0 && product.login;

  return {
    tabProps: { title: t('orderTypes:finished'), count },
    isVisible: isVisibleProductsFinished,
    content: {
      orderType,
      offers: getOffers(),
      setParentCounter: setCount,
      coilProductionAvailable: false,
      customLengthAvailable: false,
      isReady: true,
      availableAddToCart: !product.notAvilableByContract,
      stockType: stockKey,
    },
  };
};
