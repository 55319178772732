import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { SLICE_NAME } from './consts';
import { MetalCostService } from 'app/services/metalCost';

export const getActiveMetalCosts = createAsyncThunk(
  `${SLICE_NAME}/getActiveMetalCosts`,
  async (_, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.getActiveMetalCost();
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

export const checkFileLink = createAsyncThunk(
  `${SLICE_NAME}/checkFileLink`,
  async (payload: { filepath: string }, { rejectWithValue }) => {
    const { data, success, message } = await MetalCostService.checkFileLink(payload);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
