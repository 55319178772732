import {
  createSlice,
  PayloadAction,
  nanoid,
  AnyAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import {
  CheckedImport,
  CheckedImportType,
  CheckedError,
  ImportForm,
} from 'app/types/imports';
import * as actions from './actions';
import * as ImportsApi from 'app/services/imports';
import { sendEvent, actionTypes } from 'logger';
import { fetchCart } from 'app/ducks/cart';

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('imports/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('imports/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('imports/') && action.type.endsWith('/fulfilled');

export interface InitialStateTypes {
  loading: boolean;
  imported: CheckedImportType[];
  notImported: CheckedError[] | null;
  alertBlock: boolean;
  importBlock: boolean;
  notImportedBlock: boolean;
  visibleModalImport: boolean;
  lastReset?: string;
}

const initialState: InitialStateTypes = {
  loading: false,
  imported: [],
  notImported: null,
  alertBlock: false,
  importBlock: true,
  notImportedBlock: false,
  visibleModalImport: false,
};

const importsSlice = createSlice({
  name: 'imports',
  initialState,
  reducers: {
    startImportFile(state, { payload }: PayloadAction<CheckedImport>) {
      const { error, success } = payload;
      const notImported =
        Array.isArray(error) && error.length !== 0 ? error : null;

      state.imported = success;
      state.notImported = notImported;
      if (success.length && !notImported) {
        state.alertBlock = true;
        state.notImportedBlock = false;
        state.importBlock = true;
      } else {
        state.importBlock = false;
        state.alertBlock = false;
        state.notImportedBlock = true;
      }
    },
    cancelImport(state) {
      state.notImportedBlock = false;
      state.importBlock = true;
      state.alertBlock = false;
    },
    toggleModalImport(state) {
      state.visibleModalImport = !state.visibleModalImport;
    },
    resetForm(state) {
      state.lastReset = nanoid();
    },
    clearImportResults(state) {
      state.imported = [];
      state.notImported = null;
      state.alertBlock = false;
      state.importBlock = true;
      state.notImportedBlock = false;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchContinueImportFile.fulfilled, state => {
      state.alertBlock = true;
      state.notImportedBlock = false;
      state.importBlock = true;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) {
          antdMessage.error(action.error.message);
        }
        state.loading = false;
      },
    );
  },
});

export const {
  startImportFile,
  cancelImport,
  toggleModalImport,
  resetForm,
  clearImportResults,
} = importsSlice.actions;

//TODO: нужно убрать зависимость от других экшенов, логику перенести в контейнер
export const fetchImportFile = createAsyncThunk(
  'imports/fetchImportFile',
  async (values: ImportForm, { dispatch }) => {
    const { data, success, code } = await ImportsApi.importFile(values);
    if (code >= 500) throw new Error('Ошибка сервера');
    if (success) {
      const changedSucceses = data.success
        .filter(({ orderType }) => orderType !== values.productType)
        .reduce<CheckedError[]>((acc, item) => {
          const index = acc.findIndex(({ code }) => code === item.orderType);
          if (~index) {
            acc[index].items = [...acc[index].items, item];
          } else {
            acc.push({ code: item.orderType, items: [item] });
          }
          return acc;
        }, []);

      if (
        data.error.length === 0 &&
        data.success.length > 0 &&
        changedSucceses.length === 0
      ) {
        await ImportsApi.executeImportFile(values);
        await dispatch(fetchCart());
        dispatch(resetForm());
        sendEvent({
          ActionType: actionTypes.IMPORT_CLICK_LOAD_BLANK_SUCCESS,
          ContextKey: values.productType,
        });
      } else {
        data.error = [...data.error, ...changedSucceses];
      }
      dispatch(startImportFile(data));
      if (Array.isArray(data.error) && data.error.length !== 0) {
        sendEvent({
          ActionType: actionTypes.IMPORT_CLICK_LOAD_BLANK_QUESTION,
          ContextKey: values.productType,
        });
      }
    } else {
      dispatch(cancelImport());
      throw new Error('Неверная структура файла или файл пустой');
    }
  },
);

export default importsSlice.reducer;
