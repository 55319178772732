import { apiGet, apiDelete } from '../connect';
import { Result } from 'app/types/api';
import { WaitingLists, WaitingListOffer, DataForGetOfferList } from 'app/types/waitingLists';
import { Pagination } from 'app/types/pagination';

export const getWaitingLists = async (search: string) => {
  const { data: response } = await apiGet<Result<{ items: WaitingLists; pagination: Pagination }>>(
    `/admin/waitinglist/groups${search}`,
  );
  return response;
};

export const getOffersList = async ({ productId, ...filters }: DataForGetOfferList) => {
  const { data: response } = await apiGet<Result<WaitingListOffer[]>>(`/admin/waitinglist/group/${productId}`, filters);
  return response;
};

export const deleteOffer = async (cartPositionId: number) => {
  const { data: response } = await apiDelete<Result<{}>>(`/admin/waitinglist/position/${cartPositionId}`);
  return response;
};

export const getWaitingListsExcel = async (search: string) => {
  const { data: response } = await apiGet<
    Result<{
      item: {
        name: string;
        data: string;
        mimeType: string;
      };
    }>
  >(`/export/waitinglists?${search}`);
  return response;
};
