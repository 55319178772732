import { ValuesType } from '../types/Forms';
import * as Yup from 'yup';
import i18n from 'i18n';

export const defaultValues: ValuesType = {
  type: '',
  ruTitle: '',
  enTitle: '',
  ruDescription: '',
  enDescription: '',
  categoryId: 0,
  alloyIds: [],
  alloyWeights: {},
  alloyImages: '',
  active: false,
  image: '',
};

export const defaultValidationSchema = {
  type: Yup.string().required(i18n.t('form:required')),
  ruTitle: Yup.string().required(i18n.t('form:required')),
  enTitle: Yup.string().required(i18n.t('form:required')),
  ruDescription: Yup.string().required(i18n.t('form:required')),
  enDescription: Yup.string().required(i18n.t('form:required')),
  categoryId: Yup.number()
    .integer()
    .required(i18n.t('form:required')),
  active: Yup.boolean().required(i18n.t('form:required')),
};
