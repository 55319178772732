import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as actions from './actions';
import { RootState } from 'app/store/reducer';
import { UpdateOrderDto } from 'app/types/adminOrders';
import { AppDispatch } from 'app/store/store';

export const useAdminOrders = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.adminOrders);

  const fetchOrders = (search = '') => {
    return dispatch(actions.fetchOrders(search));
  };

  const updateOrder = async (updateData: UpdateOrderDto) => {
    const resultAction = await dispatch(actions.updateOrder(updateData));
    return actions.updateOrder.fulfilled.match(resultAction);
  };

  const fetchUserCompanies = async (userId: number | string) => {
    const resultAction = await dispatch(actions.fetchUserCompanies(Number(userId)));
    if (actions.fetchUserCompanies.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload || [];
    }
  };

  const fetchCompanyContracts = async (companyId: number | string) => {
    const resultAction = await dispatch(actions.fetchCompanyContracts(Number(companyId)));
    if (actions.fetchCompanyContracts.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload.items || [];
    }
  };

  const fetchProductTypes = async () => {
    const resultAction = await dispatch(actions.fetchProductTypes());
    if (actions.fetchUserCompanies.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload || [];
    }
  };

  return {
    state,
    fetchOrders,
    updateOrder,
    fetchUserCompanies,
    fetchCompanyContracts,
    fetchProductTypes,
  };
};
