import React from 'react';
import * as S from './styled';
export interface Props {
  title: string;
  count?: number;
  id: number | string;
  active?: number | string;
  onClick: (index: number | string) => void;
  showZeroCount?: boolean;
}

export const Tab: React.FC<Props> = ({
  count = 0,
  active,
  id,
  onClick,
  title,
  showZeroCount = false,
}) => {
  const clickHandler = () => {
    onClick(id);
  };

  return (
    <S.Tab active={active === id} onClick={clickHandler}>
      {title}{' '}
      {(count > 0 || showZeroCount) && (
        <S.TabCount>{count > 999 ? '999+' : count}</S.TabCount>
      )}
    </S.Tab>
  );
};
