import React, { useEffect } from 'react';
import { Row, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ValidProps } from '../../Fields/Valid';
import { AutocompleteCompany } from 'app/features/Admin/AutocompleteForms/CompaniesDaData';
import { CompanyResponse } from '../../../app/types/company';
import { useTranslation } from 'react-i18next';
import { actionTypes, sendEvent } from 'logger';
import { Modal, Field, SubTitle, Title, Text, Button } from './styled';
import { User } from 'app/types/user';
import { ConsultationFormData } from 'app/types/consultation';

export const MODAL_NAME = 'consultation';

export interface Props {
  loading?: boolean;
  visible?: boolean;
  onSubmit: (value: ConsultationFormData) => void;
  hideModal: () => void;
  company: CompanyResponse | null;
  user: User | null;
}

export const ConsultationForm: React.FC<Props> = ({ onSubmit, hideModal, loading, visible, user, company }) => {
  const { t } = useTranslation(['layout', 'consultation', 'company', 'form', 'modal']);

  const shape = {
    theme: Yup.string()
      .trim()
      .required(t('form:required')),
    username: Yup.string()
      .trim()
      .required(t('form:required')),
    companyName: Yup.string().trim(),
    email: Yup.string()
      .trim()
      .email(t('form:email'))
      .required(t('form:required')),
    phone: Yup.string(),
  };

  const initial: ConsultationFormData = {
    theme: 'Абонентское обслуживание РФМ',
    companyName: company?.name ?? '',
    username: user?.name ?? '',
    email: user?.email ?? '',
    phone: '',
  };

  const ConsultationSchema = Yup.object().shape(shape);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initial,
    validationSchema: ConsultationSchema,
    onSubmit: values => {
      onSubmit(values);
      sendEvent(actionTypes.CONSULTING_REQUEST);
    },
  });

  const formRowProps: Omit<ValidProps, 'component' | 'name'> = {
    form: formik,
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    labelAlign: 'left',
  };

  useEffect(() => {
    formik.resetForm();
  }, [visible]);

  return (
    <Modal open={visible} onCancel={hideModal} title={null} footer={null}>
      <Title style={{ marginBottom: 0 }} level={3}>
        {t('consultation:title')}
      </Title>
      <SubTitle style={{ marginBottom: 0 }} level={4}>
        {t('consultation:subtitle')}
      </SubTitle>
      <form onSubmit={formik.handleSubmit}>
        <Field
          name="username"
          label={t('consultation:username')}
          placeholder={t('feedback:fullNamePlaceholder')}
          component={Input}
          {...formRowProps}
        />
        <Field
          name="theme"
          label={t('consultation:theme')}
          placeholder=""
          component={Input}
          readOnly={true}
          {...formRowProps}
        />
        <Field name="email" label="E-mail" placeholder="example@mail.ru" component={Input} {...formRowProps} />
        <Field
          name="phone"
          label={t('consultation:phone')}
          placeholder="89123458912"
          component={Input}
          {...formRowProps}
        />
        <Field
          name="companyName"
          label={t('consultation:companyName')}
          placeholder={t('company:name')}
          component={AutocompleteCompany}
          {...formRowProps}
        />
        <Row justify="center">
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            {t('consultation:submit')}
          </Button>
        </Row>
      </form>
      <Row justify="start">
        <Text>
          {t('consultation:privacy.0')}{' '}
          <a href="https://www.krastsvetmet.ru/privacy-policy/" target="_blank" rel="noopener noreferrer">
            {t('consultation:privacy.1')}
          </a>
          .
        </Text>
      </Row>
    </Modal>
  );
};
