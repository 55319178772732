import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import {
  ResiduePayloadWithId,
  ResiduePayload,
} from 'app/types/residuesFinished';
import { clearLengthsAndCoatings as $clearLengthsAndCoatings } from './slice';
import { unwrapResult } from '@reduxjs/toolkit';

export const useResiduesFinished = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.ResiduesFinished);

  const fetchResidues = (search: string) => {
    dispatch(actions.fetchResidues(search));
  };

  const getTypesResidues = () => {
    dispatch(actions.fetchTypesResidues());
  };

  const addResidue = async (data: ResiduePayload) => {
    const resultAction = await dispatch(actions.addResidue(data));
    if (actions.addResidue.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.article : undefined;
    }
  };

  const updateResidue = async (data: ResiduePayloadWithId) => {
    const resultAction = await dispatch(actions.updateResidue(data));
    return actions.updateResidue.fulfilled.match(resultAction);
  };

  const deleteResidue = (id: number) => {
    dispatch(actions.deleteResidue(id));
  };
  const getLengthsAndCoatings = (productId: number) => {
    dispatch(actions.getOffersProduct(productId));
  };
  const clearLengthsAndCoatings = () => {
    dispatch($clearLengthsAndCoatings());
  };
  return {
    state,
    fetchResidues,
    getTypesResidues,
    addResidue,
    updateResidue,
    deleteResidue,
    getLengthsAndCoatings,
    clearLengthsAndCoatings,
  };
};
