import React, { Fragment } from 'react';
import * as S from './styled';

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: JSX.Element | string;
  description?: JSX.Element | string;
  icon?: JSX.Element;
  actions?: JSX.Element[];
  notice?: JSX.Element | string;
  alertType?: 'success' | 'info' | 'warning' | 'error' | 'notAvailable';
}

export const Alert: React.FC<Props> = ({ title, icon, description, actions, notice, alertType = 'info', ...props }) => {
  const isSingleLine = !description;
  const withActions = Boolean(actions);
  if (actions) {
    notice = undefined;
  } else if (notice) {
    actions = undefined;
  }

  return (
    <S.Alert {...props} withActions={withActions} alertType={alertType}>
      <S.Body>
        {icon && (
          <S.Icon isSingleLine={isSingleLine} alertType={alertType}>
            {icon}
          </S.Icon>
        )}
        <S.Content>
          <S.Title isSingleLine={isSingleLine}>{title}</S.Title>
          {description && <S.Description>{description}</S.Description>}
        </S.Content>
      </S.Body>
      {notice && (
        <S.Notice isSingleLine={isSingleLine} alertType={alertType}>
          {notice}
        </S.Notice>
      )}
      {actions && (
        <S.Affix>
          {actions.map((action, index) => (
            <Fragment key={index}>{action}</Fragment>
          ))}
        </S.Affix>
      )}
    </S.Alert>
  );
};
