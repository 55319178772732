import React from 'react';
import { DetailOrderType } from 'components/ProductDetail';
import { useTranslation } from 'react-i18next';
import { OrderTypeHook } from '../types';
import { useInFinished } from './useInFinished';
import { useInCoils } from './useInCoils';
import { useResidues } from './useResidues';

export const useInProduction: OrderTypeHook = product => {
  const [orderInProduction, setOrderInProduction] = React.useState<number>(0);
  const { t } = useTranslation('productDetail');
  const orderType: DetailOrderType = 'products_production';

  const { content: finishedContent } = useInFinished(product);
  const { isVisible: coilTabVisible } = useInCoils(product);
  const { residues } = useResidues(product, orderType);

  const getProductionOffers = () => product.offers.map(offer => ({ ...offer, max: null }));

  const isVisibleProductsFinished = finishedContent.offers.length > 0 && product.login;

  const inStockCurrentContractType = product.conditions.inStock.find(
    item => item.contractType === product.activeContract?.contractType,
  )?.availability;

  const productionPower = product.productionPower === null ? Infinity : product.productionPower;

  return {
    tabProps: { title: t('orderTypes:inProduction'), count: orderInProduction },
    isVisible: inStockCurrentContractType ? !(product.conditions.isClosed || product.conditions.isDiscontinued) : true,
    content: {
      orderType,
      offers: getProductionOffers(),
      requiredSetCovering: true,
      weightInBasket: product.weightInProductionInBasket,
      productionBalance: product.login
        ? productionPower / product.productionFactor - product.powerInProductionInBacket
        : undefined,
      inCoilsBalance: residues,
      balanceUnit: t('units:g'),
      setParentCounter: setOrderInProduction,
      availableAddToCart: product?.login && !product.conditions.isClosed && !product.notAvilableByContract,
      leftoversAvailable: coilTabVisible || isVisibleProductsFinished,
      minOrderWeight: product.conditions.minOrderWeight - product.weightInProductionInBasket,
      minOrderCount: product.conditions.minOrderCount - product.countInProductionInBasket,
      productionPowerMode: product.coilProductionPower.mode,
    },
  };
};
