/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Modal, Switch } from 'antd';
import { useModal } from 'app/ducks/modals';
import { UploadFiles } from 'components/Upload';
import { useCategories } from 'app/ducks/categories';

export const UPDATE_CATEGORY_MODAL = 'UPDATE_CATEGORY_MODAL';

export const UpdateCategory = () => {
  const { resetCategoryDetail, update, state } = useCategories();
  const { detail, detailShowParams } = state;
  const [uuid, setUuid] = useState<string | null>(null);
  const [show_params, setShowParams] = useState<boolean>(false);
  const { hideModal, visible } = useModal(UPDATE_CATEGORY_MODAL);

  const cancelHandler = () => {
    hideModal();
    resetCategoryDetail();
  };

  const submitHandler = () => {
    if (detail) {
      const body: { image?: string; show_params?: number } = {};

      if (uuid) body.image = uuid;

      body.show_params = show_params ? 1 : 0;

      update({ id: detail, body });
      cancelHandler();
    }
  };

  useEffect(() => {
    setUuid(null);
    setShowParams(detailShowParams === 1 ? true : false);
  }, [detail]);

  return (
    <Modal title="Обновить категорию" onCancel={cancelHandler} open={visible} onOk={submitHandler}>
      {detail && (
        <div>
          <h4>Видимость параметров</h4>
          <Switch checked={show_params} onChange={setShowParams} />
          <br />
          <br />
          <UploadFiles onChange={setUuid}>Загрузить изображение</UploadFiles>
        </div>
      )}
    </Modal>
  );
};
