import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import download from 'downloadjs';
import { apiGet } from 'app/services/connect';
import { Result } from 'app/types/api';
import { File } from 'app/types/documents';
import { message } from 'antd';

export const useStorage = (file: File) => {
  const trigger = useCallback(async () => {
    try {
      const { data: response } = await apiGet<
        Result<{
          data: string;
          name: string;
        }>
      >('/storage/receive', {
        uuid: file.uuid,
      });
      const { data } = response;

      if (response.success) {
        download(data.data, data.name, file.mimeType);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      //@ts-expect-error
      message.error(error.message);
    }
  }, [file.uuid]);
  return trigger;
};
