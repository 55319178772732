import { ItemsResultWithMeta, ItemResult, ItemsResult, Result } from 'app/types/api';
import { apiGet, apiPost, apiDelete } from '../connect';
import * as types from 'app/types/products';
import { Metal } from 'app/types/orders';

export type ProductResult = Result<types.Product | null>;

export type AdminProductResult = ItemResult<types.AdminProduct | null>;
export type AdminProductsResult = ItemsResultWithMeta<types.AdminProduct>;

export const getProduct = async (id: number) => {
  try {
    const { data } = await apiGet<ProductResult>(`/product/detail/${id}`);
    return data;
  } catch (error) {
    //@ts-expect-error
    return error.response.data as Result;
  }
};

export const getProducts = async (search: string) => {
  const { data } = await apiGet<AdminProductsResult>(`/admin/products${search}`);
  return data;
};

export const updateProduct = async (product: types.AdminProductsUpload) => {
  const { data } = await apiPost<AdminProductResult>(`/admin/products/${product.id}`, product);
  return data;
};

export const addProduct = async (product: types.AdminProductsUpload) => {
  const { data } = await apiPost<AdminProductResult>('/admin/products', product);
  return data;
};

export const deleteProduct = async (productId: number) => {
  const { data } = await apiDelete<OfferResult>(`/product/${productId}`);
  return data;
};

export type OfferResult = Result<types.AdminOffer>;
export const updateOffer = async (offer: types.AdminOffer) => {
  const { data } = await apiPost<OfferResult>(`/offer/${offer.id}`, offer);
  return data;
};
export const addOffer = async (offer: types.AdminOffer) => {
  const { data } = await apiPost<OfferResult>('/offer', offer);
  return data;
};
export const deleteOffer = async (id: number) => {
  const { data } = await apiDelete<OfferResult>(`/offer/${id}`);
  return data;
};

export const getCoatings = async () => {
  const { data } = await apiGet<Result<types.Coating[]>>('/offer/coatings');
  return data;
};
export const getOfferTypes = async () => {
  const { data } = await apiGet<Result<types.Coating[]>>('/offer/types');
  return data;
};
export const getMetals = async () => {
  const { data } = await apiGet<Result<Metal[]>>('/metals');
  return data;
};
export const getFeatures = async () => {
  const { data } = await apiGet<ItemsResult<types.Feature>>('/products/features');
  return data;
};
export const getAlloys = async () => {
  const { data } = await apiGet<ItemsResult<types.Alloys>>('/products/alloys');
  return data;
};
export const getCategories = async (q: string) => {
  const { data } = await apiGet<Result<types.CategoryAdmin[]>>('/catalog/sections', {
    q,
  });
  return data;
};

export const setProductImage = async (params: types.ImageUpdate) => {
  const { type, file, rotate, productId } = params;

  const form = new FormData();
  form.set('type', type);
  form.set('file', file);
  form.set('rotate', rotate.toString());

  const { data } = await apiPost<Result<types.ProductImagePayload>>(`product/${productId}/image`, form);
  return data;
};

export const setCoatingImage = async (params: types.ImageCoatingUpdate) => {
  const { file, productId, coatingId } = params;

  const form = new FormData();
  form.set('file', file);

  const { data } = await apiPost<Result<types.ImageCoatingPayload>>(
    `product/${productId}/coating/${coatingId}/image`,
    form,
  );
  return data;
};
export const setAdditionalImage = async (params: types.ImageAdditionalUpdate) => {
  try {
    const { file, productId } = params;

    const form = new FormData();
    form.set('file', file);

    const { data } = await apiPost<Result<types.ImageAdditionalPayload>>(
      `products/${productId}/images/additional`,
      form,
    );
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const deleteAdditionalImage = async (params: types.ImageAdditionalDelete) => {
  try {
    const { imageId, productId } = params;

    const { data } = await apiDelete<Result>(`products/${productId}/images/additional/${imageId}`);
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const getDetails = async () => {
  const { data } = await apiGet<Result<types.AdminProductsDetails>>('products/details');
  return data;
};
