import { sendEvent } from './sendEvent';
import { LoggerPayload } from './types';
/**
 * Функция-обертка для создания событий аналитики.
 * Дженерик получает два типа: EventArg, EventOutput
 *
 * @example
 * const handleClick = createLoggerEvent<object, void>(
 *  (data) => console.log(data),
 *  { Action: 'Hello' }
 * );
 * return <button onClick={handleClick}>Say Hello</button>
 */

export const createLoggerEvent = <EventArg, EventOutput>(
  handler: (val: EventArg) => EventOutput,
  payload:
    | Omit<
        LoggerPayload,
        | 'SaltHash'
        | 'Connection'
        | 'Resolution'
        | 'isAdmin'
        | 'isManager'
        | 'BitrixId'
        | 'isMajor'
        | 'Channel'
      >
    | string,
) => {
  return (params: EventArg) => {
    sendEvent(payload);
    return handler(params);
  };
};
