import React, { useMemo } from 'react';
import { CountWrapper, CountAction, CountInput } from '../styled/Count.styled';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface Props {
  count: number;
  onChange: (value: number) => void;
  max?: number | null;
  hideTenIncr?: boolean;
  article: string;
}

export const Count: React.FC<Props> = ({ count, onChange, max = Infinity, hideTenIncr, article }) => {
  const clearMax = max === null ? Infinity : max;

  const customIncrement = (value: number) => {
    if (clearMax >= count + value) onChange(count + value);
  };

  const increment = () => customIncrement(1);

  const increment10 = () => customIncrement(10);

  const decrement = () => {
    if (count - 1 >= 0) onChange(count - 1);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const orderValue = Number.isNaN(Math.abs(parseInt(value))) ? 0 : Math.abs(parseInt(value));

    onChange(orderValue > clearMax ? clearMax : orderValue);
  };

  return useMemo(
    () => (
      <CountWrapper>
        <CountAction onClick={decrement} disabled={count === 0}>
          <MinusOutlined />
        </CountAction>
        <CountInput value={count} onChange={changeHandler} />
        <CountAction onClick={increment} disabled={count === clearMax}>
          <PlusOutlined />
        </CountAction>
        {!hideTenIncr && (
          <Button shape="round" size="small" disabled={clearMax - count < 10} onClick={increment10}>
            +10
          </Button>
        )}
      </CountWrapper>
    ),
    [count, clearMax, onChange],
  );
};
