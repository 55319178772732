import styled from 'styled-components';
import { Menu as MenuAntd } from 'antd';
import { MenuItemSuffix } from '../../styled';
import { PageNavProps } from './PageNav';

const { Item, SubMenu } = MenuAntd;

type Props = Required<Pick<PageNavProps, 'size' | 'variant'>> & {
  active: boolean;
};

export const Nav = styled(MenuAntd)<{ inverse: boolean }>`
  &&& {
    display: flex;
    align-items: center;
    /* margin-left: 1.2em; */
    font-size: inherit;
    line-height: inherit;
    color: ${({ inverse }) => (inverse ? '#fff' : 'inherit')};
    background-color: transparent;
    border-bottom: 0;
  }
`;
export const MenuItemName = styled.span``;

export const MenuItemTitle = styled('div')<{ hideSm: boolean }>`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  transition: all 0.2s linear;

  @media screen and (max-width: 1300px) {
    > ${MenuItemName} {
      display: ${({ hideSm }) => (hideSm ? 'none' : 'unset')};
    }

    > ${MenuItemSuffix} {
      margin-left: 0;
    }
  }
`;

const getStylesBySize = (size: string) => {
  if (size === 'medium') {
    return `
      ${MenuItemTitle} {
        font-size: 16px;
      }
    `;
  }
  if (size === 'small') {
    return `
      ${MenuItemTitle} {
        font-size: 14px;
        @media screen and (max-width: 1200px) {
          font-size: 0.875em;
        }
      }  
    `;
  }
};

const getStylesByVariant = (variant: 'light' | 'dark', active: boolean) => {
  if (variant === 'dark') {
    return `
      ${MenuItemTitle} {
        padding: 8px 12px;
        font-weight: 500;
        line-height: 22px;
        color: ${active ? '#fff' : '#C9CDD1'};
        background: ${active ? 'rgba(33, 37, 41, 0.4)' : 'transparent'};
        border-radius: 50px;
        @media screen and (max-width: 1100px) {
          padding: 8px;
        }
        
      }
      ${MenuItemSuffix} {
        background: ${active ? '#fff' : '#C9CDD1'};
      }

      :hover ${MenuItemTitle} {
        color: #fff;
      }
      :hover ${MenuItemSuffix} {
        background: #fff;
      }
    `;
  }
  if (variant === 'light') {
    return `
      ${MenuItemTitle} {
        font-weight: ${active ? '700' : '400'};
        color: ${active ? '#4F4F4F' : '#767676'}
      }

      :hover ${MenuItemTitle} {
        color: #4F4F4F;
      }
      :hover ${MenuItemSuffix} {
        background: #4F4F4F;
      }
    `;
  }
};

export const SubMenuStyled = styled(SubMenu)<Props>`
  &&&& {
    padding: 0;
    ${({ size }) => getStylesBySize(size)};
    ${({ variant, active }) => getStylesByVariant(variant, active)};

    ::after {
      display: none;
    }
  }
`;

export const NavItem = styled(Item)<Props>`
  &&&&& {
    margin: 0;
    padding: 0;
    margin-right: 6px;
    background: transparent;
    ${({ size }) => getStylesBySize(size)}
    ${({ variant, active }) => getStylesByVariant(variant, active)}

    ::after {
      display: none;
    }
    :hover ${MenuItemSuffix} {
      background: #fff;
    }
  }
`;

//TODO fix any, ошибка появилась после обновления @types/react@17.0.0
//Ошибка фиксится обновлением styled-components >=5v
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SubNavItem = styled(NavItem as any)<Props>`
  &&&&& {
    padding: 0.5em 0.85em;
    width: 100%;
    ${({ size }) => getStylesBySize(size)};
    ${({ variant, active }) => getStylesByVariant(variant, active)};

    :hover a {
      background: #f4f4f4;
      font-weight: bold;
      color: #4f4f4f !important;
      transition: all 0.2s linear;
    }
  }
`;

export const NavIcon = styled.span`
  width: 100%;
  height: 100%;
`;
