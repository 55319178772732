import { createSlice } from '@reduxjs/toolkit';
import { AdditionalProductAlloy, AdditionalProductItem } from 'app/types/adminAdditional';
import { BarsItem, FittingItem, StripesItem, WireItem } from '../types/OrderItem';

type BarsPayload = { payload: BarsItem };

type RibbonsPayload = {
  payload: {
    alloyId: number;
    width: number;
    thickness: string;
    index?: number;
    item?: StripesItem;
  };
};

type WiresPayload = {
  payload: {
    alloyId: number;
    diameterKey: string;
    index?: number;
    item?: WireItem;
  };
};

type FittingsPayload = {
  payload: {
    alloy: AdditionalProductAlloy;
    fittingType: string;
    index?: number;
    item?: FittingItem;
    count?: number;
    weight?: number;
  };
};

export type RibbonType = {
  [key: string]: {
    [key: string]: {
      [key: number]: StripesItem[];
    };
  };
};

export type WireType = {
  [key: number]: {
    [key: string]: WireItem[];
  };
};

export type FittingsType = {
  [key: string]: {
    [key: number]: {
      alloy: AdditionalProductAlloy;
      products: FittingItem[];
    };
  };
};

export type initialStateType = {
  bar: BarsItem[];
  wire: WireType;
  ribbon: RibbonType;
  fitting: FittingsType;
};

const orderInitialState: initialStateType = {
  bar: [],
  wire: {},
  ribbon: {},
  fitting: {},
};

const additionalOrderSlice = createSlice({
  name: 'AdditionalProductsOrders',
  initialState: orderInitialState,
  reducers: {
    // Bars reducers
    setBars(state, { payload }: BarsPayload) {
      state.bar.push(payload);
    },
    updateBars(state, { payload }: BarsPayload) {
      const filtered = state.bar.filter(item => item.id !== payload.id || item.alloyId !== payload.alloyId);
      filtered.push(payload);
      state.bar = filtered;
    },
    removeBars(state, { payload }: BarsPayload) {
      const filtered = state.bar.filter(item => item.id !== payload.id || item.alloyId !== payload.alloyId);
      state.bar = filtered;
    },

    // Ribbons reducers
    setRibbons(state, { payload }: RibbonsPayload) {
      const { alloyId, thickness, width, item } = payload;
      if (!state.ribbon[alloyId]) {
        state.ribbon[alloyId] = {
          [thickness]: {
            [width]: [],
          },
        };
      } else if (!state.ribbon[alloyId][thickness]) {
        state.ribbon[alloyId][thickness] = {
          [width]: [],
        };
      } else if (!state.ribbon[alloyId][thickness][width]) {
        state.ribbon[alloyId][thickness][width] = [];
      }
      state.ribbon[alloyId][thickness][width].push(item as StripesItem);
    },
    updateRibbons(state, { payload }: RibbonsPayload) {
      const { alloyId, thickness, width, index, item } = payload;
      state.ribbon[alloyId][thickness][width][index as number] = item as StripesItem;
    },
    removeRibbons(state, { payload }: RibbonsPayload) {
      const { alloyId, thickness, width, index } = payload;
      if (state.ribbon[alloyId][thickness][width].length === 1) {
        state.ribbon[alloyId][thickness][width][0].weight = 0;
      } else {
        state.ribbon[alloyId][thickness][width] = state.ribbon[alloyId][thickness][width].filter(
          (_, itemIndex) => itemIndex !== index,
        );
      }
    },

    // Wires reducers
    setWires(state, { payload }: WiresPayload) {
      const { alloyId, diameterKey, item } = payload;
      if (!state.wire[alloyId]) {
        state.wire[alloyId] = {
          [diameterKey]: [],
        };
      } else if (!state.wire[alloyId][diameterKey]) {
        state.wire[alloyId][diameterKey] = [];
      }
      state.wire[alloyId][diameterKey].push(item as WireItem);
    },
    updateWires(state, { payload }: WiresPayload) {
      const { alloyId, diameterKey, index, item } = payload;
      state.wire[alloyId][diameterKey][index as number] = item as WireItem;
    },
    removeWires(state, { payload }: WiresPayload) {
      const { alloyId, diameterKey, index } = payload;
      if (state.wire[alloyId][diameterKey].length === 1) {
        state.wire[alloyId][diameterKey][0].weight = 0;
      } else {
        state.wire[alloyId][diameterKey] = state.wire[alloyId][diameterKey].filter(
          (item, itemIndex) => itemIndex !== (index as number),
        );
      }
    },

    // Fittings reducers
    setFittings(state, { payload }: FittingsPayload) {
      const { fittingType, alloy, item } = payload;
      if (!state.fitting[fittingType]) {
        state.fitting[fittingType] = {
          [alloy.id]: {
            alloy,
            products: [],
          },
        };
      } else if (!state.fitting[fittingType][alloy.id]) {
        state.fitting[fittingType][alloy.id] = {
          alloy,
          products: [],
        };
      }
      state.fitting[fittingType][alloy.id].products.push(item as FittingItem);
    },
    updateFittings(state, { payload }: FittingsPayload) {
      const { alloy, fittingType, index, count, weight } = payload;
      state.fitting[fittingType][alloy.id].products[index as number] = {
        ...state.fitting[fittingType][alloy.id].products[index as number],
        count: count as number,
        weight: weight as number,
      };
    },
  },
});

const {
  actions: {
    setBars,
    updateBars,
    removeBars,
    setRibbons,
    updateRibbons,
    removeRibbons,
    setWires,
    updateWires,
    removeWires,
    setFittings,
    updateFittings,
  },
  reducer,
} = additionalOrderSlice;

export {
  setBars,
  updateBars,
  removeBars,
  setRibbons,
  updateRibbons,
  removeRibbons,
  setWires,
  updateWires,
  removeWires,
  setFittings,
  updateFittings,
  reducer as additionalProductsOrder,
};
