import React, { useEffect } from 'react';
import { LayoutContainer } from './containers/LayoutContainer';
import { Provider } from 'react-redux';
import store from './app/store/store';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import enGB from 'antd/es/locale/en_GB';
import './style.less';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { useTranslation } from 'react-i18next';
import { useVersionChecker } from 'hooks/versionChecker';
import OldBrowserDetector from 'old-browser-detector';
import { Alert } from 'antd';
import { sendEvent } from 'logger';
import { THEME_CONFIG } from 'theme-overrides';

dayjs.locale('ru');

export const App = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const isRu = language === 'ru';
  const { unsubscribe, subscribe } = useVersionChecker();

  const Detector = new OldBrowserDetector({ i: 12 });

  const unloadApp = () => {
    sendEvent('OUTPOINT');
  };

  useEffect(() => {
    subscribe();
    window.addEventListener('unload', unloadApp);
    return () => {
      // window.removeEventListener('unload', unloadApp);
      unsubscribe();
    };
  }, []);

  return (
    <ConfigProvider locale={isRu ? ruRu : enGB} theme={THEME_CONFIG}>
      {Detector.detect() && (
        <Alert type="warning" message="Ваш браузер больше не поддерживается. Установите более современный" banner />
      )}

      <Provider store={store}>
        <LayoutContainer />
      </Provider>
    </ConfigProvider>
  );
};
