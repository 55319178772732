import { number } from 'helpers/format';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type HasUnitParams = {
  readonly isDimensioned: boolean;
};

export type FormatSizeParams = HasUnitParams;

export const useFormatSize = () => {
  const { t } = useTranslation();

  const getUnit = useCallback(
    (params: HasUnitParams) => {
      const { isDimensioned } = params;

      // NOTE: dimensioned product does not have units
      const hasUnit = !isDimensioned;

      if (hasUnit) {
        return t('units:cm');
      }

      return null;
    },
    [t],
  );

  const formatSize = useCallback(
    (value: number, params: FormatSizeParams) => {
      if (value === 0) {
        return '-';
      }

      const size = number(value, [0, 1]);
      const composition = [size];
      const unit = getUnit(params);

      if (unit) {
        composition.push(unit);
      }

      return composition.join(' ');
    },
    [getUnit],
  );

  return {
    formatSize,
    getUnit,
  };
};
