import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import * as actions from './actions';
import { ReasonList, ClosedPositionsDateType } from 'app/types/closedPositions';

export interface ClosedPositionsSliceProps {
  list: ReasonList;
  loading: boolean;
  date: ClosedPositionsDateType;
}

const initialState: ClosedPositionsSliceProps = {
  list: [],
  loading: false,
  date: null,
};

const closedPositionsSlice = createSlice({
  name: 'closedPositions',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.fetchClosedPositions.pending, state => {
      state.loading = true;
    });
    addCase(
      actions.fetchClosedPositions.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          date: ClosedPositionsDateType;
          reasons: ReasonList;
        }>,
      ) => {
        state.list = payload.reasons;
        state.date = payload.date;
        state.loading = false;
      },
    );
    addCase(actions.fetchClosedPositions.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});

export default closedPositionsSlice.reducer;
