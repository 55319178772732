import { DeleteOutlined } from '@ant-design/icons';
import { Typography, Button, Col, Divider, InputNumber, Row, Alert } from 'antd';
import { RootState } from 'app/store/reducer';
import { Tabs } from 'components/KrasUIKit';
import { TabCount } from 'components/KrasUIKit/components/Tabs/styled';
import { removeRibbons, setRibbons, updateRibbons } from 'features/AdditionalProducts/slice/AdditionalProductsOrder';
import { ProductTab } from 'features/AdditionalProducts/types/Bars';
import { StripesItem } from 'features/AdditionalProducts/types/OrderItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface AlloyTabsProps {
  sortedByAlloy: ProductTab[];
  onTabChange?: (tabIndex: number) => void;
}

export const AlloysTabs = ({ sortedByAlloy, onTabChange }: AlloyTabsProps) => {
  const { t } = useTranslation(['additional']);
  const [activeTab, setActiveTab] = useState<string | number>(1);
  const dispatch = useDispatch();
  const { ribbon } = useSelector((state: RootState) => state.additionalProductsOrder);

  const createThickness = (alloyId: number, width: number, thickness: string, item: StripesItem) => {
    dispatch(
      setRibbons({
        alloyId,
        width,
        thickness,
        item,
      }),
    );
  };

  const removeThicknessItem = (alloyId: number, width: number, thickness: string, index: number) => {
    dispatch(
      removeRibbons({
        alloyId,
        width,
        thickness,
        index,
      }),
    );
  };

  const changeThicknessItem = (alloyId: number, width: number, thickness: string, index: number, item: StripesItem) => {
    dispatch(
      updateRibbons({
        alloyId,
        width,
        thickness,
        index,
        item,
      }),
    );
  };

  const handleTabChange = (tabIndex: string | number) => {
    setActiveTab(tabIndex);
    onTabChange && onTabChange(Number(tabIndex));
  };

  const tabs = sortedByAlloy.map((tab, index) => {
    const alloyItems = ribbon[tab.alloy.id] ? Object.values(ribbon[tab.alloy.id]) : [];
    const tabsCount = alloyItems.reduce((thicknessAcc, thicknessGroup) => {
      const thicknessCount = Object.values(thicknessGroup).reduce((groupAcc, group) => {
        return groupAcc + group.filter(item => item.weight > 0).length;
      }, 0);
      return thicknessAcc + thicknessCount;
    }, 0);

    return {
      id: index + 1,
      count: tabsCount,
      title: tab.alloy.name,
      content: (
        <>
          {tab.products.map(product => {
            const {
              features: [width, minThickness, maxThickness, stepThickness],
            } = product;
            const thicknessKey = `${minThickness.value}-${maxThickness.value}-${stepThickness.value}`;
            const thicknessItems = ribbon?.[tab.alloy.id] && ribbon?.[tab.alloy.id]?.[thicknessKey];

            const thicknessCount =
              thicknessItems &&
              Object.values(thicknessItems).reduce(
                (acc, group) => acc + group.filter(item => item.weight > 0).length,
                0,
              );

            width.value.forEach(async (widthValue: number) => {
              if (!thicknessItems?.[widthValue]) {
                const item = {
                  id: product.id,
                  alloyId: tab.alloy.id,
                  thickness: Number(minThickness.value),
                  width: widthValue,
                  weight: 0,
                };
                dispatch(
                  setRibbons({
                    alloyId: tab.alloy.id,
                    width: widthValue,
                    thickness: thicknessKey,
                    index: 0,
                    item,
                  }),
                );
              }
            });

            return (
              <div style={{ marginTop: '20px' }} key={thicknessKey}>
                <Typography.Title level={5}>
                  {t('thickness', {
                    minThickness: minThickness.value,
                    maxThickness: maxThickness.value,
                    stepThickness: stepThickness.value,
                  })}
                  {thicknessCount > 0 && <TabCount>{thicknessCount}</TabCount>}
                </Typography.Title>
                <Divider type="horizontal" style={{ margin: '16px 0' }} />
                <Row gutter={25}>
                  {width.value.map((widthValue: number) => {
                    const items = ribbon?.[tab.alloy.id]?.[thicknessKey]?.[widthValue];
                    const count = items && items.filter(item => item.weight > 0).length;

                    return (
                      <Col md={12} key={`${thicknessKey}-${tab.alloy.id}-${widthValue}`}>
                        <Typography.Text>
                          {t('width', { width: widthValue })} {count > 0 ? <TabCount>{count}</TabCount> : null}
                        </Typography.Text>
                        <Divider type="horizontal" style={{ margin: '16px 0' }} />
                        {items &&
                          items.map((item, index) => {
                            return (
                              <Row
                                key={`${thicknessKey}-${item.thickness}-${index}-${item.id}`}
                                gutter={20}
                                style={{ marginBottom: '20px' }}
                                align="middle"
                              >
                                <Col md={11}>
                                  <InputNumber
                                    value={item.thickness > maxThickness.value ? maxThickness.value : item.thickness}
                                    min={minThickness.value}
                                    max={maxThickness.value}
                                    step={stepThickness.value}
                                    style={{ width: '100%' }}
                                    onChange={value => {
                                      changeThicknessItem(tab.alloy.id, widthValue, thicknessKey, index, {
                                        ...item,
                                        thickness: value,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col md={11}>
                                  <InputNumber
                                    placeholder={t('table.setWeight')}
                                    defaultValue={item.weight || undefined}
                                    min={0}
                                    style={{ width: '100%' }}
                                    onChange={value => {
                                      changeThicknessItem(tab.alloy.id, widthValue, thicknessKey, index, {
                                        ...item,
                                        weight: value || 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col md={2}>
                                  {items.length > 1 && (
                                    <DeleteOutlined
                                      onClick={() => removeThicknessItem(tab.alloy.id, widthValue, thicknessKey, index)}
                                    />
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                        <Button
                          style={{
                            marginBottom: '40px',
                          }}
                          shape="round"
                          onClick={() => {
                            const item = {
                              id: product.id,
                              alloyId: tab.alloy.id,
                              width: widthValue,
                              thickness: items.length
                                ? items[items.length - 1].thickness + stepThickness.value
                                : minThickness.value,
                              weight: 0,
                            };
                            createThickness(tab.alloy.id, widthValue, thicknessKey, item);
                          }}
                        >
                          {t('addThickness')}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
          })}
        </>
      ),
    };
  });

  const getTabContent = (tabIndex: string | number) => {
    const neededTab = tabs.find(tab => tab.id === tabIndex);
    if (!tabIndex || !neededTab) return null;
    return neededTab.content;
  };
  return (
    <>
      <Alert
        message=""
        description={t('alert', { silver: 300, gold: 150 })}
        type="info"
        showIcon
        style={{
          margin: '20px 0',
        }}
      />
      <Tabs tabs={tabs} active={activeTab} onChange={handleTabChange} />
      {getTabContent(activeTab)}
    </>
  );
};
