import { createAsyncThunk } from '@reduxjs/toolkit';
import * as CartService from 'app/services/cart';
import { message as toast } from 'antd';
import download from 'downloadjs';
import { fetchFreeMetals } from 'features/slices/contracts';
import { sendEvent, actionTypes } from 'logger';
import * as types from 'app/types/cart';
import { LoggerPayload } from 'logger/types';

export const checkCart = createAsyncThunk('cart/checkCart', async (options: types.CheckCartData) => {
  const { metals, orderType, contractId } = options;
  const { data, message, success } = await CartService.checkCart({ metals, contractId });
  if (success) {
    const currentData = data.find(prosition => prosition.orderType === orderType);
    if (currentData && Object.keys(currentData.errors).length !== 0) {
      sendEvent({
        ActionType: actionTypes.CART_CHECK_ERROR,
        ContextKey: orderType,
        ArKey: Object.keys(currentData.errors),
      });
    } else {
      sendEvent({
        ActionType: actionTypes.CART_CHECK_SUCCESS,
        ContextKey: orderType,
      });
    }
    return { data, metals };
  } else {
    toast.error(message);
    sendEvent({
      ActionType: actionTypes.CART_CHECK_ERROR,
      ContextKey: orderType,
      ArKey: ['critical'],
    });
    throw new Error(message);
  }
});

export const fetchCart = createAsyncThunk('cart/fetchCart', async (_, { getState }) => {
  const data = await CartService.getCart();

  if (data.success) {
    const positionTotals: LoggerPayload['CartPositionTotals'] = [];
    let totalWeight = 0;
    let totalCount = 0;

    data.data.positions.forEach(position => {
      let weight = 0;
      let count = 0;

      position.offers.forEach(offer => {
        weight += offer.weight;
        count += offer.count;
      });

      positionTotals.push({
        ProductId: position.productId,
        OrderType: position.orderType,
        Weight: weight,
        Count: count,
      });

      totalWeight += weight;
      totalCount += count;
    });

    const { auth } = getState() as { auth: { roles: string[]; user: { id: number } } };

    sendEvent({
      ActionType: actionTypes.CART_FETCH_DATA,
      CartPositionTotals: positionTotals,
      CartTotalWeight: totalWeight,
      CartTotalCount: totalCount,
      UserRoles: auth.roles,
    });
  }

  return data;
});

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async (product: types.AddingProduct, { dispatch, getState }) => {
    const data = await CartService.addToCart(product);

    if (data.success) {
      const { auth } = getState() as { auth: { roles: string[]; user: { id: number } } };

      const totals: Required<LoggerPayload['AddToCartTotals']> = {
        Count: 0,
        Weight: 0,
      };

      const offersToAdd: LoggerPayload['AddToCartOffers'] = [];
      const offerTypes: (keyof types.AddingProduct['offers'])[] = ['coil', 'custom', 'finished'];

      for (const type of offerTypes) {
        const rawOffers = product.offers[type];

        if (!rawOffers) {
          continue;
        }

        const offers = Array.isArray(rawOffers) ? rawOffers : [rawOffers];

        for (const offer of offers) {
          const weight = offer.weight * offer.count;

          offersToAdd.push({
            OfferId: offer.offer_id,
            OrderType: product.order_type,
            OfferType: type,
            Count: offer.count,
            Weight: weight,
          });

          totals.Count += offer.count;
          totals.Weight += weight;
        }
      }

      sendEvent({
        ActionType: actionTypes.CART_ADD_PRODUCT,
        AddToCartOffers: offersToAdd,
        AddToCartTotals: totals,
        UserRoles: auth.roles,
      });
    }

    await dispatch(fetchCart());
    return data;
  },
);

export const removeToCart = createAsyncThunk(
  'cart/removeToCart',
  async ({ action }: { action: types.UpdateCartPayload; isAdminOrManager?: boolean }) => {
    const { data } = await CartService.updateCart({
      payload: action,
      type: 'delete',
    });

    // await dispatch(fetchCart());
    return data;
  },
);

export const updateToCart = createAsyncThunk(
  'cart/updateToCart',
  async ({ action }: { action: types.UpdateCartPayload; isAdminOrManager?: boolean }) => {
    const { data, success, message } = await CartService.updateCart({
      payload: action,
      type: 'update',
    });

    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  },
);

export const snapshotCart = createAsyncThunk('cart/snapshotCart', async () => {
  const { data, success, message } = await CartService.snapshotCart();

  if (success) {
    return data;
  } else {
    throw new Error(message);
  }
});

export const getDifferenceCartOrders = createAsyncThunk(
  'cart/getDifferenceCartOrders',
  async (payload: types.CartDifferenceOptions) => {
    const { data, success, message } = await CartService.getDifferenceCartOrders(payload);

    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  },
);

export const transferTo = createAsyncThunk(
  'cart/transferToProduction',
  async (payload: types.TransferPosition, { dispatch }) => {
    const { data } = await CartService.transferToProduction(payload);

    await dispatch(fetchCart());
    return data;
  },
);

export const fetchCartExcel = createAsyncThunk('cart/fetchCartExel', async (orderType: types.OrderType) => {
  const { data } = await CartService.getCartExcel(orderType);

  download(data.data, data.name, data.mimeType);
  return data;
});

export const fetchCartImportExcel = createAsyncThunk(
  'cart/fetchCartImportExel',
  async (payload: { orderType: types.OrderType; metals?: number[] }) => {
    const { data } = await CartService.getCartImportExcel(payload);

    download(data.data, data.name, data.mimeType);
    return data;
  },
);

export const unionCoilAndFinished = createAsyncThunk(
  'cart/unionCoilAndFinished',
  async (isUnion: boolean, { dispatch }) => {
    const data = await CartService.unionCoilAndFinished(isUnion);

    await dispatch(fetchCart());
    return data;
  },
);

/**
 * Вызывается при оформлении заказа
 */
export const checkoutOrder = createAsyncThunk(
  'cart/checkoutOrder',
  async (orderOptions: types.OrderOptions, { dispatch, getState }) => {
    const data = await CartService.checkoutOrder(orderOptions);

    if (!data.success) {
      toast.error(data.message);
    }

    const { auth } = getState() as { auth: { roles: string[]; user: { id: number } } };

    sendEvent({
      ActionType: actionTypes.CHECKOUT_CREATE_ORDER,
      ContextKey: orderOptions.orderType,
      OrderId: data.data.createdOrders.map(({ id }) => id).join(','),
      UserRoles: auth.roles,
    });

    await dispatch(fetchFreeMetals(orderOptions.contractId) as any);
    await dispatch(fetchCart());
    return data;
  },
);
