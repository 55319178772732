/* eslint-disable camelcase */
import { Metal } from './orders';
import { ContractType } from './contracts';
import { Pagination } from './pagination';
import { Option } from 'components/Autocomplete';
import { Dayjs } from 'dayjs';

export type InStock = {
  contractType: string;
  availability: boolean;
};

export type ProductConditions = {
  forContractType?: ContractType;
  hasProductionPowers: boolean;
  inStock: InStock[];
  isClosed: boolean;
  isDiscontinued: boolean;
  minOrderWeight: number;
  minOrderCount: number;
  coilProductionAvailable: boolean;
  customLengthAvailable: boolean;
};

export type MassData = {
  slope: number | null;
  intercept: number | null;
};

export enum CoilProductionPowerMode {
  EMPTY = 0,
  OVERALL = 1,
  TYPE_AND_COATING = 2,
  ONLY_COATING = 3,
  ONLY_TYPE = 4,
}

export type CoilProductionPowerValue = {
  value: number;
  withCoating: boolean | null;
  isChain: boolean | null;
};

export type CoilProductionPower = {
  mode: CoilProductionPowerMode;
  values: CoilProductionPowerValue[];
};

export type Product = {
  weaving: string;
  article: string;
  diameter: number;
  description: string;
  massData: MassData;
  // enableProduction: boolean;
  productionPower: number;
  coilProductionPower: CoilProductionPower;
  productionFactor: number;
  // minOrderWeight: number;
  // minOrderCount: number;
  title: string;
  conditions: ProductConditions;
  images: Image;
  equipment: boolean;
  additionalImages: {
    detail: string;
    preview: string;
  }[];
  orderTerms: {
    production: number;
    readyOffers: number;
    coilProduction: number;
  };

  params: AdditionalProductParams;
  type: ProductTypes;
  covering: Coverings;
  offers: Offers;
  fullDescr: string;
  disabledPlatings: number[];
  isDimensioned: boolean;
};

export type Coating = {
  id: number;
  title: string;
  code: string;
  sort: number;
};

export type CoatingOffer = {
  id: number;
  length: number;
  weight: number;
  coating: Coating;
};

export type Covering = {
  id: number;
  name: string;
  images: Image;
};

export type TypeImages = 'preview' | 'detail' | 'detailRotate' | 'previewRotate';
export type ImageUpdate = {
  type: TypeImages;
  productId: number;
  file: File;
  rotate: boolean;
};

export type ProductImagePayload = {
  productId: number;
  detail: string;
  preview: string;
  detailRotate: string;
  previewRotate: string;
};

export type ImageCoatingPayload = ProductImagePayload & {
  coatingId: number;
};

export type AdminProductImage = Record<TypeImages, string | null>;
export type Feature = {
  id: number;
  code: string;
  title: string;
  showInLlist: boolean;
};

export type FeatureAndValue = {
  feature: Feature;
  id: number;
  value: string;
};

export type CategoryAdmin = {
  id: number;
  name: string;
  code: string;
};

export type Alloys = {
  id: number;
  code: string;
  name: string;
};

export type AdminOffer = {
  id: number;
  active: boolean;
  length: number;
  weight: number;
  typeId: number;
  coating: Coating;
  coatingId: number;
  type: ProductType;
  productId: number;
};

export type AdditionalImage = Image & {
  id: number;
};

export type ManufacturingType = {
  value: number;
  key: string;
  description: string;
};

export type AdminProductsDetails = {
  manufacturingTypes: ManufacturingType[];
};

export type AdminProductDetails = {
  manufacturingType: ManufacturingType;
};

export type AdminProduct = {
  id: number;
  article: string;
  title: string;
  descr: string;
  engDescr: string;
  alterDescr: string;
  fullDescr: string;
  images: AdminProductImage;
  diameter: number;
  powerCode: string;
  powerFactor: number;
  metal: Metal;
  metalId: number;
  alloy: Alloys;
  alloyId: number;
  active: boolean;
  categories: CategoryAdmin[];
  offers: AdminOffer[] | null;
  covering: Covering[] | null;
  additionalImages: AdditionalImage[];
  features: FeatureAndValue[];
  novelty: Dayjs | string | null;
  details: AdminProductDetails;
  isDimensioned: boolean;
  productBadgeId: number | null;
  badgeDateEnd: string | null;
};

// TODO: не нравится решение с тремя состояниями
export type Detail = AdminProduct | null | undefined;
export type AdminProductsForm = Omit<
  AdminProduct,
  'images' | 'additionalImages' | 'metal' | 'covering' | 'categories'
> & {
  categories: Option[];
  features: { id: number; value: string }[];
  details: {
    manufacturingTypeId: number;
  };
};

export type AdminProductsUpload = Omit<AdminProductsForm, 'categories'> & {
  id?: number;
  categories: number[];
};

export type ImageCoatingUpdate = {
  productId: number;
  coatingId: number;
  file: File;
};

export type ImageAdditionalPayload = {
  item: {
    productId: number;
    id: number;
    preview: string;
    detail: string;
  };
};

export type ImageAdditionalUpdate = {
  productId: number;
  file: File;
};

export type ImageAdditionalDelete = {
  productId: number;
  imageId: number;
};

export type AdminProductsPayload = {
  items: AdminProduct[];
  pagination: Pagination;
};

export type ProductType = {
  id: number;
  title: string;
  code: string;
  sort: number;
};

export type AdditionalProductParam = {
  name: string;
  value: string | number | boolean;
  code: string;
  show?: 0 | 1;
};

export type StockType = 'finished' | 'finished_self_marked';
export type Offer = {
  active: boolean;
  id: number;
  length: number;
  weight: number;
  // max: number | null;
  covering: string;
  coveringId: number;
  typeId: number;
  // count: number;
  coating: Coating;
  type: Coating;
  typeName: string;
  productId?: number;
  countInProduction: number;
  countInWaitingList: number;
  countInCoils: number;
  countInFinished: number;
  countInSelfMark: number;
  residues: Record<StockType, number | null>;
  withCoating?: boolean;
  isChain?: boolean;
};

export type Image = {
  preview: string;
  detail: string;
  // productId: number;
};

export type WeightInCoilValue = {
  total: number;
  withCoating: number;
  withoutCoating: number;
};

export type WeightInCoilInCart = WeightInCoilValue & {
  chain: WeightInCoilValue;
  band: WeightInCoilValue;
};

export type AdditionalProductParams = AdditionalProductParam[];
export type Coverings = Covering[];
export type Offers = Offer[];
export type ProductTypes = ProductType[];

export type ProductBadgeEntity = {
  id: number;
  text: string;
};
