import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { actionTypes, sendEvent } from 'logger';
import { ConsultationData } from 'app/types/consultation';
import { postConsultation } from 'app/services/consultation';

export interface ConsultationSliceProps {
  loading?: boolean;
  success?: boolean;
}

const initialState: ConsultationSliceProps = {
  loading: false,
  success: false,
};

export const requestConsultation = createAsyncThunk(
  'consultation/requestConsultation',
  async (data: ConsultationData) => {
    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach(key => {
      formData.append(key, data[key as keyof ConsultationData]);
    });
    const { message, success } = await postConsultation(formData);
    if (success) {
      return message;
    } else {
      throw new Error('Неизвестная ошибка сервера');
    }
  },
);

const consultationSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(requestConsultation.pending, state => {
      state.loading = true;
    });
    builder.addCase(requestConsultation.fulfilled, state => {
      state.success = true;
      state.loading = false;
    });
    builder.addCase(requestConsultation.rejected, (state, action) => {
      antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});
const { reducer } = consultationSlice;

export { reducer as consultation };
