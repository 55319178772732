import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from './Header';
import * as S from './AutoOrder.styled';
import { Aside } from './Aside';
import { Col, Row, Spin } from 'antd';
import { useAutoOrders } from 'app/ducks/autoOrders';
import { Empty } from './Empty';
import { TableGeneral, TableOffer } from './tables';
import { TableOrder } from './tables/TableOrder';
import { getPercentCompleted } from '../utils';
import { ButtonExpand, useButtonExpand } from '../components/ButtonExpand';

export const AutoOrder = () => {
  const { id } = useParams<{ id: string }>();
  const { detail, isLoading, getAutoOrder, cancelAutoOrder } = useAutoOrders();

  const {
    filteredList: filteredOffers,
    isExpanded: isExpandedOffers,
    isButtonVisible: isButtonOffersVisible,
    toggleVisible: toggleVisibleOffers,
  } = useButtonExpand(detail?.cartOffers || []);

  const {
    filteredList: filteredOrders,
    isExpanded: isExpandedOrders,
    isButtonVisible: isButtonOrdersVisible,
    toggleVisible: toggleVisibleOrders,
  } = useButtonExpand(detail?.cartOrders || []);

  const generalList = detail
    ? [
        {
          key: 'count',
          name: 'Количество, шт',
          completed: detail.countCompleted,
          notCompleted: detail.countAll - detail.countCompleted,
          all: detail.countAll,
        },
        {
          key: 'weight',
          name: 'Вес, г',
          completed: detail.weightCompleted,
          notCompleted: detail.weightAll - detail.weightCompleted,
          all: detail.weightAll,
        },
      ]
    : [];

  useEffect(() => {
    getAutoOrder(Number(id));
  }, [id]);

  return (
    <S.Wrapper>
      <Spin spinning={isLoading}>
        {!!detail ? (
          <S.BodyWrapper>
            <Header
              id={id}
              dateReturnToCart={detail.status.code === 'processing' ? detail.dateReturnToCart : undefined}
            />
            <Row gutter={30}>
              <Col span={18}>
                <S.Body>
                  <TableGeneral
                    dataSource={generalList}
                    rowKey="key"
                    percentCompleted={getPercentCompleted(detail.countCompleted, detail.countAll)}
                  />
                  <S.TableWrapper>
                    <TableOffer dataSource={filteredOffers} />
                    {isButtonOffersVisible && (
                      <ButtonExpand
                        isExpanded={isExpandedOffers}
                        total={detail.cartOffers.length}
                        onClick={toggleVisibleOffers}
                      />
                    )}
                  </S.TableWrapper>
                  <S.TableWrapper>
                    <TableOrder dataSource={filteredOrders} />
                    {isButtonOrdersVisible && (
                      <ButtonExpand
                        isExpanded={isExpandedOrders}
                        total={detail.cartOrders.length}
                        onClick={toggleVisibleOrders}
                      />
                    )}
                  </S.TableWrapper>
                </S.Body>
              </Col>
              <Col span={6}>
                <Aside
                  createdAt={detail.createdAt}
                  user={detail.user}
                  productType={detail.productType}
                  knfmpNumber={detail.knfmpNumber}
                  countAll={detail.countAll}
                  weightAll={detail.weightAll}
                  status={detail.status}
                  comment={detail.comment}
                  metalName={detail.metalName}
                  onCancelOrder={() => cancelAutoOrder(detail.id)}
                />
              </Col>
            </Row>
          </S.BodyWrapper>
        ) : (
          <Empty isLoading={isLoading} />
        )}
      </Spin>
    </S.Wrapper>
  );
};
