import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';

export const usePublicBanners = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.publicBanners);

  const fetchPubicBanners = (search = '') => {
    return dispatch(actions.fetchPublicBanners(search));
  };

  return {
    state,
    fetchPubicBanners,
  };
};
