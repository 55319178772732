import * as React from 'react';
import { Dropdown as DropdownAntd, Popover, PopoverProps } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { IconLetter } from 'components/KrasUIKit/icons';
import * as S from './styled';

interface Props extends Partial<DropDownProps> {
  content?: React.ReactNode;
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  popoverProps?: PopoverProps;
}

export const Dropdown: React.FC<Props> = ({
  menu,
  content,
  children,
  label,
  icon,
  disabled = false,
  popoverProps,
  ...props
}) => {
  const button = (
    <S.DropdownButton disabled={disabled}>
      {icon && (
        <IconLetter size="1.57em" style={{ color: '#767676', minWidth: '1.57em' }}>
          {icon}
        </IconLetter>
      )}
      <S.DropdownButtonLabel>{children}</S.DropdownButtonLabel>
      {!disabled && <S.CaretDownFilled />}
    </S.DropdownButton>
  );
  return (
    <S.DropdownWrap>
      <S.DropdownLabel>{label}</S.DropdownLabel>
      {menu ? (
        <DropdownAntd
          menu={menu}
          overlayStyle={{ minWidth: 'auto' }}
          placement="bottom"
          arrow={true}
          disabled={disabled}
          {...props}
        >
          {button}
        </DropdownAntd>
      ) : (
        <Popover content={content} {...popoverProps}>
          {button}
        </Popover>
      )}
    </S.DropdownWrap>
  );
};

export * from './styled';
