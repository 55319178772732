import { createAsyncThunk } from '@reduxjs/toolkit';
import { PowersService } from 'app/services/admin/powers';
import { CreatePowerDto, UpdatePowerDto } from 'app/types/powers';
import { message as antdMessage } from 'antd';
import i18next from '../../../i18n';

const fetchPowers = createAsyncThunk(
  'powers/fetchPowers',
  async (search: string, { rejectWithValue }) => {
    const { data, message, success } = await PowersService.getMany(search);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);

const updatePower = createAsyncThunk(
  'powers/updatePower',
  async (payload: UpdatePowerDto, { rejectWithValue }) => {
    const { data, message, success } = await PowersService.update(payload);
    if (success && data) {
      antdMessage.success(message || i18next.t('admin.powers.update.success'));
      return data;
    } else {
      antdMessage.error(message || i18next.t('admin.powers.update.failed'));
      return rejectWithValue(data);
    }
  },
);

const addPower = createAsyncThunk(
  'powers/addPower',
  async (power: CreatePowerDto, { rejectWithValue }) => {
    const { data, success, message } = await PowersService.create(power);
    if (success) {
      antdMessage.success(message || i18next.t('admin.powers.create.success'));
      return data;
    } else {
      antdMessage.error(message || i18next.t('admin.powers.create.failed'));
      return rejectWithValue(data);
    }
  },
);

const deletePower = createAsyncThunk(
  'powers/deletePower',
  async (id: number, { rejectWithValue }) => {
    const { success, message } = await PowersService.delete(id);
    if (success) {
      antdMessage.success(message || i18next.t('admin.powers.delete.success'));
      return id;
    } else {
      antdMessage.error(message || i18next.t('admin.powers.delete.failed'));
      return rejectWithValue(id);
    }
  },
);

export { fetchPowers, updatePower, addPower, deletePower };
