import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import { setEditable } from './slice';
import { selectAllResidues } from './selectors';
import * as actions from './actions';
import * as types from 'app/types/coilResidues';

export const useCoilResidues = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.coilResidues);
  const list = useSelector((state: RootState) => selectAllResidues(state));

  const fetchCoilResidues = useCallback((search: string) => {
    return dispatch(actions.fetchCoilResidues(search));
  }, []);

  const fetchResiduesTypes = useCallback(() => {
    return dispatch(actions.fetchResiduesTypes());
  }, []);

  const createOrUpdateCoilResidue = useCallback(
    async (residue: types.CreateOrUpdateResidueDto) => {
      const resultAction = await dispatch(
        actions.createOrUpdateCoilResidue(residue),
      );
      if (actions.createOrUpdateCoilResidue.fulfilled.match(resultAction)) {
        return unwrapResult(resultAction);
      }
    },
    [],
  );

  const deleteCoilResidue = useCallback((payload: types.DeleteResidueDto) => {
    return dispatch(actions.deleteCoilResidue(payload));
  }, []);

  return {
    state: { ...state, list },
    fetchCoilResidues,
    createOrUpdateCoilResidue,
    deleteCoilResidue,
    fetchResiduesTypes,
  };
};

export const useEditableResidue = () => {
  const dispatch: AppDispatch = useDispatch();

  const setEditableResidue = useCallback(
    (residue: types.EditableResidue | null) => {
      return dispatch(setEditable(residue));
    },
    [],
  );

  return {
    setEditableResidue,
  };
};
