import React from 'react';
import * as S from './styled';
import { Tab, Props as TabProps } from './Tab';

export interface Props
  extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  active?: number | string;
  onChange: (index: number | string) => void;
  tabs: Omit<TabProps, 'onClick' | 'active'>[];
  showZeroCount?: boolean;
}

export const Tabs: React.FC<Props> = ({
  active,
  onChange,
  tabs,
  style,
  showZeroCount,
}) => {
  return (
    <S.Tabs count={tabs.length} style={style}>
      {tabs.map(tab => (
        <Tab
          key={tab.id}
          {...tab}
          onClick={onChange}
          active={active}
          showZeroCount={showZeroCount}
        />
      ))}
    </S.Tabs>
  );
};
