import { apiPost, apiGet } from './connect';
import { Result } from 'app/types/api';
import * as types from 'app/types/cart';

export type AddCartResult = Result<[]>;

export type BasketInfo = Result<types.Total | []>;

export const getCart = async () => {
  const { data } = await apiGet<Result<types.CartEntity>>('/cart');

  return data;
};

export const addToCart = async (product: types.AddingProduct) => {
  const { data } = await apiPost<AddCartResult>('/cart/positions', product);

  return data;
};

export const updateCart = async (action: { type: 'update' | 'delete'; payload: types.UpdateCartPayload }) => {
  const { data } = await apiPost<Result<types.CartOfferUpdateEntity>>('/cart/update', {
    [action.type]: JSON.stringify(action.payload),
  });

  return data;
};

export const getCartExcel = async (orderType: types.OrderType) => {
  const { data } = await apiGet<
    Result<{
      name: string;
      data: string;
      mimeType: string;
    }>
  >('/cart/excel', { orderType });

  return data;
};

export const getCartImportExcel = async ({ orderType, metals }: { orderType: types.OrderType; metals?: number[] }) => {
  const { data } = await apiGet<
    Result<{
      name: string;
      data: string;
      mimeType: string;
    }>
  >('/cart/excel/import', { orderType, metals: metals?.join() });
  return data;
};

export const checkCart = async (options: Omit<types.CheckCartData, 'orderType'>) => {
  const { data } = await apiPost<Result<types.CheckedOrderType[]>>('/cart/check', {
    metals: [...new Set(options.metals)].map(id => ({ id })),
    contractId: options.contractId,
  });
  return data;
};

export const snapshotCart = async () => {
  const { data } = await apiPost<Result>('/cart/snapshot');

  return data;
};
export const getDifferenceCartOrders = async (payload: types.CartDifferenceOptions) => {
  const { data } = await apiGet<Result<types.CartDifferenceOrder[]>>('/cart/get-difference-cart-orders', {
    ...payload,
    isCostOrderIncreased: payload.isCostOrderIncreased ? 1 : 0,
  });

  return data;
};

export const checkoutOrder = async (orderOptions: types.OrderOptions) => {
  const { data } = await apiPost<Result<types.CheckoutOrder>>('/cart/checkout', orderOptions);

  return data;
};

export const transferToProduction = async (payload: types.TransferPosition) => {
  const { to, offers } = payload;
  const { data } = await apiPost<Result<{}>>('/cart/update', {
    change: JSON.stringify({ action: 'order_type', to, offers }),
  });

  return data;
};

export const unionCoilAndFinished = async (isUnion: boolean) => {
  const { data } = await apiPost<Result<{}>>('/cart/update', {
    change: JSON.stringify({ action: 'union', value: isUnion }),
  });

  return data;
};
