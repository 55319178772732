import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ProductService from 'app/services/admin/products';

export const fetchProduct = createAsyncThunk(
  'productDetail/fetchProduct',
  async (id: number) => {
    const { message, success, data } = await ProductService.getProduct(id);
    if (success && data) return { data, id };
    else throw new Error(message);
  },
);
