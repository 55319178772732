import { apiGet } from './connect';
import { Products } from 'app/types/categories';
import { Result } from 'app/types/api';
import { keysToCamel } from './helper';
import { Pagination } from 'app/types/pagination';
import { Suggestions } from 'app/types/search';

import axios, { Canceler } from 'axios';

export type SearchProductsResult = Result<{
  list: Products;
  pagination: Pagination;
}>;

export type SuggestionsResult = Result<Suggestions>;

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export const getProductsBySearchQuery = async (query: string, page = 1) => {
  if (cancel) cancel();
  const { data: response } = await apiGet<SearchProductsResult>(
    '/products/search',
    {
      query,
      page,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    },
  );
  const { data, success, message } = response;

  return keysToCamel({
    data,
    message,
    success,
  });
};

export const getProductSuggestions = async (query: string) => {
  const { data: response } = await apiGet<SuggestionsResult>(
    '/products/search/help',
    {
      query,
    },
  );
  const { data, success, message } = response;

  return keysToCamel({
    data,
    message,
    success,
  });
};
