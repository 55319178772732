import * as actions from './actions';
import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { BannerEntity } from 'app/types/banners';
import { PaginationNew } from 'app/types/pagination';

const SLICE_NAME = 'banners';

interface InitialState {
  loading: boolean;
  list: BannerEntity[];
  pagination?: PaginationNew;
}

export const initialState: InitialState = {
  loading: true,
  list: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith(`${SLICE_NAME}/`) &&
  action.type.endsWith('/fulfilled');

const bannersSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchBanners.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.updateBanner.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(item => item.id === payload.item.id);
      if (index > -1) state.list[index] = payload.item;
    });
    addCase(actions.deleteBanner.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(item => item.id === payload);
      if (index > -1) state.list.splice(index, 1);
    });
    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default bannersSlice.reducer;
