import { ItemResult, ItemsResult, Result } from 'app/types/api';
import { CreateProductCategoryDto, ProductCategoryEntity, UpdateProductCategoryDto } from 'app/types/productCategories';
import { apiGet, apiPost, apiDelete } from '../connect';

class ProductCategoriesServiceFactory {
  public async getMany() {
    try {
      const { data } = await apiGet<ItemsResult<ProductCategoryEntity>>('/admin/products/categories');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async create(payload: CreateProductCategoryDto) {
    try {
      const { data } = await apiPost<ItemResult<ProductCategoryEntity>>('/admin/products/categories', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async update(categoryId: number, payload: UpdateProductCategoryDto) {
    try {
      const { data } = await apiPost<ItemResult<ProductCategoryEntity>>(
        `/admin/products/categories/${categoryId}`,
        payload,
      );
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async delete(categoryId: number) {
    try {
      const { data } = await apiDelete<Result>(`/admin/products/categories/${categoryId}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const ProductCategoriesService = new ProductCategoriesServiceFactory();
