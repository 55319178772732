import React from 'react';
import { Covering, Props as CoveringProps } from './Covering';
import * as S from './styled';

export type Item = Omit<CoveringProps, 'onChange' | 'active'>;
export interface Props {
  list: Item[];
  onChange: (id: number) => void;
  active: number | null;
}
export const Coverings: React.FC<Props> = ({ list, onChange, active }) => {
  // const hasWithout = list.find(({ id }) => id === 0);
  // const coverings = hasWithout ? list :
  return (
    <S.Coverings>
      {list.map(covering => (
        <Covering
          key={covering.id}
          {...covering}
          onChange={onChange}
          active={active}
        />
      ))}
    </S.Coverings>
  );
};
