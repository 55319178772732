import styled from 'styled-components';

const getColorByType = (type: 'success' | 'warning' | 'error' | 'default') => {
  const defaultColor = {
    icon: '#4DBF7B',
    color: '#76767',
    border: '#767676',
    disabledBg: '#f4f4f4',
    disabledBorder: '#e0e0e0',
    interactiveBorder: '#4f4f4f',
    shadow: 'rgba(118, 118, 118, 0.5)',
  };
  switch (type) {
    case 'success':
      return {
        ...defaultColor,
        icon: '#4DBF7B',
      };
    case 'warning':
      return {
        ...defaultColor,
        icon: '#E4B714',
        color: '#E4B714',
        border: '#E4B714',
        disabledBorder: '#E4B714',
        interactiveBorder: '#E4B714',
        shadow: 'rgba(228, 183, 20, 0.5)',
      };
    case 'error':
      return {
        ...defaultColor,
        icon: '#DF4545',
        color: '#DF4545',
        border: '#DF4545',
        disabledBorder: '#DF4545',
        interactiveBorder: '#DF4545',
        shadow: 'rgba(223, 69, 69, 0.5)',
      };
    case 'default':
      return defaultColor;
  }
};

export const Container = styled.label`
  font-size: 14px;
  display: inline-flex;
  width: 274px;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 1em;
  line-height: 1.286em;
  margin-bottom: 0.286em;
  color: #212529;
`;

export const Clear = styled.button<{
  colorType: 'success' | 'warning' | 'error' | 'default';
}>`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: ${({ colorType }) =>
    colorType !== 'default' ? '2.714em' : '0.857em'};
  width: 1.375em;
  height: 100%;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Field = styled.div`
  display: inline-block;
  position: relative;
  font-size: 1.143em;
  :hover,
  :focus,
  :active {
    ${Clear} {
      opacity: 1;
    }
  }
`;

export const Affix = styled.div`
  position: absolute;
  left: 0.7em;
  width: 1.375em;
  height: 100%;
  padding: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const Description = styled.div<{
  colorType: 'success' | 'warning' | 'error' | 'default';
}>`
  display: inline-block;
  font-size: 0.857em;
  line-height: 1.167em;
  margin-top: 0.333em;
  max-width: 100%;
  color: ${({ colorType }) => getColorByType(colorType).color};
`;

export const Input = styled.input<{
  withAffix?: boolean;
  colorType: 'success' | 'warning' | 'error' | 'default';
  clearable?: boolean;
}>`
  border: 1px solid ${({ colorType }) => getColorByType(colorType).border};
  border-radius: 3px;
  padding: 0.45em
    ${({ clearable, colorType }) =>
      clearable
        ? colorType !== 'default'
          ? '4.6em'
          : '2.3em'
        : colorType !== 'default'
        ? '2.3em'
        : '0.75em'}
    0.55em ${({ withAffix }) => (withAffix ? '2.3em' : '0.75em')};
  outline: none;
  width: 100%;
  line-height: 1em;
  vertical-align: baseline;
  :not(:disabled) {
    :hover,
    :focus,
    :active {
      border-color: ${({ colorType }) =>
        getColorByType(colorType).interactiveBorder};
      & + ${Clear} {
        opacity: 1;
      }
    }

    :focus {
      box-shadow: 0px 0px 4px
        ${({ colorType }) => getColorByType(colorType).shadow};
    }
  }
  :disabled {
    background-color: ${({ colorType }) =>
      getColorByType(colorType).disabledBg};
    border-color: ${({ colorType }) =>
      getColorByType(colorType).disabledBorder};
  }
`;

export const TypeMark = styled.div<{
  colorType: 'success' | 'warning' | 'error' | 'default';
}>`
  color: ${({ colorType }) => getColorByType(colorType).icon};
  position: absolute;
  right: 0.857em;
  top: 0;
  width: 1.375em;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
`;
