import styled from 'styled-components';

export const Card = styled.div<{
  disabled?: boolean;
  horizontal?: boolean;
}>`
  width: 100%;

  display: inline-flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  padding: ${({ horizontal }) => (horizontal ? '1.5em 2em' : '1.25em')};
  vertical-align: top;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  word-break: break-word;
  will-change: transform;
  transition: transform 0.2s, box-shadow 0.2s;
  height: 100%;
  ${({ disabled }) =>
    disabled
      ? 'cursor: not-allowed;'
      : `
  :hover {
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.12);
    transform: translateY(-8px);
  }
  `};
`;

export const Image = styled.div<{
  horizontal?: boolean;
}>`
  position: relative;
  overflow: hidden;
  width: ${({ horizontal }) => (horizontal ? '15.313em' : '100%')};
  height: ${({ horizontal }) => (horizontal ? '12.5em' : '11.188em')};
  margin-bottom: ${({ horizontal }) => (horizontal ? '0' : '1em')};
  margin-right: ${({ horizontal }) => (horizontal ? '1.75em' : '0')};
  /* background-color: #f4f4f4; */
  border: 1px solid #f4f4f4;
  flex: none;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  img {
    width: 100%;
  }
  div {
    align-self: baseline;
    position: absolute;
    top: 0.45em;
    right: 0.5em;
  }
`;

export const Horizontal = styled.div<{
  horizontal?: boolean;
}>`
  position: relative;
  display: block;
  padding-bottom: ${({ horizontal }) => (horizontal ? '1.875em' : '')};
`;

export const Tags = styled.div<{
  horizontal?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ horizontal }) => (horizontal ? '1.75em' : '0')};
  button:last-child {
    margin-right: 0;
  }
  button {
    font-size: 0.875em;
    margin-right: 0.571em;
    margin-bottom: 0.25em;
  }
`;

export const Description = styled.div<{
  disabled?: boolean;
  horizontal?: boolean;
}>`
  margin-bottom: ${({ horizontal }) => (horizontal ? '0.5em' : '0.75em')};
  font-size: 0.875em;
  line-height: 1.125em;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed; color: #bbbbbb;' : 'color: #212529;')}
`;

export const SubTitle = styled.span<{
  disabled?: boolean;
}>`
  line-height: 1.188em;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed; color: #bbbbbb;' : 'color: #767676;')}
`;

export const Title = styled.span<{
  disabled?: boolean;
}>`
  font-size: 1.125em;
  line-height: 1.188em;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed; color: #bbbbbb;' : 'color: #212529;')}
`;

export const Container = styled.div<{
  horizontal?: boolean;
}>`
  display: ${({ horizontal }) => (horizontal ? 'flex' : 'block')};
  margin-top: ${({ horizontal }) => (horizontal ? '0' : '1em')};
  position: ${({ horizontal }) => (horizontal ? 'absolute' : '')};
  bottom: ${({ horizontal }) => (horizontal ? '0' : '')};
`;

export const Label = styled.div`
  display: inline-flex;
  margin-top: 1em;
  margin-right: 1em;
`;

export const Icon = styled.span`
  display: flex;
  button {
    color: #4f4f4f;
    font-size: 1.375em;
  }
`;

export const Count = styled.span<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: 0.3333em;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 0.875em;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed; color: #bbbbbb;' : 'color: #4f4f4f;')}
`;
