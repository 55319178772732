import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Route } from './Route';
import { Page404 } from 'app/pages/404';
import { ItemInterface } from 'app/pages/routes';
import { Route as NativeRoute } from 'react-router-dom';
import { AdminNavigation } from '../Layout/AdminNavigation';
import * as S from './styled';
import { Banners } from 'features/Banners';
export interface Props {
  routes?: ItemInterface[];
}

export const Root: React.FC<Props> = ({ routes = [] }) => {
  return (
    <>
      <Suspense fallback={<div>Загрузка...</div>}>
        <S.Layout>
          <NativeRoute path="/admin">
            <AdminNavigation />
          </NativeRoute>
          <S.Layout>
            <Banners />
            <Switch>
              {routes.map((item, index) => (
                <Route key={index} {...item} />
              ))}
              <NativeRoute path="/catalog">
                <Redirect to="/catalog/products" />
              </NativeRoute>
              <NativeRoute path="/import">
                <Redirect to="/import/standard" />
              </NativeRoute>
              <Route path="*" component={Page404} />
            </Switch>
          </S.Layout>
        </S.Layout>
      </Suspense>
    </>
  );
};
