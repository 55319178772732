import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import * as actions from './actions';
import {
  Section,
  DocumentsTree,
  TessaDocument,
  Acts,
} from 'app/types/documents';

export interface StateProps {
  loading: boolean;
  sections: Section[];
  documents?: DocumentsTree;
  sendingAct?: boolean;
  tessaDocuments: TessaDocument[];
  acts: Acts;
}

const initialState: StateProps = {
  loading: false,
  sections: [],
  tessaDocuments: [],
  acts: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documents/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documents/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('documents/') && action.type.endsWith('/fulfilled');

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      actions.fetchDocumentSections.fulfilled,
      (state, { payload }: PayloadAction<Section[]>) => {
        state.sections = payload;
      },
    );
    addCase(
      actions.fetchSectionContent.fulfilled,
      (state, { payload }: PayloadAction<DocumentsTree>) => {
        state.documents = payload;
      },
    );
    addCase(actions.fetchDocuments.fulfilled, (state, { payload }) => {
      state.tessaDocuments = payload.items;
    });
    addCase(
      actions.fetchActs.fulfilled,
      (state, { payload }: PayloadAction<Acts>) => {
        state.acts = payload;
      },
    );
    addCase(actions.submitActRequest.pending, state => {
      state.sendingAct = true;
    });
    addCase(actions.submitActRequest.rejected, state => {
      state.sendingAct = false;
    });
    addCase(actions.submitActRequest.fulfilled, state => {
      state.sendingAct = false;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action: AnyAction): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state, action) => {
        if (isRejectedAction(action)) {
          antdMessage.error(action.error.message);
        }
        state.loading = false;
      },
    );
  },
});

export default documentsSlice.reducer;
