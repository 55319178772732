import styled from 'styled-components';

export const CustomCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomCell = styled.div`
  line-height: 16px;
  text-align: center;
`;

export const CancelledStatus = styled.div`
  color: #767676;
`;
