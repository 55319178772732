export const passwordRequirements = (login: string | null) => [
  {
    code: 'length',
    validator: (value: string) =>
      !!value && typeof value === 'string' && value.length < 8,
  },
  {
    code: 'login',
    validator: (value: string) =>
      !!login && typeof value === 'string' && value === login,
  },
  {
    code: 'case',
    validator: (value: string) =>
      !!value &&
      typeof value === 'string' &&
      !(/[A-Z]/.test(value) && /[a-z]/.test(value)),
  },
  {
    code: 'symbols',
    validator: (value: string) =>
      !!value &&
      typeof value === 'string' &&
      !(/[\W]/.test(value) || /[\d]/.test(value)),
  },
];
