import { apiGet, apiPost } from './connect';
import { ItemResult, ItemsResult, Result } from 'app/types/api';
import { MetalReportCreateBody, MetalReportEntity } from 'app/types/metalReport';

class MetalReportServiceFactory {
  public async getMetalReports() {
    try {
      const { data } = await apiGet<ItemsResult<MetalReportEntity>>('/report-use-metal');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
  public async createReportMetal(payload: MetalReportCreateBody) {
    try {
      const { data } = await apiPost<ItemResult<MetalReportEntity>>('/report-use-metal', payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const MetalReportService = new MetalReportServiceFactory();
