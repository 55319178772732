import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import { actionTypes, sendEvent } from 'logger';
import { getPanes as userMenu } from 'components/Personal';
import { useIsAutoOrderVisible } from 'hooks/useIsAutoOrderVisible';

export const useUserMenu = () => {
  const { t } = useTranslation('main');
  const location = useLocation();
  const isAutoOrderVisible = useIsAutoOrderVisible();
  const list = userMenu({ isAutoOrderVisible })
    .slice()
    .sort((a, b) => a.sort - b.sort);
  const selectedKeys = list.filter(item => location.pathname.includes(item.to)).map(item => item.to);
  const isSelected = selectedKeys.some(key => location.pathname.includes(key));

  const menuItems: MenuProps['items'] = list.map(({ to, name, hideInDropDown = false }) => ({
    key: to,
    type: 'item',
    label: t(`personal:${name}`),
    disabled: !!hideInDropDown,
    onClick: () =>
      sendEvent({
        ActionType: actionTypes.HEADER_PERSONAL_CLICK_NAV,
        ContextKey: to,
      }),
  }));

  const menu: MenuProps = {
    items: menuItems,
    selectedKeys,
    style: { maxWidth: '17em' },
  };

  return {
    menu,
    isSelected,
  };
};
