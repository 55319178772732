import React from 'react';
import { Footer } from 'components/Layout/Footer';
import { useSelector } from 'react-redux';

import { RootState } from 'app/store/reducer';

export const FooterContainer: React.FC = () => {
  const { menu } = useSelector((state: RootState) => state.auth);

  const items = menu?.footer || [];
  return <Footer items={items} />;
};
