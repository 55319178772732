import styled from 'styled-components';

export const Overlay = styled.div`
  padding: 24px;
  max-width: 388px;
  min-width: 330px;
  width: auto;
  background-color: #fff;
  border-radius: 3px;
  filter: drop-shadow(0px 0px 4px #dfdfdf);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
`;
