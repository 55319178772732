import React from 'react';
import { Content } from '../Content';
import { Props as DetailProps } from 'components/ProductDetail';
import { initOrderTypes } from './orderTypeRegister';
import { Tabs } from 'components/KrasUIKit';
import { createLoggerEvent, actionTypes } from 'logger';
import { Offers } from 'app/types/products';
import { useCart } from 'app/ducks/cart';
import { getCustomWeight } from '../helpers';
import { useAuth } from 'app/ducks/auth';
import { CartOffer } from 'app/types/cart';

export type Props = DetailProps & {
  updateActiveCovering: (coveringId: number) => void;
  notAvilableByContract?: boolean;
  isDimensioned: boolean;
};

const getOffersProps = (offers: Offers, customOffers: CartOffer[], chainMinLength: number, activeCovering?: number) => {
  // TODO: Реализовать корректное списание нестандартной длины при добавлении в корзину
  const { total, weightsByType } = getCustomWeight({
    offers: customOffers,
    chainMinLength,
    activeCovering: activeCovering ?? null,
  });

  const defaultOffersProps = {
    powerInProductionInBacket: 0,
    weightInCoilInCart: {
      total: total,
      withCoating: 0,
      withoutCoating: 0,
      chain: {
        total: weightsByType.chain,
        withCoating: 0,
        withoutCoating: 0,
      },
      band: {
        total: weightsByType.band,
        withCoating: 0,
        withoutCoating: 0,
      },
    },
    countInProductionInBasket: 0,
    weightInProductionInBasket: 0,
    countInFinishedInBasket: 0,
    countInSelfMarkInBasket: 0,
  };

  return offers.reduce(
    (sum, { countInProduction, weight, countInCoils, coveringId, countInFinished, countInSelfMark, isChain }) => ({
      powerInProductionInBacket: sum.powerInProductionInBacket + countInProduction * weight,
      // weightInCoilInBasket: sum.weightInCoilInBasket + countInCoils * weight,
      // chainWeightInCoilInBasket:
      //   isChain && countInCoils > 0
      //     ? sum.chainWeightInCoilInBasket + countInCoils * weight
      //     : sum.chainWeightInCoilInBasket,
      // bandWeightInCoilInBasket:
      //   !isChain && countInCoils > 0
      //     ? sum.bandWeightInCoilInBasket + countInCoils * weight
      //     : sum.bandWeightInCoilInBasket,
      weightInCoilInCart: {
        // Нет деления ни по типу, ни по покрытию
        total: sum.weightInCoilInCart.total + countInCoils * weight,
        // Деление только по покрытию
        withCoating: sum.weightInCoilInCart.withCoating + (coveringId !== 1 ? countInCoils * weight : 0),
        withoutCoating: sum.weightInCoilInCart.withoutCoating + (coveringId === 1 ? countInCoils * weight : 0),
        chain: {
          // Деление только по типу
          total: sum.weightInCoilInCart.chain.total + (isChain ? countInCoils * weight : 0),
          // Деление по типу и по покрытию
          withCoating:
            sum.weightInCoilInCart.chain.withCoating + (isChain && coveringId !== 1 ? countInCoils * weight : 0),
          withoutCoating:
            sum.weightInCoilInCart.chain.withoutCoating + (isChain && coveringId === 1 ? countInCoils * weight : 0),
        },
        band: {
          total: sum.weightInCoilInCart.band.total + (!isChain ? countInCoils * weight : 0),
          withCoating:
            sum.weightInCoilInCart.band.withCoating + (!isChain && coveringId !== 1 ? countInCoils * weight : 0),
          withoutCoating:
            sum.weightInCoilInCart.band.withoutCoating + (!isChain && coveringId === 1 ? countInCoils * weight : 0),
        },
      },
      countInProductionInBasket: sum.countInProductionInBasket + countInProduction,
      weightInProductionInBasket: sum.weightInProductionInBasket + countInProduction * weight,
      countInFinishedInBasket: sum.countInFinishedInBasket + countInFinished,
      countInSelfMarkInBasket: sum.countInSelfMarkInBasket + countInSelfMark,
    }),
    defaultOffersProps,
  );
};

export const OrderTypeTabs: React.FC<Props> = props => {
  const { state: cartState } = useCart();
  const {
    state: { variables },
  } = useAuth();

  const productionPower = props.productionPower === null ? Infinity : props.productionPower;

  const cartPosition = cartState.positions.find(
    position => position.article === props.article && position.diameter === props.diameter,
  );

  const customCartOffers = cartPosition?.offers.filter(offer => offer.typeOffer === 'custom') ?? [];

  const offersProps = getOffersProps(props.offers, customCartOffers, variables?.chainMinLength ?? 0);

  const product = {
    ...props,
    ...offersProps,
    productionPower,
  };

  const tabs = initOrderTypes(product);

  const firstActiveTab = tabs.find(({ isVisible }) => isVisible);
  const [tab, setTab] = React.useState<string | number | undefined>(firstActiveTab?.content.orderType);

  const getTabContent = (tab: string | number | undefined) => {
    const neededTab = tabs.find(({ content }) => content.orderType === tab);
    if (!tab || !neededTab) return null;
    const isDemo = props.activeContract?.contractType === 'demo';

    return { ...neededTab.content, isDemo };
  };

  const activeTabContent = getTabContent(tab);

  const tabClickHandler = createLoggerEvent((tab: number | string) => setTab(tab), {
    ActionType: actionTypes.DETAIL_CLICK_TAB,
    ContextKey: tab,
    DetailOfferArticle: props.article,
  });

  return (
    <>
      <Tabs
        onChange={tabClickHandler}
        active={tab}
        tabs={tabs
          .filter(({ isVisible }) => isVisible)
          .map(({ tabProps, content }) => ({
            title: tabProps.title,
            count: tabProps.count,
            id: content.orderType,
          }))}
        showZeroCount
      />
      <div style={{ padding: '10px 10px 0 10px' }}>
        {activeTabContent && <Content {...activeTabContent} isDimensioned={props.isDimensioned} />}
      </div>
    </>
  );
};
