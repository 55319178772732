import { apiPost } from 'app/services/connect';
import { ChangePasswordPayload, SetPasswordPayload } from 'app/types/login';
import { Token, ItemResult, Result } from 'app/types/api';

export type TokenResult = ItemResult<Token>;

class LoginServiceFactory {
  public requestPasswordRecovery = async (email: string) => {
    const { data } = await apiPost<Result>('/users/password-recovery', {
      email,
    });
    return data;
  };
  public changePassword = async (payload: ChangePasswordPayload) => {
    const { data } = await apiPost<Result>('users/password-change', payload);
    return data;
  };
  public setPassword = async (payload: SetPasswordPayload) => {
    const { data } = await apiPost<TokenResult>('users/set-password', payload);
    return data;
  };
}
export const LoginService = new LoginServiceFactory();
