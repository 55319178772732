import { arrowBottomFilled, arrowTopFilled, number25, number50, number100 } from 'components/KrasUIKit/icons';
import { actionTypes } from 'logger';

export const METAL_COST_WITH_NDS = 'с НДС';
export const MENA_BUY_DESCRIPTION =
  'Цена покупки металла — стоимость, по которой Красцветмет выкупает металл клиента. Рассчитывается с учетом рыночных условий и производственных потерь. Денежные средства зачисляются на баланс  и могут быть использованы для покупки ювелирных изделий Красцветмета.';
export const MENA_SELL_DESCRIPTION =
  'Цена продажи металла — стоимость продажи ювелирных изделий Красцветмета клиентам. Рассчитывается за грамм изделия согласно категориям и действующему прайсу.';
export const PRICES_DESCRIPTION =
  'Поставка при предоплате — предложение позволяет зафиксировать стоимость изделий на дату размещения заказа и не зависеть от колебаний курса металла и других факторов. Выгодные цены на основной ассортимент и значительная экономия на общей стоимости заказа.';

export const metalCostInfoMap = {
  buy: {
    title: 'Цена покупки металла',
    icon: arrowBottomFilled,
    linkTitle: undefined,
    eventName: undefined,
  },
  sell: {
    title: 'Цена продажи металла',
    icon: arrowTopFilled,
    linkTitle: 'Скачать прайс по условиям взаимозачета',
    eventName: actionTypes.METAL_COST_HEADER_EXCHANGE_TERMS,
  },
  25: {
    title: 'Поставка с предоплатой 25%',
    icon: number25,
    linkTitle: 'Скачать прайс на поставку с предоплатой 25%',
    eventName: actionTypes.METAL_COST_HEADER_PREPAYMENT_25_TERMS,
  },
  50: {
    title: 'Поставка с предоплатой  50%',
    icon: number50,
    linkTitle: 'Скачать прайс на поставку с предоплатой 50%',
    eventName: actionTypes.METAL_COST_HEADER_PREPAYMENT_50_TERMS,
  },
  100: {
    title: 'Поставка с предоплатой  100%',
    icon: number100,
    linkTitle: 'Скачать прайс на поставку с предоплатой 100%',
    eventName: actionTypes.METAL_COST_HEADER_PREPAYMENT_100_TERMS,
  },
};
