import React, { useState } from 'react';
import { Option } from 'components/Autocomplete';
import { AutoCompleteProps } from 'formik-antd';
import * as S from './styled';

export interface Props
  extends Omit<AutoCompleteProps, 'value' | 'onChange' | 'name'> {
  value?: Option[];
  onSetTags: (name: string, options: Option[]) => void;
  component: typeof React.Component | React.FunctionComponent;
  id?: string;
  singleTag?: boolean;
  tagsStyle?: React.CSSProperties;
}

export const TagsAndAutocomplete: React.FC<Props> = ({
  component: Autocomplete,
  onSetTags,
  id: nameField = '',
  singleTag = false,
  value: list = [],
  tagsStyle,
  ...props
}) => {
  const [valueAutocomplete, setValueAutocomplete] = useState('');

  const filterOptions = (data: Option[]) => {
    return data.filter(({ key }) => !list.some(elem => elem.key === key));
  };

  const handleSelect = (option: Option) => {
    onSetTags(nameField, [...list, option]);
    setValueAutocomplete('');
  };
  const handleChange = (value: string) => {
    setValueAutocomplete(value);
  };
  const onDelete = (key: string) => {
    onSetTags(nameField, [...list.filter(option => option.key !== key)]);
  };

  return (
    <S.TagsControlWrap>
      {list.length !== 0 && (
        <div style={tagsStyle}>
          {list.map(({ label, key }) => (
            <S.ClosableTag key={key} closable onClose={() => onDelete(key)}>
              {label}
            </S.ClosableTag>
          ))}
        </div>
      )}
      {(!singleTag || list.length === 0) && (
        <Autocomplete
          dropdownMatchSelectWidth={250}
          {...props}
          onSelect={handleSelect}
          filterOptions={filterOptions}
          value={valueAutocomplete}
          onChange={handleChange}
        />
      )}
    </S.TagsControlWrap>
  );
};
