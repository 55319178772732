import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';

export const useClosedPositions = () => {
  const dispatch: AppDispatch = useDispatch();

  const state = useSelector((state: RootState) => state.closedPositions);

  const fetchClosedPositions = () => {
    dispatch(actions.fetchClosedPositions());
  };

  return {
    state,
    fetchClosedPositions,
  };
};
