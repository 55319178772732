import styled from 'styled-components';
import { Title } from './Common.styled';

export const CharsWrapper = styled.div`
  margin: var(--s6) 3px 3px;
  padding: 32px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #dfdfdf;

  > ${Title} {
    margin: 0;
  }
`;

export const CharsItem = styled.div<{ elIndex: number }>`
  margin-top: 24px;
  text-align: center;
  padding-top: ${({ elIndex }) => (elIndex !== 4 ? '0' : '24px')};
  border-right: ${({ elIndex }) => (elIndex === 4 || elIndex === 3 ? 'none' : '1px solid #e0e0e0')};
  border-top: ${({ elIndex }) => (elIndex !== 4 ? 'none' : '1px solid #e0e0e0')};
`;

export const CharsValue = styled.p`
  margin: 0 0 12px;
  color: #212529;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

export const CharsName = styled.p`
  margin: 0;
  color: #767676;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
`;
