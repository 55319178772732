import { Bonus, isWelcomeBonus, isSilverBonus } from '../utils/bonusTypeGuards';

export type BonusType = 'WELCOME_BONUS' | 'SILVER_BONUS';

export const useBonusType = (data: Bonus): BonusType | undefined => {
  if (isWelcomeBonus(data)) {
    return 'WELCOME_BONUS';
  }
  if (isSilverBonus(data)) {
    return 'SILVER_BONUS';
  }
  return undefined;
};
