import React from 'react';
import { Col } from 'antd';
import { actionTypes, sendEvent } from 'logger';
import CoinsSilver from 'images/png/coins-silver.png';
import { CaretLeft } from 'components/Icon';
import * as S from '../styled/SilverBonus.styled';
import { SlideProps } from '../types/Slide';
import { usePromotions } from 'app/ducks/companies';

export const SilverBonus = ({ banner }: SlideProps) => {
  const { promotions } = usePromotions();

  if (!promotions?.silverBonus || !banner) {
    return null;
  }

  const handleClick = () => {
    sendEvent(actionTypes.AG_BONUS_CLICK_BANNER);
    banner.hide();
  };
  return (
    <div>
      <S.Banner wrap={false} justify="start">
        <S.Content span={16}>
          <S.Heading>{'Бонусы на серебряные\nизделия для новых клиентов'}</S.Heading>
          <S.Subheading level={4}>
            {'Оформляйте заказы\n'}
            <strong>c 15.09.2022 до 31.10.2022</strong>
            {'\nи получайте дисконт на следующие'}
          </S.Subheading>
          <S.Button
            href="/silver_bonus.pdf"
            target="_blank"
            type="primary"
            iconDirection="right"
            onClick={() => handleClick()}
          >
            Узнать подробности <CaretLeft />
          </S.Button>
        </S.Content>
        <Col span={8}>
          <img src={CoinsSilver}></img>
        </Col>
      </S.Banner>
    </div>
  );
};
