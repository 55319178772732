import * as R from 'ramda';
import { AdminOffer } from 'app/types/products';
import { Coating } from 'app/types/products';

const getCatings = R.pipe(R.map(R.prop('coating')), R.uniq);
const getLengths = R.pipe(R.map(R.prop('length')), R.uniq);

export const transformOffersData = (
  offers: AdminOffer[],
): { lengths: number[]; coatings: Coating[] | [] } => ({
  lengths: getLengths(offers),
  coatings: getCatings(offers),
});
