import Axios from 'axios';

export const useVersionChecker = () => {
  let prevVersion: undefined | number;
  let timer: undefined | number;

  const handler = () => {
    Axios.get<{ hash: number }>(`/version.json?_=${Date.now()}`)
      .then(latestVersion => {
        if (prevVersion && prevVersion !== latestVersion.data.hash) {
          window.location.reload();
        } else {
          prevVersion = latestVersion.data.hash;
        }
      })
      .catch(error => console.log(error));
  };
  document.addEventListener('visibilitychange', handler);

  const subscribe = () => {
    timer = setInterval(handler, 10000);
  };

  return {
    subscribe,
    unsubscribe: () => {
      if (timer) clearInterval(timer);
      document.removeEventListener('visibilitychange', handler);
    },
  };
};
