import { createSlice, AnyAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import * as T from 'app/types/adminContracts';
import { PaginationNew } from '../../types/pagination';

export interface AdminContractsSliceProps {
  loading: boolean;
  list: T.ContractEntity[];
  pagination?: PaginationNew;
  contractTypes: T.ContractTypeAdmin[];
}

export const contractsInitialState: AdminContractsSliceProps = {
  loading: true,
  list: [],
  contractTypes: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('adminContracts/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('adminContracts/') &&
  action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('adminContracts/') &&
  action.type.endsWith('/fulfilled');

const adminContractsSlice = createSlice({
  name: 'adminContracts',
  initialState: contractsInitialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchContracts.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.updateContract.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(
        item => item.sapNumber === payload.item.sapNumber,
      );
      if (index > -1) state.list[index] = payload.item;
    });
    addCase(actions.fetchContractTypes.fulfilled, (state, { payload }) => {
      state.contractTypes = payload.items;
    });
    addMatcher(
      (action: AnyAction): action is AnyAction => isPendingAction(action),
      (state: AdminContractsSliceProps) => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default adminContractsSlice.reducer;
