export const adminPermissions = {
  full: 'admin.*',
  companies: 'admin.company.*',
  contracts: 'admin.contract.*',
  users: 'admin.user.*',
  offers: 'admin.offer.*',
  catalog: 'admin.catalog.*',
  managers: 'admin.managers.*',
  products: 'admin.product.*',
  powers: 'admin.power.*',
  techOrders: 'admin.tech_order.*',
  waitingList: 'admin.waitinglist.*',
  feedback: 'admin.feedback.*',
  news: 'admin.news.*',
  banners: 'admin.banner',
  orders: 'admin.order.update',
  roles: 'admin.role.*',
  log: 'admin.log.*',
  managersList: 'admin.managers.list',
  managersBinding: 'admin.managers.binding',
  residuesCoil: 'admin.residues.coil.*',
  residuesProduct: 'admin.residues.product.*',
  documents: 'admin.documents.*',
  documentsSections: 'admin.documents.sections.*',
  closeOpen: 'admin.closeopen.*',
  costMetal: 'admin.cost_metal.*',
  costMetalGet: 'admin.cost_metal.get',
  costMetalAdd: 'admin.cost_metal.add',
} as const;
