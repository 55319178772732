import { RootState } from 'app/store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlanks } from './actions';

export const useBlanks = () => {
  const state = useSelector((state: RootState) => state.blanks);

  const dispatch = useDispatch();

  const requestBlanks = () => {
    dispatch(fetchBlanks());
  };

  return {
    state,
    requestBlanks,
  };
};
