import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col } from 'antd';
import { Button, Text } from 'components/Modals/styled';
import { Title } from 'components/Title';
import { CompanyResponse } from 'app/types/company';
import { Trans, useTranslation } from 'react-i18next';
import { setDisabledAccount } from 'app/ducks/auth/slice';
import { Paragraph, SuccessIcon, Title as SuccessTitle } from '../../styled/Common.styled';
import { Link } from 'react-router-dom';
import { OrderForm } from '../OrderForm';
import { useForm } from 'features/AdditionalProducts/hooks/useForm';
import { sendReachGoal } from 'common/ymetrica/utils';

export const MODAL_NAME = 'additionalRequest';

export type FormValues = {
  type: string;
  company: string;
  inn?: string;
  username: string;
  email: string;
  phone: string;
  additionalProducts?: string;
};

export interface Props {
  loading: boolean;
  visible?: boolean;
  ymTargetName?: string;
  onSubmit?: (value: FormValues) => void;
  hideModal?: () => void;
  company?: CompanyResponse | null;
  form?: ReturnType<typeof useForm>;
  showSuccess: boolean;
}

export const AdditionalRequest: React.FC<Props> = ({
  onSubmit = () => null,
  loading,
  hideModal,
  form,
  visible,
  showSuccess,
  ymTargetName,
}) => {
  const { t } = useTranslation(['additional', 'modal']);
  const dispatch = useDispatch();

  const okHandler = (): void => {
    if (form) {
      form.formik.submitForm();

      if (ymTargetName) {
        sendReachGoal({ targetName: ymTargetName });
      }
    }
  };

  useEffect(() => {
    if (form) {
      form.formik.resetForm();
    }
    if (!visible) dispatch(setDisabledAccount({ disabled: false }));
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={hideModal}
      closable={!showSuccess}
      title={
        <Title style={{ marginBottom: 0 }} level={3} upper>
          {t('claim')}
        </Title>
      }
      footer={
        !showSuccess && (
          <Text>
            <Trans
              t={t}
              i18nKey="policy"
              components={[
                <a
                  key={1}
                  href="https://www.krastsvetmet.ru/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </Text>
        )
      }
    >
      {!showSuccess && form ? (
        <>
          <OrderForm formRowProps={form?.formRowProps} showFooter={false} />
          <Row justify="start" gutter={8}>
            <Col>
              <Button onClick={hideModal}>{t('modal:cancel')}</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={okHandler} loading={loading}>
                {t('submit')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col md={24}>
            <SuccessIcon />
          </Col>
          <SuccessTitle>{t('success.title')}</SuccessTitle>
          <Paragraph>{t('success.subtitle')}</Paragraph>
          <Link to="/catalog" style={{ marginTop: '28px', width: '100%', display: 'inline-block' }}>
            <Button type="primary" style={{ width: '285px' }}>
              {t('success.back')}
            </Button>
          </Link>
        </Row>
      )}
    </Modal>
  );
};
