/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import {
  CartPosition,
  Total,
  OrderType,
  CartOffer,
  TotalByMetall,
  CartDifferenceOrder,
  CartDifferencePosition,
} from 'app/types/cart';
import * as R from 'ramda';
import { CartState } from 'app/ducks/cart';

const getFlatPositions = (positions: CartPosition[]) => positions;

export const getTotalPosition = (offers: CartOffer[]) => {
  const { count, weight } = offers.reduce(
    (positionTotal, offer) => {
      const { count, weight } = offer;
      return {
        count: positionTotal.count + count,
        weight: positionTotal.weight + count * weight,
      };
    },
    { count: 0, weight: 0 },
  );
  return { count, weight };
};

export const getTotal = (cartPositions: CartPosition[]): Total => {
  const { count, weight, positions } = cartPositions.reduce(
    (total, position) => {
      const { count, weight } = getTotalPosition(position.offers);
      return {
        count: count + total.count,
        weight: weight + total.weight,
        positions: position.offers.length + total.positions,
      };
    },
    { count: 0, weight: 0, positions: 0 },
  );

  return {
    count,
    weight,
    positions,
  };
};

export const getDifferenceTotalPosition = (differenceCartPositions: CartDifferencePosition[]) => {
  const { count, weight } = differenceCartPositions.reduce(
    (acc, cartPosition) => {
      return {
        count: acc.count + cartPosition.count,
        weight: acc.weight + cartPosition.totalWeight,
      };
    },
    { count: 0, weight: 0 },
  );
  return {
    count,
    weight,
  };
};

export const getDifferenceTotal = (differenceCartOrders: CartDifferenceOrder[]): Total => {
  const { count, weight, positions } = differenceCartOrders.reduce(
    (acc, cartOrder) => {
      const { count, weight } = getDifferenceTotalPosition(cartOrder.cartPositions);
      return {
        count: acc.count + count,
        weight: acc.weight + weight,
        positions: acc.positions + cartOrder.cartPositions.length,
      };
    },
    { count: 0, weight: 0, positions: 0 },
  );
  return {
    count,
    weight,
    positions,
  };
};

export const getGroupedPositionsByMetal = (positions: CartPosition[]) => {
  return positions.reduce<TotalByMetall[]>((list, position) => {
    const metalIndex = list.findIndex(({ metal }) => metal === position.metal?.name);

    if (~metalIndex) {
      return [
        ...list.slice(0, metalIndex),
        {
          metal: position.metal?.name,
          metalId: position.metal?.id,
          positions: [...list[metalIndex].positions, position],
          total:
            list[metalIndex].total +
            position.offers.reduce<number>((sum, offer) => sum + offer.weight * offer.count, 0),
          count: list[metalIndex].count + position.offers.reduce<number>((sum, offer) => sum + offer.count, 0),
        },
        ...list.slice(metalIndex + 1, list.length),
      ];
    }

    return [
      ...list,
      {
        metal: position.metal?.name,
        metalId: position.metal?.id,
        positions: [position],
        total: position.offers.reduce<number>((sum, offer) => {
          const isCoil = offer.typeOffer === 'coil';
          const offerCount = isCoil ? 1 : offer.count;
          return sum + offer.weight * offerCount;
        }, 0),
        count: position.offers.reduce<number>((sum, offer) => {
          const isCoil = offer.typeOffer === 'coil';
          const offerCount = isCoil ? 1 : offer.count;
          return sum + offerCount;
        }, 0),
      },
    ];
  }, []);
};

export const cartSelector = (store: RootState) => store.cart;

export const flatPositionsSelector = createSelector(cartSelector, (cart: CartState): CartPosition[] => {
  const { positions } = cart;
  return getFlatPositions(positions);
});

export const getSortedPositions = (positions: CartPosition[]) => {
  const sortPositions = (a: CartPosition, b: CartPosition) => {
    const articleOrder = a.article.localeCompare(b.article);
    const diameterOrder = a.diameter - b.diameter;
    return articleOrder || diameterOrder;
  };
  return positions.slice().sort(sortPositions);
};

export const getPositionsByOrderType = R.groupBy((position: CartPosition) => position.orderType);

export const positionsByOrderTypeSelector = createSelector(flatPositionsSelector, (positions: CartPosition[]): {
  [key in string]: CartPosition[];
} => {
  const sortedPositions = getSortedPositions(positions);
  return getPositionsByOrderType(sortedPositions);
});

export const positionsByMetalsInOrderTypeSelector = createSelector(
  positionsByOrderTypeSelector,
  (
    positions: { [key in string]: CartPosition[] },
  ): {
    [key in OrderType]: TotalByMetall[];
  } => {
    return Object.entries<CartPosition[]>(positions).reduce(
      (result, [order, list]) => ({
        ...result,
        [order]: getGroupedPositionsByMetal(list),
      }),
      {
        products_production: [],
        products_coil: [],
        products_finished: [],
        products_finished_self_marked: [],
        union: [],
        waiting_list: [],
      },
    );
  },
);

export const positionsByMetalSelector = createSelector(
  flatPositionsSelector,
  (positions: CartPosition[]): TotalByMetall[] => {
    return getGroupedPositionsByMetal(positions.filter(({ attributes }) => attributes.orderType !== 'waiting_list'));
  },
);

export const waitingListTotalSelector = createSelector(
  flatPositionsSelector,
  (positions: CartPosition[]): Total => {
    return getTotal(positions.filter(({ attributes }) => attributes.orderType === 'waiting_list'));
  },
);
