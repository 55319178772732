import styled from 'styled-components';
import { laptop } from '../../styles/media';

const incativeColor = '#767676';
const activeColor = '#212529';
const getColor = (active?: boolean, fallback?: string) =>
  active ? activeColor : fallback || incativeColor;

export const Tabs = styled.div<{ count?: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ count = 0 }) => (count < 3 ? 3 : count)},
    1fr
  );
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-bottom: 1px solid #212529;
  background-color: #fff;
`;

export const TabCount = styled.span`
  background-color: #4f4f4f;
  border-radius: 50px;
  color: #fff;
  padding: 0px 12px;
  line-height: 1.7em;
  margin-left: 8px;

  @media all and (${laptop}) {
    margin-left: 0px;
    margin-top: 8px;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 12px 24px;
  color: ${props => getColor(props.active)};
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2em;
  border: 1px solid ${props => (props.active ? activeColor : '#E0E0E0')};
  margin-bottom: -1px;
  border-bottom: 1px solid ${props => (props.active ? '#fff' : activeColor)};
  cursor: pointer;
  ${TabCount} {
    background-color: ${props => getColor(props.active)};
  }

  transition: all 0.4s ease;

  @media all and (${laptop}) {
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
