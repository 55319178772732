import React, { lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { adminRoutes } from './admin/routes';
import { routes as staticRoutes } from './static/routes';

const Main = lazy(() => import('./Main'));
const MainFeedback = lazy(() => import('./MainFeedback'));
const Contacts = lazy(() => import('./Contacts'));
const LoginPage = lazy(() => import('./Login'));
const ClosePosition = lazy(() => import('./ClosePosition'));
const Catalog = lazy(() => import('./Catalog'));
const NewsPage = lazy(() => import('./News'));
const NewsDetailPage = lazy(() => import('./NewsDetail'));
const Manual = lazy(() => import('./Manual'));
const BlanksPage = lazy(() => import('./Blanks'));
const Import = lazy(() => import('./Import'));
const Search = lazy(() => import('./Search'));
const CartPage = lazy(() => import('./Cart'));
const CheckoutPage = lazy(() => import('./Checkout'));
const PersonalPage = lazy(() => import('./Profile'));
const Documents = lazy(() => import('./Documents'));
const Privacy = lazy(() => import('./Privacy'));
const ManagerUsers = lazy(() => import('./ManagerUsers'));
const PasswordReset = lazy(() => import('./PasswordReset'));
const PasswordRecovery = lazy(() => import('./PasswordRecovery'));
const ReturnConditionsPage = lazy(() => import('./ReturnConditions'));
const DocumentsBlanks = lazy(() => import('./DocumentsBlanks'));
const RhodiumPage = lazy(() => import('./AdditionalProducts/RhodiumPage'));
const BarsPage = lazy(() => import('./AdditionalProducts/BarsPage'));
const RibbonsPage = lazy(() => import('./AdditionalProducts/RibbonsPage'));
const WiresPage = lazy(() => import('./AdditionalProducts/WiresPage'));
const FurniturePage = lazy(() => import('./AdditionalProducts/FurniturePage'));

export interface ItemInterface {
  path: string;
  component: React.FC<RouteComponentProps> | React.FC;
  clearLayout?: boolean;
  exact?: boolean;
  privateRoute?: boolean;
  title?: string;
  permissions?: string[];
}

export const routes: ItemInterface[] = [
  {
    path: '/',
    component: Main,
    exact: true,
  },
  {
    path: '/feedback-success',
    component: MainFeedback,
    exact: true,
  },
  {
    path: '/contacts',
    component: Contacts,
  },
  {
    path: '/manual',
    component: Manual,
    privateRoute: true,
    permissions: ['cart.common'],
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/login/recovery',
    component: PasswordRecovery,
    exact: true,
  },
  {
    path: '/catalog/:section',
    component: Catalog,
  },
  {
    path: '/blanks',
    component: BlanksPage,
    privateRoute: true,
    permissions: ['cart.common'],
  },
  {
    path: '/close-position',
    component: ClosePosition,
    privateRoute: true,
    permissions: ['closeopen.*'],
  },
  {
    path: '/news',
    component: NewsPage,
    exact: true,
    privateRoute: true,
  },
  {
    path: '/news/:id',
    component: NewsDetailPage,
    privateRoute: true,
  },
  {
    path: '/import/:type',
    component: Import,
    privateRoute: true,
    permissions: ['cart.common'],
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/cart',
    component: CartPage,
    exact: true,
    privateRoute: true,
    permissions: ['cart.common'],
  },
  {
    path: '/cart/:order',
    component: CartPage,
    exact: true,
    privateRoute: true,
    permissions: ['cart.common'],
  },
  {
    path: '/cart/:order/checkout',
    component: CheckoutPage,
    privateRoute: true,
    permissions: ['cart.common', 'order.*'],
  },
  {
    path: '/personal',
    component: PersonalPage,
    privateRoute: true,
    permissions: ['personal.*'],
  },
  {
    path: '/documents',
    component: Documents,
    exact: true,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/users',
    component: ManagerUsers,
    privateRoute: true,
    permissions: ['manager.*'],
  },
  {
    path: '/password',
    component: PasswordReset,
    exact: true,
  },
  {
    path: '/return',
    component: ReturnConditionsPage,
    exact: true,
  },
  {
    path: '/documents/blanks',
    component: DocumentsBlanks,
    exact: true,
  },
  {
    path: '/additional/rhodium',
    component: RhodiumPage,
    exact: true,
  },
  {
    path: '/additional/bars',
    component: BarsPage,
    exact: true,
  },
  {
    path: '/additional/ribbons',
    component: RibbonsPage,
    exact: true,
  },
  {
    path: '/additional/wire',
    component: WiresPage,
    exact: true,
  },
  {
    path: '/additional/furniture',
    component: FurniturePage,
    exact: true,
  },

  ...adminRoutes,
  ...staticRoutes,
];

export const pathsWithoutLayout = routes.filter(({ clearLayout }) => clearLayout).map(({ path }) => path);
