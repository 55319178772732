/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsWithRemainderInCart } from 'app/types/autoOrders';
import { CompanyResponse, StatusSettings } from 'app/types/company';
import { ParentMenuItem } from 'app/types/layout';
import { MetalEntity } from 'app/types/metal';
import { ProductCategoryBadgeEntity } from 'app/types/productCategories';
import { ProductBadgeEntity } from 'app/types/products';
import { User, UserAccount, UserAccountVariables } from 'app/types/user';

type navBlock = 'header' | 'footer' | 'top';
export interface AuthSliceProps {
  login: boolean;
  loading: boolean;
  disabledAccount: boolean;
  pages: ParentMenuItem[];
  menu: { [key in navBlock]: ParentMenuItem[] } | null;
  user: User | null;
  company: CompanyResponse | null;
  companies: CompanyResponse[] | null;
  permissions: string[];
  roles: string[];
  show503?: boolean;
  variables: UserAccountVariables | null;
  options: {
    orderStatus: StatusSettings;
  };
  productBadges: ProductBadgeEntity[];
  categoryBadges: ProductCategoryBadgeEntity[];
  metals: MetalEntity[];
  hasParentUser: boolean;
  actionsWithRemainderInCart: ActionsWithRemainderInCart;
}

export const authInitialState: AuthSliceProps = {
  login: false,
  loading: true,
  disabledAccount: false,
  pages: [],
  menu: null,
  user: null,
  company: null,
  companies: [],
  permissions: [],
  roles: [],
  variables: null,
  options: {
    orderStatus: {} as StatusSettings,
  },
  productBadges: [],
  categoryBadges: [],
  metals: [],
  hasParentUser: false,
  actionsWithRemainderInCart: {
    products_production: [],
    products_coil: [],
    products_finished: [],
    products_finished_self_marked: [],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state) {
      state.loading = false;
      state.login = true;
    },
    loginFailed(state) {
      state.loading = false;
      state.login = false;
    },
    logOutSuccess(state) {
      state.login = false;
    },
    initFailed(state) {
      state.login = false;
    },
    saveUserInfo(state, { payload }: PayloadAction<UserAccount>) {
      const {
        user,
        pages,
        companies,
        permissions,
        menu,
        roles,
        variables,
        options,
        productBadges,
        categoryBadges,
        metals,
        hasParentUser,
        actionsWithRemainderInCart,
      } = payload;
      state.menu = menu;
      state.pages = pages;
      state.user = user;
      state.company = companies ? companies.find(({ active }) => active) || null : null;
      state.companies = companies;
      state.permissions = permissions;
      state.roles = roles;
      state.options = options;
      state.productBadges = productBadges;
      state.categoryBadges = categoryBadges;
      state.metals = metals;
      state.hasParentUser = hasParentUser;
      state.actionsWithRemainderInCart = actionsWithRemainderInCart;

      if (user) state.login = true;
      else state.login = false;
      state.show503 = false;
      state.variables = variables;
    },
    set503(state) {
      state.show503 = true;
      state.loading = false;
    },
    setDisabledAccount(state, { payload }) {
      state.disabledAccount = payload.disabled;
    },
    clearCompanies(state) {
      state.companies = [];
      state.company = null;
    },
  },
});

const { actions, reducer } = authSlice;

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  logOutSuccess,
  saveUserInfo,
  initFailed,
  set503,
  setDisabledAccount,
  clearCompanies,
} = actions;

export default reducer;
