import React from 'react';
import * as S from './styled';
import { SectionTitle } from './SectionTitle';
import { Table, Typography } from 'antd';

const { Text } = Typography;

export interface Props {
  title: string;
  dataSource: object[];
  columns: object[];
  message: string;
}

export const CustomOffer: React.FC<Props> = ({
  dataSource,
  columns,
  message,
  title,
}) => {
  return (
    <>
      <SectionTitle style={{ marginTop: 16 }}>{title}</SectionTitle>
      <S.CustomProductWrapper>
        <Table
          size="small"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Text style={{ padding: 8 }} type="secondary">
          {message}
        </Text>
      </S.CustomProductWrapper>
    </>
  );
};
