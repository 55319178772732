import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as actions from './actions';

export const useSearchProducts = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.searchProducts);
  const productBadges = useSelector((state: RootState) => state.auth.productBadges);

  const searchProducts = (payload: { search: string; page?: string | null }) => {
    dispatch(actions.searchProducts(payload));
  };

  return { state, productBadges, searchProducts };
};
