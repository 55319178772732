import { apiPost, apiGet } from '../connect';
import { ItemsResultWithMeta, ItemResult, Result } from 'app/types/api';
import { OrderEntity, UpdateOrderDto } from 'app/types/adminOrders';

class OrdersServiceFactory {
  public async getMany(search = '') {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<OrderEntity>>(`/admin/orders${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async update({ id, ...payload }: UpdateOrderDto) {
    try {
      const { data } = await apiPost<ItemResult<OrderEntity>>(`/orders/${id}`, payload);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }

  public async getProductTypes() {
    try {
      const { data } = await apiGet<Result<string[]>>('/orders/product-types');
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const OrdersService = new OrdersServiceFactory();
