import { createSlice, AnyAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import { PowerEntity } from 'app/types/powers';
import { PaginationNew } from '../../types/pagination';

interface InitialState {
  loading: boolean;
  list: PowerEntity[];
  pagination?: PaginationNew;
}

export const initialState: InitialState = {
  loading: true,
  list: [],
};

const isPendingAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('powers/') && action.type.endsWith('/pending');

const isRejectedAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('powers/') && action.type.endsWith('/rejected');

const isFulFilledAction = (action: AnyAction): action is AnyAction =>
  action.type.startsWith('powers/') && action.type.endsWith('/fulfilled');

const powersSlice = createSlice({
  name: 'powers',
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.fetchPowers.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.pagination = payload.meta.pagination;
    });
    addCase(actions.updatePower.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(item => item.id === payload.item.id);
      if (index > -1) state.list[index] = payload.item;
    });
    addCase(actions.deletePower.fulfilled, (state, { payload }) => {
      const index = state.list.findIndex(item => item.id === payload);
      if (index > -1) state.list.splice(index, 1);
    });
    addMatcher(
      (action): action is AnyAction => isPendingAction(action),
      state => {
        state.loading = true;
      },
    );
    addMatcher(
      (action): action is AnyAction => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      state => {
        state.loading = false;
      },
    );
  },
});

export default powersSlice.reducer;
