import { AdminRenderRequest } from 'app/types/renderRequests';
import { apiGet } from '../connect';
import { ItemsResultWithMeta, Result } from 'app/types/api';

class RenderRequestServiceFactory {
  public async getRenderRequests(search: string) {
    try {
      const { data } = await apiGet<ItemsResultWithMeta<AdminRenderRequest>>(`/admin/order/renders/${search}`);
      return data;
    } catch (error) {
      //@ts-expect-error
      return error.response.data as Result;
    }
  }
}

export const RenderRequestService = new RenderRequestServiceFactory();
