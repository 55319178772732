import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { message as antdMessage } from 'antd';
import { Products } from 'app/types/categories';
import { Pagination } from 'app/types/pagination';
import * as actions from './actions';

export interface CategoriesSlice {
  products: Products;
  loading?: boolean;
  search?: string;
  pagination?: Pagination;
}

const initialState: CategoriesSlice = {
  loading: false,
  products: [],
};

const searchProductsSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(
      actions.searchProducts.fulfilled,
      (state, { payload }: PayloadAction<CategoriesSlice>) => {
        state.products = payload.products;
        state.pagination = payload.pagination;
        state.loading = false;
      },
    );
    addCase(actions.searchProducts.pending, state => {
      state.loading = true;
    });
    addCase(actions.searchProducts.rejected, (state, action) => {
      // antdMessage.error(action.error.message);
      state.loading = false;
    });
  },
});

export default searchProductsSlice.reducer;
