import * as actions from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from './consts';
import { MetalCostEntity } from 'app/types/metalCost';

interface InitialState {
  loading: boolean;
  loadingFile: boolean;
  costMetal: MetalCostEntity;
}

export const initialState: InitialState = {
  loading: true,
  loadingFile: false,
  costMetal: null,
};

const metalCostSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(actions.getActiveMetalCosts.pending, state => {
      state.loading = true;
    });
    addCase(actions.getActiveMetalCosts.fulfilled, (state, { payload }) => {
      state.costMetal = payload.item;
      state.loading = false;
    });
    addCase(actions.getActiveMetalCosts.rejected, state => {
      state.loading = false;
    });
    addCase(actions.checkFileLink.pending, state => {
      state.loadingFile = true;
    });
    addCase(actions.checkFileLink.fulfilled, state => {
      state.loadingFile = false;
    });
    addCase(actions.checkFileLink.rejected, state => {
      state.loadingFile = false;
    });
  },
});

export default metalCostSlice.reducer;
