import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Input, Select as AntdSelect, Form, Row, Col, Checkbox } from 'antd';
import { Button, Text } from './styled';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Title } from '../Title';
import { Valid, ValidProps } from '../Fields/Valid';
import { SelectProps } from 'antd/lib/select';
import { AutocompleteCompany } from 'app/features/Admin/AutocompleteForms/CompaniesDaData';
import { Option as OptionAutocomplete } from 'components/Autocomplete';
import { Feedback as FeedbackType } from '../../app/types/feedback';
import { CompanyResponse } from '../../app/types/company';
import { User } from '../../app/types/user';
import { useTranslation } from 'react-i18next';
import { typesRu, typesEn, sectionsRu, sectionsEn } from './static';
import { UploadFiles } from 'components/Upload';
import { actionTypes, createLoggerEvent } from 'logger';
import { setDisabledAccount } from 'app/ducks/auth/slice';
import { useAuth } from 'app/ducks/auth';

const { TextArea } = Input;
const { Option } = AntdSelect;

// TODO: move to separate component
// FIXME: caused by wrong typings in Valid component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CheckboxWrapperProps = any;

const CheckboxWrapper = ({ name, placeholder, value, readOnly, onChange }: CheckboxWrapperProps) => (
  <Checkbox name={name} checked={Boolean(value)} disabled={readOnly} onChange={event => onChange(event.target.checked)}>
    {placeholder}
  </Checkbox>
);

export const MODAL_NAME = 'feedback';
export interface Props {
  loading?: boolean;
  visible?: boolean;
  onSubmit: (value: FeedbackType) => void;
  hideModal: () => void;
  company: CompanyResponse | null;
  user: User | null;
}

export const Feedback: React.FC<Props> = ({ onSubmit, hideModal, loading, visible, user, company }) => {
  const { t, i18n } = useTranslation(['layout', 'feedback', 'company', 'form', 'modal']);
  const { language } = i18n;
  const isRu = language === 'ru';
  const dispatch = useDispatch();
  const {
    state: { disabledAccount },
  } = useAuth();
  const shape = {
    theme: Yup.string().required(t('form:required')),
    section: Yup.string().required(t('form:required')),
    company: Yup.string().required(t('form:required')),
    inn: Yup.number()
      .typeError('ИНН должен содержать только цифры')
      .integer('ИНН должен содержать только цифры'),
    fio: Yup.string().required(t('form:required')),
    email: Yup.string()
      .email(t('form:email'))
      .required(t('form:required')),
    phone: Yup.string().required(t('form:required')),
    comment: Yup.string().required(t('form:required')),
    file: Yup.string(),
    agreement: Yup.boolean()
      .oneOf([true], t('form:required'))
      .required(),
  };

  const initial: FeedbackType = {
    theme: disabledAccount ? (isRu ? typesRu[4] : typesEn[4]) : '',
    section: disabledAccount ? (isRu ? sectionsRu[0] : sectionsEn[0]) : '',
    company: company?.name || '',
    fio: user ? `${user.name}` : '',
    email: user?.email || '',
    phone: '',
    comment: '',
    file: '',
    agreement: false,
  };

  if (isRu) {
    initial.inn = company ? String(company.inn) : '';
  }

  const FeedbackSchema = Yup.object().shape(shape);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initial,
    validationSchema: FeedbackSchema,
    onSubmit,
  });

  const formRowProps: Omit<ValidProps, 'component' | 'name'> = {
    form: formik,
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: 'right',
  };

  const okHandler = (): void => {
    formik.submitForm();
  };

  interface CustomSelect extends SelectProps<string> {
    variants: string[];
  }

  const Select: React.FC<CustomSelect> = ({ variants, ...props }) => {
    return (
      <AntdSelect {...props} style={{ width: '100%' }}>
        {variants.map((value, index) => (
          <Option key={index} value={value}>
            {value}
          </Option>
        ))}
      </AntdSelect>
    );
  };

  useEffect(() => {
    formik.resetForm();
    if (!visible) dispatch(setDisabledAccount({ disabled: false }));
  }, [visible]);

  const onSelectCompany = (option: OptionAutocomplete) => {
    formik.setFieldValue('inn', option.description);
  };

  const onCloseModal = createLoggerEvent(hideModal, actionTypes.MODAL_SUPPORT_CANCEL);

  return (
    <Modal
      open={visible}
      onCancel={onCloseModal}
      title={
        <Title style={{ marginBottom: 0 }} level={3} upper>
          {t('feedback:title')}
        </Title>
      }
      footer={null}
    >
      <Valid
        name="theme"
        label={t('feedback:subject')}
        component={props => <Select {...props} variants={isRu ? typesRu : typesEn} />}
        {...formRowProps}
      />
      <Valid
        name="section"
        label={t('feedback:section')}
        component={props => <Select {...props} variants={isRu ? sectionsRu : sectionsEn} />}
        {...formRowProps}
      />
      <Valid
        name="company"
        label={t('feedback:company')}
        placeholder={t('company:name')}
        onSelect={onSelectCompany}
        component={AutocompleteCompany}
        {...formRowProps}
      />
      {isRu && (
        <Valid name="inn" label={t('feedback:inn')} placeholder="000000000000" component={Input} {...formRowProps} />
      )}
      <Valid
        name="fio"
        label={t('feedback:fullName')}
        placeholder={t('feedback:fullNamePlaceholder')}
        component={Input}
        {...formRowProps}
      />
      <Valid name="email" label="E-mail" placeholder="example@mail.ru" component={Input} {...formRowProps} />
      <Valid name="phone" label={t('feedback:phone')} placeholder="89123458912" component={Input} {...formRowProps} />
      {visible && (
        <Form.Item {...formRowProps} label={t('feedback:file')}>
          <UploadFiles {...formRowProps} onChange={(uuid: string) => formik.setFieldValue('file', uuid)}>
            {t('feedback:attach')}
          </UploadFiles>
        </Form.Item>
      )}
      <Valid
        name="comment"
        label={t('feedback:message')}
        placeholder={t('feedback:messagePlaceholder')}
        component={TextArea}
        {...formRowProps}
      />

      <Valid
        name="agreement"
        label={<span></span>}
        colon={false}
        placeholder={
          <Text style={{ textAlign: 'left' }}>
            Нажимая кнопку «Отправить», я даю{' '}
            <a href="/privacy_policy_get_in_touch.pdf" target="_blank" rel="noopener noreferrer">
              согласие на&nbsp;обработку персональных данных
            </a>{' '}
            и&nbsp;подтверждаю, что&nbsp;ознакомлен с&nbsp;порядком обработки и&nbsp;защиты персональных данных согласно{' '}
            <a href="https://www.krastsvetmet.ru/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Политики в&nbsp;отношении обработки и&nbsp;защиты персональных данных
            </a>
            .
          </Text>
        }
        component={CheckboxWrapper}
        {...formRowProps}
      />

      <Row justify="center" gutter={8}>
        <Col>
          <Button onClick={onCloseModal}>{t('modal:cancel')}</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={okHandler} loading={loading}>
            {t('modal:okSubmit')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
