/* eslint-disable prettier/prettier */
import React from 'react';
import { sendEvent } from 'logger';
import { useStageShowingConditions } from 'features/Bonus/hooks/useStageShowingConditions';
import { WelcomeBonus, SilverBonus } from 'models/api/promotions';
import { useBonusType } from 'features/Bonus/hooks/useBonusType';
import { isSilverBonus } from 'features/Bonus/utils/bonusTypeGuards';
import { Start, Accumulation, Withdrawal, Discount } from './stages';

type StageProps = {
  data: WelcomeBonus | SilverBonus;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  analyticsAction: 'WELCOME_BONUS_CLICK_SPECIAL_PAGE' | 'AG_BONUS_CLICK_RULES';
  rulesUrl: string;
  openRulesInNewTab?: boolean;
};

export const Stage = ({ data, setOpen, analyticsAction, rulesUrl, openRulesInNewTab = false }: StageProps) => {
  const bonusType = useBonusType(data);
  const { start, accumulation, withdrawal, discount } = useStageShowingConditions(data);
  const { tariff, balance, currentWeight, neededWeight, orders, orderExpirationDate, bonusExpirationDate, updateDate } =
    data;
  const currentTariff = tariff.find(item => item.weight === neededWeight);

  const handleRulesClick = () => {
    setOpen(false);
    sendEvent(analyticsAction);
  };

  if (start) {
    return (
      <Start
        url={rulesUrl}
        bonusType={bonusType}
        updateDate={updateDate}
        handleRulesClick={handleRulesClick}
        openRulesInNewTab={openRulesInNewTab}
      />
    );
  }
  if (accumulation) {
    return (
      <Accumulation
        url={rulesUrl}
        balance={balance}
        currentWeight={currentWeight}
        neededWeight={neededWeight}
        tariff={tariff}
        currentTariff={currentTariff}
        updateDate={updateDate}
        handleRulesClick={handleRulesClick}
        openRulesInNewTab={openRulesInNewTab}
        isHolding={isSilverBonus(data) ? data.holding : false}
      />
    );
  }
  if (withdrawal) {
    return (
      <Withdrawal
        url={rulesUrl}
        balance={balance}
        currentWeight={currentWeight}
        neededWeight={neededWeight}
        updateDate={updateDate}
        bonusExpirationDate={bonusExpirationDate}
        orderExpirationDate={orderExpirationDate}
        handleRulesClick={handleRulesClick}
        openRulesInNewTab={openRulesInNewTab}
        isHolding={isSilverBonus(data) ? data.holding : false}
      />
    );
  }
  if (discount) {
    return (
      <Discount
        url={rulesUrl}
        balance={balance}
        orders={orders}
        updateDate={updateDate}
        handleRulesClick={handleRulesClick}
        openRulesInNewTab={openRulesInNewTab}
        isHolding={isSilverBonus(data) ? data.holding : false}
      />
    );
  }
  return null;
};
