import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { SLICE_NAME } from './consts';
import { MetalReportService } from 'app/services/metalReport';
import { MetalReportCreateBody } from 'app/types/metalReport';

export const getMetalReports = createAsyncThunk(`${SLICE_NAME}/getMetalReport`, async (_, { rejectWithValue }) => {
  const { data, success, message } = await MetalReportService.getMetalReports();
  if (success && data) {
    return data;
  } else {
    antdMessage.error(message);
    return rejectWithValue(data);
  }
});

export const createReportMetal = createAsyncThunk(
  `${SLICE_NAME}/createReportMetal`,
  async (payload: MetalReportCreateBody, { rejectWithValue }) => {
    const { data, success, message } = await MetalReportService.createReportMetal(payload);
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
