import React from 'react';
import { Table as AntdTable, TableProps as AntdTableProps, Button, Popconfirm } from 'antd';
import { AutoOrderEntity } from 'app/types/autoOrders';
import * as S from './Table.styled';
import { date, number } from 'helpers/format';
import { OrderNumber } from '../../components/OrderNumber';
import { actionTypes, sendEvent } from 'logger';

const { Column } = AntdTable;
type TableProps = {
  onCancelOrder: (id: number) => void;
} & AntdTableProps<AutoOrderEntity>;

export const Table = ({ onCancelOrder, ...props }: TableProps) => {
  const cancelHandler = (id: number) => {
    onCancelOrder(id);
    sendEvent(actionTypes.O2C_ORDER_LIST_CANCEL);
  };

  return (
    <AntdTable {...props}>
      <Column title="№ заказа" dataIndex="id" render={id => <OrderNumber id={id} type="autoOrder" />} />
      <Column title="Дата заказа" dataIndex="createdAt" render={(createdAt: string) => date(createdAt)} />
      <Column<AutoOrderEntity>
        title={
          <S.CustomCellWrapper>
            <S.CustomCell>Кол-во, шт</S.CustomCell>
            <S.CustomCell>оформлено / общее</S.CustomCell>
          </S.CustomCellWrapper>
        }
        dataIndex="countAll"
        align="center"
        render={(countAll: number, record) => `${record.countCompleted} / ${countAll}`}
      />
      <Column<AutoOrderEntity>
        title={
          <S.CustomCellWrapper>
            <S.CustomCell>Вес, г</S.CustomCell>
            <S.CustomCell>оформлено / общий</S.CustomCell>
          </S.CustomCellWrapper>
        }
        dataIndex="weightAll"
        align="center"
        render={(weightAll: number, record) => `${number(record.weightCompleted)} / ${number(weightAll)}`}
      />
      <Column title="Договор" dataIndex="knfmpNumber" />
      <Column title="Имя пользователя" dataIndex="user" />
      <Column<AutoOrderEntity>
        title="Статус"
        dataIndex={['status', 'title']}
        render={(statusTitle, render) =>
          render.status.code === 'cancelled' ? <S.CancelledStatus>{statusTitle}</S.CancelledStatus> : statusTitle
        }
      />
      <Column<AutoOrderEntity>
        title="Действие"
        key="id"
        render={(_, record) =>
          record.status.code === 'processing' && (
            <Popconfirm title="Подтвердить действие?" onConfirm={() => cancelHandler(record.id)}>
              <Button type="link" style={{ padding: 0 }}>
                Остановить автозаказ
              </Button>
            </Popconfirm>
          )
        }
      />
    </AntdTable>
  );
};
