import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sendEvent, actionTypes } from 'logger';
import { getOrderTypeTitles } from '../../utils/getOrderTypeTitles';
import { OrderType } from 'app/types/cart';
import * as S from '../styled/Table.styled';

type OrderNumberProps = {
  numberOrder: number;
  orderType: OrderType;
};

export const OrderNumber = ({ numberOrder, orderType }: OrderNumberProps) => {
  const { t } = useTranslation(['personal']);
  return (
    <S.OrderNumberWrap>
      <Link
        to={`/personal/orders/${numberOrder}`}
        onClick={() =>
          sendEvent({
            ActionType: actionTypes.ORDERS_CLICK_ORDER,
            OrderId: numberOrder.toString(),
          })
        }
      >
        {t('orderParameters:order')} {numberOrder}
      </Link>
      {orderType !== 'products_production' && <div>{`(${getOrderTypeTitles(t)[orderType]})`}</div>}
    </S.OrderNumberWrap>
  );
};
