import styled from 'styled-components';
import { Carousel, Modal, Typography } from 'antd';
import { Title } from '../Title';
const { Text } = Typography;

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
  font-size: 16px;
`;

export const StyledModal = styled(Modal)`
  &&& {
    max-width: 47.5em;
  }
`;

export const ImgCarousel = styled.img`
  height: 17.5em;
  object-fit: cover;
`;

export const H4 = styled.h4`
  font-size: 1.43rem;
  margin: 1.2em 0 0 0;
  font-weight: 400;
`;

export const StyledText = styled(Text)`
  font-size: 1rem;
  margin-top: 0.93em;
  margin-bottom: 1.314em;
  font-weight: 400;
`;

export const BannersCarousel = styled(Carousel)`
  &&& {
    padding: 16px 44px;
    .slick-dots {
      position: relative;
      margin: 5.14em 0 0 0;
      bottom: 0;
    }
    .slick-dots li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      margin: 0 3px;
    }
    .slick-dots li button {
      border: 1px solid #d77721;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .slick-dots li.slick-active button {
      background: #d77721;
    }
  }
`;
