import React from 'react';
import { TopNav } from './TopNav';
import { MainNav } from './MainNav';
import { User } from 'app/types/user';
import { ParentMenuItem } from 'app/types/layout';
import { useTranslation } from 'react-i18next';
import { Total, TotalByMetall } from 'app/types/cart';
import { Contracts, sapNumber, Balance } from 'app/types/contracts';
import { CompanyResponse } from 'app/types/company';

import * as S from './styled';
import { MetalCostEntity } from 'app/types/metalCost';
export interface Props {
  user: User | null;
  isLogin: boolean;
  total: TotalByMetall[];
  waitingListTotal: Total;
  onChangeLocale: (lng: string) => void;
  headerPages: ParentMenuItem[];
  topPages: ParentMenuItem[];
  onLogout: () => void;
  contracts: Contracts;
  balance: Balance;
  contractsLoading?: boolean;
  metalsLoading?: boolean;
  onChangeContract: (id: sapNumber) => void;
  company: CompanyResponse | null;
  companies: CompanyResponse[] | null;
  changeCompanyHandler: (company: CompanyResponse) => void;
  costMetal: MetalCostEntity;
  isLoadingMetalCost?: boolean;
  isLoadingMetalCostFile?: boolean;
  onCheckMetalCostFile: (payload: {
    filepath: string;
  }) => Promise<{
    filepath: string;
  }>;
}

export const Header: React.FC<Props> = ({
  user,
  isLogin,
  total,
  onChangeLocale,
  headerPages,
  topPages,
  onLogout,
  waitingListTotal,
  ...props
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const isRu = language === 'ru';
  return (
    <S.Header>
      <TopNav isLogin={isLogin} user={user} pages={topPages} onChangeLocale={onChangeLocale} isRu={isRu} {...props} />
      <MainNav
        pages={headerPages}
        total={total}
        waitingListTotal={waitingListTotal}
        user={user}
        isLogin={isLogin}
        onLogout={onLogout}
        isRu={isRu}
      />
    </S.Header>
  );
};
