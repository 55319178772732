import * as actions from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { Feedback } from 'app/types/feedback';
import { AppDispatch } from 'app/store/store';

export const useFeedback = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.feedback);

  const saveFeedback = async (data: Feedback) => {
    const resultSaveFeedback = await dispatch(actions.saveFeedback(data));
    return actions.saveFeedback.fulfilled.match(resultSaveFeedback);
  };

  const fetchFeedbacks = () => {
    dispatch(actions.fetchFeedbacks());
  };

  return {
    state,
    saveFeedback,
    fetchFeedbacks,
  };
};
