import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  font-size: 16px;
  line-height: 1.188em;
`;

export const Input = styled.input<{
  value?: number;
}>`
  width: 52px;
  padding: 0.375em;
  text-align: center;
  border: 1px solid #e0e0e0;
  outline: none;
  color: ${({ value }) => (value === 0 ? '#bbbbbb' : '#212529')};
  :hover {
    border: 1px solid #4f4f4f;
  }
  :focus {
    border: 1px solid #4f4f4f;
    box-shadow: 0px 0px 4px rgba(118, 118, 118, 0.5);
  }
  :disabled {
    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;
    color: #bbbbbb;
    cursor: not-allowed;
  }
`;

export const Prefix = styled.div`
  display: flex;
  padding: 0.5em;
  button {
    font-size: 1em;
  }
`;

export const Affix = styled.div`
  display: flex;
  padding: 0.5em;
  button {
    font-size: 1em;
  }
`;
