import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as actions from './action';

export const useActionLogs = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.actionLogs);

  const fetchLogs = (params = '') => {
    return dispatch(actions.fetchLogs(params));
  };

  return {
    state,
    fetchLogs,
  };
};
