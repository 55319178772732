import React from 'react';
import { Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { date } from 'helpers/format';
import { useUnitsConverter } from 'hooks/useUnitsConverter';
import { WelcomeBonusOrder, SilverBonusOrder } from 'models/api/promotions';
import * as S from './styled/OrdersTable.styled';

type BonusOrdersProps = {
  orders: WelcomeBonusOrder[] | SilverBonusOrder[];
  isHolding: boolean;
};

type BonusOrder = WelcomeBonusOrder | SilverBonusOrder;

const ORDER_URL = '/personal/orders';

export const OrdersTable = ({ orders, isHolding }: BonusOrdersProps) => {
  const { toRubles } = useUnitsConverter();

  const onHeaderCell = () => ({
    style: { padding: '16px 8px', fontSize: '12px', fontWeight: 400, color: '#767676', minWidth: '72px' },
  });
  const onCell = () => ({ style: { padding: '12px 8px' } });

  const columns: ColumnType<BonusOrder>[] = [
    {
      title: '№ заказа',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      onHeaderCell,
      onCell,
      render: (_, { orderId }) => (
        <S.Link to={`${ORDER_URL}/${orderId}`} target="_blank" rel="noopener noreferrer">
          {orderId}
        </S.Link>
      ),
    },
    {
      title: 'дата заказа',
      dataIndex: 'date',
      key: 'date',
      onHeaderCell,
      onCell,
      render: (_, { orderDate }) => date(orderDate, 'D.MM.YYYY'),
    },
    {
      title: 'бонусы',
      dataIndex: 'bonus',
      key: 'bonus',
      align: 'right',
      onHeaderCell,
      onCell,
      render: (_, { discount }) => <S.Discount>{toRubles(discount)}</S.Discount>,
    },
  ];

  if (isHolding) {
    (columns as ColumnType<SilverBonusOrder>[]).unshift({
      title: 'компания',
      dataIndex: 'company',
      key: 'company',
      onHeaderCell,
      onCell,
      render: (_, { company }) => (
        <Tooltip placement="top" title={company.name}>
          <S.CompanyName>{company.name}</S.CompanyName>
        </Tooltip>
      ),
    });
    (columns as ColumnType<SilverBonusOrder>[]).splice(1, 1, {
      title: '№ заказа',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      onHeaderCell,
      onCell,
      render: (_, { orderId, link }) =>
        link ? (
          <S.Link to={`${ORDER_URL}/${orderId}`} target="_blank" rel="noopener noreferrer">
            {orderId}
          </S.Link>
        ) : (
          <span>{orderId}</span>
        ),
    });
  }

  return <Table dataSource={orders} columns={columns} pagination={false} />;
};
