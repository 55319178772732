import { Result } from 'app/types/api';
import { apiGet, apiPost, apiDelete } from '../connect';
import * as types from 'app/types/residuesFinished';

export const getResidues = async (search = '') => {
  try {
    const { data } = await apiGet<Result<types.ResiduesResponse>>(`/residues/products/${search}`);
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const getTypesResidues = async () => {
  try {
    const { data } = await apiGet<Result<types.ResiduesType[]>>('/residues/products/types');
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const addResidue = async (residue: types.ResiduePayload) => {
  try {
    const { data } = await apiPost<Result<types.Residue>>('/residues/products', residue);
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const updateResidue = async (residue: types.ResiduePayloadWithId) => {
  try {
    const { types, id } = residue;
    const { data } = await apiPost<Result<types.Residue>>(`/residues/products/${id}`, { types });
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const deleteResidue = async (id: number) => {
  try {
    const { data } = await apiDelete<Result>(`/residues/products/${id}`);
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};

export const getOffersProduct = async (productId: number) => {
  try {
    const { data } = await apiGet<Result<types.OffersResponse>>('/offers', {
      productId,
    });
    return data;
  } catch (err) {
    //@ts-expect-error
    return err.response.data as Result;
  }
};
