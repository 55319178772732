import { THookProps } from '../types';
import { Props as ContentProps } from '../../Content';

export type ContentDefaultProps = Pick<
  ContentProps,
  | 'types'
  | 'article'
  | 'diameter'
  | 'massData'
  | 'productionFactor'
  | 'updateActiveCovering'
  | 'onAddToCart'
  | 'addingToCart'
  | 'wasAdded'
  | 'setWasAdded'
  | 'order'
  | 'setOrder'
  | 'login'
  | 'coilProductionAvailable'
  | 'customLengthAvailable'
>;

export const useDefaultOrderTypeProps = ({
  type,
  article,
  diameter,
  massData,
  productionFactor,
  updateActiveCovering,
  onAddToCart,
  addingToCart,
  wasAdded,
  setWasAdded,
  order,
  setOrder,
  login,
  conditions,
}: THookProps): ContentDefaultProps => {
  return {
    types: type,
    article,
    diameter,
    massData,
    productionFactor,
    updateActiveCovering,
    onAddToCart,
    addingToCart,
    wasAdded,
    setWasAdded,
    order,
    setOrder,
    login,
    coilProductionAvailable: conditions.coilProductionAvailable,
    customLengthAvailable: conditions.customLengthAvailable,
  };
};
