import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-width: 24em;
  padding: 8px 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
`;

export const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

export const Cost = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 4px;
  font-size: 19px;
  line-height: 22px;
  font-weight: 500;
`;

export const CostSecondary = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #767676;
`;

export const CostDescription = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
`;

export const Description = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: justify;
`;

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: #767676;
`;

export const CostIcon = styled.div`
  &&& {
    min-width: 20px;
    width: 20px;
    height: 20px;
    color: #4f4f4f;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const UpdateIcon = styled.div`
  &&& {
    min-width: 16px;
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const LinkButton = styled(AntdButton)`
  &&& {
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    height: auto;
    span {
      color: #767676;
      text-decoration: underline;
    }
    span:hover {
      filter: brightness(1.2);
    }
    span:active,
    span:focus {
      filter: brightness(0.8);
    }
  }
`;
