import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'app/store/reducer';
import { UpdateUserDto, CreateUserDto } from 'app/types/userAdmin';
import { AppDispatch } from 'app/store/store';
import { ChangePasswordPayload } from 'app/types/login';

export const useUser = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.users);

  const fetchUsers = (search = '') => {
    return dispatch(actions.fetchUsers(search));
  };

  const updateUser = async (updateData: UpdateUserDto) => {
    const resultAction = await dispatch(actions.updateUser(updateData));
    return actions.updateUser.fulfilled.match(resultAction);
  };

  const deleteUser = (id: number) => {
    return dispatch(actions.deleteUser(id));
  };

  const addUser = async (user: CreateUserDto) => {
    const resultAction = await dispatch(actions.addUser(user));
    if (actions.addUser.fulfilled.match(resultAction)) {
      const payload = unwrapResult(resultAction);
      return payload !== null ? payload.login : undefined;
    }
  };

  const getAssignableRoles = () => {
    dispatch(actions.getAssignableRoles());
  };

  const requestPasswordRecovery = async (email: string) => {
    const resultAction = await dispatch(actions.requestPasswordRecovery(email));
    return actions.requestPasswordRecovery.fulfilled.match(resultAction);
  };

  const changePassword = async (payload: ChangePasswordPayload) => {
    const resultAction = await dispatch(actions.changePassword(payload));
    if (actions.changePassword.fulfilled.match(resultAction)) {
      return resultAction;
    }
  };

  return {
    state,
    fetchUsers,
    updateUser,
    deleteUser,
    addUser,
    getAssignableRoles,
    requestPasswordRecovery,
    changePassword,
  };
};
