import React, { useEffect, useState } from 'react';
import { Valid } from 'components/Fields/Valid';
import { Modal, Select as AntdSelect, Button, Row, Col, DatePicker, Form, Checkbox } from 'antd';
import { Title } from 'components/Title';
import { Alert } from 'components/KrasUIKit';
import { info } from 'components/KrasUIKit/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectProps } from 'antd/lib/select';
import { Contracts, sapNumber as TSapNumber } from 'app/types/contracts';
import { DateRangeWrap } from '../styled';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { actionTypes, sendEvent } from 'logger';
import { alertText } from './consts';
import { MetalReportCreateBody } from 'app/types/metalReport';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isNil } from 'ramda';

const { Option } = AntdSelect;
interface CustomSelect extends SelectProps<string> {
  variants: { label: string; value: TSapNumber }[];
}
export interface Props {
  visible?: boolean;
  loading?: boolean;
  contracts: Contracts;
  onSubmit: (data: MetalReportCreateBody) => void;
  onCancel: () => void;
}

export const MetalReportRequest: React.FC<Props> = ({ visible, onCancel, onSubmit, loading, contracts }) => {
  const { t } = useTranslation(['personal']);
  const [isAllPeriodChecked, setIsAllPeriodChecked] = useState(false);

  const schema = Yup.object().shape({
    dateTo: isAllPeriodChecked
      ? Yup.string()
      : Yup.string()
          .typeError(t('form:required'))
          .required(t('form:required')),
    dateFrom: isAllPeriodChecked
      ? Yup.string()
      : Yup.string()
          .typeError(t('form:required'))
          .required(t('form:required')),
    sapNumber: Yup.string().required(t('form:contract')),
  });

  const formik = useFormik<MetalReportCreateBody>({
    initialValues: {
      sapNumber: '',
      dateTo: '',
      dateFrom: '',
      isAllPeriod: false,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (isBeforeContractStartDate(dayjs(values.dateFrom))) {
        formik.setFieldError('dateFrom', 'Некорректная дата');
        return;
      }
      onSubmit(values);
    },
  });

  const activeContractStartDate = contracts.filter(({ sapNumber }) => sapNumber === formik.values.sapNumber)[0]
    ?.contractStartDate;

  const isBeforeContractStartDate = (current: dayjs.Dayjs) =>
    !isNil(activeContractStartDate) && current.isBefore(dayjs(activeContractStartDate), 'day');

  const { handleSubmit } = formik;

  const Select: React.FC<CustomSelect> = ({ variants, ...props }) => {
    return (
      <AntdSelect {...props} style={{ width: '100%' }}>
        <Option disabled value={0}>
          {t('form:selectContract')}
        </Option>
        {variants.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </AntdSelect>
    );
  };

  const changeAllPeriodCheckboxHandler = (e: CheckboxChangeEvent) => {
    setIsAllPeriodChecked(e.target.checked);
  };

  const dateHandler = (name: 'dateFrom' | 'dateTo') => {
    if (
      formik.values[name] &&
      formik.values[name] !== '' &&
      dayjs(formik.values[name]).diff(dayjs(formik.values[name]), 'day') !== 0
    ) {
      formik.setFieldValue(name, dayjs(formik.values[name]));
    }
  };

  useEffect(() => {
    dateHandler('dateFrom');
  }, [formik.values.dateFrom, dateHandler]);

  useEffect(() => {
    dateHandler('dateTo');
  }, [formik.values.dateTo, dateHandler]);

  useEffect(() => {
    formik.setFieldValue('isAllPeriod', isAllPeriodChecked);
    if (isAllPeriodChecked) {
      formik.setFieldValue('dateFrom', '');
      formik.setFieldValue('dateTo', '');
    }
  }, [isAllPeriodChecked]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
      setIsAllPeriodChecked(false);
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel();
        sendEvent(actionTypes.MODAL_ACT_CLOSE);
      }}
      okText={t('modal:request')}
      footer={
        <Row justify="end">
          <Col>
            <Button
              onClick={() => {
                onCancel();
                sendEvent(actionTypes.MODAL_ACT_CANCEL);
              }}
              type="link"
            >
              {t('modal:cancel')}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => formik.submitForm()} loading={loading} type="primary">
              {t('modal:request')}
            </Button>
          </Col>
        </Row>
      }
      title={
        <Title style={{ marginBottom: 0 }} level={3} upper>
          {t('actModalTitle')}
        </Title>
      }
    >
      <Alert icon={info} alertType="info" title={alertText} />
      <form onSubmit={handleSubmit}>
        <Form layout="vertical" style={{ marginTop: 20 }}>
          <Form.Item label={t('form:period')} style={{ marginBottom: 0 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Checkbox checked={isAllPeriodChecked} onChange={changeAllPeriodCheckboxHandler}>
                Сформировать за весь период
              </Checkbox>
            </Form.Item>
            <DateRangeWrap>
              <Valid
                name="dateFrom"
                form={formik}
                component={props => (
                  <DatePicker
                    {...props}
                    style={{ width: '100%' }}
                    inputReadOnly
                    format="DD.MM.YYYY"
                    disabledDate={current =>
                      current.isAfter(dayjs(formik.values.dateTo), 'day') ||
                      current.isAfter(dayjs(), 'day') ||
                      isBeforeContractStartDate(current)
                    }
                    disabled={isAllPeriodChecked}
                  />
                )}
              />
              <Valid
                name="dateTo"
                form={formik}
                component={props => (
                  <DatePicker
                    {...props}
                    style={{ width: '100%' }}
                    inputReadOnly
                    format="DD.MM.YYYY"
                    disabledDate={current =>
                      current &&
                      (current.isBefore(dayjs(formik.values.dateFrom), 'day') ||
                        current.isAfter(dayjs(), 'day') ||
                        isBeforeContractStartDate(current))
                    }
                    disabled={isAllPeriodChecked}
                  />
                )}
              />
            </DateRangeWrap>
          </Form.Item>
          <Valid
            name="sapNumber"
            label={t('layout:contract')}
            form={formik}
            placeholder={t('form:selectContract')}
            component={props => (
              <Select
                {...props}
                variants={contracts
                  .filter(({ contractType }) => contractType === 'with_metals')
                  .map(({ name, sapNumber, knfmpNumber }) => ({
                    label: `${knfmpNumber} ${name !== 'Тестовый' ? `(${name})` : ''}`,
                    value: sapNumber,
                  }))}
              />
            )}
          />
        </Form>
      </form>
    </Modal>
  );
};
