import * as actions from './actions';
import {
  toggleModalImport as $toggleModalImport,
  clearImportResults as $clearImportResults,
  cancelImport as $cancelImport,
  fetchImportFile as $fetchImportFile,
} from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/reducer';
import { AppDispatch } from 'app/store/store';
import * as types from 'app/types/imports';
import { useSyncTabs } from 'hooks/useSyncTabs';

export const useImports = () => {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.imports);
  const { syncAnyTabs } = useSyncTabs();

  const toggleModalImport = () => {
    dispatch($toggleModalImport());
  };

  const clearImportResults = () => {
    dispatch($clearImportResults());
  };

  const cancelImport = () => {
    dispatch($cancelImport());
  };

  const fetchImportFile = async (values: types.ImportForm) => {
    const result = await dispatch($fetchImportFile(values));

    syncAnyTabs('import');
    return result;
  };

  const fetchContinueImportFile = async (values: types.ImportForm) => {
    const result = await dispatch(actions.fetchContinueImportFile(values));

    syncAnyTabs('import');
    return result;
  };

  return {
    state,
    toggleModalImport,
    clearImportResults,
    cancelImport,
    fetchImportFile,
    fetchContinueImportFile,
  };
};
