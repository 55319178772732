import styled from 'styled-components';

export const Phone = styled.span`
  margin-left: 0.38em;
`;
export const IconLetter = styled.span<{ size: string; type: string }>`
  color: ${({ type }) => (type === 'normal' ? '#767676' : 'white')};
  display: flex;
  align-items: center;
  width: ${({ size }) => (size ? size : '1em')};
  height: ${({ size }) => (size ? size : '1em')};
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const ButtonOutline = styled.button`
  padding: 0;
  background: none;
  border: none;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
