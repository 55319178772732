import styled from 'styled-components';
import { Menu as LayoutMenu, Layout } from 'antd';

export const Sider = styled(Layout.Sider)`
  margin-right: var(--s8);
`;

export const Menu = styled(LayoutMenu)`
  height: 100%;
`;
export const MenuItem = styled(LayoutMenu.Item)`
  &&& {
    padding: var(--s3) var(--s3);
    margin: 8px 0;
    width: 100%;
    border-radius: 0;
    ::after {
      border-inline-end-width: 3px;
    }
  }
`;
