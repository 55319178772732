/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import {
  jwtAccess,
  refreshAccess,
  apiGet,
  apiPost,
} from 'app/services/connect';
import { Token, Result } from 'app/types/api';
import { UserAccount } from 'app/types/user';

export const saveToken = (
  token: string,
  refresh: string,
  isManager?: boolean,
): void => {
  /* 
    Если авторизуется менеджер под пользователем,
    то сохраняем токен менеджера в sessionStorage
  */
  if (isManager) {
    sessionStorage.setItem(jwtAccess, token);
    sessionStorage.setItem(refreshAccess, refresh);
  } else {
    localStorage.setItem(jwtAccess, token);
    localStorage.setItem(refreshAccess, refresh);
  }
};

export const removeToken = (): void => {
  const sessionStorageJwt = sessionStorage.getItem(jwtAccess);
  /* 
    Если авторизован менеджер под пользователем,
    то удаляем токен в sessionStorage
  */
  if (sessionStorageJwt) {
    sessionStorage.removeItem(jwtAccess);
    sessionStorage.removeItem(refreshAccess);
  } else {
    localStorage.removeItem(jwtAccess);
    localStorage.removeItem(refreshAccess);
  }
};

export type TokenResult = Result<Token | []>;

export type InitResult = Result<UserAccount>;
export interface UserLogin {
  login: string;
  password: string;
}

export type RefreshToken = Pick<Token, 'refresh_token'>;

export type LoginArgsType = UserLogin | { refreshToken: string };

export const login = async (payload: LoginArgsType) => {
  const { data } = await apiPost<TokenResult>('/login', payload);

  return data;
};

export type ManagerLoginPayload = {
  userId: number;
  companyId?: string | number;
  contractId?: string | number;
};

export const managerLogin = async (payload: ManagerLoginPayload) => {
  const { data } = await apiPost<TokenResult>('/loginAs', payload);

  /* 
    Если менеджер успешно авторизовался, сохраняем новые токены 
    TODO: Разобраться почему бек может вернуть внезапно пустой массив???
  */
  if (data.success && !Array.isArray(data.data)) {
    const { data: user } = data;
    saveToken(user.access_token, user.refresh_token, true);
  }

  return data;
};

export const logout = async () => {
  const { data: response } = await apiGet<TokenResult>('/logout');
  const { data, success, message, code } = response;

  if (success) {
    removeToken();
  }

  return {
    data,
    message,
    success,
    code,
  };
};

export const init = async () => {
  const { data } = await apiGet<InitResult>('/init');
  return data;
};

export const setUserCompany = async (companyId: number) => {
  const { data } = await apiPost<Result<[]>>('/users/company/change', {
    companyId,
  });

  return data;
};

export const initForEpic = () => {
  return apiGet<InitResult>('/init');
};

export const loginForEpic = (payload: UserLogin | { refreshToken: string }) => {
  return apiPost<TokenResult>('/login', payload);
};
