import styled from 'styled-components';
import { Button as ButtonUiKit } from 'components/KrasUIKit/components/Button';

export const Footer = styled.footer`
  font-size: 0.875rem;
  color: white;
  background-color: #4f4f4f;
  min-width: 1000px;
`;
export const MainNav = styled.div`
  display: flex;
  padding: 1.7em 2.85em 2em 2.85em;
`;
export const NavWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  @media (min-width: 1920px) {
    width: 71%;
  }
`;
export const CompanyInfo = styled.div`
  font-size: 0.75rem;
  border-top: 0.5px solid #767676;
  padding: 0.7em 3.2em;
`;
export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  height: 2.7em;
`;
export const LogoWrap = styled(TitleWrap)`
  width: 9.2em;
  flex-shrink: 0;
  margin-right: 4.5em;
  @media (min-width: 1920px) {
    margin-right: 6.4rem;
  }
`;

export const GroupNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
export const NavItem = styled.span`
  a {
    color: inherit;
  }
  :hover,
  :focus,
  :active {
    color: var(--primary);
  }
  margin-bottom: 0.3em;
`;
export const Clarification = styled.div`
  font-size: 0.75rem;
  color: #e0e0e0;
  margin-bottom: 0.5em;
`;

export const Title = styled(TitleWrap)`
  font-weight: bold;
  margin-bottom: 0.2em;
`;
export const Button = styled(ButtonUiKit)`
  min-width: 9.5em;
  margin-top: 1.1em;
`;
export const FeedbackWrap = styled.div`
  margin-top: 0.8em;
`;
