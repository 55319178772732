import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Title } from '../../Title';
import { File } from '../../KrasUIKit';
import { TessaDocument } from '../../../app/types/documents';
import { date } from '../../../helpers/format';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const TessaSections: {
  [key: string]: { title: string; documentType: string };
} = {
  ['/personal/return/universal']: {
    title: 'universalCorrectionDocuments',
    documentType: 'КорУПД',
  },
  ['/personal/return/invoices']: {
    title: 'correctionInvoices',
    documentType: 'КорСФ',
  },
  ['/personal/return/ar2']: {
    title: 'correctionAr2',
    documentType: 'АР-2',
  },
};

const getTessaSectionByPath = (path: string) => {
  const section = TessaSections[path];
  const defaultPath = '/personal/return/invoices';

  return section || TessaSections[defaultPath];
};

export interface Props {
  getDocuments: () => void;
  documents?: TessaDocument[];
  onDownload: (guuid: string) => void;
}

export const TessaDocuments: React.FC<Props> = ({
  getDocuments,
  documents = [],
  onDownload,
}) => {
  const { t } = useTranslation('personal');
  const location = useLocation();
  const section = getTessaSectionByPath(location.pathname);
  useEffect(() => {
    getDocuments();
  }, []);

  const sectionDocuments = documents.filter(
    ({ documentType }) => documentType === section.documentType,
  );

  const downloadHandler = (uuid: string) => {
    onDownload(uuid);
  };
  return (
    <>
      <Title upper level={3}>
        {t(section.title)}
      </Title>
      {sectionDocuments.length > 0 ? (
        sectionDocuments.map(({ id, eventDate, documentType }) => (
          <File
            key={id}
            style={{ marginBottom: 10 }}
            name={`${documentType} от ${date(eventDate)}`}
            onDownload={() => downloadHandler(id)}
          />
        ))
      ) : (
        <Empty />
      )}
    </>
  );
};
