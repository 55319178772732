import { createAsyncThunk } from '@reduxjs/toolkit';
import { message as antdMessage } from 'antd';
import { ActionLogsService } from 'app/services/admin/actionLogs';

export const fetchLogs = createAsyncThunk(
  'admin/actionLogs/getActionLogs',
  async (params: string, { rejectWithValue }) => {
    const { data, message, success } = await ActionLogsService.fetchLogs(
      params,
    );
    if (success && data) {
      return data;
    } else {
      antdMessage.error(message);
      return rejectWithValue(data);
    }
  },
);
