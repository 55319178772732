/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { ProductSlider } from './ProductSlider';
import { Properties } from './Properties';
import { Product } from '../../app/types/products';
import * as S from './styled';
import { AddingProduct } from '../../app/types/cart';
import { Contract } from 'app/types/contracts';
import { Image } from 'app/types/products';
import { OrderType as OrderStateType } from 'containers/ProductDetailModalContainer';
import { useTranslation } from 'react-i18next';
import { Alerts } from './Alerts';
import { OrderTypeTabs } from './OrderTypeTabs';
import { useIsRole } from 'hooks/useIsRole';

export interface Props extends Product {
  login: boolean;
  addingToCart?: boolean;
  order: OrderStateType | null;
  setOrder: React.Dispatch<React.SetStateAction<OrderStateType | null>>;
  onAddToCart: (product: Omit<AddingProduct, 'product_id'>) => void;
  activeContract?: Contract;
  wasAdded: null | 'waiting' | 'cart';
  setWasAdded: React.Dispatch<React.SetStateAction<'waiting' | 'cart' | null>>;
  isOneContract: boolean;
}

export type DetailOrderType =
  | 'products_production'
  | 'products_finished'
  | 'products_coil'
  | 'products_finished_self_marked';

export const ProductDetail: React.FC<Props> = props => {
  const { t } = useTranslation('productDetail');
  const [mainImage, setImages] = useState<Image>(props.images);

  const { isClosed, inStock, forContractType, isDiscontinued } =
    props.conditions;

  const isDemo = useIsRole('demo');

  const inStockInAnyContractType = inStock.find(
    item => item.availability === true,
  );
  const notAvilableByContract =
    (!(isClosed && !inStockInAnyContractType) &&
      forContractType &&
      !props.isOneContract &&
      props.activeContract?.contractType !== forContractType) ||
    (isClosed && isDemo);

  const updateActiveCovering = (coveringId: number): void => {
    const current = props.covering.find(({ id }) => id === coveringId);

    if (!current) return;

    const { images: coveringImages } = current;
    const { preview, detail } = coveringImages;
    if (preview !== '' && detail !== '') {
      setImages(coveringImages);
    } else {
      setImages(props.images);
    }
  };

  useEffect(() => {
    setImages(props.images);
  }, [props.article, props.images]);

  return (
    <>
      <Alerts
        login={props.login}
        notAvilableByContract={notAvilableByContract}
        isDiscontinued={isDiscontinued}
        inStock={!!inStockInAnyContractType}
        isClosed={isClosed}
        activeContract={props.activeContract}
        isOneContract={props.isOneContract}
      />
      <S.BlockedContainer disabled={props.addingToCart}>
        <Row gutter={50}>
          <Col md={8} sm={24}>
            <S.ProductDescription>
              <ProductSlider list={props.additionalImages} main={mainImage} />
              <Properties
                list={[
                  {
                    name: t('orderParameters:article'),
                    value: props.article,
                    code: 'article',
                  },
                  {
                    name: t('orderParameters:diameter'),
                    value: `ø ${props.diameter}`,
                    code: 'diameter',
                  },
                  ...props.params,
                ]}
                fullDescr={props.fullDescr}
              />
            </S.ProductDescription>
          </Col>
          <Col md={16} sm={24}>
            <OrderTypeTabs
              {...props}
              updateActiveCovering={updateActiveCovering}
              notAvilableByContract={notAvilableByContract}
            />
          </Col>
        </Row>
      </S.BlockedContainer>
    </>
  );
};
