import { createSlice } from '@reduxjs/toolkit';
import { BlanksType } from 'features/Blanks';
import { fetchBlanks } from './actions';

export interface BlanksState {
  loading: boolean;
  list: BlanksType;
}

export const blanksInitialState: BlanksState = {
  loading: true,
  list: [],
};

const blanksSlice = createSlice({
  name: 'blanks',
  initialState: blanksInitialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(fetchBlanks.pending, state => {
      state.loading = true;
    });
    addCase(fetchBlanks.fulfilled, (state, { payload }) => {
      state.list = payload.items;
      state.loading = false;
    });
  },
});

const { reducer } = blanksSlice;

export { reducer as blanks };
